export const filterHighlightFields = [
  "ALD",
  "LST",
  "PTS",
  "PT",
  "PNC",
  "AC",
  "PRC",
  "PCTPC",
  "DSEP",
  "DSEP_CST",
  "DSEP_EST",
  "DSEP_VST",
  "DSEP_PST",
  "DSPCT",
  "DSPCT_RGCN",
  "DSPCT_AOST",
  "DSPCT_RGNCN",
  "DSPCT_NT",
  "DSPCT_NDSCN",
  "PN",
  "AN",
  "PRN",
  "AD",
  "AY",
  "PCTAD",
  "PCTPD",
  "PRD",
  "EPRD",
  "EPRY",
  "PRY",
  "PD",
  "PY",
  "ED",
];
