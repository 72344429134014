import React from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const SecondPart = ({ features, activeIndex, handleToggle }) => (
  <div className="second_part d-flex px-4 py-4">
    <img
      className="second_part_img mr-3"
      alt=""
      src={RWIRE_IMAGES.RwireSecondSectionImage}
    />

    <div>
      <div className="first_block mb-2 py-2  px-2">
        <p className="mb-2 first_block_heading">Who We Are</p>
        <p className="first_block_para">
          Researchwire is a leading patent database platform, designed to serve
          the needs of professionals across various industries. Our team
          comprises seasoned experts in intellectual property law, data science,
          and technology, all united by a common goal: to make patent
          information more accessible and actionable for everyone.
        </p>
      </div>

      <div className="second_block py-2 px-2 mb-2">
        <p className="second_block_heading mb-2">What We Do</p>
        <p className="second_block_para">
          Our platform offers an extensive collection of patent documents from
          around the globe, meticulously curated and constantly updated to
          ensure you have access to the latest information. Whether you are a
          startup looking to navigate the patent landscape, an established
          corporation seeking to monitor competitors, or a legal professional
          providing strategic advice, Researchwire provides the tools and
          insights you need.
        </p>
      </div>

      <div className="our_features_main">
        <div className="ml-2 pt-2 our_feature_heading">Our Features</div>
        <div className="accordion px-3 py-4">
          {features.map((feature, index) => (
            <div key={index} className="accordion-item mb-2">
              <div
                className="accordion-title ml-2"
                onClick={() => handleToggle(index)}
              >
                <div>
                  <img
                    className="mr-2 accordion_tick_image"
                    src={RWIRE_IMAGES.RwireTickImage}
                    alt=""
                  />
                  {feature.title}
                </div>
                <span className="mr-3">
                  {activeIndex === index ? (
                    <img src={RWIRE_IMAGES.RwireMinusImage} alt="collapse" />
                  ) : (
                    <img src={RWIRE_IMAGES.RwirePlusImage} alt="expand" />
                  )}
                </span>
              </div>
              <div
                className={`accordion-content ${
                  activeIndex === index ? "active ml-4" : ""
                }`}
              >
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default SecondPart;
