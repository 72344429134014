import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { HighlightModal } from "../../common/highlight";
import { setDetails, setFilterFields } from "../../action/result-table";
import { setHighlight } from "../../action/highlight";

const Container = (props) => {
  const { isVisibleHighlightModal, onSetHighlight } = props;

  return (
    <Modal
      className="modalClassFilter"
      static="static"
      keyboard={false}
      open={isVisibleHighlightModal}
    >
      <div
        className="highlight-modal-close-btn"
        onClick={() => onSetHighlight({ isVisibleHighlightModal: false })}
      >
        <IoMdClose className="modal__close" title="Close" />
      </div>
      <HighlightModal {...props} />
    </Modal>
  );
};

const mapStateToProps = ({
  resultTable: { isExact, displayData: result },
  highlight,
}) => ({
  ...highlight,
  isExact,
  result,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetDetails: setDetails,
      onSetFilter: setFilterFields,
      onSetHighlight: setHighlight,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
