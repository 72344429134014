import React from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const SecondSection = ({ services, selectedService, handleClick }) => (
  <div className="second_section px-5 py-4">
    <div className="d-flex row">
      <div className="services_section py-3 col-md-4">
        <div className="service_left_list ml-2 mb-2">Our Services</div>
        <div className="services_btns ml-4 mr-4">
          {services.map((service, index) => (
            <div
              key={index}
              className={`service-btn d-flex px-2 ${
                selectedService.title === service.title ? "selected" : ""
              }`}
              onClick={() => handleClick(service)}
            >
              <div>{service.title}</div>
              <div className="">
                <img
                  src={RWIRE_IMAGES.RwireServicesArrow}
                  alt=""
                  className="services_arrow ml-2"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedService && (
        <div className="img_part col-md-8">
          <img className="img_size ml-3" alt="" src={selectedService.image} />
          <p className="img_heading mt-2 ml-3">{selectedService.title}</p>
          <p className="img_para ml-3">{selectedService.description}</p>
        </div>
      )}
    </div>
  </div>
);

export default SecondSection;
