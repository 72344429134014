import React from 'react';
import "./style.scss";
import Slider from "react-slick";

const ImageSlider = ({ images, settings }) => (
    <div className="image-section position-relative">
      <div className="fixed-text-container px-4">
        <div className="mb-4 image_slider_heading">Join Our Community</div>
        <p className="image_slider_para">
          Become a part of the Researchwire community and unlock the full potential of patent data. Whether you are driving innovation, securing your inventions, or exploring new market possibilities, Researchwire is here to support your journey.
        </p>
      </div>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="position-relative">
            <img src={image} alt={`Slide ${index + 1}`} className="carousel-image img-fluid" />
          </div>
        ))}
      </Slider>
    </div>
  );

export default ImageSlider