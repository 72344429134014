export const otherHighlightWordsColors = [
  "#fffb00",
  "#BBE9DB",
  "#D0CECE",
  "#DAFF8A",
  "#DFB7A1",
  "#AECCC6",
  "#4EAEA5",
  "#83D9EF",
  "#FEFF94",
  "#DA77FB",
  "#CDAA2F",
  "#BCFFF2",
  "#FFC0D0",
  "#FFE0E0",
  "#EEFCA9",
  "#B7E576",
  "#ACE6F6",
  "#62D2A2",
  "#DFBAF7",
  "#FA7F7F",
  "#E0FFCD",
  "#FFEBBB",
  "#FFCAB0",
  "#F4CB81",
  "#A0E4B0",
  "#83CC61",
  "#FFE6CC",
  "#F1F592",
  "#9DF3C4",
  "#A6E3E9",
  "#FFE6EB",
  "#769FCD",
  "#F8FBA2",
  "#ECFFC1",
  "#C7D154",
  "#A7DEB9",
  "#F8F1E5",
  "#E4D183",
  "#FFA63E",
  "#D6E6F2",
  "#FFE3B9",
  "#C2FCD9",
  "#92CCE1",
  "#2794EB",
  "#81DDFF",
  "#B5B2FF",
  "#FFE1A1",
  "#FCFFCC",
  "#CBF1F5",
  "#D3E785",
  "#B0DF7E",
  "#9FA4FA",
  "#EFDFBF",
  "#DDE7F2",
  "#00DB47",
  "#D8C4C4",
  "#60A9A6",
  "#B8FFD0",
  "#B9D7EA",
  "#ECFFC1",
  "#EB8AE6",
  "#B3F7F6",
  "#FDFFCD",
  "#7FDFD4",
  "#FBAC91",
  "#5C7EFA",
  "#D7FBE8",
  "#B4DA85",
  "#F38181",
  "#F5FAC8",
  "#8198F7",
  "#F0ECE2",
  "#FFD782",
  "#C7B198",
  "#DEFCFC",
  "#CAABD8",
  "#FFCCCC",
  "#C2FAF1",
  "#4993FA",
  "#A8D8EA",
  "#FFFFD2",
  "#F6F39F",
  "#F392F2",
  "#B062EA",
  "#E5F9BD",
  "#BEFF3B",
  "#CBBCF6",
  "#EBAF81",
  "#FFDEDE",
  "#5BBFE0",
  "#118DF0",
  "#DFD3C3",
  "#46C3A7",
  "#FCBAD3",
  "#FF9CFE",
  "#F4FF61",
  "#FFA4A4",
  "#D4CEB0",
  "#5C7EFA",
  "#C1F880",
  "#FF6BD6",
];

export const queryHighlightWordsColors = otherHighlightWordsColors;

export const dashboardKeyFeaturesColor = [
  "#D7FCF1",
  "#EEEBEB",
  "#F2FFD6",
  "#D3E7CE",
  "#BCFFF2",
  "#FFDCE5",
  "#D8EBBE",
  "#F0DBFE",
  "#E0FFCD",
  "#FEEDC5",
  "#C8EED1",
  "#FFE6EB",
  "#D5D9FB",
  "#ECFFC1",
  "#FFD9F6",
  "#DEFAFD",
  "#C5FCF2",
  "#E1DDFA",
  "#EDEEEE",
];
