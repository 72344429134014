import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { Image } from "../../../ui-components";
import { EditBlackIcon, deleteIcon } from "../../../assets/images/report";
import checkboxIcon from "../../../assets/images/RightTick.svg";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../../common/user-roles";
const DataRow = (props) => {
  const {
    rowIndex,
    headers,
    editingCell,
    field,
    mapping,
    row,
    handleCellChange,
    hideEditingAndDeletingUI,
    handleCellClick,
    onDeleteCell,
    data,
  } = props;
  const getCustomValue = (row, header) => {
    const firstParam = header.split("/")[0];
    const secondParam = header.split("/")[1];
    return `${row[firstParam]}/${row[secondParam]}`;
  };
  const [localRow, setLocalRow] = useState({});
  useEffect(() => {
    setLocalRow(row);
  }, [row]);
  const handleChange = (e, header) => {
    setLocalRow({ ...localRow, [mapping[header]]: e.target.value });
  };
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  return (
    <>
      {" "}
      <tr key={rowIndex} className="">
        {headers.map((header, colIndex) => (
          <td
            key={colIndex}
            className={`${
              editingCell?.row === rowIndex && editingCell?.field === field
                ? "editing-td"
                : "read-only-td"
            }`}
          >
            {
              <ContentEditable
                className="editable-cell report-string-cell-height"
                disabled={
                  editingCell?.row !== rowIndex || editingCell?.field !== field
                }
                html={
                  mapping[header].includes("/")
                    ? getCustomValue(localRow, mapping[header])
                    : localRow[mapping[header]]
                        ?.toString()
                        ?.replaceAll("&nbsp;", " ")
                }
                onChange={(e) => handleChange(e, header)}
                onPaste={(e) => {
                  e.preventDefault();
                  const text = e.clipboardData.getData("text");
                  document.execCommand("insertText", false, text);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  const textData = e.dataTransfer.getData("text/plain");
                  document.execCommand("insertText", false, textData);
                }}
              />
            }
          </td>
        ))}
        <td>
          {!hideEditingAndDeletingUI && (
            <div className="d-flex gap-1 cursor-pointer">
              {editingCell?.row === rowIndex && editingCell?.field === field ? (
                <div
                  className="p-1 act-btns"
                  onClick={() => {
                    handleCellClick(null, "");
                    handleCellChange(rowIndex, localRow);
                  }}
                  title="Save Row"
                >
                  <Image
                    src={checkboxIcon}
                    style={{ width: "16px", height: "16px" }}
                  />
                </div>
              ) : (
                <div
                  className={`p-1 act-btns ${
                    userProjectAccess == ACCESS_VIEWER ? "d-none" : ""
                  }`}
                  onClick={() => handleCellClick(rowIndex, field)}
                  title="Edit Row"
                >
                  <Image
                    src={EditBlackIcon}
                    style={{ width: "16px", height: "16px" }}
                  />
                </div>
              )}
              <div
                className={`p-1 act-btns ${
                  userProjectAccess == ACCESS_VIEWER ? "d-none" : ""
                }`}
                title="Delete Row"
                onClick={() => onDeleteCell(data, field, rowIndex)}
              >
                <Image
                  src={deleteIcon}
                  style={{ width: "16px", height: "16px" }}
                />
              </div>
            </div>
          )}
        </td>
      </tr>
    </>
  );
};

export default DataRow;
