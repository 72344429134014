import { Modal, SelectPicker } from "rsuite";
import React, { useState, useEffect } from "react";
import CloseIcon from "@rsuite/icons/Close";
import { Message, toaster } from "rsuite";

const RwireSaveExistingTemplate = (props) => {
  const {
    existingTemplateModal,
    setExistingTemplateModal,
    onGetExportTemplates,
    selectedFields,
    fieldNameFromShortCodeExport,
    onSetSelectedField,
    onUpdateTemplate,
  } = props;

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (existingTemplateModal) {
      loadExportTemplates();
    }
  }, [existingTemplateModal]);

  const loadExportTemplates = async () => {
    try {
      const templatesData = await onGetExportTemplates(1);
      setTemplates(templatesData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateTemplate = async () => {
    if (selectedTemplate) {
      try {
        const updatedFields = selectedFields.join(",");

        const updatedTemplate = {
          name: selectedTemplate.name,
          fields: updatedFields,
        };

        const response = await onUpdateTemplate({
          id: selectedTemplate.id,
          body: updatedTemplate,
        });
        toaster.push(<Message type="success">Template updated successfully</Message>)
        setExistingTemplateModal(false);
        setSelectedTemplate(null);
      } catch (error) {
        console.error("Error updating template:", error);
      }
    }
    else {
      setValidationMessage("Please select the template");
    }
  };

  return (
    <>
      {existingTemplateModal && (
        <Modal
          className="modalClassFilterLoad"
          backdrop="true"
          keyboard={false}
          open={true}
          onClose={() => {
            setExistingTemplateModal(!existingTemplateModal);
          }}
        >
          <div className="save-as-existing">
            <div className="d-flex justify-content-between download-center-header light-blue-background">
              <div className="download-center-text">Edit Export Template</div>
              <div
                className="cursor-pointer close-btn"
                title="Close"
                onClick={() => {
                  setExistingTemplateModal(!existingTemplateModal)
                  setValidationMessage("")
                }}
              >
                <CloseIcon />
              </div>{" "}
            </div>
            <div className="loadtempltedexport">
              <div className="Properties-export-load">Select Template</div>
              <div className="template-list">
                <SelectPicker
                  appearance="default"
                  placeholder="Select Template"
                  className="template-dropdown-list"
                  value={selectedTemplate ? selectedTemplate.id : ""}
                  onChange={(value) => {
                    setSelectedTemplate(
                      templates.find((template) => template.id === value)
                    )
                    if (value != "") {
                      setValidationMessage("")
                    }
                  }
                  }
                  searchable
                  cleanable={false}
                  data={templates.map((template) => ({
                    label: template.name,
                    value: template.id,
                  }))}
                  onSearch={(keyword) => setSearchKeyword(keyword)}
                  searchKeyword={searchKeyword}
                />
              </div>
              {validationMessage && (
                <div className="validation-update-msg text-danger">{validationMessage}</div>
              )}

              <div className="selected-filed-save-tempate-main existing-selected ">
                <div className="select-export">
                  Selected Fields ({selectedFields.length})
                </div>
                <div className="selected-filed-save-tempate d-flex justify-content-start">
                  {selectedFields.map((field, index) => (
                    <React.Fragment key={index}>
                      <div className="select-list">
                        {fieldNameFromShortCodeExport(field)}
                      </div>
                      {index !== selectedFields.length - 1 && (
                        <span>,&nbsp;</span>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-between footer-load">
              <div></div>
              <div className="d-flex justify-content-between">
                <button
                  className="email-send-download-cancel"
                  onClick={() => {
                    setExistingTemplateModal(!existingTemplateModal);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="email-send-download-save"
                  onClick={handleUpdateTemplate}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default RwireSaveExistingTemplate;
