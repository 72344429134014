import React, { useEffect, useState } from "react";
import replaceSpecialChars from "../../../common/replace-special-chars";
import truncateText from "../../../common/truncate-text";
import { HighlightFromQueryOrManually } from "../../../container/highlight";

function RwireAbstract(props) {
  const { displayAbstract, index } = props;
  const [abstract, setAbstract] = useState(displayAbstract);
  const [abstractToggle, setAbstractToggle] = useState(false);

  useEffect(() => {
    const button = document.querySelector(`.abstract_no_${index}`);
    button && button.addEventListener("click", handleAbstractToggle);
    return () => {
      button && button.removeEventListener("click", handleAbstractToggle);
    };
  });
  useEffect(() => {
    let tempAbstract = "";

    if (!displayAbstract) {
      tempAbstract = "Data not available";
    } else if (displayAbstract.length < 1000) {
      tempAbstract = replaceSpecialChars(displayAbstract);
    } else if (abstractToggle) {
      // for ...less
      var span = `<span class="font-weight-bold cursor-pointer abstract_no_${index} ">  Less</span></p>`;
      // replace last occurance of </p> with span
      tempAbstract = replaceSpecialChars(displayAbstract).replace(
        /(<\/p>)(?![\s\S]*<\/p>)/,
        span
      );
    } else {
      // for ...more
      tempAbstract =
        replaceSpecialChars(truncateText(displayAbstract)) +
        `<span class="font-weight-bold cursor-pointer abstract_no_${index} ">  More</span>`;
    }

    tempAbstract = tempAbstract.replace(/<\/?(p|li|ul)[^>]*>/g, "");

    setAbstract(tempAbstract);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abstractToggle, displayAbstract]);
  const handleAbstractToggle = () => {
    setAbstractToggle(!abstractToggle);
  };

  return (
    <td className="result-table-abstract">
      <div
        className={`result-table-abstract dynamic-height ${
          !displayAbstract ? "no-data" : ""
        }`}
      >
        <HighlightFromQueryOrManually>{abstract}</HighlightFromQueryOrManually>
      </div>
    </td>
  );
}

export default RwireAbstract;
