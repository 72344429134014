const initialState = {
  userAnnotations: [],
  stringsHighlights: [],
  stringsComments: [],
  openTextarea: false,
  isEditingComment: false,
  languageSelected: "EN",
  isSummaryFetching: false,
  isKeyfeaturesExtracting: false,
  isClaimMappingsGenerating: false,
};

const fullView = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FULL_VIEW_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default fullView;
