const TableHeader = ({ columnName }) => {
  return (
    <div className="header_wrapper table-row rwire-tableheader">
      <div className="table-column definition justify-content-start">
        <div>Synonym: {columnName}</div>
      </div>{" "}
    </div>
  );
};

export default TableHeader;
