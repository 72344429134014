import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TimeLoader } from "../../../assets/images/report";
import { TickImage } from "../../../assets/images/report";
import "./style.scss";

const AcceptInvitation = () => {
  const [seconds, setSeconds] = useState(10);
  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/en/login");
    }
  }, [seconds, navigate]);

  const handleLoginClick = () => {
    navigate("/en/login");
  };
  return (
    <>
      <div className="parent justify-content-center">
        <div className="card_bg">
          <div className="invitation_banner">
            <img className="tick_sign my-3" src={TickImage} alt="" />
            <p className="invitation_sucess mb-2">SUCESS</p>
            <p className="invitation_para px-5">
              The project has been successfully added to your account. Please
              login and check the existing project for access
            </p>
          </div>

          <div className="my-5">
            <span className="start_text"> Let’s Get It Started</span>
            <div className="timer">
              {`00:${seconds < 10 ? "0" : ""}${seconds}`}
            </div>
            <div>
              <img className="loader_img" src={TimeLoader} alt="" />
            </div>
            <button className="goto_login_btn" onClick={handleLoginClick}>
              Go to Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AcceptInvitation;
