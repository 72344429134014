import React, { useState } from "react";

const TextTabContent = (props) => {
  const {
    onCheckIfPatentAvailable,
    onFindKeywordsFromText,
    onSetResultKeywords,
  } = props;
  const [text, setText] = useState("");

  const searchForPatent = async () => {
    if (!text) return;
    try {
      const response = await onFindKeywordsFromText(text);
      onSetResultKeywords(response);
    } catch (error) {
      // Handle errors
      console.error("Error searching for patent:", error);
    }
  };
  return (
    <div className="patent-tab-content">
      <div className="flex-grow-1 input-section">
        <div className="input-container h-100">
          <textarea
            className={`w-100 patent-input h-100 `}
            onChange={(e) => {
              setText(e.target.value);
            }}
            placeholder="Type or Paste text to Generate Synonyms"
            value={text}
            onKeyDown={(e) => e.key === "Enter" && searchForPatent()}
          />

          {/* Pass a valid image source */}
        </div>
      </div>
      <button className="ms-auto" onClick={searchForPatent}>
        Search
      </button>
    </div>
  );
};

export default TextTabContent;
