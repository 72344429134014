import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setApp } from "../action/app";
import { setQuery } from "../action/search-query";
import { setClassesModal } from "../action/classes-suggestor";
import RwireHome from "../pages/rwire-home";
import { ACCESS_VIEWER } from "../common/user-roles";
import { useNavigate } from "react-router-dom";


const Container = (props) => {
  const navigate = useNavigate();
  if (sessionStorage.getItem("userProjectAccess") === ACCESS_VIEWER && sessionStorage.getItem("projectMode") === 'true') {

    navigate("/en/report")
  }
  else {
    return <RwireHome {...props} />;
  }
};

const mapStateToProps = ({ app }) => ({
  ...app
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetApp: setApp,
      onSetQuery: setQuery,
      onSetClassesModal: setClassesModal
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Container);
