/* eslint-disable react/prop-types */
const Pill = ({ image, text, onClick ,color }) => {
  return (
    <span className="user-pill" style={{ color }} >
      <span>{text} </span>
      <span className="fw-bold" title="Remove" onClick={onClick}>
        x
      </span>
    </span>
  );
};

export default Pill;
