import React, { useRef, useEffect, useState } from "react";
const colorPallets = [
  "#4D4D4D",
  "#999999",
  "#F44E3B",
  "#FE9200",
  "#FCDC00",
  "#DBDF00",
  "#A4DD00",
  "#68CCCA",
  "#73D8FF",
  "#AEA1FF",
  "#FDA1FF",
  "#333333",
  "#808080",
  "#D33115",
  "#E27300",
  "#FCC400",
  "#B0BC00",
  "#68BC00",
  "#16A5A5",
  "#009CE0",
  "#7B64FF",
  "#FA28FF",
  "#000000",
  "#666666",
  "#9F0500",
  "#C45100",
  "#FB9E00",
  "#808900",
  "#194D33",
  "#0C797D",
  "#0062B1",
  "#653294",
  "#AB149E",
];

const TextColorsMenu = ({ handleAction }) => {
  const menuRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleColorClick = (e, color) => {
    e.stopPropagation();
    handleAction(e, "foreColor", color);
    setSelectedColor(color);
  };
  return (
    <div>
      <div
        ref={menuRef}
        className="d-flex justify-content-start flex-wrap gap-2 py-1"
      >
        {colorPallets.map((color, index) => (
          <button
            key={index}
            className="border-0"
            style={{
              backgroundColor: color,
              height: 14,
              width: 14,
            }}
            onClick={(e) => handleColorClick(e, color)}
          />
        ))}
      </div>

      <div>
        <div
          style={{
            backgroundColor: selectedColor || "#000000",
            width: "12px",
            height: "12px",
            display: "inline-block",
          }}
        ></div>
        <span className="ml-1 selected_color">
          {selectedColor || "#000000"}
        </span>
      </div>
    </div>
  );
};

export default TextColorsMenu;
