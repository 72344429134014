import React from "react";
import { Image } from "../ui-component";
import {
  CSVFile,
  DeleteBinIcon,
  DownloadIcon,
  ExcelFile,
  ImageFile,
  JSONFile,
  Mp3File,
  Mp4File,
  PDFFile,
  PPTFile,
  TextFile,
  WordFile,
  XMLFile,
  ZipFile,
} from "../../assets/images/report";
import { downloadFileUsingS3URL } from "../functions/notes/utils";
import { Loader } from "rsuite";
import {
  isAudioFile,
  isCSVFile,
  isDOCFile,
  isImageFile,
  isJSONFile,
  isPDFFile,
  isPPTXFile,
  isTXTFile,
  isVideoFile,
  isXLSFile,
  isXMLFile,
  isZIPFile,
} from "../functions/report-collab/file-type-helpers";

const ListOfUploadedMedia = ({
  uploadedFiles,
  setUploadedFiles,
  isShowList,
  onSetfilesToBeRemovedFromDB,
  filesToBeRemovedFromDB,
}) => {
  const handleDownloadFile = (file, index) => {
    downloadFileUsingS3URL(file.s3FilePath, file.orignalFilename, index);
  };

  const handleRemoveFile = (file) => {
    const updatedFiles = uploadedFiles.filter(
      (f) => f.name !== file.name || f.storeFilename !== file.storeFilename
    );
    if (file.storeFilename && onSetfilesToBeRemovedFromDB) {
      onSetfilesToBeRemovedFromDB([...filesToBeRemovedFromDB, file.id]);
    }
    setUploadedFiles(updatedFiles);
  };

  function getFileType(file) {
    if (file.s3FilePath) {
      const fileExtension = file.storeFilename.match(/\.(.+)$/);
      if (fileExtension && fileExtension.length > 1) {
        return fileExtension[1].toLowerCase();
      } else {
        return "File type not found";
      }
    } else {
      return file.type;
    }
  }

  return (
    <div className="d-flex flex-column align-items-center">
      {uploadedFiles &&
        uploadedFiles.length > 0 &&
        uploadedFiles.map((file, index) => (
          <div
            key={index}
            className="file-item position-relative d-flex justify-content-between align-items-center"
          >
            <div className="d-flex gap-2" style={{ width: "70%" }}>
              <div style={{ width: "40px" }}>
                {isImageFile(getFileType(file)) ? (
                  <img
                    src={ImageFile}
                    alt={file.orignalFilename}
                    className="preview-image"
                    effect="blur"
                  />
                ) : null}
                {isPDFFile(getFileType(file)) ? (
                  <Image
                    src={PDFFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isAudioFile(getFileType(file)) ? (
                  <Image
                    src={Mp3File}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isVideoFile(getFileType(file)) ? (
                  <Image
                    src={Mp4File}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}

                {isTXTFile(getFileType(file)) ? (
                  <Image
                    src={TextFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isCSVFile(getFileType(file)) ? (
                  <Image
                    src={CSVFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isJSONFile(getFileType(file)) ? (
                  <Image
                    src={JSONFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isXMLFile(getFileType(file)) ? (
                  <Image
                    src={XMLFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isPPTXFile(getFileType(file)) ? (
                  <Image
                    src={PPTFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isDOCFile(getFileType(file)) ? (
                  <Image
                    src={WordFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isXLSFile(getFileType(file)) ? (
                  <Image
                    src={ExcelFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
                {isZIPFile(getFileType(file)) ? (
                  <Image
                    src={ZipFile}
                    title={file.name}
                    className="file-icon"
                  ></Image>
                ) : null}
              </div>
              <div
                className=""
                style={{
                  wordBreak: "break-all",
                  fontWeight: 600,
                  fontSize: "11px",
                }}
              >
                {" "}
                {file.orignalFilename ? file.orignalFilename : file.name}
              </div>
            </div>
            <div className="d-flex align-items-center">
              {isShowList ? (
                <>
                  <button
                    onClick={() => handleDownloadFile(file, index)}
                    title="Download"
                    id={`collab-file-download-icon-${index}`}
                  >
                    <Image src={DownloadIcon} />
                  </button>
                  <button>
                    <Loader
                      id={`collab-file-download-loader-${index}`}
                      size="sm"
                      style={{ display: "none", paddingRight: "15px" }}
                    ></Loader>
                  </button>
                </>
              ) : (
                <button onClick={() => handleRemoveFile(file)} title="Remove">
                  <Image src={DeleteBinIcon} />
                </button>
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ListOfUploadedMedia;
