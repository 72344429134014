import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const headingText = {
  bold: true,
  fontSize: 12,
  fontFamily: "Arial",
  fontColor: "366092",
  horizontalAlignment: "left",
  verticalAlignment: "center",
};
const normalText = {
  fontSize: 10,
  fontFamily: "Arial",
  fontColor: "000000",
  horizontalAlignment: "left",
  verticalAlignment: "center",
};
export const commonStyles = (sheet) => {
  sheet
    .range("A1:B1")
    .merged(true)
    .style({ ...headingText, italic: true });

  sheet.range("A3:B3").merged(true).style(headingText);
  sheet.range("A4:B4").merged(true).style(normalText);

  sheet.column("A").width(20);
  sheet.column("B").width(90);
  sheet.column("C").width(20);
  // styles for some normal text (italic)
  sheet.cell("A4").style({
    italic: true,
  });

  sheet.row("5").height(25);
  sheet.row("1").height(40);
  sheet.row("2").height(1);

  // styles for the rest of the table
  const lastRowNumber = sheet.usedRange().endCell().rowNumber();

  const dataRange = `A5:B${lastRowNumber}`;
  sheet.range(dataRange).style({
    border: true,
    fontSize: 10,
    fontFamily: "Arial",
  });
  sheet.range(`A5:A${lastRowNumber}`).style({
    horizontalAlignment: "center",
  });
  sheet.cell(`C1`).value("<< Back to contents").hyperlink(`Contents!A1`).style({
    bold: true,
    fontColor: "0048a2",
    underline: true,
    fontFamily: "Arial",
  });
  // styles for the table header
  sheet.range("A5:B5").style({
    bold: true,
    fill: "4f81bd",
    border: true,
    fontColor: "ffffff",
    fontSize: 12,
  });
};

export const keywordStyles = (sheet, workbook) => {
  sheet.freezePanes(4, 2);
};
export const keyFeaturesStyles = (sheet) => {
  sheet.freezePanes(4, 2);
};
export const assigneesStyles = (sheet) => {
  sheet.freezePanes(4, 2);
};
export const inventorsStyles = (sheet) => {
  sheet.freezePanes(4, 2);
};
export const usClassesStyles = (sheet) => {
  sheet.freezePanes(4, 2);
};
export const cpcClassesStyles = (sheet) => {
  sheet.freezePanes(4, 2);
};
export const ipcClassesStyles = (sheet) => {
  sheet.freezePanes(4, 2);
};
export const stringsStyles = (sheet) => {
  sheet.column("B").width(30);
  sheet.column("A").width(90);
  sheet.freezePanes(4, 2);
};
export const ContentStyles = (sheet, workbook, data) => {
  sheet
    .range("A1:B1")
    .merged(true)
    .style({ ...headingText, italic: true });

  sheet.column("A").width(50);
  //   sheet.range("A3:B3").merged(true).style(headingText);
  sheet.range("A3:B3").merged(true).style({
    bold: true,
    fill: "4f81bd",
    border: true,
    fontColor: "ffffff",
    fontSize: 14,
    horizontalAlignment: "center",
  });
  sheet.row("3").height(25);
  sheet.row("1").height(40);
  sheet.row("2").height(10);

  // styles for the rest of the table
  const lastRowNumber = sheet.usedRange().endCell().rowNumber();

  const dataRange = `A4:A${lastRowNumber}`;
  sheet.range(dataRange).style({
    fontSize: 12,
    fontFamily: "Arial",
    horizontalAlignment: "left",
  });

  for (let currentRow = 4; currentRow <= lastRowNumber; currentRow++) {
    sheet.row(currentRow).height(20);
    sheet.cell(`A${currentRow}`).style({
      leftBorder: true,
    });
    sheet.cell(`B${currentRow}`).style({
      rightBorder: true,
    });
  }
  sheet.range(`A${lastRowNumber}:B${lastRowNumber}`).style({
    bottomBorder: true,
  });

  // Filter out items without a sheetName
  const sheetsWithData = data
    .filter((item) => item.sheetName)
    .filter((item) => item.details.length > 0);

  // Extract sheetNames
  const sheetNamesArray = sheetsWithData.map((item) => item.sheetName);

  // eslint-disable-next-line array-callback-return
  sheetNamesArray.map((name, index) => {
    sheet
      .cell(`B${4 + index}`)
      .value(">>")
      .hyperlink(`${name}!A1`)
      .style({
        bold: true,
        fontColor: "0048a2",
        underline: true,
        fontFamily: "Arial",
      });
  });
};

export const coverPageStyles = (sheet, workbook) => {
  sheet.column("A").width(5);
  sheet.column("B").width(80);
  sheet.row("4").height(50);
  sheet.row("5").height(30);
  sheet.row("6").height(20);
  sheet.row("7").height(20);
  sheet.row("10").height(20);
  const cellA3 = sheet.cell("B4");
  // set a cell value to rich text
  cellA3.value(new XlsxPopulate.RichText());
  // add two rich text fragments
  cellA3
    .value()
    .add("research", {
      fontFamily: "Montserrat",
      fontColor: "0048a2",
      fontSize: 48,
      bold: true,
    })
    .add("wire", {
      fontFamily: "Courgette",
      fontColor: "999999",
      fontSize: 48,
      bold: true,
    });

  sheet.cell("B5").style({
    fontColor: "999999",
    fontSize: 12,
    bold: true,
    italic: true,
    fontFamily: "Montserrat",
    verticalAlignment: "top",
  });

  sheet.range("B6:B7").style({
    fontColor: "000000",
    fontSize: 14,
    bold: true,
    verticalAlignment: "center",
    fontFamily: "Montserrat",
  });

  sheet.cell("B10").style({
    fontColor: "ffffff",
    fontSize: 12,
    bold: true,
    border: true,
    fill: "4f81bd",
    fontFamily: "Arial",
    verticalAlignment: "center",
    horizontalAlignment: "left",
  });
  sheet.cell("B11").style({
    fontColor: "000000",
    fontSize: 10,
    border: true,
    fontFamily: "Arial",
    verticalAlignment: "center",
    horizontalAlignment: "left",
  });
};
