import React from "react";
import { useNavigate } from "react-router-dom";
import { HighlightFromQueryOrManually } from "../../../container/highlight";
function FamilyLink(props) {
    const {
        familyList = "",
        onSetFilter,
        index,
        length
    } = props;
    const navigate = useNavigate();
    // const onSetPatentLink = (PN) => {
    //     onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
    //     sessionStorage.setItem("patentId", PN);
    //     navigate(`/en/patent-view/${PN}`);
    // };
    return (
        <span className="family-link" onClick={() => {
            // onSetPatentLink(familyList)
        }}>
            <HighlightFromQueryOrManually component="span">
                {`${familyList} ${index === (length - 1) ? "" : "| "}`}
            </HighlightFromQueryOrManually>
        </span>

    );
}

export default FamilyLink;