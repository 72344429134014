import { Block, Image, Heading, Slider } from "../../../ui-components";
import {
  nextIcon,
  prevIcon,
  assigneeIcon,
  relevantResultIcon,
  keywordIcon,
} from "../../../assets/images/report";

const NextArrow = ({ onClick }) => {
  return (
    <Block className="next-arrow" onClick={onClick}>
      <Image src={nextIcon} />
    </Block>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <Block className="prev-arrow" onClick={onClick}>
      <Image src={prevIcon} />
    </Block>
  );
};

const CountSlider = (props) => {
  const {
    relevantResult,
    assignee,
    keyFeature,
    string,
    inventor,
    keywords,
    isOpenSidebar,
  } = props;

  return (
    <Block
      className={`slider_wrapper ${isOpenSidebar ? "side_bar_expanded" : ""}`}
    >
      <Heading as="h3" className="current_status">
        Current Status
      </Heading>
      <Slider nextIcon={<NextArrow />} prevIcon={<PrevArrow />}>
        <Block
          className={` ${isOpenSidebar ? "count_box_expanded" : "count_box"}`}
        >
          <Block className="box-image-wrapper">
            <Image src={relevantResultIcon} alt="" />
          </Block>
          <Block className="text-content count">{relevantResult?.count}</Block>
          <Block className="text-content">Relevant Result</Block>
        </Block>
        <Block
          className={` ${isOpenSidebar ? "count_box_expanded" : "count_box"}`}
        >
          <Block className="box-image-wrapper">
            <Image src={assigneeIcon} alt="" />
          </Block>
          <Block className="text-content count">{assignee?.count}</Block>
          <Block className="text-content">Assignee</Block>
        </Block>
        <Block
          className={` ${isOpenSidebar ? "count_box_expanded" : "count_box"}`}
        >
          <Block className="box-image-wrapper">
            <Image src={keywordIcon} alt="" />
          </Block>
          <Block className="text-content count">{keyFeature?.count}</Block>
          <Block className="text-content">Key Features</Block>
        </Block>
        <Block
          className={` ${isOpenSidebar ? "count_box_expanded" : "count_box"}`}
        >
          <Block className="box-image-wrapper">Str.</Block>
          <Block className="text-content count">{string?.count}</Block>
          <Block className="text-content">String</Block>
        </Block>
        <Block
          className={` ${isOpenSidebar ? "count_box_expanded" : "count_box"}`}
        >
          <Block className="box-image-wrapper">Key.</Block>
          <Block className="text-content count">{keywords?.count}</Block>
          <Block className="text-content">Keywords</Block>
        </Block>
        <Block
          className={` ${isOpenSidebar ? "count_box_expanded" : "count_box"}`}
        >
          <Block className="box-image-wrapper">Inv.</Block>
          <Block className="text-content count">{inventor?.count}</Block>
          <Block className="text-content">Inventor</Block>
        </Block>
      </Slider>
    </Block>
  );
};

export default CountSlider;
