/* eslint-disable no-alert */
import React, { useState } from "react";
import { EditedCommentIcon, UserAvatar } from "../../assets/images/report";
import { Image } from "../ui-component";
import ListOfUploadedMedia from "./list-of-upload-media";
import CommentInput from "./comment-input";

const SingleComment = ({
  comment,
  commentingRowID,
  onUpdateCommentById,
  rowComments,
  isEditing,
  setIsEditing,
  idx,
  onDeleteCommentByCommentId,
  onSetReportCollab,
  onGetAllCommentsByRowId,
  workspaceId,
  onDeleteCollaborationFile,
  userEmailId,
}) => {
  const [newText, setNewText] = useState(comment.comment);
  const [newFiles, setNewFiles] = useState(comment.files);
  const [filesToBeRemovedFromDB, setfilesToBeRemovedFromDB] = useState([]);
  const getFormattedDate = (strDate) => {
    let date = new Date(strDate * 1000);

    let options = {
      month: "short",
      day: "numeric",
      year: "numeric",
    };

    let formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);

    return (
      formattedDate.replace(",", "") +
      " at " +
      new Intl.DateTimeFormat("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }).format(date)
    );
  };

  return (
    <div className="single-comment">
      <div className="d-flex justify-content-between author-details">
        <div className="d-flex gap-2 align-items-center">
          <div className="author-avatar">
            <Image src={UserAvatar} alt="" />
          </div>
          <div className="author-name">{comment.name}</div>
          <div className="comment-time">
            {getFormattedDate(comment.createdAt)}
          </div>
        </div>
        <div className="pt-1 d-flex align-items-center gap-1">
          {comment.createdAt !== comment.updatedAt && (
            <div
              className="edit-buttons"
              title={`On ${getFormattedDate(comment.updatedAt)}`}
            >
              <Image src={EditedCommentIcon} />
              Edited
            </div>
          )}
        </div>
      </div>
      <div className="comment-text">
        {isEditing ? (
          <>
            <CommentInput
              comment={newText}
              setComment={setNewText}
              uploadedFiles={newFiles}
              setUploadedFiles={setNewFiles}
              commentingRowID={commentingRowID}
              onSetfilesToBeRemovedFromDB={setfilesToBeRemovedFromDB}
              filesToBeRemovedFromDB={filesToBeRemovedFromDB}
            />
            <div className="d-flex px-2 py-2 gap-2">
              <button
                onClick={async (e) => {
                  e.preventDefault();
                  const filteredFiles = newFiles.filter(
                    (file) =>
                      !(
                        file.storeFilename &&
                        file.orignalFilename &&
                        file.s3FilePath
                      )
                  );
                  const filteredText =
                    comment.comment !== newText ? newText : "";

                  await onUpdateCommentById({
                    comment: comment.comment !== newText ? newText : "",
                    contentId: comment.id,
                    collaborationFile: filteredFiles,
                  });
                  if (filesToBeRemovedFromDB.length > 0) {
                    await Promise.all(
                      filesToBeRemovedFromDB.map(async (i) => {
                        await onDeleteCollaborationFile({
                          id: parseInt(i),
                        });
                      })
                    );
                  }
                  if (
                    filteredFiles.length > 0 ||
                    filteredText ||
                    filesToBeRemovedFromDB.length > 0
                  ) {
                    await onGetAllCommentsByRowId({
                      elementId: workspaceId,
                      contentId: commentingRowID.toString(),
                    });
                  }
                  setIsEditing(null);
                }}
                className="save-updated-comment-button"
              >
                Save
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditing(null);
                }}
                className="cancel-updated-comment-button"
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            <div
              className="px-2 pb-1 comment-html text-break"
              dangerouslySetInnerHTML={{ __html: comment.comment }}
            ></div>
            <ListOfUploadedMedia
              uploadedFiles={comment.files}
              isShowList={true}
            />
            {comment.email === userEmailId && (
              <div className="d-flex gap-1 px-2 edit-buttons">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setfilesToBeRemovedFromDB([]);
                    setNewFiles(comment.files);
                    setNewText(comment.comment);
                    setIsEditing({
                      id: idx,
                    });
                  }}
                >
                  Edit |
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    const isConfirmed = window.confirm(
                      "Are you sure you want to delete this comment?"
                    );
                    if (isConfirmed) {
                      onDeleteCommentByCommentId(
                        comment.id,
                        onSetReportCollab,
                        rowComments
                      );
                    }
                  }}
                >
                  Delete
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default SingleComment;
