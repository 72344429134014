/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import RWIRE_IMAGES from "../common-functions/rwire-images";
import RWireButton from "../../rwire-ui/rwire-button/rwire-button";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { Pagination } from "rsuite";
import { useTranslation } from "react-i18next";
import RwirePatentInfo from "./rwire-patentinfo";
import RwireAbstract from "./rwire-abstract";
import RwireClaims from "./rwire-claims";
import RwireTableTdComponent from "./rwire-table-td";
import RwireJumpPages from "./rwire-jump-pages";
import { throttle } from "../../../common/utils.js";
import NoResultsFound from "../../../common/no-results-found";
import RwireImages from "./rwire-images";
import RwireFamiliesTdComponent from "./rwire-family-column";
import { Modal } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { useStore } from "react-redux";
import { openPatentInNewTab } from "../../../common/open-new-tab.js";

export default function Table(props) {
  const {
    onPerformSearch,
    onSetResultTable,
    displayData,
    totalRecordsCount,
    dataSize,
    patentReamainingList,
    onSetFilter,
    setExportToggle,
    onSetSelectedRows,
    selectedRows,
    isRetreiveSelectedAll,
    isArrow,
    exportToggle,
    activePages,
    isImageCarouselModalOpen,
    setIsImageCarouselModalOpen,
    setImagePath,
    isLoadingResult,
    isLoadingTable,
    onUpdateColumnWidth,
    columnWidths,
    handleJumpPage,
    jumpPage,
    filtersSelected,
    showFilters,
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scollTableTop = useRef();
  const tableRef = useRef();
  const [openTabs, setOpenTabs] = useState([]);
  const [isVisibleMessageModal, setVisibilityMessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const store = useStore();
  let { locale } = useParams();
  const {
    app: { isSearchFromIncremental },
  } = store.getState();

  const onSetPatentLink = (e) => {
    const id = e.currentTarget.id;
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
    sessionStorage.setItem("patentId", id);
    openPatentInNewTab(
      id,
      locale,
      openTabs,
      setOpenTabs,
      setMessageModalContent,
      setVisibilityMessageModal
    );
  };

  // Storing patent id's for multitab purpose
  useEffect(() => {
    const ids = displayData.map((item) => item._id);
    localStorage.setItem("storedPatentIds", JSON.stringify(ids));
  }, [displayData]);

  const handleSetPageSize = (value, query) => {
    onSetResultTable({ dataSize: value });
    onPerformSearch(query, { isReRunId: true, runAggregate: false }).then(
      (data) => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.log("Error:", props.error);
        }
      }
    );
  };
  const [prev] = useState(true);
  const [next] = useState(true);
  const [first] = useState(true);
  const [last] = useState(true);
  const [ellipsis] = useState(true);
  const [boundaryLinks] = useState(true);
  const [size] = useState("xs");
  const [maxButtons] = useState(5);
  const [layout] = useState(["pager"]);
  const [isChecked, setIsChecked] = useState();
  const [selectedRecords, setSelectedRecords] = useState(
    totalRecordsCount && totalRecordsCount
  );

  const handleChangePage = (e) => {
    tableRef.current.scrollTop = 0;
    onSetResultTable({
      dataFrom: (parseInt(e) - 1) * dataSize,
      activePages: e,
    });
    onPerformSearch(null, { isReRunId: true, runAggregate: false }).then(
      (data) => {
        if (!data) {
          // eslint-disable-next-line no-console
          console.log("Error:", props.error);
        }
      }
    );
  };

  const handleClick = () => {
    setExportToggle(!exportToggle);
  };

  const selectAllChange = (event) => {
    let selectIds = [];
    // eslint-disable-next-line array-callback-return
    displayData.map((i) => {
      selectIds.push(i._id);
    });

    if (event.target.checked) {
      setIsChecked(true);
      onSetSelectedRows({ selectedRows: selectIds });
    } else {
      setIsChecked(false);
      const difference = selectedRows.filter((x) => !selectIds.includes(x));

      onSetSelectedRows({ selectedRows: difference });
    }
  };

  const handleChange = (e) => {
    let selectedId = [];
    selectedId = [...selectedRows, e.target.value];
    if (!e.target.checked) {
      selectedId = selectedId.filter((item) => {
        return item !== e.target.value;
      });
    }
    onSetSelectedRows({ selectedRows: selectedId });
  };

  useEffect(() => {
    setSelectedRecords(
      selectedRows.length > 0 ? selectedRows.length : totalRecordsCount
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows.length, totalRecordsCount]);

  // Render the UI for your table

  const handleOpenImagecarousel = (path) => {
    setIsImageCarouselModalOpen(!isImageCarouselModalOpen);
    setImagePath(path);
  };

  const getElementWidth = () => {
    const wrapperElem = document.getElementById("patent-details-table-wrapper");
    const tableElem = document.getElementById("patent-details-table");

    const scrollbarElem = document.getElementById("scrollbar");
    const innerScrollElem = document.getElementById("inner-scroll");

    let wrapperWidth = wrapperElem?.getBoundingClientRect()?.width || 0;
    let tableWidth = tableElem?.getBoundingClientRect()?.width || 0;

    scrollbarElem?.setAttribute("style", `width: ${wrapperWidth - 10}px`);
    innerScrollElem?.setAttribute("style", `width: ${tableWidth - 10}px`);
  };

  const handleScroll = (scrollId, tableId) => {
    if (tableRef.current) {
      localStorage.setItem("tableScrollPosition", tableRef.current.scrollTop);
    }
    const scrollbarElem = document.getElementById(scrollId);
    const tableElem = document.getElementById(tableId);

    const rect = scrollbarElem && scrollbarElem.scrollLeft;
    if (tableElem) {
      tableElem.scrollLeft = rect;
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getElementWidth);
    return () => {
      window.removeEventListener("resize", getElementWidth);
    };
  });
  setTimeout(() => {
    getElementWidth();
  }, 200);

  useEffect(() => {
    const savedScrollPosition = localStorage.getItem("tableScrollPosition");

    if (savedScrollPosition && tableRef.current) {
      tableRef.current.scrollTop = savedScrollPosition;
    }
    const handleScrollEvent = () => handleScroll();

    if (tableRef.current) {
      tableRef.current.addEventListener("scroll", handleScrollEvent);
    }
    return () => {
      if (tableRef.current) {
        tableRef.current.removeEventListener("scroll", handleScrollEvent);
      }
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      const scrollStep = 50;

      if (e.key === "ArrowUp") {
        window.scrollBy(0, -scrollStep);
      } else if (e.key === "ArrowDown") {
        window.scrollBy(0, scrollStep);
      } else if (e.key === "ArrowLeft") {
        window.scrollBy(-scrollStep, 0);
      } else if (e.key === "ArrowRight") {
        window.scrollBy(scrollStep, 0);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleMouseDown = (e, header, columnWidth, onResize) => {
    if (e.target.classList.contains("resize-handle")) return;
    e.preventDefault();
    const startX = e.clientX;
    const currentWidth = columnWidth;

    document.body.style.cursor = "col-resize";

    const onMouseMove = throttle((e) => {
      const newWidth = currentWidth + (e.clientX - startX);
      if (Math.abs(newWidth - currentWidth) >= 5) {
        onResize(header, newWidth);
      }
    }, 5);

    const onMouseUp = () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
      document.body.style.cursor = "auto";
    };

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
  };

  const handleTouchStart = (e, header, columnWidth, onResize) => {
    if (e.target.classList.contains("resize-handle")) return;
    e.preventDefault();
    const touch = e.touches[0];
    const startX = touch.clientX;
    const currentWidth = columnWidth;

    document.body.style.cursor = "col-resize";

    const onTouchMove = throttle((e) => {
      const touch = e.touches[0];
      const newWidth = currentWidth + (touch.clientX - startX);
      if (Math.abs(newWidth - currentWidth) >= 5) {
        onResize(header, newWidth);
      }
    }, 5);

    const onTouchEnd = () => {
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchend", onTouchEnd);
      document.body.style.cursor = "auto";
    };

    window.addEventListener("touchmove", onTouchMove);
    window.addEventListener("touchend", onTouchEnd);
  };

  const onResize = (header, newWidth) => {
    const updatedWidths = {
      ...columnWidths,
      [header]: newWidth,
    };
    onUpdateColumnWidth({ columnWidths: updatedWidths });
  };

  const minWidthValues = {
    patent_information: 285,
    title: 200,
    Claims: 460,
    abstract: 380,
    Images: 260,
  };

  const getColumnMinWidth = (header) => {
    return minWidthValues[header] || 150;
  };
  useEffect(() => {
    setTimeout(() => {
      getElementWidth();
    }, 500);
  }, [showFilters]);
  return (
    <>
      {!isLoadingResult && totalRecordsCount === 0 ? (
        <NoResultsFound />
      ) : isLoadingResult === false ? (
        <div
          id="patent-details-table-wrapper"
          onScroll={() =>
            handleScroll("patent-details-table-wrapper", "scrollbar")
          }
          className={`result-page-table  ${
            isArrow && Object.keys(filtersSelected).length === 0
              ? "full-height"
              : ""
          } ${
            Object.keys(filtersSelected).length === 0 && !isArrow
              ? "partial-height"
              : ""
          }${
            Object.keys(filtersSelected).length !== 0 && !isArrow
              ? "partial-filter-height"
              : ""
          }
          ${
            Object.keys(filtersSelected).length !== 0 && isArrow
              ? "full-filter-height"
              : ""
          }
${isSearchFromIncremental && !isArrow ? "partial-incremental-height" : ""}`}
          ref={tableRef}
        >
          <table className="table table-striped" id="patent-details-table">
            <div
              className="sticy-scrollbar cursor-pointer"
              onScroll={() =>
                handleScroll("scrollbar", "patent-details-table-wrapper")
              }
              id="scrollbar"
            >
              <div className="inside-scrollbar" id="inner-scroll"></div>
            </div>
            <thead>
              <tr>
                <th className="header-table-result-page">
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    checked={isChecked || isRetreiveSelectedAll}
                    onChange={selectAllChange}
                  />
                </th>
                {patentReamainingList &&
                  // eslint-disable-next-line array-callback-return
                  patentReamainingList.map((header, k) => {
                    if (typeof header !== "object") {
                      const columnWidth =
                        columnWidths[header] || getColumnMinWidth(header);
                      return (
                        <th
                          className="result-page-header resizable-column"
                          style={{ minWidth: columnWidth }}
                          key={header}
                        >
                          <div
                            className="header-wrapper"
                            style={{ userSelect: "none" }}
                            onTouchStart={(e) =>
                              handleTouchStart(e, header, columnWidth, onResize)
                            }
                          >
                            {t([header])}
                            <div
                              className="resize-handle"
                              onMouseDown={(e) =>
                                handleMouseDown(
                                  e,
                                  header,
                                  columnWidth,
                                  onResize
                                )
                              }
                            >
                              <div className="resize-handle-icon"></div>
                            </div>
                          </div>
                        </th>
                      );
                    }
                  })}
              </tr>
            </thead>
            <tbody className="table-body-result text-black">
              {displayData &&
                displayData.map((row, key) => {
                  const displayTitle = row._source["TI_EN"];
                  const displayAbstract = row._source["AB_EN"];
                  const displayPN = row._source["PN_B"];
                  const displayClaims = row._source["CL_EN"];

                  // prepareRow(row);
                  return (
                    <tr key={key}>
                      <td className="result-page-abstract">
                        <input
                          className="mx-1 cursor-pointer"
                          type="checkbox"
                          defaultChecked={
                            selectedRows.includes(`${row._id}`) ||
                            isRetreiveSelectedAll
                          }
                          checked={
                            selectedRows.includes(`${row._id}`) ||
                            isRetreiveSelectedAll
                          }
                          value={row._id}
                          onChange={handleChange}
                        />
                      </td>

                      {patentReamainingList &&
                        patentReamainingList.map((rows) => {
                          switch (rows) {
                            case "publication_no":
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-pub-no dynamic-height-pubNo font-weight-bold menu"
                                  htmlText={displayPN}
                                  isPubNumber={true}
                                  onClick={onSetPatentLink}
                                  tdId={row._source["PN_B"]}
                                  patentStatus={row._source["ALD"]}
                                  innerDivClassName="publication-no pn-hyperlink"
                                  isAddHighlight={true}
                                />
                              );

                            case "patent_information":
                              return <RwirePatentInfo row={row} {...props} />;

                            case "title":
                              return (
                                <RwireTableTdComponent
                                  tdClassName={`result-table-title dynamic-height ${
                                    !displayTitle ? "no-data" : ""
                                  }`}
                                  htmlText={
                                    displayTitle
                                      ? displayTitle
                                          .toString()
                                          .charAt(0)
                                          .toUpperCase() + displayTitle.slice(1)
                                      : "Data not available"
                                  }
                                />
                              );
                            case "abstract":
                              return (
                                <RwireAbstract
                                  displayAbstract={displayAbstract}
                                  index={key}
                                />
                              );

                            case "Claims":
                              return displayClaims ? (
                                <RwireClaims
                                  displayClaims={displayClaims}
                                  index={key}
                                />
                              ) : (
                                ""
                              );

                            case "ipc":
                              const ipcData = row._source["IPC"];
                              const ipcText =
                                ipcData && ipcData.length > 0
                                  ? ipcData.join(", ")
                                  : "Data not available";
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-ipc ipc dynamic-height "
                                  htmlText={ipcText}
                                />
                              );

                            case "cpc":
                              const cpcData = row._source["CPC"];
                              const cpcText =
                                cpcData && cpcData.length > 0
                                  ? cpcData.join(", ")
                                  : "Data not available";
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-ipc dynamic-height "
                                  htmlText={cpcText}
                                />
                              );

                            case "US Class":
                              const usData = row._source["USM"];
                              const usText =
                                usData && usData.length > 0
                                  ? usData.join(", ")
                                  : "Data not available";
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-ipc dynamic-height"
                                  htmlText={usText}
                                />
                              );

                            case "ECL":
                              const eclaData = row._source["ECL"];
                              const eclaText =
                                eclaData && eclaData.length > 0
                                  ? eclaData.join(", ")
                                  : "Data not available";
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-ipc dynamic-height "
                                  htmlText={eclaText}
                                />
                              );
                            case "JP-FI/F-Terms":
                              const jpfiData = row._source["JCL"];
                              const jpfiText =
                                jpfiData && jpfiData.length > 0
                                  ? jpfiData.join(", ")
                                  : "Data not available";
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-ipc dynamic-height "
                                  htmlText={jpfiText}
                                />
                              );
                            case "Legal Status":
                              const legalStatusData = row._source["LST"];
                              const legalStatusText = legalStatusData
                                ? legalStatusData
                                : "Data not available";
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-ipc dynamic-height"
                                  htmlText={legalStatusText}
                                />
                              );

                            case "Images":
                              return <RwireImages row={row} {...props} />;

                            case "Relevancy Score":
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-score dynamic-height"
                                  htmlText={
                                    row._score ? row._score.toFixed(3) : "0"
                                  }
                                />
                              );
                            case "Simple Family":
                              return (
                                <RwireFamiliesTdComponent
                                  tdClassName={`result-table-ipc ipc dynamic-height`}
                                  families={row._source["SF"]}
                                />
                              );
                            case "Main Family":
                              return (
                                <RwireFamiliesTdComponent
                                  tdClassName={`result-table-ipc ipc dynamic-height`}
                                  families={row._source["MF"]}
                                />
                              );

                            case "Extended Family":
                              return (
                                <RwireFamiliesTdComponent
                                  tdClassName={`result-table-ipc ipc dynamic-height`}
                                  families={row._source["EF"]}
                                />
                              );

                            case "Domestic Family":
                              return (
                                <RwireFamiliesTdComponent
                                  tdClassName={`result-table-ipc ipc dynamic-height`}
                                  families={row._source["DF"]}
                                />
                              );

                            case "Complete Family":
                              return (
                                <RwireFamiliesTdComponent
                                  tdClassName={`result-table-ipc ipc dynamic-height`}
                                  families={row._source["CF"]}
                                />
                              );

                            default:
                              return (
                                <RwireTableTdComponent
                                  tdClassName="result-table-column"
                                  header={rows}
                                />
                              );
                          }
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      {/*
          Pagination can be built however you'd like.
          This is just a very basic UI implementation:
        */}
      <div>
        <div
          className={`search-result-pagination-main ${
            !showFilters ? "hide-filters" : ""
          }`}
        >
          <div
            className={`pagination-next-previos ${
              totalRecordsCount === 0 ? "button-disabled" : ""
            }`}
          >
            <div className="result-page">
              <span className="filter-font">Results / page</span>
              <select
                value={dataSize}
                onChange={(e) => {
                  handleSetPageSize(Number(e.target.value));
                }}
                disabled={totalRecordsCount === 0}
              >
                {[50, 100, 150, 200].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <Pagination
              layout={layout}
              size={size}
              prev={totalRecordsCount >= 50 ? prev : null}
              next={totalRecordsCount >= 50 ? next : null}
              first={first}
              last={last}
              ellipsis={ellipsis}
              boundaryLinks={boundaryLinks}
              total={totalRecordsCount > 10000 ? 10000 : totalRecordsCount}
              limit={dataSize}
              maxButtons={maxButtons}
              activePage={activePages}
              onChangePage={handleChangePage}
              jumpPage={jumpPage}
              onJumpPage={handleJumpPage}
            />
          </div>

          <RwireJumpPages
            onSetResultTable={onSetResultTable}
            onPerformSearch={onPerformSearch}
            dataSize={dataSize}
            totalRecordsCount={totalRecordsCount}
            navigatePath="/en/rwire-patents"
          />

          {totalRecordsCount > 0 && (
            <div className="selected-count count-width">
              {selectedRecords} / {totalRecordsCount} Selected
            </div>
          )}

          <div className="inside-highlight-result-page">
            <RWireButton
              cNameDiv={`search-query ${
                totalRecordsCount === 0 ? "button-disabled" : ""
              }`}
              buttonCName="input-button-text-form export-button"
              name="Export"
              buttonImg={RWIRE_IMAGES.RwireExportIcon}
              onClick={handleClick}
              disabled={totalRecordsCount === 0}
            />
            <Link to={`${totalRecordsCount === 0 ? "" : "/en/cognizance"}`}>
              <RWireButton
                cNameDiv={`search-query ${
                  totalRecordsCount === 0 ? "button-disabled" : ""
                }`}
                buttonCName="input-button-text-form chart-btn"
                name="Insights"
                buttonImg={RWIRE_IMAGES.RwireInsideIcon}
                disabled={totalRecordsCount === 0}
              />
            </Link>
          </div>
        </div>
      </div>
      <Modal
        className="modalClassMultitab"
        static="static"
        keyboard={true}
        open={isVisibleMessageModal}
      >
        <div
          className="highlight-modal-close-btn"
          onClick={() => setVisibilityMessageModal(false)}
        >
          <IoMdClose className="modal__close" title="Close" />
        </div>
        <div>{messageModalContent}</div>
      </Modal>
    </>
  );
}
