const KeywordModalRow = (props) => {
  const {
    selectedTextModal,
    onHandleClose,
    id,
    title,
    onUpdateProjectKeyword,
    reportTableData
  } = props;
  const handleClick = async () => {
    if (selectedTextModal) {
      let newReportTableData = [...reportTableData];
      const index = newReportTableData.findIndex(
        (item) => item.id === id
      );
      if (index !== -1) {
        newReportTableData[index].body = `${newReportTableData[index].body} ${newReportTableData[index].body ? " | " : ""
          }${selectedTextModal}`;
        await onUpdateProjectKeyword({
          id,
          body: {
            body: newReportTableData[index].body,
          },
        });
      }
      onHandleClose();
    }
  };

  return (
    <div onClick={handleClick} className="keywords-row">
      {title}
    </div>
  );
};

export default KeywordModalRow;
