import React from "react";
import { arrayMove } from "react-sortable-hoc";
import "../rwire-report-notes/styles.scss";
import { ReportSortableTable } from "../../../container/report";
import "./styles.scss";
import { updateSortOrder } from "../../../ui-components/functions/notes/utils";
import RwireLoader from "../../../container/rwire-loader";

const RwireReportString = (props) => {
  const {
    onSetReportString,
    reportTableData,
    column,
    onUpdateReportProjectStringRow,
    onAddRow,
    onUpdateReportProjectStringSortOrder,
    onDeleteRow,
    onGetReportProjectString,
  } = props;

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportString({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });

    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateReportProjectStringSortOrder(bodyData);
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex && options.isChangeText) {
        return {
          ...i,
          title: value,
        };
      } else if (i.id === rowIndex && options.isChangeDesc) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportString({
      reportTableData: updatedTableData,
    });
  };

  const handleAddRow = async (rowIndex) => {
    // const updatedTableData = [...reportTableData];
    // const newRow = { title: "", id: uuidv4(), body: "" };

    // updatedTableData.splice(rowIndex + 1, 0, newRow);

    // onSetReportString({
    //   reportTableData: updatedTableData,
    // });
    const _id = sessionStorage.getItem("projectId");
    await onAddRow({
      body: "",
      publicationCount: 0,
      applicationCount: 0,
      order: parseInt(`${rowIndex + 1}`),
      projectId: parseInt(_id),
    });
    await onGetReportProjectString({ projectId: _id });
  };

  const handleDeleteRow = async (rowIndex, id) => {
    if (reportTableData.length === 1) {
      return;
    }
    // const updatedTableData = [...reportTableData];
    // updatedTableData.splice(rowIndex, 1);
    // onSetReportString({
    //   reportTableData: updatedTableData,
    // });
    const _id = sessionStorage.getItem("projectId");
    await onDeleteRow(id);
    await onGetReportProjectString({ projectId: _id });
  };

  const handleSaveRowText = async (value, rowIndex, idx, options) => {
    if (reportTableData[idx].body !== value) {
      await onUpdateReportProjectStringRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  return (
    <>
      <RwireLoader />
      <div
        className="notes-section rwire-report-string-table"
        style={{ padding: "20px 20px 0 20px" }}
      >
        <ReportSortableTable
          columns={column}
          isEditAndRerunEnable={true}
          data={reportTableData}
          isAddExtraClass={true}
          onSetData={onSetReportString}
          onSortEnd={onSortEnd}
          pressDelay={100}
          useDragHandle
          className="w-100"
          onCellChange={(value, rowIndex, options) =>
            handleChange(value, rowIndex, options)
          }
          onAddRowBelow={(rowIndex) => handleAddRow(rowIndex)}
          onDeleteRow={(rowIndex, id) => handleDeleteRow(rowIndex, id)}
          onSaveInDb={(value, rowIndex, options) =>
            handleSaveRowText(value, rowIndex, options)
          }
          workspaceId="6"
          cellClassName="report-string-cell-height"

        />
      </div>
    </>
  );
};

export default RwireReportString;
