import { useTranslation } from "react-i18next";
import Select from "react-select";
import { useSelector } from "react-redux";

const TopNumberFilter = (props) => {
  const { t } = useTranslation();
  const { onHandleTopResultSet, topNumberSize = 10 } = props;
  const isDarkMode = useSelector((state) => state.app.isDarkMode);

  const handleTopSelect = (value) => {
    onHandleTopResultSet("", value.value);
  };

  const optionStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      cursor: "pointer",
      color: isDarkMode ? "white" : "black",
      backgroundColor: state.isFocused
        ? isDarkMode
          ? "#222"
          : "#f0f0f0"
        : "inherit",
    }),
    control: (styles) => ({
      ...styles,
      cursor: "pointer",
      minHeight: 30,
      height: 30,
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      cursor: "pointer",
      minHeight: 30,
      height: 30,
    }),
  };

  return (
    <Select
      onChange={handleTopSelect}
      options={[
        { value: "10", label: `${t("top_label")} 10` },
        { value: "20", label: `${t("top_label")} 20` },
        { value: "25", label: `${t("top_label")} 25` },
      ]}
      defaultValue={{
        value: topNumberSize,
        label: `${t("top_label")} ${topNumberSize}`,
      }}
      isSearchable={false}
      styles={optionStyles}
    />
  );
};

export default TopNumberFilter;
