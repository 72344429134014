/* eslint-disable no-useless-escape */
export const setHighlight = (payload) => ({
  type: "HIGHLIGHT_SET",
  payload: {
    ...payload,
  },
});

const findMatchingWordsForWildcard = ({
  resultString,
  word,
  result,
  isExact,
}) => {
  const escapedWildcard = word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regexPattern = escapedWildcard.replace(/\*/g, "w*").replace(/\?/g, "w");

  const regex = isExact || escapedWildcard.match(/(\*|\?)/g)
    ? new RegExp(`\\b${regexPattern}\\b`, "gi")
    : new RegExp(`${regexPattern}`, "gi");
    let matchingWords = resultString.match(regex) || [];
  result.push(...matchingWords);
};

export const findMatchingWords = (result, queryWords, isExact = false) => {
  const words = [];
  localStorage.removeItem('queryWords');
  localStorage.setItem('queryWords', JSON.stringify(queryWords));

  queryWords.forEach((wildcard) => {
    findMatchingWordsForWildcard({
      resultString: JSON.stringify(result),
      word: wildcard,
      result: words,
      isExact,
    });
  });

  let filteredWord = words.map((i) => i.toLowerCase());

  filteredWord = [...new Set(filteredWord)];

  return new Promise((resolve) => {
    filteredWord = filteredWord.map((i) => {
      return {
        word: i,
        isQueryWord: true,
        color: "#fffb00",
      };
    });
    resolve(filteredWord);
  });
};
