import { useState } from "react";
import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import KeywordDropdown from "./keyword-dropdown-field";

const KeywordForm = (props) => {
  const {
    onSearch,
    onSetQueryField,
    queryField,
    isReportModal = false,
    existingKeyword = [],
    isSynonymsLoading,
    count,
    onSetCount,
    onSelect,
    onSetSelectedRows,
  } = props;

  const [keyword, setKeyword] = useState("");
  const handleSetValue = (event) => {
    setKeyword(event.target.value);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      onSearch(keyword, count.code);
    }
  };

  const isDisable = keyword ? (isSynonymsLoading ? true : false) : true;

  return (
    <div className="form-container">
      <div className="form-inputs">
        <div className="input-suggestion-class input">
          <input
            name="keyword"
            className="suggestion-input"
            placeholder="Enter keyword"
            onChange={(event) => handleSetValue(event)}
            onKeyUp={handleKeyUp}
            disabled={isSynonymsLoading}
          />
        </div>
        <KeywordDropdown
          onSetQueryField={onSetQueryField}
          queryField={queryField}
          disabled={isDisable}
          count={count}
          onSetCount={onSetCount}
          isReportModal={isReportModal}
          existingKeywords={existingKeyword}
          onSelect={onSelect}
        />

        <RWireButton
          cNameDiv="search-query"
          buttonCName={`search-btn input-button-text-form ${
            isDisable ? "disabled" : ""
          }`}
          name="Search"
          onClick={() => {
            onSetSelectedRows && onSetSelectedRows({ selectedKeywords: [] });
            onSearch(keyword, count.code);
          }}
        />
      </div>
    </div>
  );
};

export default KeywordForm;
