import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getReportProjectDataById } from "../../action/report/report-project";
import FinalReportView from "../../components/report/final-report-view";
import { sentReportOnMail } from "../../action/report/report-generate";

const Container = (props) => {
  return <FinalReportView {...props} />;
};

const mapStateToProps = ({ project }) => ({
  ...project,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjectDataById: getReportProjectDataById,
      onSentReportOnMail: sentReportOnMail,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
