import React from "react";

const Heading = ({ children, className, as, onClick }) => {
  if (as === "h1") {
    return (
      <h1 className={className} onClick={onClick}>
        {children}
      </h1>
    );
  } else if (as === "h2") {
    return (
      <h2 className={className} onClick={onClick}>
        {children}
      </h2>
    );
  } else if (as === "h3") {
    return (
      <h3 className={className} onClick={onClick}>
        {children}
      </h3>
    );
  } else if (as === "h4") {
    return (
      <h4 className={className} onClick={onClick}>
        {children}
      </h4>
    );
  } else if (as === "h5") {
    return (
      <h5 className={className} onClick={onClick}>
        {children}
      </h5>
    );
  } else {
    return (
      <h6 className={className} onClick={onClick}>
        {children}
      </h6>
    );
  }
};

export default Heading;
