import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import PatentKeyFeatureTabContent from "./patent-keyfeature-tabcontent";
import KeyFeatureTextTabContent from "./keyfeaturetext-tab-content";

const KeyFeatureInputSection = (props) => {
  const [key, setKey] = useState("patent");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="patent" title="Patent">
        <PatentKeyFeatureTabContent {...props} key={key} />
      </Tab>
      <Tab eventKey="text" title="Text">
        <KeyFeatureTextTabContent {...props} key={key} />
      </Tab>
    </Tabs>
  );
};

export default KeyFeatureInputSection;
