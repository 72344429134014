import React, { useState } from "react";
import { arrayMove } from "react-sortable-hoc";
import "./styles.scss";
import { ReportToolbar } from "../../../ui-components";
import { ReportSortableTable } from "../../../container/report";
import ImportClaimsModal from "./import-claims-modal";
import { updateSortOrder } from "../../../ui-components/functions/notes/utils";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../../common/user-roles";

function RwireReportNotes(props) {
  const {
    onSetReportNotes,
    reportTableData,
    onSplitPatentClaims,
    onSetPatentClaims,
    claimsData,
    searchedPatentNumber,
    claimsDataFound,
    onUpdateNotesSortOrder,
    onUpdateReportProjectNotesRow,
    onAddRowToreportProject,
    onDeleteRowFromReportProject,
    onGetReportProjectNotes,
    isLoadingClaims,
  } = props;
  const [showImportClaims, setShowImportClaims] = useState(false);
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportNotes({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });

    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateNotesSortOrder(bodyData);
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });
    onSetReportNotes({
      reportTableData: updatedTableData,
    });
  };

  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (reportTableData[idx].body !== value) {
      await onUpdateReportProjectNotesRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    // let lastId = findGreatestId([...reportTableData]);
    // const updatedTableData = [...reportTableData];
    // const newRow = { body: "", id: lastId + 1 };

    // updatedTableData.splice(rowIndex + 1, 0, newRow);

    // onSetReportNotes({
    //   reportTableData: updatedTableData,
    // });
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectNotes();
  };

  const handleDeleteRow = async (rowIndex, id) => {
    if (reportTableData.length === 1) {
      return;
    }
    // const updatedTableData = [...reportTableData];
    // updatedTableData.splice(rowIndex, 1);
    // onSetReportNotes({
    //   reportTableData: updatedTableData,
    // });
    await onDeleteRowFromReportProject(id);
    await onGetReportProjectNotes();
  };

  return (
    <div className="notes-section" style={{ padding: "20px 20px 0 20px" }}>
      <div className="toolbar-inner-section">
        {userProjectAccess !== ACCESS_VIEWER && <ReportToolbar {...props} />}
        {userProjectAccess !== ACCESS_VIEWER && (
          <div className="d-flex gap-2">
            <div
              className="buttons cursor-pointer"
              onClick={() => setShowImportClaims(true)}
            >
              Import Claims
            </div>
          </div>
        )}
      </div>

      <ReportSortableTable
        columns={[]}
        data={reportTableData}
        onSetData={onSetReportNotes}
        onSortEnd={onSortEnd}
        pressDelay={100}
        useDragHandle
        className="w-100"
        onCellChange={(value, rowIndex, options) =>
          handleChange(value, rowIndex, options)
        }
        onSaveInDb={(value, rowIndex, options) =>
          handleSaveInDb(value, rowIndex, options)
        }
        onAddRowBelow={(rowIndex) => handleAddRow(rowIndex)}
        onDeleteRow={(rowIndex, id) => handleDeleteRow(rowIndex, id)}
        workspaceId="1"
      />
      {showImportClaims && (
        <ImportClaimsModal
          isOpen={showImportClaims}
          onClose={() => setShowImportClaims(false)}
          onSplitPatentClaims={onSplitPatentClaims}
          onSetPatentClaims={onSetPatentClaims}
          claimsData={claimsData}
          tableData={reportTableData}
          onSetData={onSetReportNotes}
          searchedPatentNumber={searchedPatentNumber}
          claimsDataFound={claimsDataFound}
          isLoadingClaims={isLoadingClaims}
          {...props}
        />
      )}
    </div>
  );
}

export default RwireReportNotes;
