
export const mapErrorMessage = (errorMessage) => {
  if (errorMessage.includes('[aA], [nN], [oO], [sS], or whitespace but ")" found.')) {
    return "Please input the value in a field.";
  }
  if (errorMessage.includes('end of input, or whitespace but ")" found.')) {
    return "Please check the brackets.";
  }
  if (errorMessage.includes('but "(" found.')) {
    return "Please input the value in a field.";
  }
  if (errorMessage.includes('end of input, or whitespace but ")" found.')) {
    return "Please check the brackets.";
  }
  if (
    errorMessage.includes('but "(" found.') ||
    errorMessage.includes('but "}" found.') ||
    errorMessage.includes('but "{" found.') ||
    errorMessage.includes('but "[" found.') ||
    errorMessage.includes('but "]" found.')
  ) {
    return "Please check the brackets.";
  }
  if (
    errorMessage.includes('or whitespace but "=" found.') ||
    errorMessage.includes('or whitespace but "<" found.') ||
    errorMessage.includes('but ")" found.') ||
    errorMessage.includes("or [^/] but end of input found.") ||
    errorMessage.includes("or whitespace but") ||
    errorMessage.includes('or whitespace but ">" found.')
  ) {
    return "Please check the input";
  }
  if (
    errorMessage.includes('but ">" found') ||
    errorMessage.includes('but "<" found')
  ) {
    return "Please select date field";
  }
  if (errorMessage.includes('but "=" found')) {
    return "Please select country field";
  }
  if (errorMessage.includes('Expected "0." or [0-9] but "^" found.')) {
    return "Please check the input";
  }
  if (errorMessage.includes("but end of input found")) {
    return "Please input the value in a field.";
  }
  if (
    errorMessage.includes('Please add the operand after operator ["AND"]') ||
    errorMessage.includes('Please add the operand after operator ["OR"]') ||
    errorMessage.includes('Please add the operand after operator ["NOT"]')
  ) {
    return "Please check the outer brackets";
  }
  if (
    errorMessage.includes('Operator cannot be missing')
  ) {
    return "Operator cannot be missing"
  }

  return errorMessage;
};

