import React, { useEffect, useState } from "react";
import moment from "moment";
import PriorityNumberToggle from "./priority-number-toggle";
import RwireJurisdiction from "../../components/common/patents-result-common/rwire-jurisdiction";
import RwireInventor from "../../components/common/patents-result-common/rwire-invetor";
import checkIcon from "../../assets/images/report/checked-with-close.svg";
import RwireCurrentAssignee from "../../components/common/patents-result-common/rwire-current-assignee";
import { useStore } from "react-redux";
import { ACCESS_COMMENTOR, ACCESS_VIEWER } from "./../../common/user-roles";
function RwireViewPatentInfo(props) {
  const {
    assigneeName = [],
    inventorName,
    jurisdiction1 = [],
    jurisdiction2,
    jurisdiction3,
    jurisdiction4,
    jurisdiction5,
    jurisdiction6,
    jurisdiction7,
    jurisdiction8,
    jurisdiction9,
    applNumber,
    legalStatus = "",
    pubDate,
    applDate,
    priority,
    expDate,
    priorityNumber,
    onPerformSearch,
    onAddAssignee,
    onRemoveAssignee,
    onAddInventor,
    onRemoveInventor,
    detailsData,
    onGetProjectPatentDetails,
    patentDetails = [],
    DesignatedStates,
  } = props;

  const [jurisdictionToggle, setJurisdictionToggle] = useState(false);
  const [inventorToggle, setInventorToggle] = useState(false);
  const projectId = sessionStorage.getItem("projectId");
  const [assigneeToggle, setAssigneeToggle] = useState(false);
  const patentNumber = detailsData[`PN_B`];
  const isAssigneeTick = patentDetails.filter((i) => i.type === "assignee");
  const isInventorTick = patentDetails.filter((i) => i.type === "inventor");
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  useEffect(() => {
    if (projectId && patentNumber) {
      onGetProjectPatentDetails({ projectId, patentNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patentNumber]);

  const handleJurisdictionToggle = (e) => {
    setJurisdictionToggle(!jurisdictionToggle);
  };

  const handleInventorToggle = (e) => {
    setInventorToggle(!inventorToggle);
  };

  const handleAddAssignee = async () => {
    const body = assigneeName.map((i, key) => {
      return { body: i, order: key + 1 };
    });
    await onAddAssignee({ projectId, body, patentNumber });
    await onGetProjectPatentDetails({ projectId, patentNumber });
  };

  const handleRemoveAssignee = async () => {
    await onRemoveAssignee({ projectId, body: assigneeName, patentNumber });
    await onGetProjectPatentDetails({ projectId, patentNumber });
  };

  const handleAddInventor = async () => {
    const body = inventorName.map((i, key) => {
      return { body: i, order: key + 1 };
    });
    await onAddInventor({ projectId, body, patentNumber });
    await onGetProjectPatentDetails({ projectId, patentNumber });
  };

  const handleRemoveInventor = async () => {
    await onRemoveInventor({ projectId, body: inventorName, patentNumber });
    await onGetProjectPatentDetails({ projectId, patentNumber });
  };
  const handleAssigneeToggle = (e) => {
    setAssigneeToggle(!assigneeToggle);
  };

  useEffect(() => {
    setInventorToggle(false);
    setAssigneeToggle(false);
  }, [applNumber]);

  return (
    <div className="patent-info-block d-flex flex-column">
      <div
        className="mb-2 ms-3 ps-1 details d-flex"
        onClick={handleAssigneeToggle}
      >
        {userProjectAccess !== ACCESS_VIEWER && (
          <>
            {projectId && !isAssigneeTick.length ? (
              <div className="hover-content me-2" onClick={handleAddAssignee} title=" Add to report">
                +
              </div>
            ) : isAssigneeTick.length && projectId ? (
              <img
                className="patent-full-view-page-check-icon"
                src={checkIcon}
                alt=""
                onClick={handleRemoveAssignee}
              />
            ) : (
              ""
            )}
          </>
        )}
        <div className="details-header font-weight-bold ml-2">Current Assignee:</div>
        <div className="ms-5 font-weight-bold">
          <RwireCurrentAssignee
            currentAssignee={assigneeName}
            assigneeToggle={assigneeToggle}
            setAssigneeToggle={setAssigneeToggle}
            onPerformSearch={onPerformSearch}
            isViewPage={true}
          />
        </div>
      </div>

      <div
        className="mb-2 ms-3 ps-1 details d-flex"
        onClick={handleInventorToggle}
      >
        {userProjectAccess !== ACCESS_VIEWER &&
          projectId &&
          !isInventorTick.length ? (
          <div className="hover-content me-2" onClick={handleAddInventor} title=" Add to report">
            +
          </div>
        ) : projectId && isInventorTick.length ? (
          <img
            className="patent-full-view-page-check-icon"
            src={checkIcon}
            alt=""
            onClick={handleRemoveInventor}
          />
        ) : (
          ""
        )}
        <div className="details-header font-weight-bold ml-2">Inventor:</div>
        <div className="ms-5 font-weight-bold" style={{ color: "#0048A2" }}>
          <RwireInventor
            inventor={inventorName}
            inventorToggle={inventorToggle}
            setInventorToggle={setInventorToggle}
            onPerformSearch={onPerformSearch}
            isViewPage={true}
          />
        </div>
      </div>
      <div
        className="mb-2 ms-3 ps-1 details d-flex"
        onClick={handleJurisdictionToggle}
      >
        <div className="details-header font-weight-bold ml-2">
          Designated States:
        </div>
        <div className="ms-5 font-weight-bold">
          <RwireJurisdiction
            jurisdiction={DesignatedStates || []}
            jurisdictionToggle={jurisdictionToggle}
            setJurisdictionToggle={setJurisdictionToggle}
          />
        </div>
      </div>
      <div className="mb-2 ms-3 ps-1 details d-flex">
        <div className="details-header font-weight-bold ml-2">
          Application Number:
        </div>
        <div className="ms-5 font-weight-bold">{applNumber}</div>
      </div>
      <div className="mb-2 ms-3 ps-1 details d-flex">
        <div className="details-header font-weight-bold ml-2">Legal Status:</div>
        <div className="ms-5 font-weight-bold">
          {legalStatus ? legalStatus : ""}
        </div>
      </div>
      <div className="mb-2 ms-3 ps-1 details d-flex">
        <div className="details-header font-weight-bold ml-2">Publication Date:</div>
        <div className="ms-5 font-weight-bold">
          {pubDate && moment(pubDate).format("LL")}
        </div>
      </div>
      <div className="mb-2 ms-3 ps-1 details d-flex">
        <div className="details-header font-weight-bold ml-2">Application Date:</div>
        <div className="ms-5 font-weight-bold">
          {applDate && moment(applDate).format("LL")}
        </div>
      </div>
      <div className="mb-2 ms-3 ps-1 details d-flex">
        <div className="details-header font-weight-bold ml-2">
          Earliest Priority Date:
        </div>
        <div className="ms-5 font-weight-bold">
          {priority && moment(priority).format("LL")}
        </div>
      </div>
      <PriorityNumberToggle priorityNumber={priorityNumber} />
      {!detailsData?.PNC && (
        // Hiding Expiry Date For Temporary Basis
        <div className="mb-2 ms-3 ps-1 details d-flex">
          <div className="details-header font-weight-bold ml-2">Expiry Date:</div>
          <div className="ms-5 font-weight-bold">
            {expDate ? moment(expDate).format("LL") : ""}
          </div>
        </div>
      )}
    </div>
  );
}

export default RwireViewPatentInfo;
