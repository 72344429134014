import { useState } from "react";
import TableHeader from "./table-header";
import TableRow from "./table-row";
import { uuidv4 } from "../../../action/app";

const KeywordTable = (props) => {
  const { tableData = [], selectedKeywords, onSetKeywordSelector } = props;

  const [fullDefinationText, setFullDefinationText] = useState(false);

  return (
    <div className={`table-wrapper normal-scroll-bar`}>
      <div className="table-inner mb-2">
        <TableHeader
          onSetFullDefinationText={setFullDefinationText}
          fullDefinationText={fullDefinationText}
          columnName={Object.keys(tableData)[0]}
        />
        <div className="body_wrapper">
          {Object.values(tableData).map((item) => {
            return typeof item === "undefined" || (item && !item.length) ? (
              <div className="text-center p-2 fw-bold no-results-found">
                <h3>Sorry! no records found</h3>
                <span>
                  Please try another search or contact us for more information.
                </span>
              </div>
            ) : (
              item &&
                item.length &&
                item.map((i) => (
                  <TableRow
                    key={uuidv4()}
                    item={i}
                    selectedRows={selectedKeywords}
                    onSetKeywordSelector={onSetKeywordSelector}
                  />
                ))
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default KeywordTable;
