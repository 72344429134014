import { useState } from "react";
import { Input } from "rsuite";
import RWIRE_IMAGES from "../components/common/common-functions/rwire-images";
import { maxExportRecordsCount } from "../components/constant";
import { useNavigate } from "react-router-dom";
import RWireButton from "../components/rwire-ui/rwire-button/rwire-button";

const ExportFooter = (props) => {
  const {
    onSetSelectedField,
    onExportMailRecord,
    onSetExport,
    setValue,
    selectedFields,
    exportFileName,
    isExportLoading,
    selectedRows,
    totalRecordsCount,
    exportToggle,
    setExportToggle,
    showExportMailPopup,
    handleshowExportMailPopup,
    closeExportMailPopup
  } = props;
  const navigate = useNavigate();

  const downloadLimitExceed =
    selectedRows.length > 0
      ? false
      : totalRecordsCount > maxExportRecordsCount
        ? true
        : false;

  // eslint-disable-next-line no-unused-vars
  const [fileType, setFileType] = useState("csv");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isExportDisabled, setIsExportDisabled] = useState(false);
  const [isSendMailDisabled, setIsSendMailDisabled] = useState(false);

  const [showPopup, setShowPopup] = useState(false);

  const handleExport = async (e) => {
    if (!isExportLoading && !downloadLimitExceed) {
      await onExportMailRecord({
        emails: "",
        subject: exportFileName ? exportFileName : "Rwire Data Download",
        fileName: exportFileName ? exportFileName : "Rwire Data Download",
        isDownload: true,
        message: "",
      });

      setValue([]);
      setIsExportDisabled(true);

      return navigate("/en/download-center");
    } else {
      if (downloadLimitExceed) {
        setErrorMessage("Limit Exceed! Direct download limit is 3k");
        setTimeout(() => {
          setErrorMessage("");
        }, 9000);
      }
    }
  };

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
    onSetExport({ selectedType: e.target.value });
  };

  const handleFileNameChange = (e) => {
    onSetExport({ exportFileName: e });
  };

  const handleChange = (e) => {
    setEmailValue(e);
    setEmailError("");
  };

  const handleSendMail = () => {
    if (!emailValue) {
      setEmailError("Email is Required");
      return;
    }

    if (emailValue && selectedFields.length > 0) {
      if (selectedRows.length === 0 && totalRecordsCount > 30000) {
        handleshowExportMailPopup();
        return;
      }
      onExportMailRecord({
        emails: emailValue,
        subject: exportFileName ? exportFileName : "Rwire Data Download",
        fileName: exportFileName ? exportFileName : "Rwire Data Download",
        message: "",
      });

      onSetSelectedField({ selectedFields: [] });
      setValue([]);
      setExportToggle(!exportToggle);
      setIsSendMailDisabled(true);

      navigate("/en/download-center");
      setEmailError("");
    }

    return null;
  };

  const handleClosePopup = () => {
    closeExportMailPopup();
  };

  const handleClickOk = () => {
    onExportMailRecord({
      emails: emailValue,
      subject: exportFileName ? exportFileName : "Rwire Data Download",
      fileName: exportFileName ? exportFileName : "Rwire Data Download",
      message: "",
    });

    onSetSelectedField({ selectedFields: [] });
    setValue([]);
    navigate("/en/download-center");
  };

  return (
    <>
      <div className="d-flex justify-content-end footer-export">
        <div>
          <Input
            className="export-search file-name-export"
            placeholder={"File name"}
            onChange={handleFileNameChange}
          />
        </div>
        <div>
          <select
            onChange={handleFileTypeChange}
            className="form-select export-type"
          >
            <option value="csv">CSV</option>
            <option value="xlsx">XLSX</option>
          </select>
        </div>
        <div
          className={`export-download d-flex ${isExportLoading || downloadLimitExceed || isExportDisabled ? "opacity-50" : ""
            }`}
          onClick={!isExportDisabled ? handleExport : null}
        >
          <div className="export-download-text">Download</div>
          <img
            src={RWIRE_IMAGES.RwireDownload}
            className="export-sec-icons"
            alt=""
          />
        </div>
        <div className="export-save d-flex">
          <div className="export-savedafault-text">Save default</div>
          <img
            src={RWIRE_IMAGES.RwireSave}
            className="export-sec-icons"
            alt=""
          />
        </div>
        <div className="d-flex justify-content-between footer-emailer">
          <span className="email-hard">Email</span>{" "}
          <Input
            className="export-search export-email-input"
            placeholder={`example@gmail.com`}
            onChange={handleChange}
            value={emailValue}
          />
          <span>
            <img
              src={RWIRE_IMAGES.RwireEmail}
              title="Send on mail"
              alt=""
              onClick={!isSendMailDisabled ? handleSendMail : null}
              className={`email-export ${isSendMailDisabled ? "opacity-50" : ""}`}
            />
          </span>
        </div>
      </div>
      <div className="val-msg">
        {emailError && <div className="error-message-email">{emailError}</div>}
        {errorMessage && (
          <div className="error-message-download pl-2 pt-1">{errorMessage}</div>
        )}
        {showExportMailPopup && (
          <div className="popup-overlay">
            <div className="popup-content">
              <div className="popup-message">
                Maximum limit is 30K for export. First 30K will be exported.
              </div>
              <div className="poup-buttons">
                <RWireButton
                  buttonCName="cancel-export-popup mr-2"
                  name="cancel"
                  onClick={handleClosePopup}
                />

                <RWireButton
                  buttonCName="ok-export-popup"
                  name="OK"
                  onClick={handleClickOk}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ExportFooter;
