import React, { useState } from "react";
import { Block, Image, Span } from "../ui-component";
import { useNavigate } from "react-router-dom";
import {
  SixDots,
  RightBlackIcon,
  PlusBlackIcon,
  MinusBlackIcon,
  RerunBlackIcon,
  EditBlackIcon,
} from "../../assets/images/report";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import ContentRenderer from "./content-renderer";
import SlidingSidePanel from "../sliding-side-panel";
import ReportCollaboration from "../../container/report/report-collaboration";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../common/user-roles";

const DragHandle = SortableHandle(() => (
  <div className="sortable-dots" tabIndex={0} style={{ cursor: "grabbing" }}>
    <Image src={SixDots} className="mx-auto" />
  </div>
));

const TableRow = SortableElement((props) => {
  const {
    rowIndex,
    onSetData,
    data,
    rowItem,
    isAddExtraClass = false,
    onAddRowBelow,
    onDeleteRow,
    idx,
    onCellChange,
    onAddCommentToId,
    onDeleteCommentById,
    onSaveInDb,
    isEditAndRerunEnable,
    onSetApp,
    onPerformSearch,
    workspaceId,
    tabWiseSearchQuery,
    activeTab,
    onSetAllField,
    cellClassName,
  } = props;

  const [commentingRowID, setCommentingRowID] = useState(null);
  const navigate = useNavigate();
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  const handleEditQuery = (query) => {
    onSetApp({ searchQuery: query });
    navigate("/en/");
    onSetAllField({
      tabWiseSearchQuery: {
        ...tabWiseSearchQuery,
        [activeTab]: query,
      },
    });
  };

  const handleRerun = (query) => {
    onSetApp({ searchQuery: query });

    onPerformSearch(
      query,
      {
        isReRunId: true,
        isResetHighlight: true,
      },
      navigate,
      "/en/rwire-patents"
    );
  };

  return (
    <Block className="report-sortable-table-row">
      <DragHandle />
      <Block
        className={`${
          isAddExtraClass ? "first-column" : ""
        } report-sortable-table-row-row-wrapper first-col-padding`}
      >
        <ContentRenderer
          onCellChange={onCellChange}
          rowIndex={rowIndex}
          data={data}
          onSetData={onSetData}
          text={isAddExtraClass ? rowItem.title : rowItem.body}
          isChangeText={true}
          isChangeDesc={false}
          onSaveInDb={onSaveInDb}
          idx={idx}
          rowItem={rowItem}
          cellClassName={cellClassName}
        />
      </Block>
      {isAddExtraClass && (
        <Block
          className={`second-column report-sortable-table-row-row-wrapper ${
            userProjectAccess === ACCESS_VIEWER ? "no-leftline" : ""
          }`}
        >
          <ContentRenderer
            onCellChange={onCellChange}
            rowIndex={rowIndex}
            data={data}
            onSetData={onSetData}
            text={isAddExtraClass ? rowItem.body : rowItem.desc}
            isChangeText={false}
            isChangeDesc={true}
            onSaveInDb={onSaveInDb}
            idx={idx}
            rowItem={rowItem}
          />

          {isEditAndRerunEnable && (
            <>
              {sessionStorage.getItem("userRole") !== "ROLE_VIEWER" && (
                <Block
                  className="action-button-bg h-100 d-flex align-items-center"
                  style={{
                    height: "fit-content",
                  }}
                  title="Edit"
                  onClick={() => handleEditQuery(rowItem.title)}
                >
                  {rowItem.title.trim() === "" ? null : (
                    <Span
                      className={`action-btn mr-3 action-modal ${
                        userProjectAccess === ACCESS_VIEWER ? "d-none" : ""
                      }`}
                    >
                      <Image src={EditBlackIcon} />
                    </Span>
                  )}
                </Block>
              )}
              <Block
                className="action-button-bg h-100 d-flex align-items-center"
                style={{
                  height: "fit-content",
                }}
                title="Rerun"
                onClick={() => handleRerun(rowItem.title)}
              >
                {rowItem.title.trim() === "" ? null : (
                  <Span
                    className={`action-btn mr-3 action-modal ${
                      userProjectAccess === ACCESS_VIEWER ? "d-none" : ""
                    }`}
                  >
                    <Image src={RerunBlackIcon} />
                  </Span>
                )}
              </Block>
            </>
          )}
        </Block>
      )}
      <Block
        className={`action-modal ${
          userProjectAccess === ACCESS_VIEWER ? "collaboration-arrow" : ""
        }`}
      >
        <Block
          className={`action-button-bg h-100 d-flex align-items-center col-md-4`}
          style={{
            height: "fit-content",
          }}
          title="Collaboration"
          onClick={() => setCommentingRowID(rowItem.id)}
        >
          <Span
            className={`action-btn ${
              userProjectAccess === ACCESS_VIEWER ? "last-childv" : ""
            }`}
          >
            <Image
              src={RightBlackIcon}
              title="Collaboration"
              className={`${userProjectAccess === ACCESS_VIEWER ? "" : ""}`}
            />
          </Span>
        </Block>

        {userProjectAccess !== ACCESS_VIEWER && (
          <Block
            className={`action-button-bg h-100 d-flex align-items-center col-md-4`}
            onClick={() => onAddRowBelow(idx)}
          >
            <Span
              className={`action-btn ${
                userProjectAccess === ACCESS_VIEWER ? "d-none" : ""
              }`}
            >
              <Image src={PlusBlackIcon} title="Add Row" />
            </Span>
          </Block>
        )}
        {userProjectAccess !== ACCESS_VIEWER && (
          <Block
            className={`action-button-bg h-100 d-flex align-items-center col-md-4`}
            onClick={() => onDeleteRow(idx, rowIndex)}
          >
            {data.length > 1 && (
              <Span
                className={`action-btn ${
                  userProjectAccess === ACCESS_VIEWER ? "d-none" : ""
                }`}
              >
                <Image src={MinusBlackIcon} title="Delete Row" />
              </Span>
            )}
          </Block>
        )}
      </Block>
      <SlidingSidePanel
        isOpen={rowIndex === commentingRowID ? true : false}
        onClose={() => setCommentingRowID(null)}
        id="report-collab-modal"
      >
        <ReportCollaboration
          commentingRowID={commentingRowID}
          onAddCommentToId={onAddCommentToId}
          onSetData={onSetData}
          onClose={() => setCommentingRowID(null)}
          onDeleteCommentById={onDeleteCommentById}
          workspaceId={workspaceId}
        />
      </SlidingSidePanel>
    </Block>
  );
});

export default TableRow;
