import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import PatentTabContent from "./patent-tab-content";
import TextTabContent from "./text-tab-content";

const InputSection = (props) => {
  const [key, setKey] = useState("patent");

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
    >
      <Tab eventKey="patent" title="Patent">
        <PatentTabContent {...props} />
      </Tab>
      <Tab eventKey="text" title="Text">
        <TextTabContent {...props} />
      </Tab>
    </Tabs>
  );
};

export default InputSection;
