const peggy = give_me_parser(); // Generated by Peggy 3.0.2.
//
// https://peggyjs.org/

function give_me_parser() {
  "use strict";

  function peg$subclass(child, parent) {
    function C() {
      this.constructor = child;
    }
    C.prototype = parent.prototype;
    child.prototype = new C();
  }

  function peg$SyntaxError(message, expected, found, location) {
    var self = Error.call(this, message);
    // istanbul ignore next Check is a necessary evil to support older environments
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(self, peg$SyntaxError.prototype);
    }
    self.expected = expected;
    self.found = found;
    self.location = location;
    self.name = "SyntaxError";
    return self;
  }

  peg$subclass(peg$SyntaxError, Error);

  function peg$padEnd(str, targetLength, padString) {
    padString = padString || " ";
    if (str.length > targetLength) {
      return str;
    }
    targetLength -= str.length;
    padString += padString.repeat(targetLength);
    return str + padString.slice(0, targetLength);
  }

  peg$SyntaxError.prototype.format = function (sources) {
    var str = "Error: " + this.message;
    if (this.location) {
      var src = null;
      var k;
      for (k = 0; k < sources.length; k++) {
        if (sources[k].source === this.location.source) {
          src = sources[k].text.split(/\r\n|\n|\r/g);
          break;
        }
      }
      var s = this.location.start;
      var offset_s =
        this.location.source &&
          typeof this.location.source.offset === "function"
          ? this.location.source.offset(s)
          : s;
      var loc =
        this.location.source + ":" + offset_s.line + ":" + offset_s.column;
      if (src) {
        var e = this.location.end;
        var filler = peg$padEnd("", offset_s.line.toString().length, " ");
        var line = src[s.line - 1];
        var last = s.line === e.line ? e.column : line.length + 1;
        var hatLen = last - s.column || 1;
        str +=
          "\n --> " +
          loc +
          "\n" +
          filler +
          " |\n" +
          offset_s.line +
          " | " +
          line +
          "\n" +
          filler +
          " | " +
          peg$padEnd("", s.column - 1, " ") +
          peg$padEnd("", hatLen, "^");
      } else {
        str += "\n at " + loc;
      }
    }
    return str;
  };

  peg$SyntaxError.buildMessage = function (expected, found) {
    var DESCRIBE_EXPECTATION_FNS = {
      literal: function (expectation) {
        return '"' + literalEscape(expectation.text) + '"';
      },

      class: function (expectation) {
        var escapedParts = expectation.parts.map(function (part) {
          return Array.isArray(part)
            ? classEscape(part[0]) + "-" + classEscape(part[1])
            : classEscape(part);
        });

        return (
          "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]"
        );
      },

      any: function () {
        return "any character";
      },

      end: function () {
        return "end of input";
      },

      other: function (expectation) {
        return expectation.description;
      },
    };

    function hex(ch) {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s) {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/"/g, '\\"')
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g, function (ch) {
          return "\\x0" + hex(ch);
        })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
          return "\\x" + hex(ch);
        });
    }

    function classEscape(s) {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/\]/g, "\\]")
        .replace(/\^/g, "\\^")
        .replace(/-/g, "\\-")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g, function (ch) {
          return "\\x0" + hex(ch);
        })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) {
          return "\\x" + hex(ch);
        });
    }

    function describeExpectation(expectation) {
      return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
    }

    function describeExpected(expected) {
      var descriptions = expected.map(describeExpectation);
      var i, j;

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return (
            descriptions.slice(0, -1).join(", ") +
            ", or " +
            descriptions[descriptions.length - 1]
          );
      }
    }

    function describeFound(found) {
      return found ? '"' + literalEscape(found) + '"' : "end of input";
    }

    return (
      "Expected " +
      describeExpected(expected) +
      " but " +
      describeFound(found) +
      " found."
    );
  };

  function peg$parse(input, options) {
    options = options !== undefined ? options : {};

    var peg$FAILED = {};
    var peg$source = options.grammarSource;

    var peg$startRuleFunctions = { start: peg$parsestart };
    var peg$startRuleFunction = peg$parsestart;

    var peg$c0 = ";";
    var peg$c1 = "(";
    var peg$c2 = ")";
    var peg$c3 = "=";
    var peg$c4 = ">=";
    var peg$c5 = "<=";
    var peg$c6 = "||";
    var peg$c7 = "&&";
    var peg$c8 = "!";
    var peg$c9 = ".";
    var peg$c10 = "\\";
    var peg$c11 = '"';
    var peg$c12 = '""';

    var peg$r0 = /^[<>]/;
    var peg$r1 = /^[oO]/;
    var peg$r2 = /^[rR]/;
    var peg$r3 = /^[aA]/;
    var peg$r4 = /^[nN]/;
    var peg$r5 = /^[dD]/;
    var peg$r6 = /^[tT]/;
    var peg$r7 = /^[jJ]/;
    var peg$r8 = /^[0-9]/;
    var peg$r9 = /^[eE]/;
    var peg$r10 = /^[sS]/;
    var peg$r11 = /^[mM]/;
    var peg$r12 = /^[+\-\/]/;
    var peg$r13 = /^[^= \t\r\n\f{}()"+-\/\^~[\]\^<\^>]/;
    var peg$r14 = /^[: =\t\r\n\f{}()"\/\^~[\]]/;
    var peg$r15 = /^[^"]/;
    var peg$r16 = /^[ \t\r\n\f]/;

    var peg$e0 = peg$literalExpectation(";", false);
    var peg$e1 = peg$literalExpectation("(", false);
    var peg$e2 = peg$literalExpectation(")", false);
    var peg$e3 = peg$literalExpectation("=", false);
    var peg$e4 = peg$literalExpectation(">=", false);
    var peg$e5 = peg$literalExpectation("<=", false);
    var peg$e6 = peg$classExpectation(["<", ">"], false, false);
    var peg$e7 = peg$classExpectation(["o", "O"], false, false);
    var peg$e8 = peg$classExpectation(["r", "R"], false, false);
    var peg$e9 = peg$classExpectation(["a", "A"], false, false);
    var peg$e10 = peg$classExpectation(["n", "N"], false, false);
    var peg$e11 = peg$classExpectation(["d", "D"], false, false);
    var peg$e12 = peg$classExpectation(["t", "T"], false, false);
    var peg$e13 = peg$literalExpectation("||", false);
    var peg$e14 = peg$literalExpectation("&&", false);
    var peg$e15 = peg$literalExpectation("!", false);
    var peg$e16 = peg$classExpectation(["j", "J"], false, false);
    var peg$e17 = peg$classExpectation([["0", "9"]], false, false);
    var peg$e18 = peg$classExpectation(["e", "E"], false, false);
    var peg$e19 = peg$classExpectation(["s", "S"], false, false);
    var peg$e20 = peg$classExpectation(["m", "M"], false, false);
    var peg$e21 = peg$classExpectation(["+", "-", "/"], false, false);
    var peg$e22 = peg$literalExpectation(".", false);
    var peg$e23 = peg$classExpectation(
      [
        "=",
        " ",
        "\t",
        "\r",
        "\n",
        "\f",
        "{",
        "}",
        "(",
        ")",
        '"',
        ["+", "/"],
        "^",
        "~",
        "[",
        "]",
        "^",
        "<",
        "^",
        ">",
      ],
      true,
      false
    );
    var peg$e24 = peg$literalExpectation("\\", false);
    var peg$e25 = peg$classExpectation(
      [
        ":",
        " ",
        "=",
        "\t",
        "\r",
        "\n",
        "\f",
        "{",
        "}",
        "(",
        ")",
        '"',
        "/",
        "^",
        "~",
        "[",
        "]",
      ],
      false,
      false
    );
    var peg$e26 = peg$literalExpectation('"', false);
    var peg$e27 = peg$classExpectation(['"'], true, false);
    var peg$e28 = peg$literalExpectation('""', false);
    var peg$e29 = peg$otherExpectation("whitespace");
    var peg$e30 = peg$classExpectation(
      [" ", "\t", "\r", "\n", "\f"],
      false,
      false
    );
    var peg$e31 = peg$anyExpectation();

    var peg$f0 = function (node, end) {
      function processArray(nodeArray) {
        if (Array.isArray(nodeArray)) {
          return processNode(nodeArray, {}, nodeArray.length - 1);
        } else {
          return nodeArray;
        }
      }
      function processNode(values, node, last) {
        if (last === 0) {
          node = processArray(values[last]);
          //node.text = node.left.text;
        } else if (last === 2) {
          node.left = processArray(values[last - 2]);
          node.operator = values[last - 1];
          node.right = processArray(values[last]);
          node.text =
            node.left.text + " " + node.operator + " " + node.right.text;
        } else {
          node.left = processNode(values, {}, last - 2);
          node.operator = values[last - 1];
          node.right = processArray(values[last]);
          node.text =
            node.left.text + " " + node.operator + " " + node.right.text;
        }
        return node;
      }
      return processArray(node[0]);
      return node[0];
    };
    var peg$f1 = function (field_group, operator, right_group) {
      if (operator[0] && right_group[0]) {
        if (right_group[0].parenthesis) {
          return [field_group, operator[0], right_group[0]];
        }

        return [field_group, operator[0], ...right_group[0]];
      }

      if (right_group.length > 0 && !operator.length) {
        throw new Error("Operator cannot be missing");
      }

      if (!right_group.length && operator.length > 0) {
        throw new Error("Please add the operand after operator " + JSON.stringify(operator));
      }

      return [field_group];
    };
    var peg$f2 = function (node) {
      //node[0]['parenthesis']=true
      return node[0];
    };
    var peg$f3 = function (field, op, value) {
      var text = "(" + field + " " + op + " " + value.text + ")";
      var newLeft = {};
      if (value.left) newLeft.left = value.left;
      if (value.operator) newLeft.operator = value.operator;
      if (value.right) newLeft.right = value.right;

      return [{ ...newLeft, text, field, fieldOp: op }];
    };
    var peg$f4 = function (node) {
      function textValue(node) {
        if (node.text) {
          return node.text;
        } else if (node.term) {
          return node.term;
        }
        return node;
      }

      function nodeValue(value) {
        if (typeof value === "object") {
          return value;
        } else {
          return {
            fieldOp: "<implicit>",
            field: "<implicit>",
            term: value,
            text: value,
          };
        }
      }
      function processNode(values, node, last) {
        if (last == 0) {
          node.left = nodeValue(processArray(values[last]));
          node.text = "(" + textValue(node.left) + ")";
        } else if (last === 2) {
          node.left = nodeValue(processArray(values[0]));
          node.operator = values[1];
          node.right = nodeValue(processArray(values[2]));
          node.text =
            "(" +
            textValue(node.left) +
            " " +
            node.operator +
            " " +
            textValue(node.right) +
            ")";
        } else {
          node.left = nodeValue(processNode(values, {}, last - 2));
          node.operator = values[last - 1];
          node.right = nodeValue(processArray(values[last]));
          node.text =
            "(" +
            textValue(node.left) +
            " " +
            node.operator +
            " " +
            textValue(node.right) +
            ")";
        }
        return node;
      }
      function processArray(nodeArray) {
        if (!Array.isArray(nodeArray)) {
          return nodeArray;
        }
        return processNode(nodeArray, {}, nodeArray.length - 1);
      }
      return processArray(node[0]);
    };
    var peg$f5 = function (left, operator, right) {
      if (right[0]) {
        if (operator.length > 1) {
          var e = new Error(
            "Consecutive operators are not allowed " +
            operator.join(" ") +
            "."
          );
          e.name = "SyntaxError";
          e.column = location;
          throw e;
        }
      }
      if (operator[0] && right[0]) {
        if (right[0].parenthesis) {
          delete right[0].parenthesis;
          return [left, operator[0], right[0]];
        }

        return [left, operator[0], ...right[0]];
      }

      if (right[0]) {
        return [left, "<implicit>", ...right[0]];
      }
      return [left];
    };
    var peg$f6 = function (left) {
      return left[0];
    };
    var peg$f7 = function (term) {
      return term;
    };
    var peg$f8 = function (term) {
      return term;
    };
    var peg$f9 = function (term) {
      return term;
    };
    var peg$f10 = function (term) {
      return " ";
    };
    var peg$f11 = function (node) {
      node[0]["parenthesis"] = true;
      return node[0];
    };
    var peg$f12 = function (operator) {
      return operator;
    };
    var peg$f13 = function (operator) {
      return operator;
    };
    var peg$f14 = function (term_start, term) {
      var res = term_start + term.join("");
      if (
        /^(?:[aA][nN][dD]|[oO][rR]|[nN][oO][tT]|[aA][dD][jJ][0-9]{0,2}|[nN][eE][aA][rR]{0,2}|[sS][aA][mM][eE]|\|\||&&)$/.test(
          res
        )
      ) {
        var e = new Error(
          "Term can not be AND, OR, NOT, ADJ, NEAR, SAME ||, && " + term
        );
        e.name = "SyntaxError";
        e.column = location;
        throw e;
      }
      return res;
    };
    var peg$f15 = function (operator) {
      return operator;
    };
    var peg$f16 = function (operator) {
      return operator;
    };
    var peg$f17 = function () {
      return "OR";
    };
    var peg$f18 = function () {
      return "AND";
    };
    var peg$f19 = function () {
      return "NOT";
    };
    var peg$f20 = function () {
      return "OR";
    };
    var peg$f21 = function () {
      return "AND";
    };
    var peg$f22 = function () {
      return "NOT";
    };
    var peg$f23 = function (val) {
      if (val.join("") > 99) {
        var e = new Error("Proximity number greater than 99");
        throw e;
      }
      return "ADJ" + ":" + val.join("");
    };
    var peg$f24 = function (val) {
      if (val.join("") > 99) {
        var e = new Error("Proximity number greater than 99");
        throw e;
      }
      return "NEAR" + ":" + val.join("");
    };
    var peg$f25 = function (val) {
      if (val.join("") > 99) {
        var e = new Error("Proximity number greater than 99");
        throw e;
      }
      return "SAME" + ":" + val.join("");
    };
    var peg$f26 = function (operator) {
      return operator;
    };
    var peg$f27 = function (operator) {
      return operator;
    };
    var peg$f28 = function () {
      return "OR";
    };
    var peg$f29 = function () {
      return "AND";
    };
    var peg$f30 = function () {
      return "NOT";
    };
    var peg$f31 = function () {
      return "OR";
    };
    var peg$f32 = function () {
      return "AND";
    };
    var peg$f33 = function () {
      return "NOT";
    };
    var peg$f34 = function (escaping_char) {
      return "\\" + escaping_char;
    };
    var peg$f35 = function (term) {
      return term.join("");
    };
    var peg$currPos = options.peg$currPos | 0;
    var peg$savedPos = peg$currPos;
    var peg$posDetailsCache = [{ line: 1, column: 1 }];
    var peg$maxFailPos = peg$currPos;
    var peg$maxFailExpected = options.peg$maxFailExpected || [];
    var peg$silentFails = options.peg$silentFails | 0;

    var peg$result;

    if (options.startRule) {
      if (!(options.startRule in peg$startRuleFunctions)) {
        throw new Error(
          "Parser Error \"" + options.startRule + '".'
        );
      }

      peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }

    function text() {
      return input.substring(peg$savedPos, peg$currPos);
    }

    function offset() {
      return peg$savedPos;
    }

    function range() {
      return {
        source: peg$source,
        start: peg$savedPos,
        end: peg$currPos,
      };
    }

    function location() {
      return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function expected(description, location) {
      location =
        location !== undefined
          ? location
          : peg$computeLocation(peg$savedPos, peg$currPos);

      throw peg$buildStructuredError(
        [peg$otherExpectation(description)],
        input.substring(peg$savedPos, peg$currPos),
        location
      );
    }

    function error(message, location) {
      location =
        location !== undefined
          ? location
          : peg$computeLocation(peg$savedPos, peg$currPos);

      throw peg$buildSimpleError(message, location);
    }

    function peg$literalExpectation(text, ignoreCase) {
      return { type: "literal", text: text, ignoreCase: ignoreCase };
    }

    function peg$classExpectation(parts, inverted, ignoreCase) {
      return {
        type: "class",
        parts: parts,
        inverted: inverted,
        ignoreCase: ignoreCase,
      };
    }

    function peg$anyExpectation() {
      return { type: "any" };
    }

    function peg$endExpectation() {
      return { type: "end" };
    }

    function peg$otherExpectation(description) {
      return { type: "other", description: description };
    }

    function peg$computePosDetails(pos) {
      var details = peg$posDetailsCache[pos];
      var p;

      if (details) {
        return details;
      } else {
        if (pos >= peg$posDetailsCache.length) {
          p = peg$posDetailsCache.length - 1;
        } else {
          p = pos;
          while (!peg$posDetailsCache[--p]) { }
        }

        details = peg$posDetailsCache[p];
        details = {
          line: details.line,
          column: details.column,
        };

        while (p < pos) {
          if (input.charCodeAt(p) === 10) {
            details.line++;
            details.column = 1;
          } else {
            details.column++;
          }

          p++;
        }

        peg$posDetailsCache[pos] = details;

        return details;
      }
    }

    function peg$computeLocation(startPos, endPos, offset) {
      var startPosDetails = peg$computePosDetails(startPos);
      var endPosDetails = peg$computePosDetails(endPos);

      var res = {
        source: peg$source,
        start: {
          offset: startPos,
          line: startPosDetails.line,
          column: startPosDetails.column,
        },
        end: {
          offset: endPos,
          line: endPosDetails.line,
          column: endPosDetails.column,
        },
      };
      if (offset && peg$source && typeof peg$source.offset === "function") {
        res.start = peg$source.offset(res.start);
        res.end = peg$source.offset(res.end);
      }
      return res;
    }

    function peg$fail(expected) {
      if (peg$currPos < peg$maxFailPos) {
        return;
      }

      if (peg$currPos > peg$maxFailPos) {
        peg$maxFailPos = peg$currPos;
        peg$maxFailExpected = [];
      }

      peg$maxFailExpected.push(expected);
    }

    function peg$buildSimpleError(message, location) {
      return new peg$SyntaxError(message, null, null, location);
    }

    function peg$buildStructuredError(expected, found, location) {
      return new peg$SyntaxError(
        peg$SyntaxError.buildMessage(expected, found),
        expected,
        found,
        location
      );
    }

    function peg$parsestart() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsefield_group_exp();
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsefield_group_exp();
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        if (input.charCodeAt(peg$currPos) === 59) {
          s3 = peg$c0;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e0);
          }
        }
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          if (input.charCodeAt(peg$currPos) === 59) {
            s3 = peg$c0;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e0);
            }
          }
        }
        peg$savedPos = s0;
        s0 = peg$f0(s1, s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsefield_group_exp() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parsefield_exp();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseoperator_field_exp();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseoperator_field_exp();
        }
        s3 = [];
        s4 = peg$parsefield_group_exp();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parsefield_group_exp();
        }
        peg$savedPos = s0;
        s0 = peg$f1(s1, s2, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseparen_field_group_exp() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c1;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e1);
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parse_();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parse_();
        }
        s3 = [];
        s4 = peg$parsefield_group_exp();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parsefield_group_exp();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parse_();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parse_();
          }
          if (input.charCodeAt(peg$currPos) === 41) {
            s5 = peg$c2;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e2);
            }
          }
          if (s5 !== peg$FAILED) {
            s6 = [];
            s7 = peg$parse_();
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$parse_();
            }
            peg$savedPos = s0;
            s0 = peg$f2(s3);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsefield_exp() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = peg$parseunquoted_term();
      if (s1 !== peg$FAILED) {
        s2 = peg$parsefield_operator_exp();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseparen_value_exp();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f3(s1, s2, s3);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parseparen_field_group_exp();
      }

      return s0;
    }

    function peg$parsevalue_group_main() {
      var s0, s1, s2;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parsevalue_group();
      if (s2 !== peg$FAILED) {
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parsevalue_group();
        }
      } else {
        s1 = peg$FAILED;
      }
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f4(s1);
      }
      s0 = s1;

      return s0;
    }

    function peg$parsevalue_group() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = peg$parseterm_exp_array();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseoperator_exp();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseoperator_exp();
        }
        s3 = [];
        s4 = peg$parsevalue_group();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parsevalue_group();
        }
        peg$savedPos = s0;
        s0 = peg$f5(s1, s2, s3);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parseterm_exp_array();
        if (s2 !== peg$FAILED) {
          while (s2 !== peg$FAILED) {
            s1.push(s2);
            s2 = peg$parseterm_exp_array();
          }
        } else {
          s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$f6(s1);
        }
        s0 = s1;
      }

      return s0;
    }

    function peg$parseterm_exp_array() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parse_();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parse_();
      }
      s2 = peg$parseterm_exp();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parse_();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parse_();
        }
        peg$savedPos = s0;
        s0 = peg$f7(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseterm_exp() {
      var s0, s1;

      s0 = peg$currPos;
      s1 = peg$parsequoted_term();
      if (s1 !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$f8(s1);
      }
      s0 = s1;
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseunquoted_term();
        if (s1 !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$f9(s1);
        }
        s0 = s1;
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parseblank_term();
          if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f10(s1);
          }
          s0 = s1;
          if (s0 === peg$FAILED) {
            s0 = peg$parseparen_value_exp();
          }
        }
      }

      return s0;
    }

    function peg$parseparen_value_exp() {
      var s0, s1, s2, s3, s4, s5, s6, s7;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 40) {
        s1 = peg$c1;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e1);
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parse_();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parse_();
        }
        s3 = [];
        s4 = peg$parsevalue_group_main();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parsevalue_group_main();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          s4 = [];
          s5 = peg$parse_();
          while (s5 !== peg$FAILED) {
            s4.push(s5);
            s5 = peg$parse_();
          }
          if (input.charCodeAt(peg$currPos) === 41) {
            s5 = peg$c2;
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e2);
            }
          }
          if (s5 !== peg$FAILED) {
            s6 = [];
            s7 = peg$parse_();
            while (s7 !== peg$FAILED) {
              s6.push(s7);
              s7 = peg$parse_();
            }
            peg$savedPos = s0;
            s0 = peg$f11(s3);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsefield_operator_exp() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parse_();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parse_();
      }
      s2 = peg$parsefield_operator();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parse_();
        while (s4 !== peg$FAILED) {
          s3.push(s4);
          s4 = peg$parse_();
        }
        peg$savedPos = s0;
        s0 = peg$f12(s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parse_();
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parse_();
        }
        s2 = peg$parsefield_operator();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseEOF();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f13(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parsefield_operator() {
      var s0;

      if (input.charCodeAt(peg$currPos) === 61) {
        s0 = peg$c3;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e3);
        }
      }
      if (s0 === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c4) {
          s0 = peg$c4;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e4);
          }
        }
        if (s0 === peg$FAILED) {
          if (input.substr(peg$currPos, 2) === peg$c5) {
            s0 = peg$c5;
            peg$currPos += 2;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e5);
            }
          }
          if (s0 === peg$FAILED) {
            s0 = input.charAt(peg$currPos);
            if (peg$r0.test(s0)) {
              peg$currPos++;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e6);
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseunquoted_term() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = peg$parseterm_start_char();
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = peg$parseterm_char();
        while (s3 !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parseterm_char();
        }
        peg$savedPos = s0;
        s0 = peg$f14(s1, s2);
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseoperator_exp() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parse_();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parse_();
      }
      s2 = peg$parseoperator();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parse_();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f15(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parse_();
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parse_();
        }
        s2 = peg$parseoperator();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseEOF();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f16(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parseoperator() {
      var s0, s1, s2, s3, s4, s5, s6;

      s0 = peg$currPos;
      s1 = input.charAt(peg$currPos);
      if (peg$r1.test(s1)) {
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e7);
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = input.charAt(peg$currPos);
        if (peg$r2.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e8);
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f17();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = input.charAt(peg$currPos);
        if (peg$r3.test(s1)) {
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e9);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = input.charAt(peg$currPos);
          if (peg$r4.test(s2)) {
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e10);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = input.charAt(peg$currPos);
            if (peg$r5.test(s3)) {
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e11);
              }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f18();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = input.charAt(peg$currPos);
          if (peg$r4.test(s1)) {
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e10);
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = input.charAt(peg$currPos);
            if (peg$r1.test(s2)) {
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e7);
              }
            }
            if (s2 !== peg$FAILED) {
              s3 = input.charAt(peg$currPos);
              if (peg$r6.test(s3)) {
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$e12);
                }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f19();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c6) {
              s1 = peg$c6;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e13);
              }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$f20();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 2) === peg$c7) {
                s1 = peg$c7;
                peg$currPos += 2;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$e14);
                }
              }
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$f21();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 33) {
                  s1 = peg$c8;
                  peg$currPos++;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$e15);
                  }
                }
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$f22();
                }
                s0 = s1;
                if (s0 === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = input.charAt(peg$currPos);
                  if (peg$r3.test(s1)) {
                    peg$currPos++;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) {
                      peg$fail(peg$e9);
                    }
                  }
                  if (s1 !== peg$FAILED) {
                    s2 = input.charAt(peg$currPos);
                    if (peg$r5.test(s2)) {
                      peg$currPos++;
                    } else {
                      s2 = peg$FAILED;
                      if (peg$silentFails === 0) {
                        peg$fail(peg$e11);
                      }
                    }
                    if (s2 !== peg$FAILED) {
                      s3 = input.charAt(peg$currPos);
                      if (peg$r7.test(s3)) {
                        peg$currPos++;
                      } else {
                        s3 = peg$FAILED;
                        if (peg$silentFails === 0) {
                          peg$fail(peg$e16);
                        }
                      }
                      if (s3 !== peg$FAILED) {
                        s4 = [];
                        s5 = input.charAt(peg$currPos);
                        if (peg$r8.test(s5)) {
                          peg$currPos++;
                        } else {
                          s5 = peg$FAILED;
                          if (peg$silentFails === 0) {
                            peg$fail(peg$e17);
                          }
                        }
                        while (s5 !== peg$FAILED) {
                          s4.push(s5);
                          s5 = input.charAt(peg$currPos);
                          if (peg$r8.test(s5)) {
                            peg$currPos++;
                          } else {
                            s5 = peg$FAILED;
                            if (peg$silentFails === 0) {
                              peg$fail(peg$e17);
                            }
                          }
                        }
                        peg$savedPos = s0;
                        s0 = peg$f23(s4);
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                  if (s0 === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = input.charAt(peg$currPos);
                    if (peg$r4.test(s1)) {
                      peg$currPos++;
                    } else {
                      s1 = peg$FAILED;
                      if (peg$silentFails === 0) {
                        peg$fail(peg$e10);
                      }
                    }
                    if (s1 !== peg$FAILED) {
                      s2 = input.charAt(peg$currPos);
                      if (peg$r9.test(s2)) {
                        peg$currPos++;
                      } else {
                        s2 = peg$FAILED;
                        if (peg$silentFails === 0) {
                          peg$fail(peg$e18);
                        }
                      }
                      if (s2 !== peg$FAILED) {
                        s3 = input.charAt(peg$currPos);
                        if (peg$r3.test(s3)) {
                          peg$currPos++;
                        } else {
                          s3 = peg$FAILED;
                          if (peg$silentFails === 0) {
                            peg$fail(peg$e9);
                          }
                        }
                        if (s3 !== peg$FAILED) {
                          s4 = input.charAt(peg$currPos);
                          if (peg$r2.test(s4)) {
                            peg$currPos++;
                          } else {
                            s4 = peg$FAILED;
                            if (peg$silentFails === 0) {
                              peg$fail(peg$e8);
                            }
                          }
                          if (s4 !== peg$FAILED) {
                            s5 = [];
                            s6 = input.charAt(peg$currPos);
                            if (peg$r8.test(s6)) {
                              peg$currPos++;
                            } else {
                              s6 = peg$FAILED;
                              if (peg$silentFails === 0) {
                                peg$fail(peg$e17);
                              }
                            }
                            while (s6 !== peg$FAILED) {
                              s5.push(s6);
                              s6 = input.charAt(peg$currPos);
                              if (peg$r8.test(s6)) {
                                peg$currPos++;
                              } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                  peg$fail(peg$e17);
                                }
                              }
                            }
                            peg$savedPos = s0;
                            s0 = peg$f24(s5);
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                    if (s0 === peg$FAILED) {
                      s0 = peg$currPos;
                      s1 = input.charAt(peg$currPos);
                      if (peg$r10.test(s1)) {
                        peg$currPos++;
                      } else {
                        s1 = peg$FAILED;
                        if (peg$silentFails === 0) {
                          peg$fail(peg$e19);
                        }
                      }
                      if (s1 !== peg$FAILED) {
                        s2 = input.charAt(peg$currPos);
                        if (peg$r3.test(s2)) {
                          peg$currPos++;
                        } else {
                          s2 = peg$FAILED;
                          if (peg$silentFails === 0) {
                            peg$fail(peg$e9);
                          }
                        }
                        if (s2 !== peg$FAILED) {
                          s3 = input.charAt(peg$currPos);
                          if (peg$r11.test(s3)) {
                            peg$currPos++;
                          } else {
                            s3 = peg$FAILED;
                            if (peg$silentFails === 0) {
                              peg$fail(peg$e20);
                            }
                          }
                          if (s3 !== peg$FAILED) {
                            s4 = input.charAt(peg$currPos);
                            if (peg$r9.test(s4)) {
                              peg$currPos++;
                            } else {
                              s4 = peg$FAILED;
                              if (peg$silentFails === 0) {
                                peg$fail(peg$e18);
                              }
                            }
                            if (s4 !== peg$FAILED) {
                              s5 = [];
                              s6 = input.charAt(peg$currPos);
                              if (peg$r8.test(s6)) {
                                peg$currPos++;
                              } else {
                                s6 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                  peg$fail(peg$e17);
                                }
                              }
                              while (s6 !== peg$FAILED) {
                                s5.push(s6);
                                s6 = input.charAt(peg$currPos);
                                if (peg$r8.test(s6)) {
                                  peg$currPos++;
                                } else {
                                  s6 = peg$FAILED;
                                  if (peg$silentFails === 0) {
                                    peg$fail(peg$e17);
                                  }
                                }
                              }
                              peg$savedPos = s0;
                              s0 = peg$f25(s5);
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseoperator_field_exp() {
      var s0, s1, s2, s3, s4;

      s0 = peg$currPos;
      s1 = [];
      s2 = peg$parse_();
      while (s2 !== peg$FAILED) {
        s1.push(s2);
        s2 = peg$parse_();
      }
      s2 = peg$parseoperator_field();
      if (s2 !== peg$FAILED) {
        s3 = [];
        s4 = peg$parse_();
        if (s4 !== peg$FAILED) {
          while (s4 !== peg$FAILED) {
            s3.push(s4);
            s4 = peg$parse_();
          }
        } else {
          s3 = peg$FAILED;
        }
        if (s3 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f26(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parse_();
        while (s2 !== peg$FAILED) {
          s1.push(s2);
          s2 = peg$parse_();
        }
        s2 = peg$parseoperator_field();
        if (s2 !== peg$FAILED) {
          s3 = peg$parseEOF();
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f27(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }

      return s0;
    }

    function peg$parseoperator_field() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      s1 = input.charAt(peg$currPos);
      if (peg$r1.test(s1)) {
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e7);
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = input.charAt(peg$currPos);
        if (peg$r2.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e8);
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f28();
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 === peg$FAILED) {
        s0 = peg$currPos;
        s1 = input.charAt(peg$currPos);
        if (peg$r3.test(s1)) {
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e9);
          }
        }
        if (s1 !== peg$FAILED) {
          s2 = input.charAt(peg$currPos);
          if (peg$r4.test(s2)) {
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e10);
            }
          }
          if (s2 !== peg$FAILED) {
            s3 = input.charAt(peg$currPos);
            if (peg$r5.test(s3)) {
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e11);
              }
            }
            if (s3 !== peg$FAILED) {
              peg$savedPos = s0;
              s0 = peg$f29();
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
          s0 = peg$currPos;
          s1 = input.charAt(peg$currPos);
          if (peg$r4.test(s1)) {
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e10);
            }
          }
          if (s1 !== peg$FAILED) {
            s2 = input.charAt(peg$currPos);
            if (peg$r1.test(s2)) {
              peg$currPos++;
            } else {
              s2 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e7);
              }
            }
            if (s2 !== peg$FAILED) {
              s3 = input.charAt(peg$currPos);
              if (peg$r6.test(s3)) {
                peg$currPos++;
              } else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$e12);
                }
              }
              if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f30();
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 2) === peg$c6) {
              s1 = peg$c6;
              peg$currPos += 2;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e13);
              }
            }
            if (s1 !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$f31();
            }
            s0 = s1;
            if (s0 === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 2) === peg$c7) {
                s1 = peg$c7;
                peg$currPos += 2;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                  peg$fail(peg$e14);
                }
              }
              if (s1 !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$f32();
              }
              s0 = s1;
              if (s0 === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 33) {
                  s1 = peg$c8;
                  peg$currPos++;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) {
                    peg$fail(peg$e15);
                  }
                }
                if (s1 !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$f33();
                }
                s0 = s1;
              }
            }
          }
        }
      }

      return s0;
    }

    function peg$parseterm_char() {
      var s0;

      s0 = input.charAt(peg$currPos);
      if (peg$r12.test(s0)) {
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e21);
        }
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parseterm_escaping_char();
        if (s0 === peg$FAILED) {
          s0 = peg$parseterm_start_char();
        }
      }

      return s0;
    }

    function peg$parseterm_start_char() {
      var s0;

      if (input.charCodeAt(peg$currPos) === 46) {
        s0 = peg$c9;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e22);
        }
      }
      if (s0 === peg$FAILED) {
        s0 = peg$parseterm_escaping_char();
        if (s0 === peg$FAILED) {
          s0 = input.charAt(peg$currPos);
          if (peg$r13.test(s0)) {
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e23);
            }
          }
        }
      }

      return s0;
    }

    function peg$parseterm_escaping_char() {
      var s0, s1, s2;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s1 = peg$c10;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e24);
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = input.charAt(peg$currPos);
        if (peg$r14.test(s2)) {
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e25);
          }
        }
        if (s2 !== peg$FAILED) {
          peg$savedPos = s0;
          s0 = peg$f34(s2);
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parsequoted_term() {
      var s0, s1, s2, s3;

      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 34) {
        s1 = peg$c11;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e26);
        }
      }
      if (s1 !== peg$FAILED) {
        s2 = [];
        s3 = input.charAt(peg$currPos);
        if (peg$r15.test(s3)) {
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) {
            peg$fail(peg$e27);
          }
        }
        if (s3 !== peg$FAILED) {
          while (s3 !== peg$FAILED) {
            s2.push(s3);
            s3 = input.charAt(peg$currPos);
            if (peg$r15.test(s3)) {
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) {
                peg$fail(peg$e27);
              }
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 34) {
            s3 = peg$c11;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e26);
            }
          }
          if (s3 !== peg$FAILED) {
            peg$savedPos = s0;
            s0 = peg$f35(s2);
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    function peg$parseblank_term() {
      var s0;

      if (input.substr(peg$currPos, 2) === peg$c12) {
        s0 = peg$c12;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e28);
        }
      }

      return s0;
    }

    function peg$parse_() {
      var s0, s1;

      peg$silentFails++;
      s0 = [];
      s1 = input.charAt(peg$currPos);
      if (peg$r16.test(s1)) {
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e30);
        }
      }
      if (s1 !== peg$FAILED) {
        while (s1 !== peg$FAILED) {
          s0.push(s1);
          s1 = input.charAt(peg$currPos);
          if (peg$r16.test(s1)) {
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
              peg$fail(peg$e30);
            }
          }
        }
      } else {
        s0 = peg$FAILED;
      }
      peg$silentFails--;
      if (s0 === peg$FAILED) {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e29);
        }
      }

      return s0;
    }

    function peg$parseEOF() {
      var s0, s1;

      s0 = peg$currPos;
      peg$silentFails++;
      if (input.length > peg$currPos) {
        s1 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
          peg$fail(peg$e31);
        }
      }
      peg$silentFails--;
      if (s1 === peg$FAILED) {
        s0 = undefined;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }

      return s0;
    }

    peg$result = peg$startRuleFunction();

    if (options.peg$library) {
      return /** @type {any} */ ({
        peg$result,
        peg$currPos,
        peg$FAILED,
        peg$maxFailExpected,
        peg$maxFailPos,
      });
    }
    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
      return peg$result;
    } else {
      if (peg$result !== peg$FAILED && peg$currPos < input.length) {
        peg$fail(peg$endExpectation());
      }

      throw peg$buildStructuredError(
        peg$maxFailExpected,
        peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
        peg$maxFailPos < input.length
          ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
          : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
      );
    }
  }

  return {
    StartRules: ["start"],
    SyntaxError: peg$SyntaxError,
    parse: peg$parse,
  };
}
export default peggy;
