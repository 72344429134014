import React, { useState } from "react";
import { addWithBlackBgIcon } from "../../../../assets/images/report";
import UsersTable from "./users-table";
import AddCollaboratorModal from "./add-collaborator-modal";

const ProjectSharedWithSection = (props) => {
  const { users, headingText = "" } = props;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="d-flex justify-content-between ">
        <div className="heading-text ">{headingText}</div>

        <div>
          <button className="new-access-btn" onClick={() => setShowModal(true)}>
            <img alt="" src={addWithBlackBgIcon} className="pe-2" />
            Add Collaborator
          </button>
        </div>
      </div>

      <UsersTable
        users={users}
        headers={["ID", "Username", "Email-Id", "Role", "Rights"]}
        isSelectable={false}
        hasActionCol={true}
        {...props}
      />

      <AddCollaboratorModal
        setShowModal={setShowModal}
        showModal={showModal}
        users={[users[0]]}
        {...props}
      />
    </>
  );
};

export default ProjectSharedWithSection;
