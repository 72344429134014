import React, { useState } from "react";
import KeywordsTable from "./keywords-table";
import FieldSelectDropdown from "./field-select-dropdown";
import { keywordFieldsOptions } from "../../../resources/data/options";
import { uuidv4 } from "../../../action/app";

const KeywordsSection = (props) => {
  const {
    tableElements,
    onSetApp,
    onSetAllField,
    onSetClassesModal,
    onSetKeywordModal,
    setIsGenerateKeywordsSection,
    existingKeyword,
    isReportModal,
    onSelect,
    onApply,
    selectedKeywordsBox,
    onSetKeywordSelector,
    currentFieldedCursorLocation,
    queryFields,
    isEditModalOpen,
    localQueryFields,
    isKeywordsGeneratorLoading,
  } = props;
  const [queryField, setQueryField] = useState({
    title: "Title",
    code: "TI",
  });

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const handleApply = () => {
    if (selectedKeywordsBox.length < 1) return;
    if (!isReportModal) {
      const fieldData = isEditModalOpen
        ? [...localQueryFields]
        : [...queryFields];
      // Find the target field based on the current cursor location
      const targetField = fieldData.find(
        (item) => item?.id === currentFieldedCursorLocation?.item?.id
      );

      // Check if the current cursor location and query field match, and if a target field exists
      if (
        currentFieldedCursorLocation &&
        queryField.code === currentFieldedCursorLocation.item.query &&
        targetField
      ) {
        let updatedValue = "";

        // Define the cursor position
        const cursorPosition = currentFieldedCursorLocation.cursorLocation;

        // Split the targetField.filedsinput into two parts at the cursor position
        const firstPart = targetField.filedsinput.slice(0, cursorPosition);
        const secondPart = targetField.filedsinput.slice(cursorPosition);

        // Construct the updatedValue by inserting the additional string between the two parts
        updatedValue = targetField.filedsinput
          ? firstPart +
            " OR " +
            selectedKeywordsBox.join(" OR ") +
            " OR " +
            secondPart
          : selectedKeywordsBox.join(" OR ");

        // Update the fields with the new value for the target field
        const updatedFields = fieldData.map((field) => {
          if (currentFieldedCursorLocation.item.id === field.id) {
            return {
              ...field,
              filedsinput: updatedValue,
              countryerror: "",
              alertData: "",
            };
          } else {
            return {
              ...field,
            };
          }
        });

        // Set all fields with the updated fields
        if (isEditModalOpen) {
          onSetAllField({
            localQueryFields: updatedFields,
          });
        } else {
          onSetAllField({
            queryFields: updatedFields,
          });
        }
      } else {
        // Generate a query using search words and selected keywords
        const query = selectedKeywordsBox.join(" OR ");
        // Create a new field with the generated query and other default values
        const newField = {
          id: uuidv4(),
          lang: "eng",
          type: queryField.title,
          dattype: "Text",
          query: queryField.code,
          error: "",
          operatersvalue: "AND",
          placeholder: "E.g. Wireless OR Mobile",
          filedsinput: query,
          textLabel: "Text",
          startdate: "",
          enddate: "",
          fielderror: "",
          dateerror: "",
        };

        // Add the new field to the list of fields
        const updatedFields = [...fieldData, newField];

        // Set all fields with the updated fields
        if (isEditModalOpen) {
          onSetAllField({
            localQueryFields: updatedFields,
          });
        } else {
          onSetAllField({
            queryFields: updatedFields,
          });
        }
      }

      onSetClassesModal({
        fromClasssSuggestor: true,
      });
      onSetKeywordModal({
        isOpenKeywordSuggestorModal: false,
        tableData: [],
      });
      onSetKeywordSelector([]);
      setIsGenerateKeywordsSection(false);
      // Reset cursor location
      onSetApp({
        currentFieldedCursorLocation: null,
      });
    } else {
      onApply();
    }
  };

  const handleClear = () => {
    onSetKeywordSelector([]);
    setIsSelectAllChecked(false);
  };

  const handleFieldChange = (field) => {
    setQueryField({ ...field, title: field.label });
    onSelect && onSelect({ name: field.label, id: field.code });
  };

  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setIsSelectAllChecked(isChecked);
    if (isChecked) {
      onSetKeywordSelector(tableElements);
    } else {
      onSetKeywordSelector([]);
    }
  };

  return (
    <div className="keywords-result-section mt-2 d-flex flex-column flex-grow-1">
      <div className=" keyword-border d-flex ">
        <div className="table-heading">
          <div className="fw-bold">Keywords</div>
          <div className="fw-bold pe-3 ms-auto">Add In:</div>
          {isReportModal ? (
            <FieldSelectDropdown
              onChange={handleFieldChange}
              data={existingKeyword}
              placeholder="Select"
            />
          ) : (
            <FieldSelectDropdown
              onChange={handleFieldChange}
              data={keywordFieldsOptions}
              defaultValue={queryField.title}
            />
          )}
        </div>
        <KeywordsTable
          options={tableElements}
          selectedRows={selectedKeywordsBox}
          onSetSelectedRows={onSetKeywordSelector}
          isKeywordsGeneratorLoading={isKeywordsGeneratorLoading}
        />
      </div>
      <div className="d-flex  mt-auto action-buttons mx-2 mb-2">
        <div className="mt-3">
          <input
            className="cursor-pointer mr-2"
            type="checkbox"
            checked={isSelectAllChecked}
            onChange={handleSelectAllChange}
            disabled={tableElements.length === 0}
          />
          <span
            className={`select-all-text ${
              selectedKeywordsBox.length > 0 ? "" : "opacity-75"
            } `}
          >
            Select All
          </span>
        </div>
        <button
          className={`yellow-primary-button-color ms-auto ${
            selectedKeywordsBox.length > 0 ? "" : "opacity-75"
          }`}
          onClick={handleClear}
          disabled={selectedKeywordsBox.length < 1}
        >
          Clear
        </button>
        <button
          className={`blue-primary-button-color  ${
            selectedKeywordsBox.length > 0 ? "" : "opacity-75"
          }`}
          onClick={handleApply}
          disabled={selectedKeywordsBox.length < 1}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default KeywordsSection;
