import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "rsuite";
import { HighlightCustomiseModal } from "../../common/highlight";
import { setDetails, setFilterFields } from "../../action/result-table";
import { setHighlight } from "../../action/highlight";

const Container = (props) => {
  const { isVisibleCustomiseHighlightModal } = props;

  return (
    <Modal
      className="modalCustomizeFilter"
      open={isVisibleCustomiseHighlightModal}
      static="static"
    >
      <HighlightCustomiseModal {...props}/>
    </Modal>
  );
};

const mapStateToProps = ({ resultTable: { isExact }, highlight }) => ({
  ...highlight,
  isExact,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetDetails: setDetails,
      onSetFilter: setFilterFields,
      onSetHighlight: setHighlight,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
