const initialState = {
  isOpenClassesSuggestorModal: false,
  tableData: [],
  classesSugestorQuery: [],
  itemsPerPage: 10,
  pageSize: 10,
  page: 1,
  limit: 10,
  dataFrom: 0,
  totalRecordsCount: 0,
  selectedRows: [],
  selectedRowsWithCodeAndDefination: [],
  selectedItemList: [],
  classesType: "cpc",
  formData: { classes: "", keywords: "" },
  fromClasssSuggestor: false,
  isNoResultFound: false,
};

const classesSuggestor = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CLASSES_SUGGESTOR_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default classesSuggestor;
