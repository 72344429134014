const initialState = {
  dataFrom: 0,
  keywordData: [],
  selectedRows: [],
  selectedKeywords: [],
  tableData: [],
  isSynonymsLoading: false,
  column: [
    {
      columnName: "Keyword",
      type: "text",
    },
    {
      columnName: "Synonyms",
      type: "text",
    },
    {
      columnName: "Add Keyword",
      className: "add-keyword-btn",
      type: "button",
    },
  ],
  reportTableData: [],
};

const keyword = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_KEYWORDS":
      return { ...initialState };
    case "GET_ALL_CLASS":
    case "KEYWORD_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default keyword;
