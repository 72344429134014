import React, { useEffect, useState } from "react";
import { getImagePathFromPN } from "../../common/images-functions";
import { handleMouseDown, handleTouchStart,extractMeaningfulPhrases, removeArticles } from "../../common/utils.js";
import "../../assets/css/style.scss";
import RwireGallary from "./rwire-view-modal-subsections/rwire-gallary";
import Tesseract from "tesseract.js";
import imageAnnotate from "../../assets/images/image-annotate.svg";

function RwireViewImageSection(props) {
  const {
    detailsData,
    setIsImageCarouselModalOpen,
    isImageCarouselModalOpen,
    setImagePath,
    setIsClipImage,
    setShowAnnotations,
    showAnnotations,
    setMeaningfulPhrases,
    setExtractedNumbers
  } = props;

  const minWidth = "40%";
  const minHeight = "40%";
  const aspectRatio = 16 / 16;

  const [imageWidth, setImageWidth] = useState(minWidth);
  const [imageHeight, setImageHeight] = useState(
    `calc(${minWidth} * ${aspectRatio})`
  );
  const [imageLoadError, setImageLoadError] = useState(false);
  const [viewAllImages, setViewAllImages] = useState(false);
  const [s3ImagePath, setS3ImagePath] = useState("");
  const [extractedNumbers, setExtractedNumbersState] = useState([]);

  const patentIdFromStorage = sessionStorage.getItem("patentId");

  const handleImageChange = (newImagePath) => {
    setS3ImagePath(newImagePath);
  };

  useEffect(() => {
    if (patentIdFromStorage) {
      const imagePath = getImagePathFromPN(patentIdFromStorage);
      setS3ImagePath(imagePath);
    }
  }, [patentIdFromStorage]);

  const handleModal = () => {
    setViewAllImages((prev) => !prev);
  };

  const handleOpenImagecarousel = () => {
    setIsClipImage(true);
    setIsImageCarouselModalOpen(!isImageCarouselModalOpen);
    setImagePath(s3ImagePath);
  };

  const handleResizeImage = (newWidth) => {
    const parsedWidth = parseFloat(newWidth);
    if (parsedWidth >= parseFloat(minWidth)) {
      const newHeight = `calc(${parsedWidth} * ${aspectRatio})`;
      setImageWidth(newWidth);
      setImageHeight(newHeight);
    }
  };

  useEffect(() => {
    const path = detailsData["PN_B"] && getImagePathFromPN(detailsData["PN_B"]);
    const image = new Image();
    image.src = path;
    image.onload = () => {
      if (image.height > image.width + 200) {
        const imageContainer = document.querySelector(".image-container");
        imageContainer.style.width = "50%";
      } else {
        const imageContainer = document.querySelector(".image-container");
        imageContainer.style.width = "";
      }
      setImageLoadError(false);
    };
    image.onerror = () => {
      setImageLoadError(true);
    };
  }, [detailsData]);

  useEffect(() => {
    const recognizeText = async () => {
      if (s3ImagePath) {
        try {
          const result = await Tesseract.recognize(s3ImagePath, "eng", {
            logger: (m) => console.log(m),
            tessedit_char_whitelist: '0123456789abcdefghijklmnopqrstuvwxyz()'
          });
          const recognizedText = result.data.text;
          const numbers = recognizedText.match(/\b\d+\w?\b/gi) || [];
          setExtractedNumbersState([...new Set(numbers)]); 
        } catch (error) {
          console.error("Error recognizing text:", error);
        }
      }
    };
    recognizeText();
  }, [s3ImagePath]);

  useEffect(() => {
    if (extractedNumbers.length > 0 && detailsData["DESC_EN"]) {
      const paragraphWithXml = detailsData["DESC_EN"];
      const regexXmlTags = /<[^>]*>/g;
      const paragraphPlainText = paragraphWithXml.replace(regexXmlTags, "");

      const phrases = extractMeaningfulPhrases(paragraphPlainText, extractedNumbers);
      const refinedPhrases = Object.fromEntries(
        Object.entries(phrases).map(([key, value]) => [key, value.map(removeArticles)])
      );
      setMeaningfulPhrases(refinedPhrases);
      setExtractedNumbers(extractedNumbers);
    }
  }, [extractedNumbers, detailsData, setMeaningfulPhrases, setExtractedNumbers]);

  return (
    <div
      className="patent-view-image-section d-flex flex-column"
      style={{ minWidth, width: imageWidth, minHeight, height: imageHeight, position: "relative" }}
    >
      <h5 className="mb-2 d-flex">
        <span>
          Images (
          <span onClick={handleModal} className="show-all-btn">
            {!viewAllImages ? "show all" : "hide images"}
          </span>
          )
        </span>
        <button
          className="show-all-btn pl-2"
          onClick={() => setShowAnnotations(!showAnnotations)}
        >
          <img src={imageAnnotate} alt="Annotate" title="Image Annotations"/>
        </button>
      </h5>
      <div className="patent-view-image-block resizable-column p-1">
        <div
          className="img-resize-handle resize-handle-left"
          onMouseDown={(e) =>
            handleMouseDown(e, -1, imageWidth, handleResizeImage)
          }
          onTouchStart={(e) =>
            handleTouchStart(e, -1, imageWidth, handleResizeImage)
          }
        >
          <div className="resize-handle-icon"></div>
        </div>
        <div className="d-flex justify-content-center">
          {!viewAllImages ? (
            <div
              className={`image-container d-flex justify-content-center align-items-center ${
                imageLoadError ? "image-not-available-block" : ""
              }`}
            >
              {imageLoadError ? (
                <div>
                  <div className="image-not-available text-center">Image</div>
                  <div className="image-not-available">Not Available!</div>
                </div>
              ) : (
                <img
                  alt=""
                  className="showcase-img cursor-pointer"
                  src={s3ImagePath}
                  onClick={handleOpenImagecarousel}
                />
              )}
            </div>
          ) : (
            <RwireGallary {...props} onImageChange={handleImageChange} />
          )}
        </div>

        <div
          className="img-resize-handle resize-handle-right"
          onMouseDown={(e) =>
            handleMouseDown(e, 1, imageWidth, handleResizeImage)
          }
          onTouchStart={(e) =>
            handleTouchStart(e, 1, imageWidth, handleResizeImage)
          }
        ></div>
      </div>
    </div>
  );
}

export default RwireViewImageSection;
