import React from "react";
import DataRow from "./data-row";
import KeyFeatureDataRow from "./keyfeature-data-row";
import { useStore } from "react-redux";
import LoaderGif from "../../../assets/images/loader.gif";
const KeyfeaturesTable = ({
  options,
  selectedRows,
  onSetSelectedRows,
  // isKeyfeaturesExtracting = false,
}) => {
  // Chunk the options array into batches of 3 elements each
  const chunkedOptions = [];
  let index = 0;
  for (let i = 0; i < options.length; i += 3) {
    chunkedOptions.push(options.slice(i, i + 3));
  }
  const store = useStore();
  const {
    fullView: { isKeyfeaturesExtracting },
  } = store.getState();
  return (
    <div
      style={{
        border: "1px solid #aeaeae",
        "border-radius": "5px",
      }}
      className="keyfeatable"
    >
      {" "}
      {isKeyfeaturesExtracting ? (
        <div className="keyfeaturesloader-table">
          <img
            alt="Loader"
            className="m-auto"
            width={60}
            height={60}
            src={LoaderGif}
          />{" "}
        </div>
      ) : (
        <div className="keyfeatures-table">
          {options.map((option, index) => {
            return (
              <KeyFeatureDataRow
                key={index++}
                options={option}
                selectedRows={selectedRows}
                onSetSelectedRows={onSetSelectedRows}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default KeyfeaturesTable;
