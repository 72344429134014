import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Light } from "../../assets/images/light.svg";
import { ReactComponent as Dark } from "../../assets/images/dark.svg";
import "../darkmode-button/style.scss";

const RWireDarkMode = (props) => {
  const {
    onSetApp,
  } = props;
  const isDarkMode = useSelector((state) => state.app.isDarkMode);

  const toggleTheme = () => {
    const newTheme = !isDarkMode;
    props.handleToggle();
    onSetApp({ darkTheme: newTheme });
    localStorage.setItem("isDarkTheme", newTheme.toString());
  };

  // Following class should call in body
  useEffect(() => {
    document.body.className = `${isDarkMode ? "dark-mode" : ""}`;
  }, [isDarkMode]);

  useEffect(() => {
    const savedTheme = localStorage.getItem("isDarkTheme");
    if (savedTheme !== null) {
      const parsedTheme = savedTheme === "true";
      onSetApp({ isDarkMode: parsedTheme });
    } else {
      onSetApp({ isDarkMode: false });
    }
  }, [onSetApp]);

  return (
    <div className="dark_mode">
      <input
        className="dark_mode_input"
        type="checkbox"
        id="darkmode-toggle"
        onChange={toggleTheme}
        checked={isDarkMode}
      />
      <label className="dark_mode_label" htmlFor="darkmode-toggle">
        <Light />
        <Dark />
      </label>
    </div>
  );
};

export default RWireDarkMode;
