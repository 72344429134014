import React from "react";
import "../../../assets/css/columns.scss";
import { HighlightFromQueryOrManually } from "../../../container/highlight";

const RwireTableTdComponent = (props) => {
  const {
    tdClassName = "",
    innerDivClassName = "",
    htmlText = "",
    isPubNumber = false,
    onClick,
    tdId = "",
    patentStatus = "",
    isAddHighlight = false,
  } = props;
  if (isAddHighlight) {
    return (
      <td className={tdClassName}>
        <div className={innerDivClassName} id={tdId} onClick={onClick}>
          <HighlightFromQueryOrManually>
            {htmlText}
          </HighlightFromQueryOrManually>
        </div>
        {isPubNumber && (
          <div
            className={`d-flex ${
              patentStatus.toLowerCase() === "dead"
                ? "pub-status-red"
                : "pub-status-green"
            }`}
          >
            {patentStatus.toUpperCase()}
            {/* <RwireTooltipButton id={tdId} /> */}
          </div>
        )}
      </td>
    );
  }

  return (
    <td className={tdClassName} id={tdId}>
      <HighlightFromQueryOrManually>{htmlText}</HighlightFromQueryOrManually>
    </td>
  );
};

export default RwireTableTdComponent;
