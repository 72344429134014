import { useState } from "react";
import CancelAlertModal from "./cancel-alert";
import StatusLabel from "./status-label";

const statusInProcess = ["Processing", "New"];

const ActionButtons = (props) => {
  const {
    item,
    setEmailDownloadToggle,
    emailDownloadToggle,
    setMailId,
    onDeleteDownloadCenter,
    onFetchDownloadCenterData,
    onCancelDownloadCenter,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClickModalEmail = (id) => {
    setEmailDownloadToggle(!emailDownloadToggle);
    setMailId(id);
  };

  const handleRemoveItem = async (id) => {
    await onDeleteDownloadCenter(id);
    await onFetchDownloadCenterData();
  };

  const handleCancelItem = () => {
    setIsOpen(true);
  };

  return (
    <div
      className={`d-flex align-items-center justify-content-between download-column ${item.status}`}
    >
      <StatusLabel statusInProcess={statusInProcess} status={item.status} />
      {statusInProcess.includes(item.status) ||
      item.status === "Cancelled" ||
      item.status === "Failed" ? (
        <div className={`row-icons download-icon disabled`} />
      ) : (
        <a
          className={"table-right-download-center mx-1"}
          href={item.attachment}
          download
          disabled={statusInProcess.includes(item.status)}
        >
          <div className={`row-icons download-icon`} title="Download" />
        </a>
      )}
      <button
        className={`table-right-download-center mx-1 ${
          statusInProcess.includes(item.status) ||
          item.status === "Cancelled" ||
          item.status === "Failed"
            ? "disabled"
            : ""
        }`}
        onClick={() => handleClickModalEmail(item.id)}
        disabled={statusInProcess.includes(item.status)}
        title="Send Email"
      >
        <div className="row-icons mail-icon" />
      </button>
      {statusInProcess.includes(item.status) ? (
        <>
          <button
            className={`table-right-download-center mx-1`}
            onClick={() => handleCancelItem(item.id)}
          >
            <div className="row-icons cancel-icon" title="Cancel" />
          </button>
          <CancelAlertModal
            onCancelDownloadCenter={onCancelDownloadCenter}
            onFetchDownloadCenterData={onFetchDownloadCenterData}
            id={item.id}
            isOpen={isOpen}
            onSetIsOpen={setIsOpen}
          />
        </>
      ) : (
        <button
          className={`table-right-download-center mx-1 ${
            statusInProcess.includes(item.status) ? "disabled" : ""
          }`}
          disabled={statusInProcess.includes(item.status)}
          onClick={() => handleRemoveItem(item.id)}
          title="Delete"
        >
          <div className="row-icons delete-icon" />
        </button>
      )}
    </div>
  );
};

export default ActionButtons;
