import React, { useEffect, useState } from "react";
import "./styles.scss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import CitedLinks from "./rwire-cited-links";

const RwireCitedReferences = (props) => {
  const { citedReferences = [], onRwireSearchAPI, onSetFilter } = props;
  const [openTabs, setOpenTabs] = useState([]);
  const [isVisibleMessageModal, setVisibilityMessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const navigate = useNavigate();

  const handleClickPN = (queryString) => {
    sessionStorage.setItem("patentId", queryString);
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
    const existingTab = openTabs.find((tab) => tab.name === queryString);

    const maxTabs = 100;

    if (openTabs.length < maxTabs) {
      const frontUrl = window.location.href.includes("/front") ? "/front" : "";
      const newTab = window.open(
        `${frontUrl}/en/patent-view/${queryString}`,
        "_blank"
      );
      setOpenTabs([...openTabs, { name: queryString, tab: newTab }]);
      if (newTab) {
        newTab.focus();
      }
    } else {
      setMessageModalContent("Maximum Tab Limit Reached");
      setVisibilityMessageModal(true);
    }
  };
  return (
    <div className="p-3 w-100 h-100 overflow-auto report-cited-references">
      <div className="h-100 overflow-auto">
        {citedReferences && citedReferences.length > 0 ? (
          <table>
            <thead>
              <tr className="header-row">
                <th className="sr-no-col">Sr. No</th>
                <th>Pub Number</th>
                <th className="date-col">Pub Date</th>
                <th className="date-col">App Date</th>
                <th className="date-col">Priority Date</th>
                <th>Current Assignee</th>
                <th>Inventor</th>
              </tr>
            </thead>
            <tbody>
              {citedReferences &&
                citedReferences.length > 0 &&
                citedReferences.map((patent, index) => (
                  <tr className="body-rows">
                    <td className="text-center">{index + 1}</td>
                    <td>
                      <span
                        className="pn-hyperlink"
                        onClick={() => handleClickPN(patent.PN)}
                      >
                        {patent.PN}
                      </span>
                    </td>
                    <td>
                      {patent.PD && moment(patent.PD).format("DD[-]MM[-]YYYY")}
                    </td>
                    <td>
                      {patent.AD && moment(patent.AD).format("DD[-]MM[-]YYYY")}
                    </td>
                    <td>
                      {patent.EPRD &&
                        moment(patent.EPRD).format("DD[-]MM[-]YYYY")}
                    </td>
                    <td>
                      {patent.CA?.map((data, index) => (
                        <CitedLinks
                          onRwireSearchAPI={onRwireSearchAPI}
                          data={data}
                          index={index}
                          length={patent.CA?.length}
                          parameter="CA"
                        />
                      ))}
                    </td>
                    <td>
                      {patent.IN?.map((data, index) => (
                        <CitedLinks
                          onRwireSearchAPI={onRwireSearchAPI}
                          data={data}
                          index={index}
                          length={patent.IN?.length}
                          parameter="IN"
                        />
                      ))}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <div className="no-cited-found-message">
            <h4> No cited references available for this patent number</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default RwireCitedReferences;
