import lineIcon from "../../images/chart-icons/simple-line.svg";
import column2dIcon from "../../images/chart-icons/column-in-2d.svg";
import bar2dIcon from "../../images/chart-icons/bar-in-2d.svg";
import bar3dIcon from "../../images/chart-icons/bar-in-3d.svg";
import column3dIcon from "../../images/chart-icons/column-in-3d.svg";
import donut2dIcon from "../../images/chart-icons/donut-in-2d.svg";
import donut3dIcon from "../../images/chart-icons/donut-in-3d.svg";
import pie2dIcon from "../../images/chart-icons/pie-in-2d.svg";
import pie3dIcon from "../../images/chart-icons/pie-in-3d.svg";
import simpleSplineAreaIcon from "../../images/chart-icons/simple-spline-area.svg";
import treemapIcon from "../../images/chart-icons/treemap.svg";
import countryMapIcon from "../../images/chart-icons/country-map.svg";
import stackBarIcon from "../../images/chart-icons/stack-bar.svg";
import stackColumnIcon from "../../images/chart-icons/stack-column.svg";
// import heatmapIcon from "../../images/chart-icons/heatmap.svg";

export const getIcon = (type) => {
  let icon = lineIcon;
  switch (type) {
    case "line":
      icon = lineIcon;
      break;
    case "column2D":
      icon = column2dIcon;
      break;

    case "bar2D":
      icon = bar2dIcon;
      break;

    case "pie2D":
      icon = pie2dIcon;
      break;

    case "splinearea":
      icon = simpleSplineAreaIcon;
      break;

    case "doughnut2D":
      icon = donut2dIcon;
      break;

    case "column3d":
      icon = column3dIcon;
      break;

    case "pie3d":
      icon = pie3dIcon;
      break;

    case "doughnut3d":
      icon = donut3dIcon;
      break;

    case "bar3d":
      icon = bar3dIcon;
      break;

    case "treemap":
      icon = treemapIcon;
      break;

    case "maps/worldwithcountries":
      icon = countryMapIcon;
      break;
    case "mscolumn2D":
      icon = column2dIcon;
      break;
    case "msbar2D":
      icon = bar2dIcon;
      break;
    case "mssplinearea":
      icon = simpleSplineAreaIcon;
      break;
    case "mscolumn3d":
      icon = column3dIcon;
      break;
    case "msbar3d":
      icon = bar3dIcon;
      break;

    case "stackedbar2D":
    case "stackedbar3D":
      icon = stackBarIcon;
      break;

    case "stackedcolumn2D":
    case "stackedcolumn3D":
      icon = stackColumnIcon;
      break;

    default:
      icon = lineIcon;
      break;
  }
  return icon;
};
