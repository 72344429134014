const initialState = {
  isOpenKeywordSuggestorModal: false,
  tableData: [],
  keywordSugestorQuery: [],
  selectedRows: [],
  selectedItemList: [],
  keywordType: "cpc"
};

const keywordSuggestor = (state = initialState, { type, payload }) => {
  switch (type) {
    case "KEYWORD_SUGGESTOR_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default keywordSuggestor;
