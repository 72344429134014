/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  AttachFileIcon,
  BoldBg,
  Italic,
  OrderedListIcon,
  TextColor,
  Underline,
  UnoOrderedListIcon,
} from "../../assets/images/report";
import { Image } from "../ui-component";
import ActionTooltip from "../report-sortable-table/action-modal";
import TextColorsMenu from "../report-toolbar/report-color-menu";

const CommentMediaUploader = ({
  uploadedFiles,
  setUploadedFiles,
  commentingRowID,
}) => {
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;

    // eslint-disable-next-line array-callback-return
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        // Check if the file size is within the limit (8MB)
        if (file.size <= 100 * 1024 * 1024) {
          uploaded.push(file);
        } else {
          // Set the limitExceeded flag if the file size exceeds the limit
          limitExceeded = true;
        }
      }
    });

    if (!limitExceeded) setUploadedFiles(uploaded);
    // eslint-disable-next-line no-alert
    else alert("File size exceeds the 100MB limit");
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleAction = (e, cmd, value = null) => {
    e.preventDefault();
    document.execCommand(cmd, true, value);
    sessionStorage.setItem("styl", cmd);
  };

  return (
    <div className="upload-section d-flex gap-2">
      <div className="ps-2 file-rt-icon d-flex gap-3 align-items-center toolbar-bg">
        <div
          className="unselectable"
          title="Bold&#10;Ctrl+b"
        >
          <img src={BoldBg} alt="" onClick={(e) => handleAction(e, "bold")} />
        </div>
        <div
          className="unselectable"
          title="Italic&#10;Ctrl+i"
        >
          <img src={Italic} alt="" onClick={(e) => handleAction(e, "italic")} />
        </div>
        <div
          className="unselectable"
          title="Underline&#10;Ctrl+u"
        >
          <img
            src={Underline}
            alt=""
            onClick={(e) => handleAction(e, "underline")}
          />
        </div>
        <ActionTooltip
          placement="top"
          tooltipContent={<TextColorsMenu handleAction={handleAction} />}
        >
          <div className="unselectable" title="Text Color">
            <img src={TextColor} alt="" />
          </div>
        </ActionTooltip>
        <div className="unselectable" title="Ordered List">
          <img
            src={OrderedListIcon}
            alt=""
            onClick={(e) => handleAction(e, "insertOrderedList")}
          />
        </div>
        <div className="unselectable" title="Unordered List">
          <img
            src={UnoOrderedListIcon}
            alt=""
            onClick={(e) => handleAction(e, "insertUnorderedList")}
          />
        </div>
      </div>
      <div>
        <>
          <input
            className="fileUpload"
            id={commentingRowID}
            type="file"
            multiple
            accept=".pdf, .png, .jpg, .jpeg, .gif, .mp3, .mp4, .zip, .html, .txt, .csv, .json, .xml, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
            onChange={handleFileEvent}
            disabled={false}
          />

          <label htmlFor={commentingRowID} className="file-upload-icon m-0">
            <div>
              <Image src={AttachFileIcon} />
            </div>
          </label>
        </>
      </div>
    </div>
  );
};

export default CommentMediaUploader;
