import React from "react";

const Span = ({ children, className, onClick }) => {
  return (
    <span className={className} onClick={onClick}>
      {children}
    </span>
  );
};

export default Span;
