import {
  keywordFieldsOptions,
  synonymnsCountOptions,
} from "../../resources/data/options";
import Dropdown from "./keywords-generator/field-select-dropdown";

const KeywordDropdown = (props) => {
  const {
    onSetQueryField,
    queryField,
    disabled,
    count,
    onSetCount,
    isReportModal = false,
    existingKeywords = [],
    onSelect,
  } = props;

  const handleSelectField = (eventKey) => {
    onSetQueryField({ title: eventKey.label, code: eventKey.code });
    onSelect && onSelect({ name: eventKey.label, id: eventKey.code });
  };
  const handleSelectCount = (eventKey) => {
    onSetCount({ title: eventKey.label, code: eventKey.code });
  };
  return (
    <div title={queryField.title} className="d-flex gap-2 align-items-center">
      <div className="fw-bold addin-text">Add In:</div>
      {isReportModal ? (
        <Dropdown
          onChange={handleSelectField}
          disabled={disabled}
          placeholder="Select"
          data={existingKeywords}
        />
      ) : (
        <Dropdown
          onChange={handleSelectField}
          disabled={disabled}
          defaultValue={queryField.title}
          data={keywordFieldsOptions}
        />
      )}
      <Dropdown
        onChange={handleSelectCount}
        disabled={disabled}
        defaultValue={count.title}
        data={synonymnsCountOptions}
        menuClassName="keyword-count-menu"
      />
    </div>
  );
};

export default KeywordDropdown;
