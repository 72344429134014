import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ResultTable from "../components/common/patents-result-common/rwire-patents-table";
import { performSearch } from "../action/rwire-search-api";
import {
  setDetails,
  setFilterFields,
  setSelectedRows,
  updateColumnWidth,
} from "../action/result-table";
import { searchForClassAndKeyword } from "../action/patent-view";
const mapStateToProps = ({
  resultTable,
  app: { isLoadingTable, showFilters },
}) => ({
  ...resultTable,
  isLoadingTable,
  highlightword: [
    ...resultTable.esHighlightwords,
    ...resultTable.highlightword,
  ],
  showFilters,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetResultTable: setDetails,
      onPerformSearch: performSearch,
      onSetFilter: setFilterFields,
      onSetSelectedRows: setSelectedRows,
      onSearchForClassAndKeyword: searchForClassAndKeyword,
      onUpdateColumnWidth: updateColumnWidth,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResultTable);
