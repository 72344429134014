import React from "react";

const SectionKeyfeaturesTable = (props) => {
  const {
    keyfeature = [],
    heading,
    checkedFeatures = [],
    onHandleKeyfeatures,
  } = props;
  return (
    <div className="mt-2">
      <div className="table-heading">{heading}</div>
      <table class="table ">
        <tbody>
          {keyfeature &&
            keyfeature.length > 0 &&
            keyfeature
              .filter((feature) => feature)
              .map((feature, index) => {
                if (!feature) return null;
                return (
                  <tr>
                    <td className="index-col">Key Feature {index + 1}</td>
                    <td className="feature-col">{feature}</td>
                    {sessionStorage.getItem("projectId") && (
                      <td className="input-col">
                        <div className="input-group">
                          <label>
                            <input
                              type="checkbox"
                              name={`${heading}-${index}`}
                              checked={
                                checkedFeatures[`${heading}-${index}`] || false
                              }
                              onChange={(e) => onHandleKeyfeatures(e, feature)}
                            />
                          </label>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default SectionKeyfeaturesTable;
