import React from "react";

const RadioInputs = (props) => {
  const { selectedOption, onSetSelectedOption } = props;

  const handleRadioChange = (event) => {
    onSetSelectedOption(event.target.value);
  };

  return (
    <div className="section-radio-inputs px-2 pt-2">
      <label>
        <input
          className="radio-input"
          type="radio"
          name="claim"
          value="CL_EN"
          checked={selectedOption === "CL_EN"}
          onChange={handleRadioChange}
        />
        <span className="radio-tile">
          <span className="radio-label">Claim</span>
        </span>
      </label>
      {/* <label>
        <input
          className="radio-input"
          type="radio"
          name="description"
          value="DESC_EN"
          checked={selectedOption === "DESC_EN"}
          onChange={handleRadioChange}
        />
        <span className="radio-tile">
          <span className="radio-label">Description</span>
        </span>
      </label> */}
      <label>
        <input
          className="radio-input"
          type="radio"
          name="abstract"
          value="AB_EN"
          checked={selectedOption === "AB_EN"}
          onChange={handleRadioChange}
        />
        <span className="radio-tile">
          <span className="radio-label">Abstract</span>
        </span>
      </label>
      <label>
        <input
          className="radio-input"
          type="radio"
          name="title"
          value="TI_EN"
          checked={selectedOption === "TI_EN"}
          onChange={handleRadioChange}
        />
        <span className="radio-tile">
          <span className="radio-label">Title</span>
        </span>
      </label>
    </div>
  );
};

export default RadioInputs;
