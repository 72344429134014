const initialState = {
  selectedFieldes: ["PN"],
  fileName: "",
  isNumberSearch: false
};

const login = (state = initialState, { type, payload }) => {
  switch (type) {
    case "NUMBER_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default login;
