import React, { useEffect } from "react";
import PatentsActionButtons from "./patents-action-buttons";
import checkIcon from "../../assets/images/report/checked-with-close.svg";
import PatentSummarizer from "../../container/patent-summarizer";
import PatentKeyFeature from "../../container/patent-keyfeature";
import ClaimChartGenerator from "../../container/claim-chart-generator";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../common/user-roles";

const ViewPubNoSection = (props) => {
  const {
    detailsData,
    onSetFilter,
    prevId,
    nextId,
    onSetImages,
    onAddRelevantResult,
    onGetReportProjectRelevantResultFromPN,
    isPatentNumberInRelevantResult,
    onRemoveFromRelevantResult,
    citedPatents,
  } = props;

  const projectId = sessionStorage.getItem("projectId");
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  useEffect(() => {
    if (projectId) {
      onGetReportProjectRelevantResultFromPN({
        patentNumber: detailsData["PN_B"],
        projectId,
        selectedId: [],
      });
    }
  }, [detailsData, onGetReportProjectRelevantResultFromPN, projectId]);

  const handleAddToRelevantResult = () => {
    onAddRelevantResult({
      patentNumber: detailsData["PN_B"],
      title: detailsData["TI_EN"],
      priorityDate: detailsData["EPRD"],
      publicationDate: detailsData["PD"],
      comment: "",
      projectId,
    });
  };

  const handleRemove = () => {
    onRemoveFromRelevantResult({
      projectId,
      patentNumber: detailsData["PN_B"],
    });
  };
  const isCitedPatent = citedPatents.includes(detailsData["PN_B"])
    ? true
    : false;
  return (
    <div
      className={`${projectId ? "project_ids" : ""
        } pub-no d-flex justify-content-between ${userProjectAccess === ACCESS_VIEWER ? "ms-0" : ""
        } pub-no d-flex justify-content-between align-items-center ${sessionStorage.getItem("userRole") === "ACCESS_VIEWER" ? "ms-0" : ""
        }`}
    >
      <div className="d-flex">
        {userProjectAccess !== ACCESS_VIEWER && (
          <div>
            {projectId && !isPatentNumberInRelevantResult ? (
              <div
                className="hover-content"
                onClick={handleAddToRelevantResult}
                title=" Add to report"
              >
                +
              </div>
            ) : projectId && isPatentNumberInRelevantResult ? (
              <img
                className="patent-full-view-page-check-icon"
                src={checkIcon}
                alt=""
                onClick={handleRemove}
              />
            ) : (
              ""
            )}
          </div>
        )}
        <div className={`${isCitedPatent ? "pub-status-red" : ""}`}>
          {detailsData[`PN_B`]}
        </div>
        {isCitedPatent && (
          <>
            <span className="pub-no-dot bg-danger"></span>
            <div className="pub-status-red">Cited</div>
          </>
        )}
        {detailsData.ALD && (
          <>
            <span
              className={`pub-no-dot ${isCitedPatent ? "bg-danger" : ""}`}
            ></span>
            <div
              className={`${detailsData.ALD.toLowerCase() === "dead"
                ? "pub-status-red"
                : "pub-status-green"
                }`}
            >
              {detailsData.ALD.toUpperCase()}
            </div>
          </>
        )}
        <PatentSummarizer />
        <PatentKeyFeature />
        {sessionStorage.getItem("projectId") && <ClaimChartGenerator />}
      </div>

      <PatentsActionButtons
        onSetFilter={onSetFilter}
        prevId={prevId}
        nextId={nextId}
        onSetImages={onSetImages}
      />
    </div >
  );
};

export default ViewPubNoSection;
