import React, { useState, useEffect, useRef } from "react";

const ActionTooltip = ({
  children,
  placement = "top",
  tooltipContent,
  className = "",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    if (showTooltip) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showTooltip]);

  const handleClick = () => {
    setShowTooltip(!showTooltip);
  };
  return (
    <div
      className={`tooltip-action-container ${className}`}
      onClick={handleClick}
      ref={tooltipRef}
    >
      {children}
      {showTooltip && (
        <div className={`actions ${placement}`}>{tooltipContent}</div>
      )}
    </div>
  );
};
export default ActionTooltip;
