import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

export const services = [
  {
    title: "Patent Search and Retrieval",
    image: RWIRE_IMAGES.RwireSecondPartFirstImage,
    description: `
        Discover Patents Effortlessly: Our powerful search engine allows
        you to find patents quickly and accurately. With access to millions
        of patents from multiple countries, you can easily locate relevant
        documents using a variety of search criteria, including keywords,
        patent numbers, inventors, and assignees.
      `,
  },
  {
    title: "Patent Analytics",
    image: RWIRE_IMAGES.RwireSecondPartSecondImage,
    description: `
      Uncover Trends and Insights:Utilize our robust analytics tools to gain a 
      deeper understanding of the patent landscape. Analyze technological trends,
      monitor competitor activities, and identify market opportunities with our 
      comprehensive data visualization and reporting features.
      `,
  },
  {
    title: "Competitive Intelligence",
    image: RWIRE_IMAGES.RwireSecondPartThirdImage,
    description: `
      Stay Ahead of the Competition:Keep a close eye on your competitors with our 
      competitive intelligence services. Track new filings, monitor patent portfolios, 
      and receive alerts on significant changes. Our tools help you anticipate market 
      movements and strategically position your business.
      `,
  },
  {
    title: "Legal and Strategic Support",
    image: RWIRE_IMAGES.RwireSecondPartForthImage,
    description: `
      Expert Guidance and Advice:Benefit from our team’s extensive expertise in 
      intellectual property law and patent strategy. Whether you need assistance with
      patent prosecution, litigation support, or portfolio management, our experts are
      here to provide tailored solutions and strategic advice.
      `,
  },
  {
    title: "Custom Research Reports",
    image: RWIRE_IMAGES.RwireSecondPartFifthImage,
    description: `
      Get Detailed Insights on Demand:Request custom research reports that cater to your 
      specific needs. Our team can compile detailed analyses on particular technologies, industry 
      sectors, or competitive landscapes, providing you with the in-depth information required 
      for strategic decision-making.
      `,
  },
];
