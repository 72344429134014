import React from "react";
import { Table } from "react-bootstrap";
import {
  ACCESS_COMMENTOR,
  ROLE_CO_OWNER,
  ACCESS_EDITOR,
  ROLE_OWNER,
  ACCESS_VIEWER,
  ROLE_PARTICIPANT,
  ROLE_RWIRE_COLLABORATOR,
} from "../../../../common/user-roles";
import { deleteIcon } from "../../../../assets/images/report";

const UsersTable = (props) => {
  const {
    users,
    headers = [],
    isSelectable = false,
    hasActionCol = false,
    onRemoveAccess,
    onChangeRole,
    onChangeAccess,
    onSelectedUsers,
    selectedUsers = [],
  } = props;

  const handleChangeUserSelection = (isChecked, userId) => {
    const checkedUsers = [...selectedUsers];
    if (isChecked) {
      // Add the user ID to the selected users list
      checkedUsers.push(userId);
    } else {
      // Remove the user ID from the selected users list
      const index = checkedUsers.indexOf(userId);
      if (index !== -1) {
        checkedUsers.splice(index, 1);
      }
    }
    onSelectedUsers(checkedUsers);
  };
  return (
    <>
      {users && users.length > 0 ? (
        <div className="users-table">
          <div className="user-table">
            <Table striped borderless>
              <thead>
                <tr>
                  {isSelectable && <th></th>}
                  {headers &&
                    headers.length > 0 &&
                    headers.map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  {hasActionCol && <th></th>}
                </tr>
              </thead>
              <tbody>
                {users &&
                  users.length > 0 &&
                  users.map((item, index) => (
                    <tr key={index}>
                      {isSelectable && (
                        <td>
                          <input
                            type="checkbox"
                            id={`selectUser${item.id}`}
                            onChange={(e) =>
                              handleChangeUserSelection(
                                e.currentTarget.checked,
                                item.email
                              )
                            }
                            className="cursor-pointer"
                            checked={selectedUsers.includes(item.email)}
                          />
                        </td>
                      )}
                      <td>{index + 1}</td>
                      <td className="pe-1">
                        <span>{item.userName || item.name}</span>
                      </td>
                      <td>{item.email}</td>
                      {hasActionCol && (
                        <>
                          {item.role === ROLE_OWNER ? (
                            <td className="access-text fw-bold">OWNER</td>
                          ) : (
                            <td className="access-text" title="Modify Role">
                              <select
                                name="role"
                                id="role"
                                className="table-access-dropdown cursor-pointer"
                                onChange={(e) =>
                                  onChangeRole(e.target.value, item.id)
                                }
                                value={item.role}
                              >
                                <option value={ROLE_OWNER}>Owner</option>
                                <option value={ROLE_CO_OWNER}>Co-Owner</option>
                                <option value={ROLE_PARTICIPANT}>
                                  Participant
                                </option>
                                <option value={ROLE_RWIRE_COLLABORATOR}>
                                  Rwire Collaborator
                                </option>
                              </select>
                            </td>
                          )}

                          {item.role === ROLE_OWNER ? (
                            <td className="access-text fw-bold">EDITOR</td>
                          ) : (
                            <td className="access-text" title="Modify Access">
                              <select
                                name="access"
                                id="access"
                                className="table-access-dropdown cursor-pointer"
                                onChange={(e) =>
                                  onChangeAccess(e.target.value, item.id)
                                }
                                value={item.access}
                              >
                                <option value={ACCESS_EDITOR}>Editor</option>
                                <option value={ACCESS_VIEWER}>Viewer</option>
                                <option value={ACCESS_COMMENTOR}>
                                  Commentator
                                </option>
                              </select>
                            </td>
                          )}
                        </>
                      )}

                      {hasActionCol && (
                        <td
                          className="cursor-pointer"
                          title="Remove Access"
                          onClick={() => onRemoveAccess(item.id)}
                        >
                          {item.role !== ROLE_OWNER && (
                            <img
                              src={deleteIcon}
                              height={16}
                              width={16}
                              alt=""
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <h6 className="m-auto">+ Please Add new users</h6>
        </div>
      )}
    </>
  );
};

export default UsersTable;
