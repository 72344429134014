import React, { useEffect, useState } from "react";
import { Block } from "../../../ui-components";
import { useNavigate } from "react-router-dom";
import { useStore } from "react-redux";
import { ACCESS_COMMENTOR, ACCESS_VIEWER } from "./../../../common/user-roles";
// import { Block, DatePicker } from "../../../ui-components";
// import { CalendarIcon } from "../../../assets/images/report";
import UserAccess from "../../../common/user-acess";
import {
  EditorIcon,
  CommentorIcon,
  ViewerIcon,
} from "../../../assets/images/report";
import { useSelector } from "react-redux";

const ProjectNameSection = (props) => {
  const {
    onSetProjectReport,
    projectName,
    onUpdateReportProject,
    onGetReportProjectNameById,
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const [projName, setProjName] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const navigate = useNavigate();
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  const {
    reportNotes,
    reportKeyword,
    reportClasses,
    reportAssignee,
    reportInventor,
    reportString,
  } = useSelector((state) => ({
    reportNotes: state.reportNotes,
    reportKeyword: state.reportKeyword,
    reportClasses: state.reportClasses,
    reportAssignee: state.reportAssignee,
    reportInventor: state.reportInventor,
    reportString: state.reportString,
  }));

  useEffect(() => {
    setProjName(projectName);
  }, [projectName]);
  const Tooltip = ({ message, children }) => (
    <div className="tooltip-container">
      {children}
      <div className="tooltip-text">{message}</div>
    </div>
  );
  const handleChange = async (e) => {
    setProjName(e.target.value);
    if (e.target.value.trim() === "") {
      setValidationMessage("Please fill in this field.");
      setTimeout(() => {
        setValidationMessage("");
      }, 3000);
      return;
    }
    setValidationMessage("");
    onSetProjectReport({ projectName: e.target.value });
    const id = sessionStorage.getItem("projectId");
    await onUpdateReportProject({
      id: id,
      body: {
        name: e.target.value,
      },
    });
  };
  useEffect(() => {
    const id = sessionStorage.getItem("projectId");

    const fetchName = async () => {
      try {
        await onGetReportProjectNameById(id);
      } catch (error) {}
    };
    if (!projectName) {
      fetchName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let imageSrc, imageAlt;
  switch (userProjectAccess) {
    case "ACCESS_EDITOR":
      imageSrc = EditorIcon;
      imageAlt = "Admin Access Image";
      break;
    case "ACCESS_COMMENTOR":
      imageSrc = CommentorIcon;
      imageAlt = "Editor Access Image";
      break;
    case "ACCESS_VIEWER":
      imageSrc = ViewerIcon;
      imageAlt = "Viewer Access Image";
      break;
    default:
      imageSrc = null;
      imageAlt = null;
  }

  const isDisabled = [
    reportNotes,
    reportKeyword,
    reportClasses,
    reportAssignee,
    reportInventor,
    reportString,
  ].every(
    ({ reportTableData }) =>
      !Array.isArray(reportTableData) ||
      reportTableData.length === 0 ||
      reportTableData.every((item) => !item.body)
  );

  return (
    <Block className="project-name-section">
      {validationMessage && <Tooltip message={validationMessage}></Tooltip>}
      {userProjectAccess !== ACCESS_VIEWER &&
      userProjectAccess !== ACCESS_COMMENTOR ? (
        <input
          type="text"
          name="projectName"
          className={`project-name-input ${isFocused ? "" : "text-truncate"}`}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            setProjName(projectName);
          }}
          value={projName}
          title={projName}
          placeholder="Project Name"
        />
      ) : (
        <div
          className={`project-name-input text-truncate me-4`}
          style={{ outline: "none" }}
        >
          {/* need to remove "me-4" when "This is under Development" this will be removed */}
          {projectName}
        </div>
      )}
      {/* <input
        type="text"
        name="projectName"
        className={`project-name-input ${isFocused ? "" : "text-truncate"}`}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={projectName}
        title={projectName}
        placeholder="Project Name"
      /> */}
      {/* <Block className="px-4 py-2">
        <DatePicker
          buttonClassName="project-name-section-datepicker-button"
          buttonText="Start Date"
          icon={CalendarIcon}
          className="project-name-section-datepicker"
        />
      </Block>
      <Block className="px-4 py-2">
        <DatePicker
          buttonClassName="project-name-section-datepicker-button"
          buttonText="End Date"
          icon={CalendarIcon}
          className="project-name-section-datepicker"
        />
      </Block> */}
      <div className="user_acess ms-auto mr-3">
        {imageSrc && <img src={imageSrc} alt={imageAlt} className="mr-2" />}
        {UserAccess(userProjectAccess)}
      </div>

      <button
        className={`me-2 create-project-btn ${isDisabled ? "disabled" : ""}`}
        onClick={() => {
          navigate("/en/report/final-view");
        }}
        disabled={isDisabled}
      >
        Create Report
      </button>
    </Block>
  );
};

export default ProjectNameSection;
