import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireReportRelevantResults } from "../../components/report";
import {
  setReportRelevantResult,
  getReportProjectRelevantResultFromPN,
  addKeyFeaturesRelevantResult,
  fetPatentDetails,
  deleteRow
} from "../../action/report/report-relevant-results";
import { getReportProjectNotes } from "../../action/report/report-notes";
import { setFilterFields } from "../../action/result-table";

const Container = (props) => {
  return <RwireReportRelevantResults {...props} />;
};

const mapStateToProps = ({ reportRelevantResults, reportNotes }) => ({
  ...reportRelevantResults,
  ...reportNotes,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportRelevantResults: setReportRelevantResult,
      onGetReportProjectRelevantResultFromPN:
        getReportProjectRelevantResultFromPN,
      onGetReportProjectNotes: getReportProjectNotes,
      onAddKeyFeaturesRelevantResult: addKeyFeaturesRelevantResult,
      onSetFilter: setFilterFields,
      onRwireSearchAPI: fetPatentDetails,
      onRemove: deleteRow
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
