import React, { useState } from "react";
import InputSection from "./input-section";
import KeywordsSection from "./keywords-section";

const GenerateKeywords = (props) => {
  const [resultKeywords, setResultKeywords] = useState([]);
  return (
    <div className="classes-suggestor-modal__wrapper">
      <div className="keywords-generator-wrapper">
        {/* input section */}
        <InputSection
          {...props}
          resultKeywords={resultKeywords}
          onSetResultKeywords={setResultKeywords}
        />

        {/* keywords section */}
        <KeywordsSection {...props} tableElements={resultKeywords} />
      </div>
    </div>
  );
};

export default GenerateKeywords;
