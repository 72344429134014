import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PatentSummarizer from "../components/patent-summarizer/patent-summarizer";
import { setFullView } from "../action/patent-view";
import {
  getKeyFeaturesOfPatent,
  getSummaryOfPatent,
} from "../action/patent-summarizer";
import {
  addRowToReportProject,
  getReportProjectNotes,
} from "../action/report/report-notes";

const Container = (props) => {
  return <PatentSummarizer {...props} />;
};

const mapStateToProps = ({
  resultTable: { detailsData },
  fullView: { isSummaryFetching, isKeyfeaturesExtracting },
}) => ({
  detailsData,
  isSummaryFetching,
  isKeyfeaturesExtracting,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetFullView: setFullView,
      onGetSummaryOfPatent: getSummaryOfPatent,
      onAddKeyFeaturesToReportProject: addRowToReportProject,
      onGetKeyFeaturesOfPatent: getKeyFeaturesOfPatent,
      onGetReportProjectNotes: getReportProjectNotes,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
