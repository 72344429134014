import React from "react";
import { useState } from "react";
import { passwordRequired, required } from "../../common/validation";
import { useParams } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";
import RwireResetLinkExpire from "../rwire-reset-link-expire";
import RwireLoader from "../../container/rwire-loader";
import Youtubevideo from "../rwire-login/video-component";

const renderField = ({
  input,
  label,
  type,
  className,
  placeholder,
  meta: { touched, error, warning },
}) => (
  <div>
    {label && <label>{label}</label>}
    <div>
      <div className="input-box">
        <input
          {...input}
          className={className}
          placeholder={placeholder}
          type={type}
        />
      </div>
      {touched &&
        ((error && (
          <div className="text-danger position-absolute">{error}</div>
        )) ||
          (warning && (
            <div className="text-danger position-absolute">{warning}</div>
          )))}
    </div>
  </div>
);

const RwireResetPassword = (props) => {
  const { t } = useTranslation();

  const { onSubmitReset, resetError, onSetReset, resetSuccess, isValidToken } =
    props;
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const params = useParams();
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const re =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    const isOk = re.test(confirmPassword);
    // eslint-disable-next-line eqeqeq
    if (newPassword == confirmPassword) {
      if (!isOk) {
        onSetReset({
          resetError:
            "Password must be atleast 8-15 characters that include lowercase character,uppercase character,number & special character",
        });
      } else {
        return onSubmitReset({
          password: confirmPassword,
          token: params.token,
        });
      }
    } else {
      onSetReset({ resetError: "Password mismatch: please check the password" });
    }
  };

  const newPasswordHandle = (e) => {
    setNewPassword(e.target.value);
    onSetReset({ resetError: "" });
  };

  const confirmPasswordHandle = (e) => {
    setConfirmPassword(e.target.value);
    onSetReset({ resetError: "" });
  };
  return (
    <>
      {isValidToken === undefined ? (
        <RwireLoader />
      ) : !isValidToken ? (
        <RwireResetLinkExpire />
      ) : (
        <div className="login-page-design reset-password-page">
          <div className="login-font row g-0 bg-image">
            <div className="d-none d-md-flex col-md-4 col-lg-6">
              <div className="d-flex flex-column justify-content-center left-side-alignment">
                <p className="left-side-value font-weight-bold">
                  Innovate with Insight: Your Gateway to
                </p>

                <p className="prop-header font-weight-bold mb-2">
                  Global Patents and Technological Brilliance
                </p>

                <div>
                  <p className="para">
                    Embark on an odyssey of intellectual ingenuity through our
                    patent database —a global patent gateway igniting
                    innovation. Explore a realm where ideas converge, exploring
                    a vast patent library's canvas of creation. Illuminate your
                    journey with intellectual discovery, powered by our
                    comprehensive repository, bridging past, future, and curious
                    minds accessing tomorrow's ideas today.
                  </p>
                </div>
                <div className="original-img mt-4">
                  <Youtubevideo
                    src="https://www.youtube.com/embed/gt57IcOl7qY"
                    title="YouTube video player"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 login-main">
              <div className="d-flex align-items-center py-4 login-page-inner">
                <div className="container container-bg">
                  <div className="row">
                    <div className="col-md-12 col-lg-11 mx-auto ">
                      <div className="forgot-main">
                        <h1 className="login-heading mb-4 pb-2">
                          {t("reset_title")}
                        </h1>
                        <p className="pb-2 link-info">{t("reset_sub_title")}</p>
                        <form
                          onSubmit={handleFormSubmit}
                          autoComplete="off"
                          method="post"
                          validate={required}
                          className="label-size pt-2 forgot-password"
                        >
                          <label>New Password</label>
                          <div className="form-group mb-3">
                            <input
                              name="password"
                              type="password"
                              className="form-control"
                              id="floatingPassword"
                              autocomplete="off"
                              placeholder="New Password"
                              component={renderField}
                              onChange={newPasswordHandle}
                              autoComplete="off"
                              validate={passwordRequired}
                            />
                          </div>
                          <label className="pt-3">Confirm New Password</label>
                          <div className="form-group mb-3">
                            <input
                              name="password"
                              type="password"
                              className="form-control"
                              id="floatingPassword"
                              autocomplete="off"
                              placeholder="Confirm New Password"
                              component={renderField}
                              onChange={confirmPasswordHandle}
                              autoComplete="off"
                              validate={passwordRequired}
                            />
                          </div>

                          <div className="d-grid mt-3 reset-group">
                            {!newPassword || !confirmPassword ? (
                              <button
                                className="button p-2 text-black reset-button reset-hide"
                                type="submit"
                                disabled
                              >
                                <b>Reset Password</b>
                              </button>
                            ) : (
                              <button
                                className="button p-2 text-black reset-button"
                                type="submit"
                              >
                                <b>Reset Password</b>
                              </button>
                            )}
                          </div>
                          {resetError ? (
                            <div className="email-link-info-error">
                              <p>{resetError}</p>
                            </div>
                          ) : (
                            ""
                          )}
                          {resetSuccess ? (
                            <div className="email-link-info">
                              <p>{resetSuccess}</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default RwireResetPassword;
