import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RWIRE_IMAGES from "../common/common-functions/rwire-images";

const TableHeader = (props) => {
  const { t } = useTranslation();
  const {
    selectedRows,
    onSetSelectedRows,
    selectedItemList,
    data,
    onSetExportSelected,
    onSetItemPerPage,
    selectedRowsForCombine,
    onSortData,
  } = props;

  const [sortOrderNumbers, setSortOrderNumbers] = useState("desc");
  const [sortOrderDateTime, setSortOrderDateTime] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);

  const handleCheckbox = (event) => {
    let selectIds = [];
    let selectRowNumber = [];
    let selectedList = [];

    data.forEach((i) => {
      selectIds.push(`${i.id}`);
      selectRowNumber.push(`${i.userSearchNumber}`);
      selectedList.push(i);
    });

    if (event.target.checked) {
      onSetSelectedRows({ selectedRows: selectIds });
      onSetItemPerPage({ selectedRowsForCombine: selectRowNumber });
      onSetExportSelected({ selectedItemList: selectedList });
    } else {
      const difference = selectedRows.filter((x) => !selectIds.includes(x));
      const differenceRowNumber = selectedRowsForCombine.filter(
        (x) => !selectRowNumber.includes(x)
      );
      onSetSelectedRows({ selectedRows: difference });
      onSetItemPerPage({ selectedRowsForCombine: differenceRowNumber });

      const result = selectedItemList.filter(
        (element) => !difference.includes(`${element.id}`)
      );
      onSetExportSelected({ selectedItemList: result });
    }
  };

  const handleSortByNumbers = () => {
    const sortedData = [...data];
    if (sortedColumn !== "numbers") {
      setSortOrderDateTime("asc");
      setSortedColumn("numbers");
    }

    if (sortOrderNumbers === "asc") {
      sortedData.sort((a, b) => a.numberOfHits - b.numberOfHits);
      setSortOrderNumbers("desc");
    } else {
      sortedData.sort((a, b) => b.numberOfHits - a.numberOfHits);
      setSortOrderNumbers("asc");
    }
    onSortData(sortedData, "numbers", sortOrderNumbers, sortOrderDateTime);
  };

  const handleSortByDateTime = () => {
    const sortedData = [...data];
    if (sortedColumn !== "date-time") {
      setSortOrderNumbers("asc");
      setSortedColumn("date-time");
    }

    if (sortOrderDateTime === "asc") {
      sortedData.sort((a, b) => {
        if (a.createdAt === b.createdAt) {
          return a.updatedAt - b.updatedAt;
        }
        return a.createdAt - b.createdAt;
      });
      setSortOrderDateTime("desc");
    } else {
      sortedData.sort((a, b) => {
        if (a.createdAt === b.createdAt) {
          return b.updatedAt - a.updatedAt;
        }
        return b.createdAt - a.createdAt;
      });
      setSortOrderDateTime("asc");
    }
    onSortData(sortedData, "date-time", sortOrderNumbers, sortOrderDateTime);
  };

  return (
    <div className="header_wrapper table-row rwire-tableheader">
      <div className="form-check regular-checkbox table-column">
        <input
          className="form-check-input regular-checkbox"
          type="checkbox"
          onChange={handleCheckbox}
        />
      </div>
      <div className="table-column numbers">
        <span>
          {t("history-table-title-numbers")}
          <span
            className="sort-button"
            onClick={handleSortByNumbers}
            style={{ cursor: "pointer" }}
          >
            {sortOrderNumbers === "asc" ? (
              <img
                src={RWIRE_IMAGES.RwireAscendSort}
                alt="Ascend"
                style={{ paddingLeft: "6px" }}
              />
            ) : (
              <img
                src={RWIRE_IMAGES.RwireDescendSort}
                alt="Descend"
                style={{ paddingLeft: "6px" }}
              />
            )}
          </span>
        </span>
      </div>
      <div className="table-column records">
        {t("history-table-title-records")}
      </div>
      <div className="table-column search-query">
        {t("history-table-title-query")}
      </div>
      <div className="table-column date-time">
        <span>
          {t("history-table-title-date")}
          <span
            className="sort-button"
            onClick={handleSortByDateTime}
            style={{ cursor: "pointer" }}
          >
            {sortOrderDateTime === "asc" ? (
              <img
                src={RWIRE_IMAGES.RwireAscendSort}
                alt="Ascend"
                style={{ paddingLeft: "8px" }}
              />
            ) : (
              <img
                src={RWIRE_IMAGES.RwireDescendSort}
                alt="Descend"
                style={{ paddingLeft: "8px" }}
              />
            )}
          </span>
        </span>
      </div>
      <div className="table-column run">{t("history-table-title-run")}</div>
      <div className="table-column bookmark">
        {t("history-table-title-bookmark")}
      </div>
      <div className="table-column delete">
        {t("history-table-title-delete")}
      </div>
    </div>
  );
};

export default TableHeader;
