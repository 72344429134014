const initialState = {
  resetError: "",
  resetSuccess: "",
  isAuthSuccess: false,
  isValidToken: undefined,
  invalidResetTokenCode: "",
};

const reset = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default reset;
