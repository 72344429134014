const initialState = {
    forgotError: "",
    forgotSuccess: "",
    isAuthSuccess: false
  };
  
  const forgot = (state = initialState, { type, payload }) => {
    switch (type) {
      case "FORGOT_SET":
        return {
          ...state,
          ...payload,
        };
      default:
        return state;
    }
  };
  
  export default forgot;
  