const initialState = {
  column: [
    {
      columnName: "Notes",
      type: "text",
    },
  ],
  reportTableData: [],
  undoStack: [],
  redoStack: [],
  claimsData: [],
  claimsDataFound: true,
  activeKeyFeature: "",
  isLoadingClaims:true,
};

const reportNotes = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_NOTES":
      return { ...initialState };
    case "GET_ALL_CLASS":
    case "SET_NOTES":
      const newState = {
        ...state,
        ...payload,
      };
      if (payload.reportTableData) {
        state.undoStack.push(state.reportTableData);
        state.redoStack = [];
      }
      return newState;

    case "UNDO_NOTES":
      if (state.undoStack.length === 0) {
        return state;
      }
      const previousState = state.undoStack.pop();
      state.redoStack.push(state.reportTableData);
      return {
        ...state,
        reportTableData: previousState,
      };

    case "REDO_NOTES":
      if (state.redoStack.length === 0) {
        return state;
      }
      const nextState = state.redoStack.pop();
      state.undoStack.push(state.reportTableData);
      return {
        ...state,
        reportTableData: nextState,
      };
    default:
      return state;
  }
};

export default reportNotes;
