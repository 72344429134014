import React, { useState, useEffect } from "react";
import { getImagePathFromPN } from "../../../common/images-functions";

const RwireImages = (props) => {
  const { row, handleOpenImagecarousel } = props;
  const imagePath = getImagePathFromPN(row._source["PN_B"]);
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = imagePath;

    img.onerror = () => {
      setImageError(true);
    };
  }, [imagePath]);
  return (
    <>
      <td className="result-table-images">
        {imageError ? (
          <div>
            <div className="img-not-available-result text-center">Image</div>
            <div className="img-not-available-result">Not Available!</div>
          </div>
        ) : (
          // eslint-disable-next-line jsx-a11y/img-redundant-alt
          <img
            align="center"
            src={imagePath}
            alt="Image not available"
            onClick={handleOpenImagecarousel}
            loading="lazy"
          />
        )}
      </td>
    </>
  );
};

export default RwireImages;
