import React from "react";
import RWIRE_IMAGES from "../components/common/common-functions/rwire-images";

const NoResultsFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column mx-auto">
      <div>
        <img
          className="nrf-gif"
          src={
            localStorage.getItem("isDarkTheme") === "true"
              ? RWIRE_IMAGES.RwireNoResultsFoundDark
              : RWIRE_IMAGES.RwireNoResultsFoundLight
          }
          alt=""
        />
      </div>
      <div className="nrf-heading-message">Sorry !! No Results Found</div>
      <div className="nrf-sub-message">
        Please try another search or contact us for more information
      </div>
    </div>
  );
};

export default NoResultsFound;
