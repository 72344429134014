import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import {
  commonStyles,
  keywordStyles,
  keyFeaturesStyles,
  assigneesStyles,
  inventorsStyles,
  usClassesStyles,
  cpcClassesStyles,
  ipcClassesStyles,
  ContentStyles,
  coverPageStyles,
  stringsStyles,
} from "./styles";

export const ExcelExportHelper = (data, isDownload = false) => {
  const exportPromise = handleExport({ data });

  if (!isDownload) {
    return exportPromise;
  }

  if (exportPromise && typeof exportPromise.then === "function") {
    exportPromise.then((blobData) => {
      const url = URL.createObjectURL(blobData);
      if (url !== null) {
        const downloadAnchorNode = document.createElement("a");
        downloadAnchorNode.setAttribute("href", url);
        downloadAnchorNode.setAttribute(
          "download",
          `${data[0].projectName}.xlsx`
        );
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
    });
  }
};

const workbook2blob = (workbook) => {
  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(workbook, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  return blob;
};

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }

  return buf;
};
const createContentsSheet = (workbook, data) => {
  let contentsSheetData = data
    .filter((sheetData) => sheetData.sheetName)
    .filter((sheetData) => sheetData.details.length > 0)
    .map((sheetData) => ({ A: sheetData.sheetName, B: "" }));

  if (contentsSheetData.length > 0) {
    contentsSheetData = [
      { A: "Invalidation Search Report" },
      { A: "" },
      { A: "Contents" },
      ...contentsSheetData,
    ];
    const contentsSheet = XLSX.utils.json_to_sheet(contentsSheetData, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(workbook, contentsSheet, "Contents");
  }
};
const createCoverPageSheet = (workbook, data) => {
  let coverSheetData = [
    { A: "", B: "" },
    { A: "", B: "" },
    { A: "", B: "" },
    { A: "", B: "" },
    { A: "", B: "Knowledge Solutions" },
    { A: "", B: "Invalidation Search Report" },
    { A: "", B: "Priority Date - January 18, 2002" },
    { A: "", B: "" },
    { A: "", B: "" },
    { A: "", B: "Report Overview" },
    {
      A: "",
      B: "2 relevant patents and 12 additional patents are auncovered during the search. The Search covered the main keyfeatures as described on Scope of the Search page. The search was conducted on basis of keywords, IPC, US, CPC classes and Assignees as depicted on search history sheet. The check list depicts the feature found in prior art. Our team has tried to map elements of the claim of interest with the features found in uncovered prior art as depicted in the claim mapping sheet.",
    },
  ];
  const contentsSheet = XLSX.utils.json_to_sheet(coverSheetData, {
    skipHeader: true,
  });
  XLSX.utils.book_append_sheet(workbook, contentsSheet, "Cover Page");
};

const handleExport = ({ data }) => {
  const workbook = XLSX.utils.book_new();

  // Create Index sheet
  createCoverPageSheet(workbook, data);
  createContentsSheet(workbook, data);
  data.forEach((sheetData) => {
    if (!sheetData.details) return;
    if (sheetData && sheetData.details && sheetData.details.length < 1) return;
    const sheetName = sheetData.sheetName;
    let details = sheetData.details;
    // Add headers for the details table
    const headers = Object.keys(details[0]);
    const headerRow = {};
    headers.forEach((value, index) => {
      headerRow[value] = value;
    });

    details = [
      { [headers[0]]: "Invalidation Search Report" },
      { [headers[0]]: "" },
      { [headers[0]]: sheetName },
      { [headers[0]]: sheetData.tableDescription },
      headerRow,
      ...details,
    ];

    const sheet = XLSX.utils.json_to_sheet(details, {
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
  });
  if (workbook.SheetNames.length < 3) {
    // eslint-disable-next-line no-alert
    alert("Add Some Project Data");
    return null;
  }

  const workbookBlob = workbook2blob(workbook);

  return addStyle(workbookBlob, data);
};

const addStyle = (workbookBlob, data) => {
  return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
    workbook.sheets().forEach((sheet) => {
      sheet.usedRange().style({
        fontFamily: "Arial",
        verticalAlignment: "center",
        wrapText: true,
      });
      sheet.gridLinesVisible(false);
      switch (sheet.name()) {
        case "Cover Page":
          coverPageStyles(sheet, workbook, data);
          break;
        case "Contents":
          ContentStyles(sheet, workbook, data);
          break;
        case "Keywords":
          commonStyles(sheet);
          keywordStyles(sheet, workbook);
          break;
        case "Key Features":
          commonStyles(sheet);
          keyFeaturesStyles(sheet, workbook);
          break;
        case "Key Assignees":
          commonStyles(sheet);
          assigneesStyles(sheet, workbook);
          break;
        case "Key Inventors":
          commonStyles(sheet);
          inventorsStyles(sheet, workbook);
          break;
        case "Relevant US Classes":
          commonStyles(sheet);
          usClassesStyles(sheet, workbook);
          break;
        case "Relevant CPC Classes":
          commonStyles(sheet);
          cpcClassesStyles(sheet, workbook);
          break;
        case "Relevant IPC Classes":
          commonStyles(sheet);
          ipcClassesStyles(sheet, workbook);
          break;
        case "Relevant Search Strings":
          commonStyles(sheet);
          stringsStyles(sheet, workbook);
          break;

        default:
          sheet.cell("A1").style({
            bold: true,
            fontSize: 12,
            fill: "FFFFFF",
          });
      }
    });

    return workbook.outputAsync().then((blob) => blob);
  });
};
