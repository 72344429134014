import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  ReportDownload,
  ReportSendOnMail,
} from "../../../assets/images/report";
import DataTable from "./data-table";
import {
  filterByType,
  filterNonBlank,
  getExcelDownloadableData,
} from "../../../ui-components/functions/report-view/utils";
import {
  assigneesMapping,
  cpcMapping,
  inventorsMapping,
  ipcMapping,
  keyFeaturesMapping,
  keywordsMapping,
  stringsMapping,
  usMapping,
  keyFeaturePatentsMapping,
  patentKeyFeatureMapping,
} from "../../../ui-components/functions/report-view/mappings";
import ProjectTitleSection from "./project-title-section";
import { useNavigate } from "react-router-dom";
import DownloadModal from "./download-modal";
import SendMailModal from "./send-mail-modal";

const FinalReportView = ({
  onGetReportProjectDataById,
  onSentReportOnMail,
}) => {
  const [projectData, setProjectData] = useState({});
  const [isEdited, setIsEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMailModal, setShowMailModal] = useState(false);
  const [editingCell, setEditingCell] = useState(null);
  const navigate = useNavigate();

  function getKeyFeaturePatents(keyFeatures, patents) {
    const keyFeatureMap = {};
    keyFeatures = keyFeatures.filter(
      (keyFeature) => keyFeature.body.trim() !== ""
    );

    // Populate keyFeatureMap with key features as keys and empty arrays as values
    keyFeatures.forEach((keyFeature, index) => {
      keyFeatureMap[`Key Feature ${index + 1}: ${keyFeature.body}`] = {
        relevance: `Key Feature-${index + 1}`,
        Defination: ` ${keyFeature.body}`,
        patents: [],
      };
    });

    // Iterate through patents and populate keyFeatureMap with associated patent numbers
    patents.forEach((patent) => {
      patent.projectRelevantResultKeyFeatureData.forEach((result) => {
        const keyFeature = keyFeatures.find(
          (feature) => feature.id === result.keyFeature
        );
        if (keyFeature) {
          const keyFeatureText = `Key Feature ${
            keyFeatures.findIndex((kf) => kf.id === keyFeature.id) + 1
          }: ${keyFeature.body}`;
          keyFeatureMap[keyFeatureText].patents.push(patent.patentNumber);
        }
      });
    });
    
    Object.keys(keyFeatureMap).forEach((key) => {
      keyFeatureMap[key].patents = keyFeatureMap[key].patents.join(" | ");
    });

    const result = Object.values(keyFeatureMap);

    return result;
  }

  function processPatentData(patentData, keyFeaturesData) {
    keyFeaturesData = keyFeaturesData.filter(
      (keyFeature) => keyFeature.body.trim() !== ""
    );

    const keyFeaturesMap = new Map();
    keyFeaturesData.forEach((kf, index) => {
      keyFeaturesMap.set(kf.id, index + 1);
    });

    const processedData = patentData.map((patent) => {
      const availableKeyFeatures = [];
      const notAvailableKeyFeatures = [];

      patent.projectRelevantResultKeyFeatureData.forEach((keyFeatureData) => {
        if (keyFeaturesMap.has(keyFeatureData.keyFeature)) {
          availableKeyFeatures.push(
            keyFeaturesMap.get(keyFeatureData.keyFeature)
          );
        }
      });

      if (availableKeyFeatures.length === 0) {
        availableKeyFeatures.push("NA");
      }
      if (keyFeaturesData.length === 0) {
        notAvailableKeyFeatures.push("NA");
      }

      for (let i = 1; i <= keyFeaturesData.length; i++) {
        if (!availableKeyFeatures.includes(i)) {
          notAvailableKeyFeatures.push(i);
        }
      }
      return {
        publicationNumber: patent.patentNumber,
        availableKeyFeatures: availableKeyFeatures,
        notAvailableKeyFeatures: notAvailableKeyFeatures,
      };
    });

    return processedData;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = sessionStorage.getItem("projectId");
        if (!id) {
          navigate("/en/existing-project");
        }
        let data = await onGetReportProjectDataById(id);
        const keyFeaturePatents = getKeyFeaturePatents(
          data.keyFeatures,
          data.relevantResults
        );

        const patentKeyFeatures = processPatentData(
          data.relevantResults,
          data.keyFeatures
        );
        data = {
          ...data,
          usClasses: filterByType(data.classes, "US"),
          cpcClasses: filterByType(data.classes, "CPC"),
          ipcClasses: filterByType(data.classes, "IPC"),
          keyFeaturePatents: keyFeaturePatents,
          patentKeyFeatures: patentKeyFeatures,
        };
        setProjectData(data);
      } catch (error) {}
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEdited) return;

    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEdited]);

  const handleCellEdit = (newData, field) => {
    setIsEdited(true);
    setProjectData((prevData) => {
      return { ...prevData, [field]: newData };
    });
  };

  const handleDeleteCell = (newData, field, indexToRemove) => {
    setIsEdited(true);

    // Check if the index is within the array bounds
    if (indexToRemove >= 0 && indexToRemove < newData.length) {
      // Remove the object at the specified index
      newData.splice(indexToRemove, 1);
    }
    setProjectData((prevData) => {
      return { ...prevData, [field]: newData };
    });
  };
  const handleDeleteTable = (field) => {
    let data = { ...projectData };
    if (field in data) {
      delete data[field];
    }
    setProjectData(data);
  };
  return (
    <div className="w-100 project-final-view">
      <div className="heading-strip">Report Generation</div>
      <div className="list-section" id="project-report-section">
        <ProjectTitleSection name={projectData && projectData.name} />
        <div className="data-tables">
          <DataTable
            data={projectData && filterNonBlank(projectData.keyFeatures)}
            mapping={keyFeaturesMapping}
            columnWidths={[100]}
            onCellEdit={handleCellEdit}
            field="keyFeatures"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.keywords)}
            mapping={keywordsMapping}
            columnWidths={[20, 80]}
            onCellEdit={handleCellEdit}
            field="keywords"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.cpcClasses)}
            mapping={cpcMapping}
            columnWidths={[20, 80]}
            onCellEdit={handleCellEdit}
            field="cpcClasses"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.ipcClasses)}
            mapping={ipcMapping}
            columnWidths={[20, 80]}
            onCellEdit={handleCellEdit}
            field="ipcClasses"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.usClasses)}
            mapping={usMapping}
            columnWidths={[20, 80]}
            onCellEdit={handleCellEdit}
            field="usClasses"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.assignees)}
            mapping={assigneesMapping}
            columnWidths={[100]}
            onCellEdit={handleCellEdit}
            field="assignees"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.inventors)}
            mapping={inventorsMapping}
            columnWidths={[100]}
            onCellEdit={handleCellEdit}
            field="inventors"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && filterNonBlank(projectData.strings)}
            mapping={stringsMapping}
            columnWidths={[80, 20]}
            onCellEdit={handleCellEdit}
            field="strings"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />
          <DataTable
            data={projectData && projectData.keyFeaturePatents}
            mapping={keyFeaturePatentsMapping}
            columnWidths={[20, 40, 40]}
            onCellEdit={handleCellEdit}
            field="keyFeaturePatents"
            editingCell={editingCell}
            setEditingCell={setEditingCell}
            onDeleteCell={handleDeleteCell}
            onDeleteTable={handleDeleteTable}
          />

          <DataTable
            data={projectData && projectData.patentKeyFeatures}
            mapping={patentKeyFeatureMapping}
            columnWidths={[30, 30, 40]}
            field="patentKeyFeatures"
            hideEditingAndDeletingUI={true}
          />
        </div>
      </div>
      <div className="create-new-section">
        <div className="inner">
          <button onClick={() => setShowModal(true)}>
            <img src={ReportDownload} alt="" />
            Download
          </button>

          <button onClick={() => setShowMailModal(true)}>
            <img src={ReportSendOnMail} alt="" />
            Send on mail
          </button>
        </div>
      </div>
      {showMailModal && (
        <SendMailModal
          projectData={projectData}
          isOpen={showMailModal}
          onClose={() => setShowMailModal(false)}
          onSentReportOnMail={onSentReportOnMail}
        />
      )}
      {showModal && (
        <DownloadModal
          projectData={projectData}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default FinalReportView;
