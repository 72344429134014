import { useEffect, useState } from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import World from "../../../../lib/chart/fusioncharts.worldwithcountries";
import Maps from "fusioncharts/fusioncharts.maps";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { formatAggregationSingleValueChartData } from "../../../rwire-cognizance/functions/searchAggregationQuery";

import { dFlexAlignCenterJustifyCenter } from "../../../../common/class-name";
charts(FusionCharts);
FusionTheme(FusionCharts);
ReactFC.fcRoot(FusionCharts, Maps, World, FusionTheme);

const ChartDataBlock = (props) => {
  const {
    item: { field1, field2, isMultiSeries },
    chartConfig,
    chartData = [],
    id,
  } = props;
  const [config, setConfigs] = useState(chartConfig);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    setConfigs(chartConfig);
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field1, field2]);

  async function getResult() {
    let data = formatAggregationSingleValueChartData(
      chartData,
      chartConfig.type,
      field1,
      field2,
      isMultiSeries,
      [],
      []
    );

    config.dataSource = {
      ...config.dataSource,
      ...chartConfig.dataSource,
      ...data,
    };

    setConfigs(config);
    setLoading(false);
  }

  if (id === "countrywise-patent-records") {
    config.dataSource.chart.paletteColors = "#019B54,#FDB82C";
  }

  if (isLoading) {
    return (
      <>
        <div className="column">
          <div className="chart-card">
            <span className={`p-5 ${dFlexAlignCenterJustifyCenter}`}>
              Loading...
            </span>
          </div>
        </div>
      </>
    );
  }
  return (
    <div
      className={`chart-block ${id === "top-inventor-name" ? "inv-graph" : ""}`}
    >
      <ReactFC {...config} />
    </div>
  );
};

export default ChartDataBlock;
