import { useDrop, useDrag } from "react-dnd";
import { ItemTypes } from "./item-types";
import CloseIcon from "../../images/close-icon-white.svg";

const YDropDiv = (props) => {
  const { currentSheet, getFullName, code, onSetGenerateChart } = props;

  const handleClick = () => {
    onSetGenerateChart({
      [currentSheet]: { ...props[currentSheet], yaxis: "" },
    });
  };

  const callHandler = (data, axis) => {
    onSetGenerateChart({
      [currentSheet]: { ...props[currentSheet], xaxis: data, yaxis: "" },
    });
  };

  // eslint-disable-next-line
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: { code },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        callHandler(item.code, dropResult.name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: props.dropboxname }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  let backgroundColor = "#0048A2";

  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }

  return (
    <div
      className="axis-block d-flex align-items-center y-axis-block flex-grow-1 justify-content-center"
      ref={(node) => drag(drop(node))}
      style={{ backgroundColor }}
      data-testid="yaxis"
      title="Drop field here">
      <div className="">
        {props[currentSheet].yaxis ? (
          <>
            <span>{`${getFullName(props[currentSheet].yaxis)} count`}</span>
            <span
              className="cursor-pointer close-icon-axis"
              onClick={handleClick}>
              <img src={CloseIcon} alt="clear-axix" />
            </span>
          </>
        ) : isActive ? (
          "Release to drop"
        ) : (
          "Drag and drop here for count"
        )}
      </div>
    </div>
  );
};

export default YDropDiv;
