import React, { useEffect, useState } from "react";
import { Image } from "../../../ui-components/ui-component";
import RadioInputs from "./radio-inputs";
import { GreenTick, RedCross } from "../../../assets/images/report";

const PatentKeyFeatureTabContent = (props) => {
  const {
    onCheckIfPatentAvailable,
    onFindKeywordsFromText,
    onSetResultKeywords,
    onGetKeyFeaturesOfPatent,
    patentId = "",
    key,
  } = props;
  const [patentNo, setPatentNo] = useState(patentId);
  const [isValid, setIsValid] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    setPatentNo(patentId);
  }, []);
  useEffect(() => {
    onSetResultKeywords([]);
  }, [key]);
  const handleSearchForPatent = async () => {
    try {
      if (patentNo) {
        const result = await onCheckIfPatentAvailable(patentNo);
        if (result) {
          setIsValid(true);
          setData(result?._source);
        } else {
          setIsValid(false);
          setData(null);
        }
      }
    } catch (error) {
      setData(null);
    }
  };

  const handleFindKeywords = async () => {
    if (!isValid) return;
    if (!selectedOption) return;
    try {
      const response = await onGetKeyFeaturesOfPatent([data[selectedOption]]);
      onSetResultKeywords(response);
    } catch (err) {
      console.error("Error Finding for keywords:", err);
    }
  };

  return (
    <div className="patent-tab-content">
      <div className="flex-grow-1 input-section">
        <div className="input-container">
          <input
            type="text"
            className={`w-100 patent-input ${
              isValid === true ? "valid" : isValid === false ? "invalid" : ""
            }`}
            onChange={(e) => {
              setPatentNo(e.target.value);
              setIsValid(null);
            }}
            placeholder="Type a Patent Publication Number to Generate Key features"
            value={patentNo}
            onKeyDown={(e) => e.key === "Enter" && handleSearchForPatent()}
          />
          <Image
            className="validation-image"
            src={
              isValid === true ? GreenTick : isValid === false ? RedCross : ""
            }
            alt=""
          />{" "}
        </div>
        {isValid === true ? (
          <div className="bg-white">
            <p className="flex-grow-1 action-section p-2">
              Please select the field
            </p>
            <RadioInputs
              selectedOption={selectedOption}
              onSetSelectedOption={setSelectedOption}
            />
          </div>
        ) : (
          <div className="bg-white flex-grow-1"></div>
        )}
      </div>
      {!isValid ? (
        <button className="ms-auto" onClick={handleSearchForPatent}>
          Search
        </button>
      ) : (
        <button className="ms-auto" onClick={handleFindKeywords}>
          Generate
        </button>
      )}
    </div>
  );
};

export default PatentKeyFeatureTabContent;
