import React from "react";
import { Block, Image, Span } from "../../../ui-components";
import { useNavigate, useLocation } from "react-router-dom";
import { NavIconClasses, dashboardIcon } from "../../../assets/images/report";
import { ACCESS_COMMENTOR, ACCESS_VIEWER } from "./../../../common/user-roles";
import { useStore } from "react-redux";

const Sidebar = ({ routes, workspaceElements, projectPatentNumber }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  const handleClick = (to) => {
    navigate(to);
  };

  return (
    <Block className="sidebar-wrapper">
      <Block className="sidebar-sections-list h-100 pb-3">
        {routes.map((option, idx) => {
          if (workspaceElements.includes(idx + 1)) {
            return (
              <Block key={idx}>
                <Block
                  className={`list-group-item text-truncate fw-bold cursor-pointer ${
                    location.pathname === option.to ? "active" : ""
                  }`}
                  onClick={() => handleClick(option.to)}
                >
                  <Span className="nav-left-icon">
                    <Image src={option.navImgIcon}></Image>
                  </Span>
                  {option.name}
                </Block>
              </Block>
            );
          } else return null;
        })}
        {projectPatentNumber && (
          <Block>
            <Block
              className={`list-group-item text-truncate fw-bold cursor-pointer ${
                location.pathname === "/en/report/cited-references"
                  ? "active"
                  : ""
              }`}
              onClick={() => handleClick("/en/report/cited-references")}
            >
              <Span className="nav-left-icon">
                <Image src={NavIconClasses}></Image>
              </Span>
              Cited References
            </Block>
          </Block>
        )}
        <Block
          className="mt-auto"
          style={
            userProjectAccess !== ACCESS_COMMENTOR
              ? { borderTop: "1px solid #ffcb0c" }
              : {}
          }
        >
          {userProjectAccess !== ACCESS_COMMENTOR && (
            <Block
              className={`list-group-item text-truncate fw-bold cursor-pointer`}
              onClick={() => handleClick("/en/report")}
            >
              <Span className="nav-left-icon">
                <Image src={dashboardIcon}></Image>
              </Span>
              Go to Dashboard
            </Block>
          )}
        </Block>
      </Block>
    </Block>
  );
};

export default Sidebar;
