const initialState = {
  orderedData: [],
  selectedFields: ["PN_B"],
  exportQuery: "",
  selectedType: "csv",
  exportFileName: ""
};

const exportField = (state = initialState, { type, payload }) => {
  switch (type) {
    case "EXPORT_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default exportField;
