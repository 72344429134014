import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireReportNotes } from "../../components/report";
import {
  addRowToReportProject,
  deleteRowFromReportProject,
  getReportProjectNotes,
  redoReportNotes,
  setPatentClaims,
  setReportNotes,
  undoReportNotes,
  updateReportProjectNotesRow,
  updateReportProjectNotesSortOrder,
} from "../../action/report/report-notes";
import { splitPatentClaims } from "../../ui-components/functions/notes/utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Container = (props) => {
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("projectId");
  useEffect(() => {
    if (_id) {
      if (props.reportTableData.length === 0) {
        props.onGetReportProjectNotes();
      }
    } else {
      navigate("/en/existing-project");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);

  return <RwireReportNotes {...props} />;
};

const mapStateToProps = ({
  reportNotes,
  project: { projectPatentNumber },
}) => ({
  ...reportNotes,
  projectPatentNumber,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportNotes: setReportNotes,
      onSplitPatentClaims: splitPatentClaims,
      onSetPatentClaims: setPatentClaims,
      onUndoReportNotes: undoReportNotes,
      onRedoReportNotes: redoReportNotes,
      onUpdateNotesSortOrder: updateReportProjectNotesSortOrder,
      onGetReportProjectNotes: getReportProjectNotes,
      onUpdateReportProjectNotesRow: updateReportProjectNotesRow,
      onAddRowToreportProject: addRowToReportProject,
      onDeleteRowFromReportProject: deleteRowFromReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
