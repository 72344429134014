import { Block, Button } from "../ui-component";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../common/user-roles";

const ColumnRenderer = (props) => {
  const {
    columName = "",
    type,
    isAddExtraClass,
    className,
    onOpenModal,
  } = props;
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  let minWidth = "";

  if (columName === "Count") {
    minWidth = "36.5%";
  } else if (columName === "Strings") {
    minWidth = "63.5%";
  }

  if (type === "text") {
    return (
      <Block
        className={`header-row ${isAddExtraClass ? "first-column" : ""}`}
        style={{ minWidth }}
      >
        {columName}
      </Block>
    );
  }

  if (type === "button") {
    if (userProjectAccess !== ACCESS_VIEWER) {
      return (
        <Button
          className={`header-row ${className}`}
          onClick={onOpenModal}
          style={{ minWidth }}
        >
          {columName}
        </Button>
      );
    }
  }
};

const Header = ({ columns = [], isAddExtraClass = false, onOpenModal }) => {
  return (
    <Block className="report-sortable-table-header position-relative">
      {columns.map((c, key) => (
        <ColumnRenderer
          columName={c.columnName}
          type={c.type}
          key={key}
          isAddExtraClass={isAddExtraClass}
          className={c.className}
          onOpenModal={onOpenModal}
        />
      ))}
    </Block>
  );
};

export default Header;
