import peggy from "../resources/parser/parser";
import { generateQuery } from "../common/generate-rec-query";
import { setQuery } from "./search-query";
import { checkSpecialConditions, cleanQuery } from "../common/query-functions";
import { addHistory, reRunHistory } from "../action/history";
import { getSelectedFilters } from "./aggregationFunction";
import { setSelectedField } from "./export";
import { filtersJson } from "../components/common/patents-result-common/rwire-filter-modal";
import { setApp } from "./app";
import { smart_search, filters_options } from "../components/constant";
import { displayError } from "../service/display-error";
import { search } from "../service/elasticSearch";
import { Highlightmap } from "../common/quick-view-heading-map";
import {
  extractSearchableFields,
  extractWords,
  formatNumberFields,
  sortSecondArrayByFirst,
} from "../common/pre-process-input/utils";
import { replaceSpace } from "../common/query-replace-space";
import { findMatchingWords, setHighlight } from "./highlight";
import { filterHighlightFields } from "../resources/data/highlight";
import { mapErrorMessage } from "../common/error-messages";
import { addString } from "./report/report-string";

const gethighlightedQueryWordsArray = (queryObj, blankArray) => {
  return new Promise((resolve, reject) => {
    if (!filterHighlightFields.includes(queryObj.field)) {
      if (queryObj.term) {
        blankArray.push(queryObj.term);
      }
      if (queryObj.left) {
        gethighlightedQueryWordsArray(queryObj.left, blankArray);
      }
      if (queryObj.right) {
        gethighlightedQueryWordsArray(queryObj.right, blankArray);
      }
    }
    resolve();
  });
};

export const setResultTable = (payload) => ({
  type: "RESULT_SET",
  payload: {
    ...payload,
  },
});

export const resetHighlightWords = (payload) => ({
  type: "RESULT_SET",
  payload: {
    ...payload,
  },
});

export const resetDetails = (payload = []) => ({
  type: "RESET_RESULT_SET",
  payload,
});
export const resetState = (payload) => ({
  type: "RESET_STATE_EXCLUDING_PAYLOAD",
  payload,
});
export const searchNewColumnField =
  (options = {}) =>
  async (dispatch, getState) => {
    const { isNumberWithIncludeSearch = false } = options;
    const {
      app: { searchQuery },
      resultTable: { isLoadingResult, displayData },
    } = getState();
    if (!isLoadingResult) {
      dispatch(setResultTable({ isLoadingResult: true }));
      const {
        resultTable: { dataSize, dataFrom, sortBy, sortType },
        app: { collapsableFields = "PN_B", selectedIncludes = [] },
      } = getState();
      const isValidQuery = await dispatch(validationQuery(searchQuery));
      if (!isValidQuery) {
        dispatch(setResultTable({ isLoadingResult: false }));
        throw new Error("Invalid Query");
      }

      await dispatch(setQuery({ syntaxqueryCorrect: "" }));
      let filters = getSelectedFilters(getState().resultTable);
      console.log(options.includeFieldsOnResult);
      const queryObj = {
        queryToSearch: searchQuery,
        isNumberWithIncludeSearch,
        selectedIncludes,
        dataSize,
        dataFrom,
        sortBy,
        sortType,
        includeFieldsOnResult: options.includeFieldsOnResult,
        collapsableField: collapsableFields,
        filters,
        highlightWords: [],
        require_field_match: false,
      };

      const body = JSON.stringify(queryObj);
      try {
        const data = await search(body, smart_search);
        const dataResponse = data.data;
        const newDisplayData = [...displayData];
        dataResponse.hits.hits.forEach((newFieldItem) => {
          newDisplayData.forEach((displayItem) => {
            if (newFieldItem._id === displayItem._id) {
              for (let key in newFieldItem._source) {
                displayItem._source[key] = newFieldItem._source[key];
              }
            }
          });
        });
        dispatch(
          setResultTable({
            displayData: newDisplayData,
          })
        );
      } catch (err) {
      } finally {
        dispatch(setResultTable({ isLoadingResult: false }));
      }
    }
  };

export const rwireSearchAPI =
  (queryToSearch = "", options = {}) =>
  async (dispatch, getState) => {
    const { isNumberWithIncludeSearch = false, isResetHighlight = false } =
      options;
    let { isReRunId } = options;
    const {
      app: { searchQuery },
      resultTable: { isLoadingResult },
    } = getState();
    if (!isLoadingResult) {
      if (queryToSearch !== "") {
        dispatch(setSelectedField({ selectedFields: ["PN_B"] }));
        dispatch(
          resetDetails([
            "filterList",
            "patentReamainingList",
            "patentInformationList",
            "dataSize",
            "includeFieldsOnResult",
          ])
        );
        dispatch(resetHighlightWords({ highlightword: [] }));
        localStorage.removeItem("highlightWords");
        localStorage.removeItem("highlightedWords");
      }

      dispatch(setResultTable({ isLoadingResult: true }));
      const {
        resultTable: {
          dataSize,
          dataFrom,
          sortBy,
          sortType,
          includeFieldsOnResult,
        },
        app: {
          collapsableFields = "PN_B",
          selectedIncludes = [],
          isQuickViewOpen = false,
        },
      } = getState();
      const finalSearchQuery = queryToSearch ? queryToSearch : searchQuery;
      const isValidQuery = await dispatch(validationQuery(finalSearchQuery));
      if (!isValidQuery) {
        dispatch(setResultTable({ isLoadingResult: false }));
        throw new Error("Invalid Query");
      }

      await dispatch(setQuery({ syntaxqueryCorrect: "" }));
      let filters = getSelectedFilters(getState().resultTable);

      const queryObj = {
        queryToSearch: finalSearchQuery,
        isNumberWithIncludeSearch,
        selectedIncludes,
        dataSize,
        dataFrom,
        sortBy,
        sortType,
        includeFieldsOnResult,
        collapsableField: collapsableFields,
        filters,
        highlightWords: [],
        require_field_match: false,
      };
      const storedQueryDetails = {
        queryToSearch: queryObj.queryToSearch,
        isNumberWithIncludeSearch: queryObj.isNumberWithIncludeSearch,
        selectedIncludes: queryObj.selectedIncludes,
        filters: queryObj.filters,
      };
      localStorage.setItem(
        "storedQueryDetails",
        JSON.stringify(storedQueryDetails)
      );
      const body = JSON.stringify(queryObj);
      try {
        const data = await search(body, smart_search);

        let dataResponse = data.data;
        dataResponse.hits.total.value = data.headers.get("X-Total-Count")
          ? data.headers.get("X-Total-Count")
          : dataResponse.hits.total.value;

        if (data.headers.get("X-DEBUG-SCORES")) {
          dataResponse.hits.hits.map((i, k) => {
            dataResponse.hits.hits[k]["_score"] = JSON.parse(
              data.headers.get("X-DEBUG-SCORES")
            ).filter((item) => item.id === i._id)[0]["score"];
          });

          if (sortBy === "_score") {
            dataResponse = sortSecondArrayByFirst(
              JSON.parse(data.headers.get("X-DEBUG-SCORES")),
              dataResponse
            );
          }
        }

        let highlightedQueryWordsArray = [];
        let processedQuery = formatNumberFields(finalSearchQuery, {
          isForHighlightWords: true,
        });

        const parser = peggy.parse(cleanQuery(replaceSpace(processedQuery)));
        await gethighlightedQueryWordsArray(parser, highlightedQueryWordsArray);
        highlightedQueryWordsArray = [...new Set(highlightedQueryWordsArray)];

        const cleanQueryOrignal = replaceSpace(finalSearchQuery);

        dispatch(
          setResultDetails({
            dataResponse,
            isReRunId,
            highlightword: highlightedQueryWordsArray,
            isResetHighlight,
            cleanQueryOrignal,
            collapsableField: collapsableFields,
          })
        );
      } catch (error) {
        displayError(error);
        dispatch(setResultTable({ isLoadingResult: false }));
        dispatch(setResultTable({ error: "Something went wrong" }));
        dispatch(
          setQuery({
            syntaxqueryError:
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message,
          })
        );
        throw error;
      }
    }
    dispatch(setResultTable({ isLoadingResult: false }));
  };

export const getQuickViewHighlights =
  (
    queryToSearch = "",
    options = {},
    shouldRunRemainingSearch = false,
    resultIds = []
  ) =>
  async (dispatch, getState) => {
    if (!shouldRunRemainingSearch) return;
    const { isNumberWithIncludeSearch = false } = options;
    const {
      app: { searchQuery },
      resultTable: { displayData },
    } = getState();
    const resultIdsAll = displayData.map((i) => i._id);
    const {
      resultTable: { dataSize, dataFrom, sortBy, sortType },
      app: { collapsableFields = "PN_B", selectedIncludes = [] },
    } = getState();
    const finalSearchQuery = queryToSearch ? queryToSearch : searchQuery;
    let filters = getSelectedFilters(getState().resultTable);

    const extractWordsArray = extractWords(finalSearchQuery);

    const queryObj = {
      queryToSearch: finalSearchQuery,
      isNumberWithIncludeSearch,
      selectedIncludes: resultIds,
      dataSize,
      dataFrom,
      sortBy,
      sortType,
      includeFieldsOnResult: [""],
      collapsableField: collapsableFields,
      filters,
      highlightWords: extractSearchableFields(extractWordsArray),
      require_field_match: false,
    };
    try {
      let quickView_highlights_data = await search(
        queryObj,
        "quick-highlights"
      );
      quickView_highlights_data = quickView_highlights_data.data;
      let quickViewData = [];
      resultIdsAll.map((i) => {
        let quickViewArray = quickView_highlights_data.hits.hits;
        let quickViewFilterArray = quickViewArray.filter(
          (item) => i === item._id
        );
        quickViewData.push({
          highlight: quickViewFilterArray[0]?.highlight
            ? quickViewFilterArray[0]?.highlight
            : "",
          _id: i,
        });
      });
      dispatch(
        setResultTable({
          quickViewHighlights: quickViewData,
        })
      );
    } catch (error) {}
  };

export const rwireAggregationCountsAPI =
  (queryToSearch = "", options = {}, shouldRunRemainingSearch = false) =>
  async (dispatch, getState) => {
    const { isNumberWithIncludeSearch = false } = options;
    let { isReRunId, runAggregate = true } = options;
    const {
      app: { searchQuery, collapsableFields = "PN_B" },
      resultTable: { totalRecordsCount },
    } = getState();
    if (!runAggregate) return;
    const finalSearchQuery = queryToSearch ? queryToSearch : searchQuery;
    if (shouldRunRemainingSearch) {
      dispatch(
        setResultTable({
          isAggregationCountsLoading: true,
        })
      );

      let filters = getSelectedFilters(getState().resultTable);

      const aggBody = JSON.stringify({
        queryToSearch: finalSearchQuery,
        isNumberWithIncludeSearch,
        includeFieldsOnResult: ["PN_B"],
        filters,
        dataSize: 0,
      });
      try {
        let aggrigated_data = await search(aggBody, "aggregate");
        aggrigated_data = aggrigated_data.data;
        let total = totalRecordsCount;
        if (collapsableFields === "PN_B") {
          aggrigated_data.aggregations = aggrigated_data.aggregations
            ? {
                ...aggrigated_data.aggregations,
                PN_B: { value: totalRecordsCount },
              }
            : {};
        } else {
          total = aggrigated_data.aggregations[collapsableFields]
            ? aggrigated_data.aggregations[collapsableFields].value
            : 0;
        }
        dispatch(
          setResultTable({
            aggregations: aggrigated_data?.aggregations,
            totalRecordsCount: total,
          })
        );
        console.log(isReRunId, typeof isReRunId);
        isReRunId = isReRunId ? isReRunId : "";
        if (!isReRunId && typeof isReRunId !== "boolean") {
          await addHistory({
            query: finalSearchQuery,
            numberOfHits: aggrigated_data.aggregations.PN_B.value,
          });
          const projectId = sessionStorage.getItem("projectId");
          const projectMode = sessionStorage.getItem("projectMode");
          if (projectId && projectMode !== "false") {
            await dispatch(
              addString({
                body: finalSearchQuery,
                publicationCount: aggrigated_data.aggregations.PN_B.value,
                applicationCount: aggrigated_data.aggregations.AN_B.value,
                order: 1,
                projectId,
              })
            );
          }
        } else if (typeof isReRunId !== "boolean") {
          await reRunHistory(
            finalSearchQuery,
            aggrigated_data.aggregations.PN_B.value,
            isReRunId
          );
        }
      } catch (error) {
      } finally {
        dispatch(
          setResultTable({
            isAggregationCountsLoading: false,
          })
        );
      }
    } else {
      isReRunId = isReRunId ? isReRunId : "";
      if (!isReRunId && typeof isReRunId !== "boolean") {
        await addHistory({
          query: finalSearchQuery,
          numberOfHits: 0,
        });
        const projectId = sessionStorage.getItem("projectId");
        const projectMode = sessionStorage.getItem("projectMode");
        if (projectId && projectMode !== "false") {
          await dispatch(
            addString({
              body: finalSearchQuery,
              publicationCount: 0,
              applicationCount: 0,
              order: 1,
              projectId,
            })
          );
        }
      } else if (typeof isReRunId !== "boolean") {
        await reRunHistory(finalSearchQuery, 0, isReRunId);
      }
    }
  };
export const performSearch =
  (query = "", options = {}, navigate, routeStr = "") =>
  async (dispatch, getState) => {
    const {
      app: { isQuickViewOpen = false },
    } = getState();
    try {
      if (query === null) {
        await dispatch(rwireSearchAPI());
      } else {
        await dispatch(rwireSearchAPI(query, options));
      }
      const {
        resultTable: { displayData },
      } = getState();
      if (navigate && routeStr) {
        navigate(routeStr);
      }
      const shouldRunRemainingSearch = displayData.length > 0;

      const resultIds = displayData.map((i) => i._id);
      if (isQuickViewOpen) {
        dispatch(
          getQuickViewHighlights(query, options, shouldRunRemainingSearch, [
            resultIds[0],
          ])
        );
        dispatch(
          rwireAggregationCountsAPI(query, options, shouldRunRemainingSearch)
        );
      } else {
        dispatch(
          rwireAggregationCountsAPI(query, options, shouldRunRemainingSearch)
        );
        dispatch(
          getQuickViewHighlights(query, options, shouldRunRemainingSearch, [
            resultIds[0],
          ])
        );
      }

      return true;
    } catch (error) {
      displayError(error);
      return false;
    }
  };

export const validationQuery = (rawQuery) => async (dispatch, getState) => {
  try {
    const {
      searchQuery: { tabWiseSearchQuery },
      app: { activeTab, isEditModalOpen },
    } = getState();
    rawQuery = replaceSpace(rawQuery);
    const cleanQueryOrignal = cleanQuery(rawQuery);

    checkSpecialConditions(rawQuery);
    rawQuery = formatNumberFields(rawQuery);

    const parser = peggy.parse(cleanQuery(rawQuery));
    generateQuery(parser, true);

    dispatch(setQuery({ syntaxqueryCorrect: "Syntax correct " }));

    if (!isEditModalOpen) {
      dispatch(
        setApp({
          searchedVisibleQuery: cleanQueryOrignal,
        })
      );

      dispatch(
        setQuery({
          syntaxqueryError: "",
          tabWiseSearchQuery: {
            ...tabWiseSearchQuery,
            [activeTab]: cleanQueryOrignal,
          },
        })
      );
    }
    return true;
  } catch (e) {
    let errorMessage = e.message ? e.message : "Syntax Wrong";
    errorMessage = mapErrorMessage(errorMessage);

    dispatch(setQuery({ syntaxqueryCorrect: "" }));
    dispatch(setQuery({ syntaxqueryError: errorMessage }));

    return false;
  }
};

const setResultDetails =
  ({
    dataResponse = [],
    elasticQuery,
    isReRunId,
    highlightword,
    isResetHighlight = false,
    cleanQueryOrignal,
    collapsableField,
  }) =>
  async (dispatch, getState) => {
    const {
      highlight: {
        highlightedWords: storedHighlightWord = [],
        highlightText: storedHighlightText = "",
      },
    } = getState();
    let updatedHighlightedWords = storedHighlightWord;
    let updatedHighlightText = storedHighlightText;
    let total = dataResponse.hits.total.value;

    const result = dataResponse.hits.hits;

    if (isResetHighlight) {
      updatedHighlightedWords = await findMatchingWords(result, highlightword);
      updatedHighlightedWords = updatedHighlightedWords.sort(
        (a, b) => b.word.length - a.word.length
      );
      updatedHighlightText = "";
    }

    dispatch(
      setResultTable({
        // totalRecordsCount: total,
        displayData: result,
        isLoadingResult: false,
        esHighlightwords: highlightword,
        highlightword,
        totalHighlightedWords: highlightword,
      })
    );
    if (collapsableField === "PN_B") {
      dispatch(
        setResultTable({
          totalRecordsCount: parseInt(total),
        })
      );
    }

    dispatch(
      setHighlight({
        highlightedWords: updatedHighlightedWords,
        highlightText: updatedHighlightText,
      })
    );
  };

export const mapKeysToProps = (filterList) => {
  return filterList.map((key) => filtersJson[key]);
};

export const getFiltersOptions =
  ({ isIndivisual = false, isDisplayListFilters = false }) =>
  async (dispatch, getState) => {
    const {
      resultTable: {
        filtersSearchText,
        openedFilterName = "",
        filterOptionsData,
        filterList,
      },
      app: { searchQuery, collapsableFields = "PN_B" },
    } = getState();
    const isValidQuery = await dispatch(validationQuery(searchQuery));
    if (!isValidQuery) {
      dispatch(
        setResultTable({
          isLoadingFilterData: false,
        })
      );
      return;
    }

    await dispatch(setQuery({ syntaxqueryCorrect: "" }));
    if (openedFilterName !== "" || isDisplayListFilters) {
      if (
        !filterOptionsData.hasOwnProperty(filtersJson[openedFilterName]) ||
        isIndivisual ||
        isDisplayListFilters
      ) {
        dispatch(
          setResultTable({
            isLoadingFilterData: filterOptionsData.hasOwnProperty(
              filtersJson[openedFilterName]
            )
              ? false
              : true,
          })
        );
        const fields = isDisplayListFilters
          ? mapKeysToProps(filterList)
          : [filtersJson[openedFilterName]];
        const queryFilters = getSelectedFilters(getState().resultTable);
        let queryObj = JSON.parse(localStorage.getItem("storedQueryDetails"));
        queryObj = {
          queryToSearch: searchQuery,
          isNumberWithIncludeSearch: queryObj.isNumberWithIncludeSearch,
          selectedIncludes: queryObj.selectedIncludes,
          fields,
          filtersSearchText,
          collapsableField: collapsableFields,
          filters: queryFilters,
        };
        const body = JSON.stringify(queryObj);

        try {
          const dataAggregationResponse = await search(body, filters_options);
          const filterOptions = dataAggregationResponse.data.aggregations;
          if (isIndivisual) {
            dispatch(
              setResultTable({
                filterOptionsData: {
                  ...filterOptionsData,
                  [[filtersJson[openedFilterName]]]:
                    filterOptions[filtersJson[openedFilterName]],
                },
                isLoadingFilterData: false,
              })
            );
          } else {
            dispatch(
              setResultTable({
                filterOptionsData: { ...filterOptionsData, ...filterOptions },
                isLoadingFilterData: false,
              })
            );
          }
        } catch (error) {
          displayError(error);
          dispatch(
            setResultTable({
              isLoadingFilterData: false,
            })
          );
          return false;
        }
      }
    }
  };

export const inputValidationQuery =
  (rawQuery) => async (dispatch, getState) => {
    try {
      const {
        searchQuery: { tabWiseSearchQuery },
        app: { activeTab, isEditModalOpen },
      } = getState();
      const cleanQueryOrignal = cleanQuery(rawQuery);

      checkSpecialConditions(rawQuery);
      rawQuery = formatNumberFields(rawQuery);
      const parser = peggy.parse(cleanQuery(rawQuery));
      generateQuery(parser, true);

      if (!isEditModalOpen) {
        dispatch(
          setApp({
            searchQuery: cleanQueryOrignal,
            searchedVisibleQuery: cleanQueryOrignal,
          })
        );

        dispatch(
          setQuery({
            syntaxqueryError: "",
            tabWiseSearchQuery: {
              ...tabWiseSearchQuery,
              [activeTab]: cleanQueryOrignal,
            },
          })
        );
      }
      return true;
    } catch (e) {
      return false;
    }
  };

export const quickViewOnPN = (id) => async (dispatch, getState) => {
  const {
    app: { searchQuery },
  } = getState();
  dispatch(getQuickViewHighlights(searchQuery, {}, true, [id]));
};
