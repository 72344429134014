import { Block } from "../../../../ui-components";
import ReactWordcloud from "react-wordcloud";

const ClassWordCloudChart = (props) => {
  const { wordCloudData } = props;
  const options = {
    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "impact",
    fontSizes: [5, 60],
    fontStyle: "normal",
    fontWeight: "normal",
    padding: 1,
    rotations: 0,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  const data = wordCloudData["PCL"].buckets.map((i) => {
    return {
      text: i.key.toUpperCase(),
      value: i.doc_count,
    };
  });
  return (
    <Block className="w-50">
      <Block className="word-cloud-chart chart-block top-classes">
        <div className="word-cloud-title">Top IPC/CPC Classes</div>
        <Block style={{ height: 400, width: "100%" }}>
          <ReactWordcloud words={data} options={options} />
        </Block>
      </Block>
    </Block>
  );
};

export default ClassWordCloudChart;
