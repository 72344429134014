import React, { useState, useEffect } from "react";
import { getImagePathFromPN } from "../../common/images-functions";

const QuickViewImages = (props) => {
  const { displayData, selectedIndex, handleOpenImagecarousel } = props;
  const imagePath = getImagePathFromPN(
    displayData?.[selectedIndex]?._source?.PN_B
  );
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    const img = new Image();
    img.src = imagePath;

    img.onerror = () => {
      setImageError(true);
    };

    img.onload = () => {
      setImageError(false);
    };
  }, [imagePath]);
  return (
    <>
      <div className="quick-view-image-container">
        <div
          className={`clip-image ${imageError ? "image-not-available" : ""}`}
        >
          {imageError ? (
            <div className="mt-4">
              <div className="image-not-available text-center">Image</div>
              <div className="image-not-available">Not Available!</div>
            </div>
          ) : (
            <img
              id="image"
              src={imagePath}
              alt=""
              onClick={() => handleOpenImagecarousel(imagePath)}
              className="clip-image"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default QuickViewImages;
