import { fetch } from "../service/fetch";
import { setFullView } from "./patent-view";

export const getSummaryOfPatent =
  (texts = []) =>
  async (dispatch, getState) => {
    dispatch(setFullView({ isSummaryFetching: true }));
    try {
      var response = {};
      response = await fetch(
        {
          url: `/parser-api/summary`,
          body: {
            texts,
          },
        },
        "POST"
      );
      return response?.summary || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(setFullView({ isSummaryFetching: false }));
    }
  };

export const getKeyFeaturesOfPatent =
  (texts = []) =>
  async (dispatch, getState) => {
    dispatch(setFullView({ isKeyfeaturesExtracting: true }));
    try {
      var response = {};
      response = await fetch(
        {
          url: `/parser-api/keyfeatures-extract`,
          body: {
            texts,
          },
        },
        "POST"
      );
      return response?.keyFeatures || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(setFullView({ isKeyfeaturesExtracting: false }));
    }
  };

export const getClaimChartMappings =
  (keyFeatures = [], patentText = "") =>
  async (dispatch, getState) => {
    dispatch(setFullView({ isClaimMappingsGenerating: true }));
    try {
      var response = {};
      response = await fetch(
        {
          url: `/parser-api/claim-chart-generate`,
          body: {
            keyFeatures,
            patentText,
          },
        },
        "POST"
      );
      return response?.mappings || [];
    } catch (err) {
      throw err;
    } finally {
      dispatch(setFullView({ isClaimMappingsGenerating: false }));
    }
  };
