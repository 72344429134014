import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setQuery, setAllField } from "../action/search-query";
import { deleteUserAnnotations, setApp } from "../action/app";
import RWireHeader from "../components/rwire-ui/rwire-header";
import { performSearch, validationQuery } from "../action/rwire-search-api";
import { setFullView } from "../action/patent-view";
import {
  getBackwardCitationsData,
  getReportProjectDataById,
  setProjectReport,
} from "../action/report/report-project";
import { getAllProjectUsers } from "../action/report/report-users";

const Container = (props) => {
  return <RWireHeader {...props} />;
};

const mapStateToProps = ({ app, searchQuery }) => ({
  ...app,
  ...searchQuery,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetQuery: setQuery,
      onSetApp: setApp,
      onPerformSearch: performSearch,
      validationQuery: validationQuery,
      setAllField: setAllField,
      onSetFullView: setFullView,
      onDeleteUserAnnotations: deleteUserAnnotations,
      onGetReportProjectDataById: getReportProjectDataById,
      onSetProjectReport: setProjectReport,
      onGetAllProjectUsers: getAllProjectUsers,
      onGetBackwardCitationsData: getBackwardCitationsData,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Container);
