import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  reportClasessSearch,
  setReportClasess,
  getPagination,
  setPage,
} from "../../action/report/report-clasess";
import ReportClassesModal from "../../components/report/rwire-report-clasess/report-classes-modal";

const Container = (props) => {
  return <ReportClassesModal {...props} />;
};

const mapStateToProps = ({
  reportClasses,
}) => ({
  ...reportClasses,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetClassesModal: setReportClasess,
      onClassesSearch: reportClasessSearch,
      onGetPagination: getPagination,
      onSetPage: setPage,
      onSetSelectedRows: setReportClasess,
      onSetItemPerPage: setReportClasess,
      onSetClassesSelector: setReportClasess,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
