import axios from "axios";

export const sentReportOnMail =
  ({ fileData, selectedFormat, email, fileName }) =>
  async (dispatch) => {
    try {
      if (email !== "" && selectedFormat !== "" && fileData !== "") {
        const formData = new FormData();

        formData.append("file", fileData);

        // formData.append("data", "data");
        formData.append("fileType", selectedFormat);
        formData.append("emails", email);
        formData.append("fileName", fileName);
        await axios.post("/api/report", formData, {
          headers: {
            ...getHeadersWithContentType(),
          },
        });
      }
    } catch (error) {
      console.log("error>>>>", error);
    }
  };

const getHeadersWithContentType = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return {};
  } else {
    return {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
  }
};
