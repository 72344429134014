import React, { useState, useEffect } from "react";
import { Block, Hyperlink, Image, InternalInput } from "../../../ui-components";
import {
  EditIcon,
  GreenTick,
  PencilIcon,
  QmarkIcon,
  RedCross,
  PatentNoIcon,
  GreenTickIcon,
  GreyTickIcon,
  RedCrossIcon,
} from "../../../assets/images/report";
import {
  ActionButtons,
  RwireProjectModal,
} from "../rwire-project-creation-wrapper";
import { useNavigate } from "react-router-dom";
import { CheckPicker, SelectPicker } from "rsuite";

const CreateProject = (props) => {
  const {
    onCreateReportProject,
    onGetReportWorkspaceElements,
    onResetProjectData,
    OnSetProjectReport,
    onGetAllProjectUsers,
    onGetReportProjects,
    onCheckIfPatentAvailable,
    onGetBackwardCitedPatentsList,
  } = props;
  const [userInput, setUserInput] = useState({});
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [projectsData, setProjectsData] = useState([]);
  const [isValidPatent, setIsValidPatent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkspaceElements = async () => {
      const data = await onGetReportWorkspaceElements();
      const fetchedOptions = data.result
        .filter((item) => item.id < 9)
        .map((item) => ({
          id: item.id,
          label: item.name,
          value: item.name,
        }));
      setOptions(fetchedOptions);
      setUserInput({
        ...userInput,
        workspaceElements: fetchedOptions.map((option) => option.value),
      });
    };
    fetchWorkspaceElements();
    const fetchData = async () => {
      try {
        const response = await onGetReportProjects();
        if (response && response.result && response.result.length > 0) {
          setProjectsData(response.result);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const projectTypeOptions = [
    "Invalidity/Validity Search",
    "Patentability/Novelty Search",
    "FTO/State Of Art Search",
  ].map((item) => ({ label: item, value: item }));

  const validateUserInput = () => {
    const newErrors = {};

    if (!userInput.name) {
      newErrors.name = "Project name is required.";
    } else if (
      projectsData.some((project) => project.name === userInput.name)
    ) {
      newErrors.name = "Project name already exists.";
    }

    if (userInput.workspaceElements.length === 0) {
      newErrors.workspaceElements = "Select at least one workspace element.";
    }

    if (!userInput.projectType) {
      newErrors.projectType = "Select project type.";
    }

    // setErrors(newErrors);
    return newErrors;
  };

  const handleCreateProject = async () => {
    try {
      const newErrors = validateUserInput();
      const bcpList = [];
      const bcpDetailsList = [];
      if (userInput.projectType === "Invalidity/Validity Search") {
        if (userInput.patentNumber) {
          if (isValidPatent === false) {
            newErrors.patentNumber = "Enter valid Patent Number.";
          } else {
            const res = await onCheckIfPatentAvailable(userInput.patentNumber);
            if (!res) {
              setIsValidPatent(false);
              newErrors.patentNumber = "Enter valid Patent Number.";
            } else {
              bcpDetailsList.push(res._source.BCPN);
              bcpList.push(res._source.BCP);
            }
          }
        } else {
          newErrors.patentNumber = "Enter patent number.";
        }
      }
      setErrors(newErrors);
      if (Object.keys(newErrors).length === 0) {
        setUserInput({
          ...userInput,
          showModal: true,
        });
        const idsArray = options
          .filter((item) => userInput.workspaceElements.includes(item.value))
          .map((item) => item.id);
        await onCreateReportProject({
          name: `${userInput.name}${
            userInput.patentNumber ? `-${userInput.patentNumber}` : ""
          }`,
          workspaceElements: idsArray.join(),
          projectType: userInput.projectType,
          patentNumber: userInput.patentNumber || "",
          projectTechnology: userInput.projectTechnology || "",
          citedPatents: [""],
        });

        OnSetProjectReport({
          name: `${userInput.name}${
            userInput.patentNumber ? `-${userInput.patentNumber}` : ""
          }`,
          workspaceElements: idsArray,
          projectType: userInput.projectType,
          projectPatentNumber: userInput.patentNumber || "",
          projectTechnology: userInput.projectTechnology || "",
        });
        onResetProjectData();

        const id = sessionStorage.getItem("projectId");
        await onGetAllProjectUsers(id);
        setTimeout(() => {
          setUserInput({
            ...userInput,
            showModal: false,
          });
          navigate("/en/report");
        }, 1000);
        onGetBackwardCitedPatentsList(bcpList[0], bcpDetailsList[0], id);
      }
    } catch (error) {
      setUserInput({
        ...userInput,
        showModal: false,
      });
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const checkAvailability = async () => {
    if (userInput?.patentNumber) {
      const res = await onCheckIfPatentAvailable(userInput.patentNumber);
      if (res) {
        setIsValidPatent(true);
      } else {
        setIsValidPatent(false);
        setErrors({ ...errors, patentNumber: "Enter valid Patent Number" });
      }
    } else {
      setErrors({ ...errors, patentNumber: "Please enter a patent number" });
      return;
    }
  };
  return (
    <Block className="w-100">
      <Block className="d-flex flex-column align-items-center px-4 pt-1">
        <Block className="create-project-logo">
          <Image src={EditIcon} alt="" />
        </Block>
        <Block className="create-project-heading ">Create Project Mode</Block>

        <Block className="w-100 px-1">
          <InternalInput
            isInputWithButton={true}
            srcImg={PencilIcon}
            InputClassName={`input-with-button`}
            inputLabel="Project Name"
            isRequired={true}
            placeholder="Enter Project Name"
            InputValue={userInput.name}
            inputName="name"
            onSetValue={(name, value) => {
              setErrors({ ...errors, name: "" });
              setUserInput({ ...userInput, [name]: value });
            }}
          />

          <div className="error-message project-creation-input-margin">
            {errors.name}
          </div>

          <Block className="internal-input-container upper-margin-for-field">
            <Block className="input-label-text">
              Select Workspace Elements
              <span className="required-star"> *</span>
            </Block>
          </Block>
          <CheckPicker
            className="workspace-checkpicker"
            data={options}
            searchable={false}
            style={{ width: "100%" }}
            value={userInput.workspaceElements}
            onChange={(selectedOptions) => {
              setErrors({ ...errors, workspaceElements: "" });
              setUserInput({
                ...userInput,
                workspaceElements: selectedOptions,
              });
            }}
          />

          <div className="error-message project-creation-input-margin">
            {errors.workspaceElements}
          </div>

          <Block className="internal-input-container upper-margin-for-field">
            <Block className="input-label-text">
              Select Project Type
              <span className="required-star"> *</span>
            </Block>
          </Block>
          <SelectPicker
            className="workspace-checkpicker"
            data={projectTypeOptions}
            block
            searchable={false}
            onChange={(selectedOptions) => {
              setErrors({ ...errors, projectType: "" });
              setUserInput({
                ...userInput,
                projectType: selectedOptions,
                patentNumber: "",
              });
              setIsValidPatent(null);
            }}
          />

          <div className="error-message project-creation-input-margin">
            {errors.projectType}
          </div>

          {userInput &&
            userInput?.projectType === "Invalidity/Validity Search" && (
              <div className="upper-margin-for-field">
                <InternalInput
                  isInputWithButton={true}
                  srcImg={
                    isValidPatent
                      ? GreenTickIcon
                      : isValidPatent === false
                      ? RedCrossIcon
                      : GreyTickIcon
                  }
                  InputClassName={`input-with-button ${
                    isValidPatent
                      ? "valid"
                      : isValidPatent === false
                      ? "invalid red-outline"
                      : ""
                  }`}
                  inputLabel="Patent Number"
                  isRequired={true}
                  placeholder="Enter Patent Number"
                  InputValue={userInput.patentNumber}
                  inputName="patentNumber"
                  onSetValue={(patentNumber, value) => {
                    setErrors({ ...errors, patentNumber: "" });
                    setUserInput({ ...userInput, [patentNumber]: value });
                    setIsValidPatent(null);
                  }}
                  imgTitle={`${
                    isValidPatent
                      ? "Valid Patent Number"
                      : isValidPatent === false
                      ? "Invalid Patent Number"
                      : "Check availability of patent"
                  }`}
                  onClickIcon={checkAvailability}
                />

                <div className="error-message project-creation-input-margin">
                  {errors.patentNumber}
                </div>
              </div>
            )}
          <div className="upper-margin-for-field">
            <InternalInput
              isInputWithButton={false}
              InputClassName={`input-with-button`}
              inputLabel="Project Technology"
              placeholder="Enter Project Technology"
              InputValue={userInput.projectTechnology}
              inputName="projectTechnology"
              onSetValue={(projectTechnology, value) => {
                setErrors({ ...errors, projectTechnology: "" });
                setUserInput({ ...userInput, [projectTechnology]: value });
              }}
            />
          </div>
        </Block>

        <ActionButtons
          buttonOneText="Create"
          buttonTwoText="Cancel"
          buttonOneAction={handleCreateProject}
          buttonTwoAction={() => navigate("/")}
        />
        <Block className="wrapper-footer mt-3 p-2">
          Already created a project{" "}
          <Hyperlink href="/en/existing-project">click here</Hyperlink>
        </Block>
      </Block>
      {userInput.showModal && <RwireProjectModal open={userInput.showModal} />}
    </Block>
  );
};

export default CreateProject;
