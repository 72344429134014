import "./style.scss";
import { Block } from "../../../ui-components";
import ClasessTab from "./clasess-tab";
import { useState } from "react";
import { ReportSortableTable } from "../../../container/report";
import { arrayMove } from "react-sortable-hoc";
import { ReportClassesModal } from "../../../container/report";
import { updateSortOrder } from "../../../ui-components/functions/notes/utils";
export const findGreatestIdFromClasses = (outputObject) => {
  return Object.values(outputObject)
    .flat()
    .reduce((maxId, item) => Math.max(maxId, item.id), 0);
};
const RwireReportClasess = (props) => {
  const {
    onSetReportClasess,
    onReportClassesSearch,
    reportTableData,
    column,
    selectedClassType,
    onUpdateClassesSortOrder,
    onUpdateReportProjectClassesRow,
    onAddRowToreportProject,
    onDeleteRowFromReportProject,
    onGetReportProjectClasses,
    isNoResultFound,
  } = props;
  const [isClassModalOpen, setClasessModal] = useState(false);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const selectedReportTable = reportTableData[selectedClassType];
    const updatedData = arrayMove(selectedReportTable, oldIndex, newIndex);

    onSetReportClasess({
      reportTableData: {
        ...reportTableData,
        [selectedClassType]: updatedData,
      },
    });
    const bodyData = updateSortOrder(selectedReportTable, oldIndex, newIndex);
    await onUpdateClassesSortOrder({
      "sort-order": bodyData["sort-order"],
      type: selectedClassType.toUpperCase(),
    });
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData[selectedClassType].map((i) => {
      if (i.id === rowIndex && options.isChangeText) {
        return {
          ...i,
          title: value,
        };
      } else if (i.id === rowIndex && options.isChangeDesc) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportClasess({
      reportTableData: {
        ...reportTableData,
        [selectedClassType]: [...updatedTableData],
        selectedRows: [],
      },
    });
  };

  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (options.isChangeText) {
      await onUpdateReportProjectClassesRow({
        id: rowIndex,
        body: {
          classes: value,
        },
      });
    } else if (options.isChangeDesc) {
      await onUpdateReportProjectClassesRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    let lastId = findGreatestIdFromClasses({ ...reportTableData });
    const updatedTableData = [...reportTableData[selectedClassType]];
    const newRow = { body: "", id: lastId + 1, title: "" };

    updatedTableData.splice(rowIndex + 1, 0, newRow);

    onSetReportClasess({
      reportTableData: {
        ...reportTableData,
        [selectedClassType]: updatedTableData,
      },
    });
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      type: selectedClassType.toUpperCase(),
      data: [
        {
          classes: "",
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectClasses();
  };

  const handleDeleteRow = async (rowIndex, id) => {
    if (reportTableData[selectedClassType].length === 1) {
      return;
    }
    // const updatedTableData = [...reportTableData[selectedClassType]];
    // updatedTableData.splice(rowIndex, 1);
    // onSetReportClasess({
    //   reportTableData: {
    //     ...reportTableData,
    //     [selectedClassType]: updatedTableData,
    //   },
    // });
    await onDeleteRowFromReportProject(id);
    await onGetReportProjectClasses();
  };

  const handleSetClassModal = () => {
    setClasessModal(true);
  };
  const handleCloseModal = () => {
    setClasessModal(false);
    onSetReportClasess({
      formData: { classes: "", keywords: "" },
      page: 1,
      selectedRows: [],
      classesType: "cpc",
      clasessData: [],
      selectedRowsWithCodeAndDefination: [],
      isNoResultFound: false,
    });
  };

  return (
    <Block className="rwire-report-clasess">
      <Block>
        <ClasessTab
          selectedClassType={selectedClassType}
          onReportClassesSearch={onReportClassesSearch}
          onSetReportClasess={onSetReportClasess}
        />
        <ReportSortableTable
          onSortEnd={onSortEnd}
          columns={column}
          data={reportTableData[selectedClassType]}
          onSetData={onSetReportClasess}
          isAddExtraClass={true}
          pressDelay={100}
          useDragHandle
          className="w-100"
          onCellChange={(value, rowIndex, options) =>
            handleChange(value, rowIndex, options)
          }
          onSaveInDb={(value, rowIndex, id, options) =>
            handleSaveInDb(value, rowIndex, id, options)
          }
          onAddRowBelow={(rowIndex) => handleAddRow(rowIndex)}
          onDeleteRow={(rowIndex, id) => handleDeleteRow(rowIndex, id)}
          onOpenModal={handleSetClassModal}
          workspaceId="3"
        />
      </Block>

      {isClassModalOpen && (
        <ReportClassesModal
          onGetReportProjectClasses={onGetReportProjectClasses}
          isClassModalOpen={isClassModalOpen}
          onCloseModal={() => handleCloseModal(false)}
          onAddRowToreportProject={onAddRowToreportProject}
          isNoResultFound={isNoResultFound}
        />
      )}
    </Block>
  );
};

export default RwireReportClasess;
