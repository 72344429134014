import React from "react";

const Block = ({
  children,
  className,
  onClick,
  ariaLabelledby,
  ref,
  id,
  style,
  title,
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      aria-labelledby={ariaLabelledby}
      ref={ref}
      id={id}
      style={style}
      title={title}
    >
      {children}
    </div>
  );
};

export default Block;
