export const categoryListing = [
  {
    category: "Number",
    bgColor: "#FFD700",
    fields: ["PN_B", "AN_B", "PRN_B", "PCT_B", "PCTN_B", "PVN"],
  },
  {
    category: "Text",
    bgColor: "#E6E6FA",
    fields: ["TI_EN", "AB_EN", "CL_EN", "DESC_EN", "FCL", "ICL_EN", "DCL_EN"],
  },
  {
    category: "Date",
    bgColor: "#FEEAA2",
    fields: [
      "EPRD",
      "PRD",
      "AD",
      "PD",
      "PCTPD",
      "PCTAD",
      "EPRY",
      "PRY",
      "AY",
      "PY",
      "ED",
      "RL",
    ],
  },
  {
    category: "Classification",
    bgColor: "#AFEEEE",
    fields: [
      "IPC",
      "IPCD",
      "CPC",
      "CPCD",
      "CPCP",
      "PCL",
      "USD",
      "USM",
      "USF",
      "USFD",
      "ECL",
      "ECLD",
      "LCM",
      "LCF",
      "JCL",
      "IPC12",
      "IPC8",
      "IPC4",
      "IPCRV",
      "CPC12",
      "CPC4",
      "CPC8",
      "CPCV",
      "CPCO",
    ],
  },
  {
    category: "People/Organization",
    bgColor: "#fae6f9",
    fields: [
      "AAPO",
      "AAPS",
      "AAPN",
      "AO_EN",
      "AOF_EN",
      "ANZ_EN",
      "AS_EN",
      "CA_EN",
      "CAS_EN",
      "CAN_EN",
      "AEX",
      "PEX",
      "EX",
      "AG_EN",
      "AP_EN",
      "API_EN",
      "APS_EN",
      "APN_EN",
      "APFO_EN",
      "APFI_EN",
      "CR_EN",
      "IN_EN",
      "INF_EN",
    ],
  },
  {
    category: "Family",
    bgColor: "#FFDAB9",
    fields: [
      "CF",
      "DF",
      "EF",
      "MF",
      "SF",
      "CFID",
      "DFID",
      "EFID",
      "MFID",
      "SFID",
    ],
  },
  {
    category: "Citation",
    bgColor: "#FFDEAD",
    fields: ["BCP", "BCN", "FCP"],
  },
  {
    category: "Botanic",
    bgColor: "#98FB98",
    fields: ["BON", "BOV"],
  },
  {
    category: "Country",
    bgColor: "#ADD8E6",
    fields: [
      "PRC",
      "PNC",
      "DSEP",
      "DSEP_CST",
      "DSEP_EST",
      "DSEP_VST",
      "DSEP_PST",
      "DSPCT",
      "DSPCT_RGCN",
      "DSPCT_AOST",
      "DSPCT_RGNCN",
      "DSPCT_NT",
      "DSPCT_NDSCN",
      "PCTPC",
      "AC",
      "ACC",
    ],
  },
  {
    category: "Address",
    bgColor: "#FFA07A",
    fields: ["AA_EN", "CAA_EN", "AGA_EN", "APA_EN", "CRA_EN", "INA_EN"],
  },
  {
    category: "Count",
    bgColor: "#FFB6C1",
    fields: ["ACN", "APCN", "CLCN", "INCN"],
  },
  {
    category: "Legal Information",
    bgColor: "#FFE4C4",
    fields: ["PTA", "USMS", "LST", "ALD", "PT", "OH", "FP", "LIT"],
  },
  {
    category: "Others",
    bgColor: "#90EE90",
    fields: ["SC", "APT", "PKC", "RDN", "GOI", "PL"],
  },
];
