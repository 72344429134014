import { Block } from "../../../ui-components";
import CountSlider from "./count-slider";
import ChartBlock from "./chart-block";
import KeyFeaturesCount from "./key-features-count";
import { useEffect } from "react";
import { useStore } from "react-redux";
import { ACCESS_COMMENTOR } from "./../../../common/user-roles";
import UserAccess from "../../../common/user-acess";
import {
  EditorIconDashboard,
  CommentorIconDashboard,
  ViewerIconDashboard,
} from "../../../assets/images/report";

const RwireReportDashboardContent = (props) => {
  const {
    string,
    keyFeaturesList = [],
    onGetReportProjectNotes,
    keyFeature,
    projectName,
  } = props;

  const projectId = sessionStorage.getItem("projectId");
  useEffect(() => {
    onGetReportProjectNotes();
  }, [onGetReportProjectNotes, projectId]);

  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  let imageSrc, imageAlt;
  switch (userProjectAccess) {
    case "ACCESS_EDITOR":
      imageSrc = EditorIconDashboard;
      imageAlt = "Admin Access Image";
      break;
    case "ACCESS_COMMENTOR":
      imageSrc = CommentorIconDashboard;
      imageAlt = "Editor Access Image";
      break;
    case "ACCESS_VIEWER":
      imageSrc = ViewerIconDashboard;
      imageAlt = "Viewer Access Image";
      break;
    default:
      imageSrc = null;
      imageAlt = null;
  }

  return (
    <Block className="p-4">
      <Block className="welcome-text d-flex">
        Welcome {projectName}{" "}
        {userProjectAccess !== "ACCESS_COMMENTOR" && (
          <div className="user_acess_dashboard ms-auto mr-2">
            {imageSrc && <img src={imageSrc} alt={imageAlt} className="mr-1" />}
            {UserAccess(userProjectAccess)}
          </div>
        )}
      </Block>

      {userProjectAccess !== ACCESS_COMMENTOR && (
        <Block className="dashboard-content">
          <CountSlider {...props} />
          <div className="d-flex content_wrapper justify-content-between">
            {string?.count > 0 &&
            keyFeaturesList.filter((i) => i.body).length !== 0 ? (
              <ChartBlock {...props} />
            ) : (
              <div></div>
            )}
            {keyFeaturesList.length > 0 ? (
              <KeyFeaturesCount
                keyFeaturesList={keyFeaturesList}
                onGetReportProjectNotes={onGetReportProjectNotes}
                keyFeature={keyFeature}
              />
            ) : (
              ""
            )}
          </div>
        </Block>
      )}
    </Block>
  );
};

export default RwireReportDashboardContent;
