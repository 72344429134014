// src/ButtonList.js
import React, { useEffect, useRef, useState } from "react";
import "./button-list.scss";
import { LeftArrowWithCircle } from "../../assets/images/report";
import { categoryListing } from "../../common/export-category-listing";
import { exportFieldsList } from "../../resources/data/export-field-list";

const ButtonList = (props) => {
  const { onSetFieldOptions, onSetSelectedCategory, selectedCategory } = props;
  const scrollContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  useEffect(() => {
    const updateScrollButtons = () => {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
    };

    // Add event listener
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("scroll", updateScrollButtons);

    // Initial check
    updateScrollButtons();

    // Cleanup event listener on component unmount
    return () => {
      scrollContainer.removeEventListener("scroll", updateScrollButtons);
    };
  }, []);
  const scrollLeft = () => {
    scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
  };
  const handleApplyCategoryFilter = (categoryName) => {
    if (categoryName === selectedCategory) {
      onSetSelectedCategory("");
      onSetFieldOptions(exportFieldsList);
    } else {
      // Find the category object in the categoryListing array
      const category = categoryListing.find(
        (cat) => cat.category === categoryName
      );

      // If the category is found, set the fields array; otherwise, set an empty array
      const newFields = category ? category.fields : [];
      onSetFieldOptions(newFields);
      onSetSelectedCategory(categoryName);
    }
  };
  return (
    <div className="button-list-container">
      {canScrollLeft && (
        <button
          className="scroll-button left"
          onClick={scrollLeft}
          disabled={!canScrollLeft}
        >
          <img alt="" src={LeftArrowWithCircle} className="rotate-180" />
        </button>
      )}
      <div className="button-list" ref={scrollContainerRef}>
        {categoryListing.map((category, index) => (
          <button
            key={index}
            className={`list-button ${
              selectedCategory === category.category ? "selectedCategory" : ""
            }`}
            style={{ backgroundColor: category.bgColor }}
            onClick={() => handleApplyCategoryFilter(category.category)}
          >
            {category.category}
          </button>
        ))}
      </div>
      {canScrollRight && (
        <button
          className="scroll-button right"
          onClick={scrollRight}
          disabled={!canScrollRight}
        >
          <img alt="" src={LeftArrowWithCircle} />
        </button>
      )}
    </div>
  );
};

export default ButtonList;
