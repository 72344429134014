import React, { useState ,useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Modal } from "../../../../ui-components";
import { correctSignGreenBgIcon } from "../../../../assets/images/report";
import {
  ACCESS_COMMENTOR,
  ACCESS_EDITOR,
  ACCESS_VIEWER,
  ROLE_PARTICIPANT,
  ROLE_RWIRE_COLLABORATOR,
} from "../../../../common/user-roles";
import MultiUserInput from "./multi-user-input";

const AddCollaboratorModal = (props) => {
  const {
    showModal,
    setShowModal,
    onSendInvitationToCollaborator,
    projectUsers,
    onSetProjectReport,
    onGetUserDetailsUsingId,
  } = props;
  const [isUserAdded, setIsUserAdded] = useState(false);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    userId: "",
    access: ACCESS_EDITOR,
  });
  const [selectedUserSet, setSelectedUserSet] = useState(new Set());
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isUserEmailDuplicate, setIsUserEmailDuplicate] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSearchUser = async (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        const regex = /RW(\d+)/;
        const match = regex.exec(formData.userId);

        if (match !== null) {
          const number = match[1];
          const user = await onGetUserDetailsUsingId(parseInt(number));
          setUsers([user]);
        }
      }
    } catch (err) {
      setUsers([]);
    }
  };

  const handleAddCollaborator = async () => {
    if (selectedUsers.length === 0) return;
    try {
      const projectIdForUsers = sessionStorage.getItem("projectIdForUsers");
      const emailIds = selectedUsers.map((entry) => entry.email);
      await onSendInvitationToCollaborator({
        userEmails: [...emailIds],
        projectId: projectIdForUsers,
        role: ROLE_RWIRE_COLLABORATOR,
        access: formData.access.toUpperCase(),
      });

      // notification that the invite has been sent to user
      resetData();
      setIsUserAdded(true);
    } catch (err) {
      setIsUserAdded(false);
    }
  };

  const resetData = () => {
    setSelectedUserSet(new Set());
    setSelectedUsers([]);
    setFormData({
      userId: "",
      access: ACCESS_EDITOR,
    });
    setUsers([]);
  };

  const checkForDuplicateEmails = () => {
    const projectUserEmails = projectUsers.map(user => user.email);
    const hasDuplicate = selectedUsers.some(user => projectUserEmails.includes(user.email));
    setIsUserEmailDuplicate(hasDuplicate);
  };

  useEffect(() => {
    checkForDuplicateEmails();
  }, [selectedUsers]);
  
  return (
    <Modal
      isOpen={showModal}
      isStatic={false}
      overlayClass="add-collaborators-modal"
      className="add-collaborators-modal-body"
    >
      <div className="title">
        <div>Add Collaborators</div>
        <div
          className="ms-auto cursor-pointer"
          title="Close"
          onClick={() => {
            resetData();
            setShowModal(false);
            setIsUserAdded(false);
          }}
        >
          x
        </div>
      </div>
      <div className="p-3 w-100">
        {!isUserAdded ? (
          <>
            <Form onSubmit={(e) => e.preventDefault()}>
              <Row>
                <Col xs={8}>
                  <Form.Group className="text-left pb-4">
                    <Form.Label className="fw-bold">User ID</Form.Label>
                    <MultiUserInput
                      {...props}
                      selectedUserSet={selectedUserSet}
                      onSetSelectedUserSet={setSelectedUserSet}
                      selectedUsers={selectedUsers}
                      onSetSelectedUsers={setSelectedUsers}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="text-left pb-4">
                    <Form.Label className="fw-bold">Rights</Form.Label>
                    <Form.Select
                      name="access"
                      value={formData.access}
                      onChange={handleInputChange}
                    >
                      <option value={ACCESS_EDITOR}>Editor</option>
                      <option value={ACCESS_VIEWER}>Viewer</option>
                      <option value={ACCESS_COMMENTOR}>Commentor</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              {/* {users && users.length > 0 && (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">ID</th>
                      <th scope="col">Username</th>
                      <th scope="col">Email-ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td>
                          <input
                            type="checkbox"
                            name={`${user.id}`}
                            id={`${user.id}`}
                            onChange={(e) =>
                              handleSelectUser(e.target.checked, user.id)
                            }
                          />
                        </td>
                        <td>RW{user.id}</td>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )} */}
            </Form>
            <div className="p-3">
              <button
                disabled={selectedUsers.length === 0 || isUserEmailDuplicate}
                onClick={handleAddCollaborator}
                className={`add-collab-btn blue-primary-button-color ${
                  selectedUsers.length === 0  || isUserEmailDuplicate ? "opacity-75" : ""
                }`}
              >
                Add Collaborator
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex flex-column align-items-center gap-2 pt-5">
              <img src={correctSignGreenBgIcon} width={80} height={80} alt="" />
              <div className="thank-you-heading">Thank You!</div>
              <h6>Invitations has been successfully sent to Collaborators.</h6>
            </div>
            <div className="d-flex w-100 px-4 py-4 gap-2 mt-auto">
              <div className="mx-auto d-flex gap-3">
                <button
                  onClick={() => {
                    setShowModal(false);
                    setIsUserAdded(false);
                    resetData();
                  }}
                  className="white-secondary-button-color send-request-button"
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    resetData();
                    setIsUserAdded(false);
                  }}
                  className="blue-primary-button-color send-request-button"
                >
                  {"+ "} Add More
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddCollaboratorModal;
