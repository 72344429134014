import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import KeyFeatureSuggestions from "../components/keyword-suggestions/keyfeature-suggestions";
import { setApp } from "../action/patentAndPublication";
import {
    setKeyword,
    setSelectedRows,
    getSynonymsOfWords,
    checkIfPatentAvailable,
    findKeywordsFromText,
} from "../action/keyword-suggestor";
import { setFullView } from "../action/patent-view";
import { setClassesModal } from "../action/classes-suggestor";
import { setAllField } from "../action/search-query";
import {
    getKeyFeaturesOfPatent
} from "../action/patent-summarizer"
import { addRowToReportProject, getReportProjectNotes } from "../action/report/report-notes"

const Container = (props) => {
    const id = sessionStorage.getItem('patentId')
    return <KeyFeatureSuggestions {...props} patentId={id} />;
};

const mapStateToProps = ({
    keywordSuggestor,
    searchQuery,
    app: {
        searchQuery: existingQuery,
        activeTab,
        currentFieldedCursorLocation,
        isEditModalOpen,
    },
    fullView: { isKeyfeaturesExtracting }
}) => ({
    ...keywordSuggestor,
    existingQuery,
    activeTab,
    ...searchQuery,
    currentFieldedCursorLocation,
    isEditModalOpen,
    isKeyfeaturesExtracting
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            onSetKeywordModal: setKeyword,
            onSearch: getSynonymsOfWords,
            onSetApp: setApp,
            onSetSelectedRows: setSelectedRows,
            onSetKeywordSelector: setKeyword,
            onSetAllField: setAllField,
            onSetClassesModal: setClassesModal,
            onCheckIfPatentAvailable: checkIfPatentAvailable,
            onFindKeywordsFromText: findKeywordsFromText,
            onGetKeyFeaturesOfPatent: getKeyFeaturesOfPatent,
            onSetFullView: setFullView,
            onAddKeyFeatureToReportProject: addRowToReportProject,
            onGetReportProjectNotes: getReportProjectNotes
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
