import React, { useEffect } from "react";
import RwireLeft from "./rwire-left";
import RwireRight from "./rwire-right";


function RwireModalBody(props) {
  // If a key-feature tooltip appeared on the left side, the slide open would be on the right, and vice versa.
  useEffect(() => {
    const addCommentModalBody = document.querySelector(".add-comment-modal-body");

    const handleLeftClick = () => {
      addCommentModalBody.style.right = "0";
      addCommentModalBody.style.left = "";
    };

    const handleRightClick = () => {
      addCommentModalBody.style.left = "0";
      addCommentModalBody.style.right = "";
    };

    const rwireLeft = document.querySelector(".smart-left");
    const rwireRight = document.querySelector(".smart-right");

    rwireLeft.addEventListener("click", handleLeftClick);
    rwireRight.addEventListener("click", handleRightClick);

    return () => {
      rwireLeft.removeEventListener("click", handleLeftClick);
      rwireRight.removeEventListener("click", handleRightClick);
    };
  }, []);

  return (
    <div className="body-section d-flex justify-content-between">
      <RwireLeft {...props} />
      <RwireRight {...props} />
    </div>
  );
}

export default RwireModalBody;
