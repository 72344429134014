const initialState = {
  isFilterOpen: false,
  currentSheet: "sheet-1",
  sheets: [
    {id: "sheet-1", title: "Sheet 1", name: "Sheet 1"},
    {id: "sheet-2", title: "Sheet 2", name: "Sheet 2"},
    {id: "sheet-3", title: "Sheet 3", name: "Sheet 3"},
  ]
};

const analyze = (state = initialState, { type, payload }) => {

  switch (type) {
    case "ANALYZE_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default analyze;
