import { Modal } from "rsuite";
import KeywordTable from "./keyword-table";
import KeywordForm from "./keyword-form";

import "./keyword-suggestions.scss";
import KeywordAction from "./action-button";
import { uuidv4 } from "../../action/app";
import { useState } from "react";
import GenerateKeyFeatures from "./keywords-generator/generate-keyfeatures";

const KeyFeatureSuggestions = (props) => {
  const {
    isOpenKeywordSuggestorModal,
    onSetKeywordModal,
    onSearch,
    tableData = [],
    onSetApp,
    onSetAllField,
    onSetClassesModal,
    isSynonymsLoading,
    currentFieldedCursorLocation,
    queryFields,
    isEditModalOpen,
    localQueryFields,
    onGetKeyFeaturesOfPatent,
  } = props;
  const [queryField, setQueryField] = useState({
    title: "Title",
    code: "TI",
  });
  const [count, setCount] = useState({
    title: "5",
    code: 5,
  });
  const [isGenerateKeywordsSection, setIsGenerateKeywordsSection] =
    useState(false);
  const [selectedKeywordsBox, setSelectedKeywordsBox] = useState([]);

  const handleApply = () => {
    const fieldData = isEditModalOpen
      ? [...localQueryFields]
      : [...queryFields];
    // Retrieve search words from table data
    const searchWords = tableData?.map((item) => Object.keys(item)[0]);

    // Find the target field based on the current cursor location
    const targetField = fieldData.find(
      (item) => item?.id === currentFieldedCursorLocation?.item?.id
    );

    // Check if the current cursor location and query field match, and if a target field exists
    if (
      currentFieldedCursorLocation &&
      queryField.code === currentFieldedCursorLocation.item.query &&
      targetField
    ) {
      let updatedValue = "";

      // Define the cursor position
      const cursorPosition = currentFieldedCursorLocation.cursorLocation;

      // Split the targetField.filedsinput into two parts at the cursor position
      const firstPart = targetField.filedsinput.slice(0, cursorPosition);
      const secondPart = targetField.filedsinput.slice(cursorPosition);

      // Construct the updatedValue by inserting the additional string between the two parts
      updatedValue = targetField.filedsinput
        ? firstPart +
          " OR " +
          searchWords.join(" OR ") +
          " OR " +
          selectedKeywordsBox.join(" OR ") +
          " " +
          secondPart
        : searchWords.join(" OR ") + " OR " + selectedKeywordsBox.join(" OR ");

      // Update the fields with the new value for the target field
      const updatedFields = fieldData.map((field) => {
        if (currentFieldedCursorLocation.item.id === field.id) {
          return {
            ...field,
            filedsinput: updatedValue,
            countryerror: "",
            alertData: "",
          };
        } else {
          return {
            ...field,
          };
        }
      });

      // Set all fields with the updated fields
      if (isEditModalOpen) {
        onSetAllField({
          localQueryFields: updatedFields,
        });
      } else {
        onSetAllField({
          queryFields: updatedFields,
        });
      }
    } else {
      // Generate a query using search words and selected keywords
      const query =
        searchWords.join(" OR ") + " OR " + selectedKeywordsBox.join(" OR ");

      // Create a new field with the generated query and other default values
      const newField = {
        id: uuidv4(),
        lang: "eng",
        type: queryField.title,
        dattype: "Text",
        query: queryField.code,
        error: "",
        operatersvalue: "AND",
        placeholder: "E.g. Wireless OR Mobile",
        filedsinput: query,
        textLabel: "Text",
        startdate: "",
        enddate: "",
        fielderror: "",
        dateerror: "",
      };

      const updatedFields = [...fieldData, newField];

      // Set all fields with the updated fields
      if (isEditModalOpen) {
        onSetAllField({
          localQueryFields: updatedFields,
        });
      } else {
        onSetAllField({
          queryFields: updatedFields,
        });
      }
    }

    // Set classes modal to indicate the change
    onSetClassesModal({
      fromClasssSuggestor: true,
    });

    // Set keyword modal state and clear selected keywords
    onSetKeywordModal({
      isOpenKeywordSuggestorModal: false,
      selectedKeywordsBox: [],
      tableData: [],
    });

    // Clear selected keywords and reset generate keyword section
    setSelectedKeywordsBox([]);
    setIsGenerateKeywordsSection(false);

    // Reset cursor location
    onSetApp({
      currentFieldedCursorLocation: null,
    });
  };

  const handleClear = () => {
    setSelectedKeywordsBox([]);
  };
  return (
    <Modal
      className="modalClassFilter keyword-suggestor-modal"
      static="static"
      keyboard={false}
      open={isOpenKeywordSuggestorModal}
      onClose={() =>
        onSetKeywordModal({ isOpenKeywordSuggestorModal: false, tableData: [] })
      }
    >
      <div className="modal-title">
        <p className="modal-title-heading">{"Key Feature Suggestions"}</p>
        <p
          className="modal-title-heading"
          onClick={() => {
            onSetKeywordModal({
              isOpenKeywordSuggestorModal: false,
              tableData: [],
            });
            setTimeout(() => {
              setIsGenerateKeywordsSection(false);
            }, 500);
          }}
        >
          X
        </p>
      </div>
      {
        <GenerateKeyFeatures
          {...props}
          setIsGenerateKeywordsSection={setIsGenerateKeywordsSection}
          selectedKeywordsBox={selectedKeywordsBox}
          onSetKeywordSelector={setSelectedKeywordsBox}
        />
      }
    </Modal>
  );
};

export default KeyFeatureSuggestions;
