import React from "react";

const SelectFieldSection = (props) => {
  const { checklistElements, checkedItems, onChange, onApply } = props;
  return (
    <div className="border h-100 d-flex flex-column py-2 px-1 gap-2 select-field-section">
      <div className="heading-text">Select Field</div>
      <div className="d-flex flex-column justify-content-between px-3 py-3 gap-3">
        {checklistElements &&
          checklistElements.length > 0 &&
          checklistElements.map((item, index) => (
            <div key={index} className="input-group">
              <label>
                <input
                  type="checkbox"
                  name={item}
                  checked={checkedItems[item] || false}
                  onChange={onChange}
                />
                {item}
              </label>
            </div>
          ))}
      </div>
      <div className="action-section border-0 w-100">
        <button
          className="white-secondary-button-color  mx-auto "
          onClick={onApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default SelectFieldSection;
