import ContentEditable from "react-contenteditable";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../common/user-roles";
const ContentRenderer = (props) => {
  const {
    onCellChange,
    rowIndex,
    text,
    isChangeText,
    isChangeDesc,
    onSaveInDb,
    idx,
    cellClassName="h-100",
  } = props;
  const options = { isChangeText, isChangeDesc };
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();  
  return (
    <>
      {userProjectAccess !== ACCESS_VIEWER ? (
        <ContentEditable
          className={`editable-cell text-start w-100 ${cellClassName}`}
          contentEditable="true"
          onChange={(e) => {
            onCellChange(e, rowIndex, options);
          }}
          onPaste={(e) => {
            e.preventDefault();
            const text = e.clipboardData.getData("text");
            document.execCommand("insertText", false, text);
          }}
          onBlur={(e) => {
            if (onSaveInDb) {
              onSaveInDb(e.target.innerHTML, rowIndex, idx, options);
            }
          }}
          onDrop={(e) => {
            e.preventDefault();
            const textData = e.dataTransfer.getData("text/plain");
            document.execCommand("insertText", false, textData);
          }}
          data-row-index={rowIndex}
          html={text}
          spellCheck="false"
        />
      ) : (
        <div
          className={`editable-cell text-start w-100 h-100 ${
            !text ? "disabled-cell" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: text || " " }}
        ></div>
      )}
    </>
  );
};
export default ContentRenderer;
