import RWirePatentView from "../pages/rwire-patent-view";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Navigate } from "react-router-dom";
import {
  setDetails,
  setFilterFields,
  setPatentDetails,
  setPatentId,
} from "../action/result-table";
import {
  setFullView,
  searchForClassAndKeyword,
  getUserAnnotations,
  updateUserAnnotations,
} from "../action/patent-view";
import { setNotes, getNote } from "../action/note";
import { performSearch } from "../action/rwire-search-api";
import { addAssignee, removeAssignee } from "../action/report/report-assignee";
import {
  addInventor,
  removeInventor,
  getProjectPatentDetails,
} from "../action/report/report-inventor";
import {
  addRelevantResult,
  getReportProjectRelevantResultFromPN,
  removeFromRelevantResult,
} from "../action/report/report-relevant-results";
import {
  addKeyFeatureDetailsHighlight,
  getReportProjectNotes,
  removeKeyFetureDetail,
} from "../action/report/report-notes";
import {
  addRowToKeywordsReportProject,
  getReportProjectKeywords,
  updateReportProjectKeywordsRow,
} from "../action/report/report-keyword";

const Container = (props) => {
  const {
    onSetDetails,
    displayData,
    onGetUserAnnotations,
    onGetReportProjectKeywords,
  } = props;
  let prevId,
    nextId = "";
  const patentIdFromStorage = sessionStorage.getItem("patentId");

  useEffect(() => {
    if (patentIdFromStorage) {
      onSetDetails(patentIdFromStorage);
      onGetUserAnnotations({
        publication_number: `${patentIdFromStorage}`,
      });
      onGetReportProjectKeywords();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patentIdFromStorage, onSetDetails]);

  if (
    patentIdFromStorage === "" ||
    typeof patentIdFromStorage === "undefined"
  ) {
    return <Navigate to="/" />;
  }

  displayData &&
    // eslint-disable-next-line array-callback-return
    displayData.map((value, key) => {
      if (value._id === patentIdFromStorage) {
        prevId = displayData[key - 1] ? displayData[key - 1]._id : "";
        nextId = displayData[key + 1] ? displayData[key + 1]._id : "";
      }
    });

  if (patentIdFromStorage) {
    return (
      <RWirePatentView
        {...props}
        prevId={prevId}
        nextId={nextId}
        currentId={patentIdFromStorage}
        onSetDetails={setDetails}
      />
    );
  }
};

const mapStateToProps = (
  {
    resultTable,
    app: { isLoadingTable, previousPage },
    fullView,
    note: { isNotesModalOpen, notes },
    reportInventor: { patentDetails },
    reportRelevantResults: { isPatentNumberInRelevantResult },
    reportNotes: { activeKeyFeature, reportTableData: keyFeatureList },
    reportKeyword: { reportTableData },
    project: { citedPatents },
  },
  ...rest
) => ({
  ...resultTable,
  ...fullView,
  isLoadingTable,
  previousPage,
  highlightword: [
    ...resultTable.esHighlightwords,
    ...resultTable.highlightword,
  ],
  rest,
  isNotesModalOpen,
  notes,
  patentDetails,
  isPatentNumberInRelevantResult,
  activeKeyFeature,
  reportTableData,
  keyFeatureList,
  citedPatents,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetDetails: setPatentDetails,
      onSetResultTable: setDetails,
      onSetPatentId: setPatentId,
      onSetFilter: setFilterFields,
      onSetFullView: setFullView,
      onAddKeyFeatureHighlight: addKeyFeatureDetailsHighlight,
      onAddProjectKeyword: addRowToKeywordsReportProject,
      onUpdateProjectKeyword: updateReportProjectKeywordsRow,
      onSearchForClassAndKeyword: searchForClassAndKeyword,
      onGetUserAnnotations: getUserAnnotations,
      onUpdateUserAnnotations: updateUserAnnotations,
      onSetNotes: setNotes,
      onGetNotes: getNote,
      onPerformSearch: performSearch,
      onAddAssignee: addAssignee,
      onAddInventor: addInventor,
      onRemoveAssignee: removeAssignee,
      onRemoveInventor: removeInventor,
      onGetProjectPatentDetails: getProjectPatentDetails,
      onAddRelevantResult: addRelevantResult,
      onGetReportProjectRelevantResultFromPN:
        getReportProjectRelevantResultFromPN,
      onRemoveFromRelevantResult: removeFromRelevantResult,
      onGetReportProjectNotes: getReportProjectNotes,
      onGetReportProjectKeywords: getReportProjectKeywords,
      onRemoveKeyFetureDetails: removeKeyFetureDetail,
      onUpdateReportProjectKeywordsRow: updateReportProjectKeywordsRow,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
