import React from "react";

const Youtubevideo = (props) => {
  const { src, title } = props;
  return (
    <>
      <iframe
        className="Yt-video"
        src={src}
        title={title}
        allowFullScreen="1"
      ></iframe>
    </>
  );
};

export default Youtubevideo;
