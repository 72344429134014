import { Block } from "../../../ui-components";
import "./style.scss";

const ProjectCreationWrapper = ({ children }) => {
  return (
    <Block className="project-creation">
      <Block className="page-container">
        <Block className="bg-image">
          <Block className="content-box">
            <Block className="color-strip"></Block>
            {children}
          </Block>
        </Block>
      </Block>
    </Block>
  );
};
export default ProjectCreationWrapper;
