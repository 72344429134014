const initialState = {
  itemsPerPage: 8,
  totalPage: 1,
  page: 1,
  limit: 10,
  data: [],
  totalRecordsCount: 0,
  selectedRows: [],
  selectedItemList: [],
  isError: "",
  totalApplicationCount: 0,
  lastQueryString: "",
  displayQueryIncremental: [],
  modalQuery: []
};

const incrementalQuery = (state = initialState, { type, payload }) => {
  switch (type) {
    case "INCREMENTAL_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default incrementalQuery;
