const initialState = {
  column: [
    {
      columnName: "String",
      type: "text",
    },
  ],
  selectedRows: [],
  relevantResultReportTableData: [],
};

const reportRelevantResults = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_RELEVANT_RESULTS":
      return { ...initialState };

    case "SET_RELEVANT_RESULTS":
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default reportRelevantResults;
