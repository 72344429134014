import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ExistingProject } from "../../components/report";
import {
  getBackwardCitationsData,
  getReportProjectDataById,
  getReportProjects,
  resetProjectData,
  setProjectReport,
  updateReportProject,
} from "../../action/report/report-project";
import { setApp } from "../../action/app";
import { getAllProjectUsers } from "../../action/report/report-users";

const Container = (props) => {
  return <ExistingProject {...props} />;
};

const mapStateToProps = ({ app }) => ({
  ...app,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjects: getReportProjects,
      onUpdateReportProject: updateReportProject,
      onResetProjectData: resetProjectData,
      onSetApp: setApp,
      onSetProjectReport: setProjectReport,
      onGetReportProjectDataById: getReportProjectDataById,
      onGetAllProjectUsers: getAllProjectUsers,
      onGetBackwardCitationsData: getBackwardCitationsData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
