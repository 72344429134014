import React from "react";
import { arrayMove } from "react-sortable-hoc";
import { ReportSortableTable } from "../../../container/report";
import { updateSortOrder } from "../../../ui-components/functions/notes/utils";

function RwireReportAssignee(props) {
  const {
    onSetReportAssignee,
    reportTableData,
    column,
    onUpdateAssigneeSortOrder,
    onUpdateReportProjectAssigneeRow,
    onAddRowToreportProject,
    onDeleteRowFromReportProject,
    onGetReportProjectAssignees,
  } = props;

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportAssignee({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });
    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateAssigneeSortOrder(bodyData);
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportAssignee({
      reportTableData: updatedTableData,
    });
  };
  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (reportTableData[idx].body !== value) {
      await onUpdateReportProjectAssigneeRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    // let lastId = findGreatestId([...reportTableData]);
    // const updatedTableData = [...reportTableData];
    // const newRow = { body: "", id: lastId + 1 };

    // updatedTableData.splice(rowIndex + 1, 0, newRow);

    // onSetReportAssignee({
    //   reportTableData: updatedTableData,
    // });
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });
    await onGetReportProjectAssignees();
  };

  const handleDeleteRow = async (rowIndex, id) => {
    if (reportTableData.length === 1) {
      return;
    }
    // const updatedTableData = [...reportTableData];
    // updatedTableData.splice(rowIndex, 1);
    // onSetReportAssignee({
    //   reportTableData: updatedTableData,
    // });
    await onDeleteRowFromReportProject(id);
    await onGetReportProjectAssignees();
  };

  return (
    <div className="notes-section" style={{ padding: "20px 20px 0 20px" }}>
      <ReportSortableTable
        columns={column}
        data={reportTableData}
        onSetData={onSetReportAssignee}
        onSortEnd={onSortEnd}
        pressDelay={100}
        useDragHandle
        className="w-100"
        onCellChange={(value, rowIndex, options) =>
          handleChange(value, rowIndex, options)
        }
        onSaveInDb={(value, rowIndex, options) =>
          handleSaveInDb(value, rowIndex, options)
        }
        onAddRowBelow={(rowIndex) => handleAddRow(rowIndex)}
        onDeleteRow={(rowIndex, id) => handleDeleteRow(rowIndex, id)}
        workspaceId="4"
      />
    </div>
  );
}

export default RwireReportAssignee;
