import { initialData } from "../common/generate-chart-initial-data";

const initialState = {
    "sheet-1": initialData,
    "sheet-2": initialData,
    "sheet-3": initialData
};

const generateChart = (state = initialState, { type, payload }) => {
    switch (type) {
        case "GENERATE_CHART_SET":

            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default generateChart;
