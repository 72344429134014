import React from "react";
import { FaHighlighter } from "react-icons/fa";
import NotePenIcon from "../../assets/images/note-pen-icon.svg";
const RwireHighlightIcon = (props) => {
  const { onSetHighlight } = props;

  return (
    <div className="notes-icon pe-2 cursor-pointer">
      <div
        className="text-center"
        onClick={() => onSetHighlight({ isVisibleHighlightModal: true })}
      >
        <img src={NotePenIcon} size={30} color="#435e99" title="Highlight" />
      </div>
    </div>
  );
};

export default RwireHighlightIcon;
