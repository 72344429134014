import React from "react";
import "./style.scss";
import Banner from "../../assets/images/our_commitment_banner.svg";

const Commitment = () => (
  <div className="position-relative text-center">
    <img className="our_commitments_section img-fluid" alt="" src={Banner} />
    <div className="position-absolute top-50 start-50 translate-middle p-3">
      <div className="third_block_heading">Our Commitment</div>
      <div className="third_block_para">
        At Researchwire, we are committed to excellence and continuous
        improvement. We understand the importance of reliable and up-to-date
        patent information in driving innovation and protecting intellectual
        property. That’s why we invest in state-of-the-art technology and a
        dedicated support team to deliver a seamless experience for our users.
      </div>
    </div>
  </div>
);

export default Commitment;
