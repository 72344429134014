import React from 'react'
import { ACCESS_EDITOR, ACCESS_COMMENTOR, ACCESS_VIEWER } from "./user-roles";

const UserAccess = (userProjectAccess) => {
    switch (userProjectAccess) {
        case ACCESS_EDITOR:
          return (
            <span>
              Editor
            </span>
          );
        case ACCESS_COMMENTOR:
          return (
            <span>
              Commentor
            </span>
          );
        case ACCESS_VIEWER:
          return (
            <span>
              Viewer
            </span>
          );
        default:
          return null;
      }
}

export default UserAccess