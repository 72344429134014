import React, { useEffect, useState, useMemo } from "react";
import { Button, Image } from "../../../ui-components";
import { CrossRedBgIcon } from "../../../assets/images/report";
import { updateTableData } from "./utils";

const ClaimsResultsTable = (props) => {
  const {
    searchedPatentNumber,
    claimsDataFound,
    claimsData,
    onSplitPatentClaims,
    onClose,
    setPatentNumber,
    setIsClaimsFetched,
    setIsManuallyEntering,
    onAddRowToreportProject,
    tableData,
    onUpdateReportProjectNotesRow,
    onGetReportProjectNotes,
  } = props;
  const [items, setItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [hideDependentItems, setHideDependentItems] = useState(false);

  useEffect(() => {
    const processedClaims = onSplitPatentClaims(claimsData);
    setItems(processedClaims);
    setSelectAll(false);
    if (claimsData.length > 0) {
      setPatentNumber(searchedPatentNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimsData, searchedPatentNumber, onSplitPatentClaims]);

  const handleCheckboxChange = (id) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setItems(updatedItems);
    setSelectAll(updatedItems.every((item) => item.checked));
  };

  const handleSelectAll = () => {
    const updatedItems = items.map((item) => ({
      ...item,
      checked: !selectAll,
    }));

    setItems(updatedItems);
    setSelectAll(!selectAll);
  };

  const handleApply = async () => {
    let checkedItems = items.filter((item) => item.checked);
    if (hideDependentItems) {
      checkedItems = checkedItems.filter((item) => item.INDP === "true");
    }
    const _id = sessionStorage.getItem("projectId");

    if (checkedItems.length > 0) {
      const updatedTableData = updateTableData(
        [...tableData],
        [...checkedItems]
      );
      // Update the tableData with the modified data in the database
      await Promise.all(
        // Check if the row already exists in the database
        updatedTableData
          .filter((row) => row.id)
          .map(async (row, index) => {
            await onUpdateReportProjectNotesRow({
              id: row.id,
              body: {
                body: row.body,
              },
            });
          })
      );

      if (updatedTableData.filter((row) => !row.id).length > 0) {
        await onAddRowToreportProject({
          data: updatedTableData.filter((row) => !row.id),
          projectId: parseInt(_id),
        });
      }

      await onGetReportProjectNotes();
      // Close modal and reset state
      onClose();
      setPatentNumber("");
      setItems([]);
      setSelectAll(false);
    }
  };

  const handleHideDependentClaims = () => {
    setHideDependentItems((prevState) => !prevState);
  };

  const filteredItems = useMemo(() => {
    if (hideDependentItems) {
      return items.filter((item) => item.INDP === "true");
    } else {
      return items;
    }
  }, [items, hideDependentItems]);

  return (
    <>
      <div className="result-section">
        <div className="header d-flex gap-3">
          <div>{searchedPatentNumber}</div>
          {searchedPatentNumber && (
            <div className="d-flex gap-2 align-items-center">
              <input
                type="checkbox"
                checked={hideDependentItems}
                onChange={handleHideDependentClaims}
              />
              <p className="hdc-heading">Hide Dependent Claims</p>
            </div>
          )}
        </div>
        <div className="results-data">
          {!claimsDataFound && (
            <div className="d-flex h-100 gap-2 m-auto flex-column justify-content-center">
              <div>
                <Image className="cross-img" src={CrossRedBgIcon} />
              </div>
              <div className="dnf-text">Data Not Found</div>
            </div>
          )}
          {filteredItems.map((item) => (
            <div key={item.id} className="d-flex w-100 single-result">
              <div className="checkbox">
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(item.id)}
                  className="checkbox"
                />
              </div>

              <div
                className="text text-left"
                dangerouslySetInnerHTML={{
                  __html: item.label,
                }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      <div className="action-section">
        <div className="select-all">
          {filteredItems.length > 0 && (
            <>
              <input
                type="checkbox"
                checked={selectAll}
                onChange={handleSelectAll}
              />
              Select All
            </>
          )}
        </div>
        <div
          className="manual-enter-btn"
          onClick={() => {
            setIsClaimsFetched(false);
            setPatentNumber("");
            setIsManuallyEntering(true);
          }}
        >
          Enter Claim Manually
        </div>
        <Button className="apply-btn" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </>
  );
};

export default ClaimsResultsTable;
