import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getReportProjectNotes } from "../action/report/report-notes";
import ClaimChartGenerator from "../components/claim-chart-generator/claim-chart-generator";
import { getClaimChartMappings } from "../action/patent-summarizer";

const Container = (props) => {
  return <ClaimChartGenerator {...props} />;
};

const mapStateToProps = ({
  reportNotes: { reportTableData },
  fullView: { isClaimMappingsGenerating },
  resultTable: { detailsData },
}) => ({
  reportTableData: reportTableData.filter((feature) => feature.body),
  isClaimMappingsGenerating,
  detailsData,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onGetReportProjectNotes: getReportProjectNotes,
      onGetClaimChartMappings: getClaimChartMappings,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Container);
