import React from "react";
import { FaCopy } from "react-icons/fa";

const CopyClipboard = (props) => {
  const { hideFloatingButton } = props;

  const handleClick = (e) => {
    navigator.clipboard.writeText(window.getSelection().toString());
    hideFloatingButton();
  };

  return (
    <>
      <div
        id="annote-icon"
        className="unselectable cursor-pointer"
        title="Copy selected text "
        onClick={handleClick}
      >
        <FaCopy className="annotations-icons" />
      </div>
    </>
  );
};

export default CopyClipboard;
