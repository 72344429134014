import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ProjectCreationWrapper } from "../../components/report";

const Container = (props) => {
  return <ProjectCreationWrapper {...props} />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
