export const options = [
    {
      title: "Expertise",
      description: `Benefit from our deep knowledge and experience in patents and intellectual property.`,
    },
    {
      title: "Accuracy",
      description: `Trust in the precision and reliability of our data, sourced from reputable global patent offices.`,
    },
    {
      title: "Support",
      description: `Receive exceptional customer service from our responsive and knowledgeable support team.`,
    },
    {
      title: "Innovation",
      description: `Experience the latest advancements in patent data technology, designed to meet your evolving needs.`,
    },
  ];


  export const features = [
    {
      title: "Comprehensive Database",
      description:
        "Access millions of patents from numerous countries, all in one place.",
    },
    {
      title: "Advanced Search Tools",
      description:
        "Utilize our powerful search engine to find relevant patents quickly and efficiently.",
    },
    {
      title: "Detailed Analytics",
      description:
        "Gain insights with our analytical tools that help you understand patent trends, technological advancements, and market opportunities.",
    },
    {
      title: "User-Friendly Interface",
      description:
        "Navigate our platform with ease, thanks to a clean, intuitive design tailored for both novices and experts.",
    },
    {
      title: "Real-Time Updates",
      description:
        "Stay informed with the latest patent filings and updates, ensuring you never miss critical information.",
    },
  ];