import React from "react";
import DataRow from "./data-row";

const KeywordsTable = ({
  options,
  selectedRows,
  onSetSelectedRows,
  isKeywordsGeneratorLoading = false,
}) => {
  // Chunk the options array into batches of 3 elements each
  const chunkedOptions = [];
  for (let i = 0; i < options.length; i += 3) {
    chunkedOptions.push(options.slice(i, i + 3));
  }
  return (
    <>
      {" "}
      {isKeywordsGeneratorLoading ? (
        <p className="m-auto">Loading...</p>
      ) : (
        <div className="keywords-table">
          {chunkedOptions.map((batch, index) => (
            <DataRow
              key={index}
              options={batch}
              isOddRow={index % 2 !== 0}
              selectedRows={selectedRows}
              onSetSelectedRows={onSetSelectedRows}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default KeywordsTable;
