import { fieldOptions, fieldList } from "../resources/data/options";

const initialState = {
  queryData: [],
  queryInput: "",
  queryOperater: "",
  syntaxqueryError: "",
  syntaxqueryCorrect: "",
  countryError: "",
  queryFields: fieldList,
  localQueryFields: [],
  fieldOptions,
  tabWiseSearchQuery: { fielded: "", expert: "", number: "" },
};

const searchquery = (state = initialState, { type, payload }) => {
  switch (type) {
    case "QUERY_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default searchquery;
