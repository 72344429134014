const initialState = {
  column: [
    {
      columnName: "Assignee",
      type: "text",
    },
  ],
  reportTableData: [],
  claimsData: [],
  claimsDataFound: true,
};

const reportAssignee = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_ASSIGNEES":
      return { ...initialState };
    case "GET_ALL_CLASS":
    case "SET_ASSIGNEE":
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default reportAssignee;
