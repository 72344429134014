import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PatentKeyFeatures from "../components/patent-summarizer/patent-keyfeature";
import {
    setKeyword
} from "../action/keyword-suggestor";
const Container = (props) => {
    return <PatentKeyFeatures {...props} />
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            onSetKeywordModal: setKeyword
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
