import React, { useState } from "react";
import ContentEditable from "react-contenteditable";
import { Image } from "../../../ui-components";
import { EditBlackIcon, deleteIcon } from "../../../assets/images/report";
import checkboxIcon from "../../../assets/images/RightTick.svg";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../../common/user-roles";
import DataRow from "./data-row";


const DataTable = ({
  data = [],
  mapping = {},
  columnWidths = {},
  onCellEdit,
  field = "",
  editingCell,
  setEditingCell,
  onDeleteCell,
  onDeleteTable,
  hideEditingAndDeletingUI = false,
}) => {
  const headers = Object.keys(mapping);

  const handleCellClick = (rowIndex, fieldName = "") => {
    setEditingCell({ row: rowIndex, field: fieldName });
  };

  const handleCellChange = (rowIndex, value) => {
    const newData = [...data];
    newData[rowIndex] = value;
    onCellEdit(newData, field);
  };
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  return (
    <>
      {data && data.length > 0 && (
        <table className="w-100 table table-borderless">
          <thead>
            <tr className="header-row">
              {headers.map((header, index) => (
                <th
                  key={index}
                  style={{
                    width: `${columnWidths[index]}%`,
                    maxWidth: `${columnWidths[index]}%`,
                  }}
                >
                  {header}
                </th>
              ))}
              <th className="text-center bg-white pb-0">
                {!hideEditingAndDeletingUI && (
                  <div
                    className={`delete-table-btn ${
                      userProjectAccess == ACCESS_VIEWER ? "d-none" : ""
                    }`}
                    onClick={() => onDeleteTable(field)}
                  >
                    <Image
                      src={deleteIcon}
                      style={{ width: "16px", height: "16px" }}
                    />
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody className="">
            {data.map((row, rowIndex) => (
              <DataRow
                key={rowIndex}
                rowIndex={rowIndex}
                headers={headers}
                editingCell={editingCell}
                field={field}
                mapping={mapping}
                row={row}
                handleCellChange={handleCellChange}
                hideEditingAndDeletingUI={hideEditingAndDeletingUI}
                handleCellClick={handleCellClick}
                onDeleteCell={onDeleteCell}
                data={data}
              />
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default DataTable;
