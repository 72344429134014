import React, { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";

const ClaimChartModalBody = (props) => {
  const {
    keyFeatures = [],
    checkedFeatures,
    onCheckboxChange,
    onContentEdit,
    handleCheckboxAll,
  } = props;
  const [focusedRowId, setFocusedRowId] = useState(null);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const keyFeatureCount = keyFeatures.filter((feature) => feature.body === "");
  useEffect(() => {
    setIsSelectAllChecked(
      keyFeatures.length > 0 && checkedFeatures.length === keyFeatures.length
    );
  }, [checkedFeatures, keyFeatures]);
  return (
    <div className="claim-chart-modal-body">
      {keyFeatures.length !== keyFeatureCount.length ? (
        <div
          className="pb-2 px-2"
          style={{
            background: "#e5edf6",
            borderRadius: "10px",
          }}
        >
          <table class="w-100">
            <thead>
              <tr>
                <th scope="" className="oneLine">
                  Key Features
                </th>
                <th scope="" className="select-all">
                  Select All
                </th>
                <th scope="">
                  <input
                    className="cb-margin"
                    type="checkbox"
                    onChange={handleCheckboxAll}
                    checked={isSelectAllChecked}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {keyFeatures &&
                keyFeatures.length > 0 &&
                keyFeatures.map((feature, index) => (
                  <tr key={feature.id}>
                    <td className="key-feature-col" valign="top">
                      Key Feature {index + 1}
                    </td>
                    <td
                      valign="top"
                      className={`${
                        focusedRowId === feature.id ? "focused-cell-border" : ""
                      }`}
                    >
                      <ContentEditable
                        className="p-2 text-break contenteditable-cell"
                        html={feature.body}
                        onFocus={() => setFocusedRowId(feature.id)}
                        onBlur={() => setFocusedRowId(null)}
                        onChange={(e) =>
                          onContentEdit(feature.id, e.target.value)
                        }
                      />
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        checked={checkedFeatures.includes(feature.id)}
                        onChange={() => onCheckboxChange(feature.id)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="no-key-features">
          <p className="no-features">Please add Key Features</p>
        </div>
      )}
    </div>
  );
};

export default ClaimChartModalBody;
