import React, { useState } from "react";
import { PlusWhiteIcon } from "../../../assets/images/report";
import { useParams } from "react-router-dom";
import ReleventResultAddModal from "./relevant-result-add-modal";
import RelevantResultsTableRow from "./relevant-result-table-row";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../../common/user-roles";
import { Modal } from "rsuite";
import { IoMdClose } from "react-icons/io";
import { openPatentInNewTab } from "../../../common/open-new-tab";

const RelevantResultsTable = (props) => {
  const {
    relevantResultReportTableData,
    onHandleModal,
    reportTableData,
    onSetFilter,
    onRwireSearchAPI,
    setUserInput,
    userInput,
    onRemove,
    isGenerateListCalled,
  } = props;

  const [isModalOpen, setModalData] = useState(false);
  const [openTabs, setOpenTabs] = useState([]);
  const [isVisibleMessageModal, setVisibilityMessageModal] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  let { locale } = useParams();
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  const handleRedirect = (id) => {
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
    sessionStorage.setItem("patentId", id);
    openPatentInNewTab(
      id,
      locale,
      openTabs,
      setOpenTabs,
      setMessageModalContent,
      setVisibilityMessageModal
    );
  };

  const handlePatentNoModal = () => {
    setModalData(true);
  };

  const hadnleRemove = (row) => {
    onRemove({ patentNumber: row.patentNumber, id: row.id });
  };

  return (
    <>
      <div className="results-header mt-3">
        <div>Relevant Results</div>
        {userProjectAccess !== ACCESS_VIEWER && (
          <div
            className="add_btn"
            title="Add Patent"
            onClick={handlePatentNoModal}
          >
            <img src={PlusWhiteIcon} alt="" />
          </div>
        )}
      </div>
      <div className="relevant-results container">
        {isGenerateListCalled && relevantResultReportTableData.length === 0 ? (
          <p className="text-center relevant_results_nrf">No results found</p>
        ) : (
          <table className="responsive-table w-100">
            <thead>
              <tr className="thead-sec">
                <th className="text-nowrap">Patent No</th>
                <th>Title</th>
                <th className="text-nowrap">Publication Date</th>
                <th>Priority Date</th>
                <th>Comments</th>
                <th>Checklist</th>
              </tr>
            </thead>
            <tbody>
              {relevantResultReportTableData &&
              relevantResultReportTableData.length > 0 ? (
                relevantResultReportTableData.map((row, index) => (
                  <RelevantResultsTableRow
                    index={index}
                    key={row.id}
                    row={row}
                    onHandleRemove={hadnleRemove}
                    onRedirect={handleRedirect}
                    onHandleModal={onHandleModal}
                    reportTableData={reportTableData}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    Please add the patents
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {isModalOpen && (
        <ReleventResultAddModal
          isModalOpen={isModalOpen}
          onCloseModal={() => setModalData(false)}
          onRwireSearchAPI={onRwireSearchAPI}
          userInput={userInput}
          setUserInput={setUserInput}
        />
      )}

      <Modal
        className="modalClassMultitab"
        static="static"
        keyboard={true}
        open={isVisibleMessageModal}
      >
        <div
          className="highlight-modal-close-btn"
          onClick={() => setVisibilityMessageModal(false)}
        >
          <IoMdClose className="modal__close" title="Close" />
        </div>
        <div>{messageModalContent}</div>
      </Modal>
    </>
  );
};

export default RelevantResultsTable;
