import React, { useEffect, useRef, useState } from "react";
import FiltersList from "./rwire-filter-list";
import GearIcon from "@rsuite/icons/Gear";
import RwireFiltersSettings from "./rwire-filter-settings";
import useClickOutside from "../../../common/useClickOutside";
import { assignTopToModal } from "../../../common/utils";
import { RightBlackIcon } from "../../../assets/images/report";

const RWirePatentSideBar = (props) => {
  const {
    filterList,
    onSetFilter,
    showFilters,
    onSetApp,
    isQuickView = false,
  } = props;

  const [toggle, setToggle] = useState(false);
  const [filtersList, setFiltersList] = useState(filterList);

  const ref = useRef(null);
  useClickOutside(ref, () => setToggle(false));
  useEffect(() => {
    setFiltersList(filterList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList]);

  const handleCloseFilter = () => {
    onSetFilter({ openedFilterName: "" });
  };
  const handleFilterSetting = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    assignTopToModal();
  }, [filtersList, showFilters]);

  useEffect(() => {
    window.addEventListener("resize", assignTopToModal);
    return () => {
      window.removeEventListener("resize", assignTopToModal);
    };
  }, []);
  const handleToggleFilters = () => {
    onSetApp({ showFilters: !showFilters });
  };
  return (
    <div className={`sidebar-patents ${!showFilters ? "bg-light" : ""}`}>
      <div
        className="patent-filter d-flex justify-content-between pe-3 position-relative"
        onClick={handleCloseFilter}
      >
        {(showFilters || isQuickView) && (
          <>
            <span className="">Filters</span>

            <div className="filter-setting-btn" ref={ref}>
              <GearIcon
                onClick={handleFilterSetting}
                style={{ cursor: "pointer" }}
              />
              {toggle && <RwireFiltersSettings {...props} />}
            </div>
          </>
        )}
        {!isQuickView && (
          <div
            className={`toggle-filter-sidebar ${
              !showFilters ? "collapse" : "expand"
            }`}
            title={`${showFilters ? "Collapse" : "Expand"}`}
            onClick={handleToggleFilters}
          >
            <img
              alt=""
              src={RightBlackIcon}
              className={`${showFilters ? "show" : "hide"}`}
            />
          </div>
        )}
      </div>
      {/* <Trans> */}
      {(showFilters || isQuickView) && (
        <ul className="list-unstyled filters-list ps-0">
          {filtersList &&
            filtersList.map((filter, index) => {
              return (
                <FiltersList
                  filter={filter}
                  {...props}
                  key={index}
                  index={index}
                />
              );
            })}
        </ul>
      )}
      {/* </Trans> */}
    </div>
  );
};

export default RWirePatentSideBar;
