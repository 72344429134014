import { useDrop, useDrag } from "react-dnd";
import { ItemTypes } from "./item-types.js";
import CloseIcon from "../../images/close-icon-white.svg";

const XDropDiv = (props) => {
  const { currentSheet, getFullName, code, onSetGenerateChart } = props;
  const handleClick = () => {
    onSetGenerateChart({
      [currentSheet]: { ...props[currentSheet], xaxis: "" },
    });
  };

  const callHandler = (data, axis) => {
    onSetGenerateChart({
      [currentSheet]: { ...props[currentSheet], yaxis: data, xaxis: "" },
    });
  };

  // eslint-disable-next-line
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.BOX,
    item: { code },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        callHandler(item.code, dropResult.name);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.BOX,
    drop: () => ({ name: props.dropboxname }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const isActive = canDrop && isOver;
  let backgroundColor = "#0048A2";
  if (isActive) {
    backgroundColor = "darkgreen";
  } else if (canDrop) {
    backgroundColor = "darkkhaki";
  }
  return (
    <div
      className="axis-block x-axis-block me-1"
      ref={(node) => drag(drop(node))}
      style={{ backgroundColor }}
      data-testid="xaxis"
      title="Drop field here">
      {props[currentSheet].xaxis ? (
        <>
          <span>{getFullName(props[currentSheet].xaxis)}</span>
          <span
            className="cursor-pointer close-icon-axis"
            onClick={handleClick}>
            <img src={CloseIcon} alt="clear-axix" />
          </span>
        </>
      ) : isActive ? (
        "Release to drop"
      ) : (
        "Drag and drop Field here"
      )}
    </div>
  );
};

export default XDropDiv;
