export const chartList = [
  {
    id: "research-center",
    type: "maps/worldwithcountries",
    field1: "PNC",
    field2: "PN_B",
    extraChartconfig: {
      showLabels: "0",
      LegendPosition: "bottom",
    },
    chartMainTitle: "Geographic Distribution",
    isDelete: false,
    isCountryMap: true,
    availableChartList: [
      "column2D",
      "bar2D",
      "pie2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "doughnut3d",
      "maps/worldwithcountries",
    ],
  },
  {
    id: "countrywise-patent-records",
    type: "pie2D",
    field1: "ALD",
    field2: "PN_B",
    extraChartconfig: {
      showLabels: "1",
      LegendPosition: "bottom",
    },
    chartMainTitle: "Legal Status",
    isDelete: false,
    isCountryMap: true,
    availableChartList: [
      "column2D",
      "bar2D",
      "pie2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "doughnut3d",
      "maps/worldwithcountries",
    ],
  },
];

export const inventorChartList = [{
  id: "top-inventor-name",
  type: "bar2d",
  field1: "IN_EN",
  field2: "PN_B",
  chartMainTitle: "Top Inventor Name",
  isDelete: false,
  availableChartList: [
    "column2D",
    "bar2D",
    "splinearea",
    "doughnut2D",
    "column3d",
    "bar3d",
    "pie3d",
    "treemap",
  ],
}];