import React from "react";

const RwirePhrasesTable = ({ meaningfulPhrases, extractedNumbers }) => {
  const filteredNumbers = Array.isArray(extractedNumbers)
    ? extractedNumbers.filter(
        (number) =>
          meaningfulPhrases[number] &&
          meaningfulPhrases[number].length > 0 &&
          meaningfulPhrases[number].some((phrase) => phrase.length > 1)
      )
    : [];

  // Remove one-character phrases
  const refinedPhrases = filteredNumbers.reduce((acc, number) => {
    const phrases = meaningfulPhrases[number].filter((phrase) => phrase.length > 1);
    if (phrases.length > 0) {
      acc[number] = phrases;
    }
    return acc;
  }, {});

  return (
    <div className="recognized-text-section">
      <table>
        <thead>
          <tr>
            <th>Number</th>
            <th>Phrases</th>
          </tr>
        </thead>
        <tbody>
          {filteredNumbers.map((number) => (
            <tr key={number}>
              <td>{number}</td>
              <td>{refinedPhrases[number].join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RwirePhrasesTable;
