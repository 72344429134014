import React from "react";
import ActionTooltip from "../report-sortable-table/action-modal";
import CharacterStyleMenu from "./report-character-style-menu";
import {
  BoldBg,
  Capitalize,
  Italic,
  RedoIcon,
  TextColor,
  Underline,
  UndoIcon,
} from "../../assets/images/report";
import TextColorsMenu from "./report-color-menu";

const Toolbar = (props) => {
  const { onUndoReportNotes, onRedoReportNotes } = props;
  const handleAction = (e, cmd, value = null) => {
    e.preventDefault();
    document.execCommand(cmd, true, value);
  };
  return (
    <div className="d-flex gap-3 align-items-center toolbar-bg">
      <ActionTooltip
        placement="bottom"
        tooltipContent={<CharacterStyleMenu handleAction={handleAction} />}
      >
        <div className="unselectable" title="More">
          <img src={Capitalize} alt="" />
        </div>
      </ActionTooltip>
      <div className="unselectable" title="Bold">
        <img src={BoldBg} alt="" onClick={(e) => handleAction(e, "bold")} />
      </div>
      <div className="unselectable" title="Italic">
        <img src={Italic} alt="" onClick={(e) => handleAction(e, "italic")} />
      </div>
      <div className="unselectable" title="Italic">
        <img
          src={Underline}
          alt=""
          onClick={(e) => handleAction(e, "underline")}
        />
      </div>
      <ActionTooltip
        placement="bottom"
        tooltipContent={<TextColorsMenu handleAction={handleAction} />}
      >
        <div className="unselectable" title="Text Color">
          <img src={TextColor} alt="" />
        </div>
      </ActionTooltip>
      <div className="unselectable" title="Undo">
        <img src={UndoIcon} alt="" onClick={onUndoReportNotes} />
      </div>{" "}
      <div className="unselectable" title="Redo">
        <img src={RedoIcon} alt="" onClick={onRedoReportNotes} />
      </div>
    </div>
  );
};

export default Toolbar;
