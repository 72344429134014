import React from "react";
import SelectFieldSection from "./select-field-section";
import LoaderGif from "../../assets/images/loader.gif";
import SectionKeyfeaturesTable from "./section-keyfeatures-table";

const KeyFeaturesTabContent = (props) => {
  const {
    keyFeatures = ["sjsj"],
    keyfeaturesSections,
    isKeyfeaturesExtracting = false,
    onHandleKeyfeatures,
    checkedFeatures,
    emptyKeyFeature,
  } = props;
  return (
    <div className="d-flex gap-3 w-100 h-100">
      <SelectFieldSection {...props} />
      <div className="d-flex flex-column w-100 overflow-auto">
        {emptyKeyFeature && (
          <div className="m-auto patent-summarizer-message">
            Please select field for generation of key features
          </div>
        )}
        {isKeyfeaturesExtracting && (
          <div className="m-auto">
            <img alt="Loader" width={60} height={60} src={LoaderGif} />{" "}
          </div>
        )}
        {!isKeyfeaturesExtracting && keyFeatures && keyFeatures.length > 0 && (
          <div className="blue-heading-row">
            <div className="blue-col-1">Key Features</div>
            <div className="blue-col-2">Definition</div>
          </div>
        )}
        {!isKeyfeaturesExtracting &&
          keyFeatures &&
          keyFeatures.length > 0 &&
          keyFeatures.map((keyfeature, index) => (
            <SectionKeyfeaturesTable
              heading={keyfeaturesSections[index]}
              keyfeature={keyfeature}
              onHandleKeyfeatures={onHandleKeyfeatures}
              checkedFeatures={checkedFeatures}
            />
          ))}
      </div>
    </div>
  );
};
export default KeyFeaturesTabContent;
