import React from "react";
import { Dropdown } from "../../../ui-components";

const FileFormatSelector = ({ selectedFormat, onChange }) => {
  const fileFormats = [
    {
      id: "PDF",
      label: "PDF",
    },
    { id: "XLSX", label: "XLSX" },
    { id: "PPT", label: "PPT" },
  ];

  return (
    <div>
      <Dropdown
        placeholder="Please select a option then download"
        options={fileFormats}
        isCustomInput={false}
        onChange={onChange}
      />
    </div>
  );
};

export default FileFormatSelector;
