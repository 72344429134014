import { Block, Modal, Image } from "../../../ui-components";
import { Like } from "../../../assets/images/report";
import { Loader } from "rsuite";

const RwireProjectModal = ({ open, onClose }) => {
  return (
    <Modal
      isOpen={open}
      isStatic={false}
      overlayClass="project-initiation-modal"
      className="project-initiation-modal-body"
    >
      <Block className="project-initiation-modal-logo">
        <Image src={Like} alt="" />
      </Block>
      <Block className="project-initiation-modal-heading">
        Let’s Get It Started
        <br />
        <span>Opening.....</span>
      </Block>
      <Loader id="collab-file-download-loader" size="sm"></Loader>
    </Modal>
  );
};
export default RwireProjectModal;
