import React from "react";
// import RequestAddUsersSection from "./request-add-users";
import ProjectSharedWithSection from "./project-shared-with-sections";
// import AddExistingUsers from "./add-existing-users";

const GeneralUserUsers = (props) => {
  const { projectUsers, existingOrganizationalUsers } = props;

  return (
    <div className="rwire-report-dashboard d-flex">
      <div className={`report-users-page`}>
        <div className="h-100 d-flex flex-column general-user">
          <div className="top-section">Request Access</div>
          <div className="bottom-section">
            <div className="d-flex justify-content-between gap-2 h-100">
              <>
                <div className="users-table-section w-100 gap-1 px-3">
                  <ProjectSharedWithSection
                    headingText="Project shared with"
                    users={[...projectUsers]}
                    {...props}
                  />
                  {/* <AddExistingUsers
                    headingText="Add More Users"
                    users={[...existingOrganizationalUsers]}
                    {...props}
                  /> */}
                </div>
                {/* DO NOT REMOVE THIS . will need this part */}
                {/* <RequestAddUsersSection {...props} /> */}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralUserUsers;
