import { Modal } from "rsuite";
import ClassesTable from "./clsses-table";
import ClassesForm from "./classes-form";

import "./classes-suggestions.scss";
import { ClassesActions } from "../../container/classes-suggestions";
import { uuidv4 } from "../../action/app";
import { dropdownFieldsPlaceholders } from "../../common/field-map";
import { useState, useEffect } from "react";

const ClassesSuggestions = (props) => {
  const {
    isOpenClassesSuggestorModal,
    onSetClassesModal,
    onClassesSearch,
    tableData = [],
    selectedItemList,
    selectedRows,
    onSetItemPerPage,
    onSetSelectedRows,
    onSetClassesSelector,
    classesType,
    formData,
    onSetAllField,
    selectedRowsWithCodeAndDefination,
    isEditModalOpen,
    localQueryFields,
    queryFields,
    isNoResultFound,
  } = props;
  const classTypeMappings = {
    cpc: { shortCode: "CPC", buttonText: "CPC" },
    us: { shortCode: "USC", buttonText: "US Class" },
    ipc: { shortCode: "IPC", buttonText: "IPC" },
  };

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    if (isOpenClassesSuggestorModal) {
      setIsSearching(false);
    }
  }, [isOpenClassesSuggestorModal]);

  const handleApply = () => {
    const fieldData = isEditModalOpen
      ? [...localQueryFields]
      : [...queryFields];

    const query = selectedRows.join(" OR ");
    const classesObj = {
      id: uuidv4(),
      lang: "eng",
      type: classTypeMappings[classesType].buttonText,
      dattype: "Text",
      query: classTypeMappings[classesType].shortCode,
      error: "",
      operatersvalue: "AND",
      placeholder: dropdownFieldsPlaceholders[
        classTypeMappings[classesType].buttonText
      ]
        ? dropdownFieldsPlaceholders[classTypeMappings[classesType].buttonText]
        : "Enter Text Here",
      filedsinput: query,
      textLabel: "newLabel",
    };

    // Add the new field to the list of fields
    const updatedFields = [...fieldData, classesObj];

    // Set all fields with the updated fields
    if (isEditModalOpen) {
      onSetAllField({
        localQueryFields: updatedFields,
      });
    } else {
      onSetAllField({
        queryFields: updatedFields,
      });
    }

    const clearedFormData = { ...formData };

    Object.keys(formData).forEach((key) => {
      clearedFormData[key] = "";
    });

    onSetClassesModal({
      fromClasssSuggestor: true,
      isOpenClassesSuggestorModal: false,
      selectedRows: [],
      tableData: [],
      formData: clearedFormData,
    });
  };

  const handleClose = () => {
    onSetClassesModal({
      isOpenClassesSuggestorModal: false,
      selectedRows: [],
      tableData: [],
      formData: {},
      isNoResultFound: false,
    });
  };

  return (
    <Modal
      className="modalClassFilter classes-suggestor-modal"
      static="static"
      keyboard={false}
      open={isOpenClassesSuggestorModal}
      onClose={handleClose}
    >
      <div className="modal-title">
        <p className="modal-title-heading">Classes Suggestions</p>
        <p className="modal-title-heading" onClick={handleClose}>
          X
        </p>
      </div>
      <div className="classes-suggestor-modal__wrapper">
        <ClassesForm
          onClassesSearch={onClassesSearch}
          classesType={classesType}
          onSetClassesSelector={onSetClassesSelector}
          onSetFormData={onSetClassesModal}
          formData={formData}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
        />
        <ClassesTable
          tableData={tableData}
          selectedRows={selectedRows}
          onSetSelectedRows={onSetSelectedRows}
          selectedItemList={selectedItemList}
          onSetItemPerPage={onSetItemPerPage}
          onSetClassesSelector={onSetClassesSelector}
          selectedRowsWithCodeAndDefination={selectedRowsWithCodeAndDefination}
          isSearching={isSearching}
          isNoResultFound={isNoResultFound}
        />
        <ClassesActions onApply={handleApply} />
      </div>
    </Modal>
  );
};

export default ClassesSuggestions;
