const initialState = {
  column: [
    {
      columnName: "Strings",
      type: "text",
    },
    {
      columnName: "Count",
      type: "text",
    },

  ],
  reportTableData: [],
};

const reportStrings = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_STRINGS":
      return { ...initialState };
    case "SET_STRING":
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default reportStrings;
