import { Button, Block } from "../../../ui-components";

const ClasessTab = ({
  selectedClassType,
  onSetReportClasess,
}) => {
  const handleChangeType = (type) => {
    onSetReportClasess({ selectedClassType: type });
  };
  return (
    <Block className="clasess-action-button">
      <Button
        className={`clasess-btn ${selectedClassType === "cpc" ? "active" : ""}`}
        onClick={() => handleChangeType("cpc")}
      >
        CPC
      </Button>
      <Button
        className={`clasess-btn ${selectedClassType === "ipc" ? "active" : ""}`}
        onClick={() => handleChangeType("ipc")}
      >
        IPC
      </Button>
      <Button
        className={`clasess-btn ${selectedClassType === "us" ? "active" : ""}`}
        onClick={() => handleChangeType("us")}
      >
        US
      </Button>
    </Block>
  );
};

export default ClasessTab;
