export const updateTableData = (tableData, checkedItems) => {
  // Identify contiguous empty rows at the end of tableData
  let endEmptyRowsCount = 0;
  let filledFromEnd = 0;
  for (let i = tableData.length - 1; i >= 0; i--) {
    if (tableData[i].body === "") {
      endEmptyRowsCount++;
    } else {
      filledFromEnd = i + 1;
      break;
    }
  }

  let checkedIndex = 0;

  // Fill the contiguous empty rows at the end
  for (
    let i = tableData.length - endEmptyRowsCount;
    i < tableData.length;
    i++
  ) {
    if (checkedIndex < checkedItems.length) {
      tableData[i].body = checkedItems[checkedIndex].label;
      checkedIndex++;
    }
  }

  // Calculate new sortOrder
  let sortOrder =
    Math.max(...tableData.map((item) => parseInt(item.sortOrder)), 0) + 1;

  // Append remaining checkedItems as new objects in tableData
  for (let i = checkedIndex; i < checkedItems.length; i++) {
    let newItem = {
      body: checkedItems[i].label,
      order: sortOrder.toString(),
    };
    tableData.push(newItem);
    sortOrder++;
  }
  return tableData.splice(filledFromEnd, tableData.length);
};
