export const setPatentOrPublication = (payload) => ({
  type: "APP_SET",
  payload: {
    ...payload,
  },
});

export const setApp = (payload) => ({
  type: "APP_SET",
  payload: {
    ...payload,
  },
});
