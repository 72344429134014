import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "rsuite";
import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import CloseIcon from "@rsuite/icons/Close";

const DownloadCenterEmailModal = (props) => {
  const {
    emailDownloadToggle,
    setEmailDownloadToggle,
    onExportFromExist,
    setMailId,
    mailId,
    data,
  } = props;

  const { t } = useTranslation();

  const [displayEmailValue, setDisplayEmailValue] = useState();

  if (!emailDownloadToggle) {
    return null;
  }

  const handleClickModalEmailClose = () => {
    setEmailDownloadToggle(false);
    setMailId("");
  };

  const handleEmailChange = (event) => {
    setDisplayEmailValue(event.target.value);
  };

  const handleSendClick = () => {
    setEmailDownloadToggle(!emailDownloadToggle);
    setDisplayEmailValue("");
    onExportFromExist({
      emails: displayEmailValue,
      parentId: mailId,
    });
  };

  // On "Enter" key email send
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSendClick();
    }
  };

  return (
    <Modal
      className="modalClassFilterEmail"
      backdrop="true"
      keyboard={false}
      open={true}
      onClose={() => {
        setEmailDownloadToggle(!emailDownloadToggle);
      }}>
      <div>
        <div className="d-flex justify-content-between download-center-header light-blue-background">
          <div className="download-center-text">Send Email</div>
          <div
            className="cursor-pointer"
            title="Close"
            onClick={handleClickModalEmailClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="email-button-center-download">
          <div className="email-text-download">Email id</div>
          <div className=" mb-3">
            <input
              type="text"
              className="form-control download-email"
              placeholder="Type an email id"
              value={displayEmailValue}
              onChange={handleEmailChange}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="d-flex justify-content-end">
            <RWireButton
              buttonCName="input-button-text-form"
              onClick={handleSendClick}>
              {t("send_button_label")}
            </RWireButton>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadCenterEmailModal;
