import { Block } from "../ui-component";
import { SortableContainer } from "react-sortable-hoc";
import Header from "./header";
import TableRow from "./table-row";

const ReportSortableTable = SortableContainer((props) => {
  const {
    columns,
    data = [],
    isAddExtraClass,
    onOpenModal,
    extraColumn = [],
  } = props;
  return (
    <Block className="report-sortable-table">
      <Header
        columns={columns}
        isAddExtraClass={isAddExtraClass}
        onOpenModal={onOpenModal}
        extraColumn={extraColumn}
      />
      {extraColumn.length > 0 && (
        <Block className="extra-heading">
          {extraColumn.map((i) => (
            <Block key={i}>{i}</Block>
          ))}
        </Block>
      )}
      <Block className="report-sortable-table_row_wrapper">
        {data.map((i, idx) => (
          <TableRow
            key={i.id}
            rowIndex={i.id}
            rowItem={i}
            idx={parseInt(i.sortOrder)}
            index={parseInt(i.sortOrder)}
            {...props}
          />
        ))}
      </Block>
    </Block>
  );
});

export default ReportSortableTable;
