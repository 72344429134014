import { Dropdown } from "rsuite";
import { fieldOptionsDate } from "../../../resources/data/options";
import { checkValidation } from "../../../common/date-validation";
import { useRef, useState } from "react";
import { handleListScroll } from "../../../common/dropdown-position";
const MAX_LENGTH = 14;

const DropDown = ({
  item,
  fieldData,
  index,
  setAllField,
  onSetApp,
  startDate,
  endDate,
  isEditModalOpen,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSelect = async (eventKey) => {
    const dtOp = fieldData.map((item, key) => {
      if (index === key) {
        return {
          ...item,
          query: eventKey[0],
          type: eventKey[1],
        };
      }
      return { ...item };
    });
    if (isEditModalOpen) {
      setAllField({ localQueryFields: dtOp });
    } else {
      setAllField({ queryFields: dtOp });
    }
    onSetApp({ smartSearchWord: "", isUpdateQuery: false });

    //We passed start and end date because after state set it's not found imediatlly
    const validData = await checkValidation({
      startDate,
      endDate,
      data: dtOp,
      index,
    });
    if (isEditModalOpen) {
      setAllField({ localQueryFields: validData });
    } else {
      setAllField({ queryFields: validData });
    }
  };

  const handleOpen = () => {
    setSearchTerm("");
    const allNode = Array.from(
      document.getElementsByClassName("rs-dropdown-inner-menu")
    );

    allNode.forEach((element) => {
      element.classList.add("d-none");
    });

    handleListScroll();
    setTimeout(() => {
      inputRef.current.focus();
    }, 50);
  };

  return (
    <>
      <Dropdown
        title={
          item.type.length < MAX_LENGTH
            ? item.type
            : `${item.type.substring(0, MAX_LENGTH)}...`
        }
        onSelect={(e) => handleSelect(e)}
        onOpen={handleOpen}
        id={item.id}
      >
        <li className="outer search-input">
          <input
            key={index}
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            ref={inputRef}
            className=" w-100"
          />
        </li>
        {fieldOptionsDate
          .filter((item) =>
            item.valuecode.toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((item, index) => {
            return (
              <Dropdown.Item
                key={index}
                title={item.valuecode}
                eventKey={[item.sortcode, item.valuecode]}
              >
                {item.valuecode
                  .split(new RegExp(`(${searchTerm})`, "gi"))
                  .map((part, i) =>
                    part.toLowerCase() === searchTerm.toLowerCase() ? (
                      <strong className="matched-string-highlight-bg" key={i}>
                        {part}
                      </strong>
                    ) : (
                      part
                    )
                  )}
              </Dropdown.Item>
            );
          })}
      </Dropdown>
    </>
  );
};

export default DropDown;
