import React, { useRef, useState, useEffect } from "react";
import { applyMultipleHighlights } from "../../action/patent-view";
import ClearAnnotationsActions from "./clear-annotations-actions";
import RwireNoteIcon from "./rwire-notes-icon";
import RwireHighlightIcon from "../../container/rwire-highlight";
import { HighlightFromQueryOrManually } from "../../container/highlight";

const ViewTitleSection = (props) => {
  const {
    detailsData,
    languageSelected,
    isLoadingPdf,
    loaderGIF,
    downloadPdfIcon,
    downloadPdf,
    isPdfAvailable,
    userAnnotations,
    onSetNotes,
    isNotesModalOpen,
    onGetNotes,
    notes,
    onSetFullView,
    stringsComments,
    stringsHighlights,
    onUpdateUserAnnotations,
  } = props;
  const containerRef = useRef(null);

  const [showMessage, setShowMessage] = useState(false);

  const downloadPdfHandler = (publicationNumber) => {
    downloadPdf(publicationNumber, setShowMessage);
  };

  const shouldShowMessage = isPdfAvailable === false;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowMessage(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [detailsData["PN_B"]]);

  const titleText = Array.isArray(detailsData[`TI_${languageSelected}`])
    ? detailsData[`TI_${languageSelected}`][0]
    : detailsData[`TI_${languageSelected}`];
  const finalText = applyMultipleHighlights(
    titleText,
    userAnnotations && userAnnotations[`TI_${languageSelected}`]
  );
  return (
    <div className="d-flex justify-content-between">
      {detailsData[`TI_${languageSelected}`] ? (
        <div
          ref={containerRef}
          className={`TI_${languageSelected} patent-title highlighted-component-title`}
        >
          <HighlightFromQueryOrManually>
            {finalText}
          </HighlightFromQueryOrManually>
        </div>
      ) : (
        <p className="patnet_view_dna">Data not available</p>
      )}
      <div className="d-flex gap-2">
        <div className="d-flex" style={{ position: "relative" }}>
          <RwireHighlightIcon />
          <RwireNoteIcon
            onSetNotes={onSetNotes}
            isNotesModalOpen={isNotesModalOpen}
            onGetNotes={onGetNotes}
            notes={notes}
          />
          <div className="download-pdf-icon">
            <div className="text-center">
              <img
                src={isLoadingPdf ? loaderGIF : downloadPdfIcon}
                width="30"
                alt=""
                title="PDF"
                onClick={() => downloadPdfHandler(detailsData["PN_B"])}
              />
            </div>
          </div>
          <div
            className="text-danger"
            style={{
              position: "absolute",
              marginTop: "35px",
              whiteSpace: "nowrap",
            }}
          >
            {showMessage ? "Pdf not available" : ""}
          </div>
        </div>

        <ClearAnnotationsActions
          onSetFullView={onSetFullView}
          stringsComments={stringsComments}
          stringsHighlights={stringsHighlights}
          onUpdateUserAnnotations={onUpdateUserAnnotations}
          detailsData={detailsData}
        />
      </div>
    </div>
  );
};

export default ViewTitleSection;
