import React, { useRef, useState } from "react";
import { Button } from "../../../ui-components";
import { splitClaimsFromText } from "../../../ui-components/functions/notes/utils";

function ManualClaimsSection(props) {
  const { onCloseModal, onAddRowToreportProject, onGetReportProjectNotes } =
    props;
  const [text, setText] = useState("");
  const [splittedClaims, setSplittedClaims] = useState([]);
  const [selectedClaims, setSelectedClaims] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [checklistErrorMessage, setChecklistErrorMessage] = useState("");
  const [caretErrorMessage, setCaretErrorMessage] = useState("");
  const [textToSplit, setTextToSplit] = useState("");
  const [caretPosition, setCaretPosition] = useState(0);
  const textAreaRef = useRef(null);

  const handleJoinClaims = () => {
    const selectedIndexes = selectedClaims.sort((a, b) => a - b);
    if (selectedIndexes.length < 2) {
      setChecklistErrorMessage("Select two or more claims to join");
      return;
    }
    const areConsecutive = selectedIndexes.every(
      (index, i) => i === 0 || index === selectedIndexes[i - 1] + 1
    );
    if (!areConsecutive) {
      setChecklistErrorMessage("Select two or more consecutive claims to join");
      return;
    }
    const newSplittedClaims = [...splittedClaims];
    const joinedClaim = splittedClaims
      .filter((claim, index) => selectedClaims.includes(index))
      .join(" ");
    selectedClaims.forEach((index) => {
      newSplittedClaims[index] = "";
    });
    newSplittedClaims[selectedClaims[0]] = joinedClaim;
    const filteredClaims = newSplittedClaims.filter((claim) => claim);
    setSplittedClaims(filteredClaims);
    setSelectedClaims([]);
    setTextToSplit("");
  };

  const handleCheckboxChange = (index) => {
    if (selectedClaims.includes(index)) {
      setSelectedClaims(
        selectedClaims.filter((selectedIndex) => selectedIndex !== index)
      );
    } else {
      setSelectedClaims([...selectedClaims, index]);
    }
    setChecklistErrorMessage("");
  };

  const handleSplitClaimText = () => {
    if (selectedClaims.length !== 1) {
      setChecklistErrorMessage("Please select a single feature");
      return;
    }

    const selectedClaimIndex = selectedClaims[0];
    const selectedClaim = splittedClaims[selectedClaimIndex];

    setTextToSplit(selectedClaim);
  };
  const handleSplit = () => {
    if (caretPosition === 0) {
      setCaretErrorMessage(
        "Please place the cursor in the textarea to split it."
      );
    } else if (caretPosition === textToSplit.length) {
      return;
    } else {
      const selectedClaimIndex = selectedClaims[0];
      const selectedClaim = splittedClaims[selectedClaimIndex];

      const firstPart = selectedClaim.slice(0, caretPosition);
      const secondPart = selectedClaim.slice(caretPosition);

      const newSplittedClaims = [...splittedClaims];

      newSplittedClaims.splice(selectedClaimIndex, 1, firstPart, secondPart);

      setCaretPosition(0);
      setSplittedClaims(newSplittedClaims);
      setTextToSplit("");
      setSelectedClaims([]);
    }
  };

  const handleSelect = () => {
    if (textAreaRef.current) {
      setCaretPosition(textAreaRef.current.selectionStart);
      setCaretErrorMessage("");
    }
  };

  const handleApply = async () => {
    const _id = sessionStorage.getItem("projectId");
    if (selectedClaims.length > 0) {
      let updatedFeatures = selectedClaims.map((claim, index) => {
        return {
          body: splittedClaims[claim],
          order: index + 1,
        };
      });

      await onAddRowToreportProject({
        data: updatedFeatures,
        projectId: parseInt(_id),
      });
      await onGetReportProjectNotes();

      onCloseModal();
    }
  };
  const handleClear = () => {
    setText("");
    setSplittedClaims([]);
    setSelectedClaims([]);
  };
  const fillArray = (count) => {
    return Array.from({ length: count }, (_, index) => index);
  };
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedClaims(fillArray(splittedClaims.length));
    } else {
      setSelectedClaims([]);
    }
  };
  return (
    <>
      <div className="manual-claims-section">
        <div className="single-manual-claim-section">
          <div className="heading-text">Place the claim below</div>
          <textarea
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              setErrorMessage("");
            }}
          />
          <div className="d-flex justify-content-center w-100">
            <p className="me-auto err-message">{errorMessage}</p>
            <div className="py-2">
              <Button
                className="btn-blue"
                onClick={() => {
                  if (text) {
                    setSplittedClaims(splitClaimsFromText(text));
                  } else {
                    setErrorMessage("Please enter text to split");
                  }
                }}
              >
                Split Claim
              </Button>
              <Button className="btn-white" onClick={handleClear}>
                Clear Text
              </Button>
            </div>
          </div>
          {splittedClaims && splittedClaims.length > 0 && (
            <div className="split-result-section">
              <div className="d-flex single-result align-items-center header">
                <div className="text text-left">Claims</div>
                <div className="checkbox">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={selectedClaims.length === splittedClaims.length}
                    onChange={(e) => handleSelectAll(e)}
                  />
                </div>
              </div>
              {splittedClaims.map((claim, index) => (
                <div key={index} className="d-flex single-result">
                  <div className="text text-left">{claim}</div>
                  <div className="checkbox">
                    <input
                      type="checkbox"
                      className="checkbox"
                      checked={selectedClaims.includes(index)}
                      onChange={() => handleCheckboxChange(index)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
          <p className="me-auto err-message">{checklistErrorMessage}</p>
          {splittedClaims && splittedClaims.length > 0 && (
            <div className="py-2">
              <Button className="btn-blue" onClick={handleJoinClaims}>
                Join Claims
              </Button>
              <Button className="btn-white" onClick={handleSplitClaimText}>
                Split Claim
              </Button>
            </div>
          )}
          {textToSplit && (
            <div className="w-100 pt-2 text-left d-flex flex-column">
              <div className="heading-text">
                Place cursor in text to split it into a new feature
              </div>
              <textarea
                value={textToSplit}
                ref={textAreaRef}
                onSelect={handleSelect}
              />
              <div className="err-message">{caretErrorMessage}</div>
              <Button className="ms-0 mt-1 btn-white" onClick={handleSplit}>
                Split it into a new claim element
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="action-section justify-content-end">
        <Button
          className={`btn-blue ${
            selectedClaims.length === 0 ? "opacity-50" : ""
          }`}
          onClick={handleApply}
          disabled={selectedClaims.length === 0}
        >
          Apply
        </Button>
      </div>
    </>
  );
}

export default ManualClaimsSection;
