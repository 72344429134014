import useDocumentTitle from "./useDocumentTitle";
import { useParams, Navigate, Link } from "react-router-dom";
import i18n from "./helpers/i18n";
import RwireInactiveScreen from "./container/rwire-inactive-screen";
import { useEffect } from "react";
import { ClassesSuggestions } from "./container/classes-suggestions";
import KeywordSuggestions from "./container/keyword-suggestions";
import { HighlightCustomiseModal, HighlightModal } from "./container/highlight";
import Header from "./container/header";
import RWirefooter from "./components/rwire-ui/rwire-footer";
import CookieConsent from "react-cookie-consent";
//Supported languages can be added to the array.
const supportedLocales = ["en"];

const LayoutWrapper = (props) => {
  const { titleKey, children, onSetQuery } = props;
  let { locale } = useParams();
  const pathName = window.location.pathname;

  useEffect(() => {
    const language = supportedLocales.includes(locale) ? locale : "en";
    i18n.changeLanguage(language);
  }, [locale]);

  useEffect(() => {
    onSetQuery({ syntaxqueryCorrect: "", syntaxqueryError: "" });
  }, [onSetQuery, pathName]);

  useDocumentTitle(titleKey);

  if (!locale) {
    return <Navigate to="/en/" />;
  }

  if (!supportedLocales.includes(locale)) {
    return <Navigate to="/en/page-not-found" />;
  }

  return (
    <div>
      <Header />
      <div>
        <RwireInactiveScreen />
        {children}
        <ClassesSuggestions />
        <KeywordSuggestions />
        <HighlightModal />
        <HighlightCustomiseModal />
        <RWirefooter />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          enableDeclineButton={true}
          declineButtonText="Decline"
          style={{
            display: "block",
            background: "#fff",
            color: "#17243a",
            border: "1px solid #efefef",
            width: "365px",
            right: "0.5%",
            marginBottom: "0.5%",
            left: "none",
            position: "fixed",
            boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
          }}
          buttonStyle={{
            background: "#ffcb0c",
            border: "1px solid #707070",
            borderRadius: "10px",
            color: "#000",
            fontSize: "16px",
            fontFamily: "Montserrat",
            width: "100px",
            height: "33px",
            fontWeight: "500",
          }}
          declineButtonStyle={{
            border: "1px solid #707070",
            borderRadius: "10px",
            fontSize: "16px",
            fontFamily: "Montserrat",
            width: "100px",
            height: "33px",
            fontWeight: "500",
          }}
        >
          By continuing to browse the Researchwire platform, you consent to the
          utilization of Cookies for statistical analysis and enhancement of
          user engagement and platform usability, thereby contributing to an
          improved browsing experience.{" "}
          <a
            href="https://www.cookiesandyou.com/"
            target="_blank"
            rel="noreferrer"
          >
            Cookies Policy
          </a>
        </CookieConsent>
      </div>
    </div>
  );
};

export default LayoutWrapper;
