export const getHeightOfChartPopupAsPerScreen = () => {
  let chartHeight = "600";
  if (window && window.innerWidth <= 1280) {
    chartHeight = "250";
  }
  if (
    window &&
    window.innerWidth >= 1281 &&
    window &&
    window.innerWidth <= 1599
  ) {
    chartHeight = "600";
  }
  if (window && window.innerWidth >= 2560) {
    chartHeight = "1000";
  }
  return chartHeight;
};
