import React from "react";
import { getTodaysDate } from "../../../ui-components/functions/report-view/utils";

const ProjectTitleSection = ({ name = "" }) => {
  return (
    <div className="project-details">
      <table className="table table-borderless">
        <tbody>
          <tr>
            <td>
              <div>Project Name</div>
              <div>:</div>
            </td>
            <td className="fw-bold">{name}</td>
          </tr>

          <tr>
            <td>
              <div>Report Date</div>
              <div>:</div>
            </td>
            <td>{getTodaysDate()}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProjectTitleSection;
