import React, { useState } from "react";
import ContentEditable from "react-contenteditable";

const ClaimChartMatchingTable = (props) => {
  const {
    keyFeatures = [],
    mappings = [],
    isClaimMappingsGenerating = false,
    onMappingEdit,
  } = props;
  const [focusedRowId, setFocusedRowId] = useState(null);
  return (
    <>
      {isClaimMappingsGenerating ? (
        <p className="flex-grow-1 pt-5 m-auto ">Loading...</p>
      ) : (
        <div className="claim-chart-modal-body">
          <div
            className="pb-2 px-2"
            style={{
              background: "#e5edf6",
              borderRadius: "10px",
            }}
          >
            <table class="w-100">
              <thead>
                <tr>
                  <th scope="">Claim Mapping</th>
                  <th scope=""></th>
                </tr>
              </thead>
              <tbody>
                {keyFeatures &&
                  keyFeatures.length > 0 &&
                  keyFeatures.map((feature, index) => (
                    <tr>
                      <td className="key-feature-col">
                        <ContentEditable
                          className="p-2 text-break cursor-auto"
                          html={feature.body}
                          disabled={true}
                        />
                      </td>
                      <td
                        valign="top"
                        className={`${
                          focusedRowId === feature.id
                            ? "focused-cell-border"
                            : ""
                        }`}
                      >
                        <ContentEditable
                          className="p-2 text-break contenteditable-cell"
                          html={mappings[index]}
                          onFocus={() => setFocusedRowId(feature.id)}
                          onBlur={() => setFocusedRowId(null)}
                          onChange={(e) => onMappingEdit(index, e.target.value)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default ClaimChartMatchingTable;
