import { Accordion } from "react-bootstrap";
import { Block } from "../../ui-components";
import RWireButton from "../../components/rwire-ui/rwire-button/rwire-button";
import closeIcon from "../../assets/images/close-big.svg";
import KeyFeatureListRow from "./key-feature-list-row";
import { ClaimMapping } from "../../assets/images/report";

const KeyFeaturesList = (props) => {
  const {
    reportTableData = [],
    onClose,
    activeKeyFeature,
    onSetReportNotes,
    onSetFullView,
    userAnnotations,
    onHandleKeyFeatureAdd,
    keyFeatureSelectList,
    onSetKeyFeatureSelectList,
    removeKeyFeatureDetails,
    onGetUserAnnotations,
  } = props;

  const nonBlankBodiesCount = reportTableData.filter(
    (item) => item.body && item.body.trim() !== ""
  ).length;

  const handleClose = () => {
    onClose();
  };

  const handleApply = () => {
    onHandleKeyFeatureAdd();
  };

  const isAnyTextSelected = window.getSelection().toString();

  return (
    <Block className="patent-full-view-page-slide">
      <Block className="head-block">
        <Block>Claim Mapping</Block>
        <Block onClick={handleClose} className="close-btn">
          <img src={closeIcon} alt="" />
        </Block>
      </Block>

      <Block className="accordion-main checkbox-20">
        {nonBlankBodiesCount === 0 ? (
          <>
            <div className="claim_mapping_noresult">
              <img
                src={ClaimMapping}
                alt=""
                style={{ height: "40%", width: "40%" }}
              />
              <div className="claim_mappaing_txt mt-2">
                No Claim elements to display
              </div>
              <div className="claim_map_txt">
                Please add key-features to display
              </div>
            </div>
          </>
        ) : (
          <Accordion alwaysOpen>
            {reportTableData
              .filter((n) => n.body)
              .map((i, key) => (
                <KeyFeatureListRow
                  key={key}
                  indexKey={key}
                  item={i}
                  activeKeyFeature={activeKeyFeature}
                  onSetReportNotes={onSetReportNotes}
                  userAnnotations={userAnnotations}
                  onSetFullView={onSetFullView}
                  keyFeatureSelectList={keyFeatureSelectList}
                  onSetKeyFeatureSelectList={onSetKeyFeatureSelectList}
                  isAnyTextSelected={isAnyTextSelected}
                  removeKeyFeatureDetails={removeKeyFeatureDetails  }
                  onGetUserAnnotations={onGetUserAnnotations}
                />
              ))}
          </Accordion>
        )}
      </Block>

      <Block className="bottom-block">
        <RWireButton
          cNameDiv="search-query ps-3"
          buttonCName="cancel-btn"
          name="Cancel"
          onClick={() => handleClose()}
        />
        <RWireButton
          cNameDiv=""
          buttonCName={`add-btn ${nonBlankBodiesCount === 0 ? ' button-disabled' : ''}`}
          name="Add"
          onClick={() => handleApply()}
          disabled={nonBlankBodiesCount === 0}
        />
      </Block>
    </Block>
  );
};

export default KeyFeaturesList;
