import React, { useState } from "react";
import { Modal } from "../../../ui-components";
import FileFormatSelector from "./file-format-selector";
import { getExcelDownloadableData } from "../../../ui-components/functions/report-view/utils";
import { ExcelExportHelper } from "./excel-export-helper";
import { PPTExportHelper } from "./ppt-export-handler";
import { PDFExportHelper } from "./pdf-export-handler";

const DownloadModal = (props) => {
  const { isOpen, onClose, projectData } = props;
  const [selectedFormat, setSelectedFormat] = useState("");
  const [error, setError] = useState("");

  const handleFormatChange = (e) => {
    setSelectedFormat(e.label);
    setError("");
  };

  const handleDownload = async () => {
    if (!selectedFormat) {
      setError("Please select a file format");
      return;
    }
    switch (selectedFormat) {
      case "PDF":
        onClose();
        setTimeout(() => {
          PDFExportHelper(getExcelDownloadableData(projectData || {}), true);
        }, 100);
        break;
      case "XLSX":
        onClose();

        setTimeout(() => {
          ExcelExportHelper(getExcelDownloadableData(projectData || {}), true);
        }, 100);
        break;
      case "PPT":
        onClose();
        PPTExportHelper(getExcelDownloadableData(projectData || {}), true);
        break;
      default:
        break;
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="project-report-download-modalbg"
      className="project-report-download-modal-body">
      <div className="report-download-modal">
        <div className="header-section">
          <div className="py-2">Download</div>
          <div className="close-btn" onClick={onClose} title="Close">
            X
          </div>
        </div>
        <div className="input-section">
          <div style={{ padding: "20px" }}>
            <div className="text-left fw-bold mb-1">Download</div>
            <div>
              <FileFormatSelector
                selectedFormat={selectedFormat}
                onChange={handleFormatChange}
              />
            </div>
            <div className="err-messgae">{error}</div>
          </div>

          <div className="download-btn" onClick={handleDownload}>
            <button>Download</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
