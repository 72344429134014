import React from "react";
import ReactDOM from "react-dom";
import "./style.scss";

const SlidingSidePanel = ({
  isOpen,
  onClose,
  children,
  className = "",
  isStatic = true,
  overlayClass = "",
  id = "",
  isSmartReaderOpen,
}) => {
  return ReactDOM.createPortal(
    <div
      className={`add-comment-modal-overlay ${
        isSmartReaderOpen ? "smart-reader-open" : ""
      } ${className} ${overlayClass} ${isOpen ? "open" : ""}`}
    >
      <div
        className={`add-comment-modal-body ${
          isSmartReaderOpen ? "smart-reader-open" : ""
        } ${className} ${isOpen ? "open" : ""}`}
        id={`${id}`}
      >
        {children}
      </div>
    </div>,
    document.getElementById("rwire-custom-modal-root")
  );
};

export default SlidingSidePanel;
