import React, { useState } from "react";
import { Button, Popover, SelectPicker, TagPicker, Whisper } from "rsuite";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { Image, Modal } from "../../../ui-components";
import { UserAvatar } from "../../../assets/images/report";
import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
const DefaultPopover = React.forwardRef(({ content, ...props }, ref) => {
  return (
    <Popover ref={ref} {...props} style={{ marginRight: "40px" }}>
      <p>
        <b>Editors</b> can edit the project data
      </p>
      <p>
        <b>Viewers</b> can see the option to download, print, and copy
      </p>
      <p>
        <b>Commentors</b> can give feedback and see the option to download,
        print, and copy
      </p>
    </Popover>
  );
});
const CustomComponent = ({ placement, loading, children }) => (
  <Whisper
    trigger="hover"
    placement={placement}
    controlId={`control-id-${placement}`}
    speaker={<DefaultPopover content={`I am positioned to the ${placement}`} />}
  >
    <div>
      <InfoOutlineIcon style={{ fontSize: 20 }} />
    </div>
  </Whisper>
);

const useUsers = (defaultUsers = []) => {
  const [users, setUsers] = useState(defaultUsers);
  const [loading, setLoading] = useState(false);
  const featUsers = (word) => {
    setLoading(true);
    fetch(`https://api.github.com/search/users?q=${word}`)
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.items);
        setLoading(false);
      })
      .catch((e) => console.log("Oops, error", e));
  };

  return [users, loading, featUsers];
};
const data = [
  { label: "Viewer", value: "Viewer", group: "access" },
  { label: "Commenter", value: "Commenter", group: "access" },
  { label: "Editor", value: "Editor", group: "access" },
  { label: "Remove Access", value: "Remove Access", group: "remove access" },
];
const ShareProjectModal = (props) => {
  const { isOpen, onClose } = props;
  const [users, loading, featUsers] = useUsers();
  const [value, setValue] = useState([]);
  const [searchValue, setsearchValue] = useState("");
  const [cacheData, setCacheData] = useState([]);

  const handleSelect = (value, item, event) => {
    setCacheData([...cacheData, item]);
    setsearchValue("");
  };
  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="share-project-modal"
      className="share-project-modal-body"
    >
      <div className="header-section">
        <div>Share "Project Name" </div>
        <div className="cursor-pointer">
          <CustomComponent placement="rightStart" loading={loading} />
        </div>
      </div>
      <div className="w-100 p-2 d-flex gap-2">
        <TagPicker
          data={users}
          cacheData={cacheData}
          value={value}
          block
          menuMaxHeight={300}
          labelKey="login"
          valueKey="id"
          onChange={setValue}
          onSearch={(word) => {
            featUsers(word);
            setsearchValue(word);
          }}
          onSelect={handleSelect}
          renderMenu={(menu) => {
            if (loading) {
              return (
                <div
                  style={{
                    padding: "6px 12px 12px",
                    color: "#999",
                    textAlign: "center",
                  }}
                >
                  <SpinnerIcon spin /> Loading...
                </div>
              );
            }
            // console.log("first", searchValue);
            if (!searchValue) {
              return null;
            }
            return menu;
          }}
        />
        {cacheData && cacheData.length > 0 && (
          <div className="pt-1">
            <SelectPicker
              data={data}
              searchable={false}
              style={{ width: 130 }}
              cleanable={false}
              menuMaxHeight={300}
              renderMenuItem={(label, item) => {
                return (
                  <div>
                    <i className="rs-icon rs-icon-user" /> {label}
                  </div>
                );
              }}
              value={"Commentor"}
              // defaultValue={"Editor"}
              renderValue={(value, item) => {
                return <div className="text-right pe-4">{value}</div>;
              }}
              onChange={(e) => console.log(e)} // viewer
            />
          </div>
        )}
      </div>
      <div className="w-100 text-left p-2 fw-bold">People with access</div>
      <div style={{ maxHeight: "220px", overflow: "auto", width: "100%" }}>
        {data.map(() => (
          <div className="p-2 w-100 d-flex gap-2 align-items-center">
            <div className="author-avatar">
              <Image src={UserAvatar} alt="" />
            </div>
            <div className="author-name d-flex flex-column text-left">
              <div style={{ fontSize: "14px", fontWeight: "500" }}>
                {"Prathamesh Patil(You)"}
              </div>
              <div style={{ fontSize: "12px" }}>
                patil.prathamesh.18et1003@gmail.com
              </div>
            </div>
            <div
              className="comment-time ms-auto"
              style={{
                fontSize: "12px",
                color: "gray",
                minWidth: 110,
                maxWidth: 130,
              }}
            >
              <SelectPicker
                data={data}
                searchable={false}
                style={{ width: 130 }}
                cleanable={false}
                menuMaxHeight={300}
                renderMenuItem={(label, item) => {
                  return (
                    <div>
                      <i className="rs-icon rs-icon-user" /> {label}
                    </div>
                  );
                }}
                defaultValue={"Editor"}
                renderValue={(value, item) => {
                  return <div className="text-right pe-4">{value}</div>;
                }}
                onChange={(e) => console.log(e)} // viewer
              />
            </div>
          </div>
        ))}
      </div>

      <div className="w-100 p-2 d-flex justify-content-end">
        <button onClick={onClose} className="btn-blue">
          Done
        </button>
      </div>
    </Modal>
  );
};

export default ShareProjectModal;
