import { getIcon } from "../../../common/charts/chart-options-list";
import closeIcon from "../../../images/close-icon.svg";

const ChartTypeOptions = (props) => {
  const { onHandleChartType, onHandleClose, availableChartList } = props;

  const handleChartType = (e) => {
    onHandleChartType(e.target.getAttribute("data-type"));
  };

  return (
    <div className="chart-filter d-flex justify-content-end">
      {availableChartList.map((i) => (
        <span className="ps-3 pe-3">
          <img
            src={getIcon(i)}
            alt={i}
            title={i}
            className="cursor-pointer"
            onClick={handleChartType}
            data-type={i}
          />
        </span>
      ))}

      <span className="filters">
        <img
          className="width-25 cursor-pointer"
          alt=""
          src={closeIcon}
          onClick={onHandleClose}
        />
      </span>
    </div>
  );
};

export default ChartTypeOptions;
