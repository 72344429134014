import { useEffect, useRef, useState } from "react";
import Pill from "./pill";
import "./multi-user-input.scss";
import { isObjectBlank } from "../../../../action/result-table";
import { fetch } from "../../../../service/fetch";
import { RedInfoIcon } from "../../../../assets/images/report";

const MultiUserInput = (props) => {
  const {
    selectedUserSet,
    onSetSelectedUserSet,
    selectedUsers,
    onSetSelectedUsers,
    projectUsers 
  } = props;
  
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState({});

  const [activeSuggestion, setActiveSuggestion] = useState(0);
  const [errorMessage, setErrorMessage] = useState(""); 

  const inputRef = useRef(null);

  useEffect(() => {
    const delay = 500; // Adjust the delay time according to your needs
    let timeoutId;

    const fetchUsers = () => {
      setActiveSuggestion(-1);
      if (searchTerm.trim() === "") {
        setSuggestions({});
        return;
      }

      timeoutId = setTimeout(async () => {
        try {
          // for email verification
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const match = emailRegex.exec(searchTerm);
          if (match) {
            const data = await fetch(
              {
                url: `/api/user/email`,
                body: { userEmail: searchTerm.trim() },
              },
              "POST"
            );

            if (data.message === "Not found data.") {
              setSuggestions({});
            } else {
              setSuggestions({ users: [data] });
              if (data.length > 0) {
                setActiveSuggestion(-1);
              }
            }
          }
        } catch (error) {
          if (error.message === "Not found data.") {
            setSuggestions({});
          }
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }, delay);
    };

    fetchUsers();

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);
  const handleSelectUser = (user) => {
    if (!selectedUsers.includes(user)) {
      onSetSelectedUsers([...selectedUsers, user]);
      onSetSelectedUserSet(new Set([...selectedUserSet, user.email]));
    }
    setSearchTerm("");
    setSuggestions([]);
    inputRef.current.focus();
  };

  const handleRemoveUser = (user) => {
    const updatedUsers = selectedUsers.filter(
      (selectedUser) => selectedUser.id !== user.id
    );
    onSetSelectedUsers(updatedUsers);

    const updatedEmails = new Set(selectedUserSet);
    updatedEmails.delete(user.email);
    onSetSelectedUserSet(updatedEmails);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Backspace" &&
      e.target.value === "" &&
      selectedUsers.length > 0
    ) {
      const lastUser = selectedUsers[selectedUsers.length - 1];
      handleRemoveUser(lastUser);
      setSuggestions([]);
    } else if (e.key === "ArrowDown" && suggestions?.users?.length > 0) {
      e.preventDefault();
      setActiveSuggestion((prevIndex) =>
        prevIndex < suggestions.users.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp" && suggestions?.users?.length > 0) {
      e.preventDefault();
      setActiveSuggestion((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (
      e.key === "Enter" &&
      activeSuggestion >= 0 &&
      activeSuggestion < suggestions.users.length
    ) {
      e.preventDefault();
      handleSelectUser(suggestions.users[activeSuggestion]);
    }
  };

  useEffect(() => {
    const duplicateEmails = selectedUsers.filter(user => 
      projectUsers.some(projectUser => projectUser.email === user.email)
    );

    if (duplicateEmails.length > 0) {
      setErrorMessage("User already added to this project");
    } else {
      setErrorMessage("");
    }
  }, [selectedUsers, projectUsers]);

  return (
    <>
    <div className="user-search-container">
      <div className="user-search-input">
        {/* Pills */}
        {selectedUsers.map((user) => {
           const isInProject = projectUsers.some(projectUser => projectUser.email === user.email);
          return (
            <Pill
              key={user.email}
              //   image={user.image}
              text={`${user.email}`}
              onClick={() => handleRemoveUser(user)}
              color={isInProject ? 'red' : ''}
            />
          );
        })}
        {/* input feild with search suggestions */}
        <div className="flex-grow-1">
          <input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Enter Email ID"
            onKeyDown={handleKeyDown}
          />
          {/* Search Suggestions */}
          {/* {isObjectBlank(suggestions) && ( */}
          <ul
            className={`suggestions-list ${
              suggestions?.users?.length > 0 ? "" : "d-none"
            }`}>
            {suggestions?.users?.map((user, index) => {
              return !selectedUserSet.has(user.email) ? (
                <li
                  className={index === activeSuggestion ? "active" : ""}
                  key={user.email}
                  onClick={() => handleSelectUser(user)}>
                  {/* <img
                    src={user.image}
                    alt={`${user.firstName} ${user.lastName}`}
                  /> */}
                  <span>{user.email}</span>
                </li>
              ) : (
                <></>
              );
            })}
          </ul>
          {/* )} */}
        </div>
      </div>
    </div>
    {errorMessage && <div className="error-message ml-2 mt-1">
    <img src={RedInfoIcon} alt="Error" className="mr-1"/>
    <div className="collab-error-text mt-1">{errorMessage}</div>
      </div>}
    </>
  );
};

export default MultiUserInput;
