import { queryHighlightWordsColors } from "../resources/data/highlight-colors";

const initialState = {
  highlightedWords: [],
  isVisibleHighlightModal: false,
  isVisibleCustomiseHighlightModal: false,
  highlightColor: queryHighlightWordsColors,
  highlightText: ""
};

const highlight = (state = initialState, { type, payload }) => {
  switch (type) {
    case "HIGHLIGHT_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default highlight;
