import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import RwireViewModalHeader from "../rwire-view-modal-header";
import RwireModalBody from "./rwire-modal-body";
import "./rwire-view-modal.scss";
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import Tooltip from "../tooltip";
import AnnotationsActionButtons from "../annotations-action-buttons";
import loading from "../../../assets/images/loader.gif"

function RwireViewModal(props) {
  const {
    onSetFilter,
    isSmartReaderOpen,
    queryKeywordsHighlightColor,
    selection,
    toolRef,
    setToolRef,
    handleClose,
    handleApplyHighlights,
    removeHighlight,
    totalHighlightedWords,
    isViewPageModalOpen,
    onSetFullView,
    handleUpdateComment,
    elementToRemoveHighlight,
    languageSelected,
    detailsData,
    isExact,
    userAnnotations,
    setField,
    setSelectionIndex,
    setSelection,
    onClaimMapping,
    onSetSlidePanel,
    onHandleKeywordsAdd,
    selectedText,
    onSetSelectedText,
  } = props;

  const [currentId, setCurrentId] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [displayDataIds, setDisplayDataIds] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const storedIds = JSON.parse(localStorage.getItem("storedPatentIds"));
    if (storedIds) {
      setDisplayDataIds(storedIds);
    }
  }, []);

  useEffect(() => {
    const patentIdFromStorage = sessionStorage.getItem("patentId");
    if (displayDataIds.length > 0 && patentIdFromStorage) {
      setCurrentId(patentIdFromStorage);
      const currentIndex = displayDataIds.indexOf(patentIdFromStorage);
      setPrevId(currentIndex > 0 ? displayDataIds[currentIndex - 1] : null);
      setNextId(currentIndex < displayDataIds.length - 1 ? displayDataIds[currentIndex + 1] : null);
    }
  }, [displayDataIds]);

  const handlePrevious = () => {
    if (!prevId) return;
    props.onSetImages([loading]);
    const currentIndex = displayDataIds.indexOf(currentId);
    const newPrevId = displayDataIds[currentIndex - 1];
    setPrevId(newPrevId);
    setNextId(currentId);
    setCurrentId(newPrevId);
    sessionStorage.setItem("patentId", newPrevId);
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: true });
    navigate(`/en/patent-view/${newPrevId}`);
    setPrevId(displayDataIds[currentIndex - 2] || null);
  };

  const handleNext = () => {
    if (!nextId) return;
    props.onSetImages([loading]);
    const currentIndex = displayDataIds.indexOf(currentId);
    const newNextId = displayDataIds[currentIndex + 1];
    setPrevId(currentId);
    setNextId(newNextId);
    setCurrentId(newNextId);
    sessionStorage.setItem("patentId", newNextId);
    onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: true });
    navigate(`/en/patent-view/${newNextId}`);
    setNextId(displayDataIds[currentIndex + 2] || null);
  };

  const handleCloseModal = () => {
    onSetFilter({
      isViewPageModalOpen: false,
      isSmartReaderOpen: false,
    });
    if (props.isNotesModalOpen) {
      props.onSetNotes({ isNotesModalOpen: false });
    }
    onSetSlidePanel(false);
  };

  return (
    <Modal
      className="smart-reader-modal position-relative"
      open={true}
      static="static"
    >
      <AnnotationsActionButtons
        isSmartReaderOpen={isSmartReaderOpen}
        isViewPageModalOpen={isViewPageModalOpen}
        removeHighlight={removeHighlight}
        onSetFullView={onSetFullView}
        handleUpdateComment={handleUpdateComment}
      />

      <Tooltip
        setIsSelection={setSelection}
        isSelection={selection}
        tool={toolRef}
        setToolRef={setToolRef}
        onClose={handleClose}
        onApplyHighlights={handleApplyHighlights}
        isOpenInModal={true}
        openTextarea={props.openTextarea}
        onSetFullView={props.onSetFullView}
        onApplyComments={props.onApplyComments}
        elementToRemoveHighlight={elementToRemoveHighlight}
        setSelectionIndex={setSelectionIndex}
        setField={setField}
        onSetSlidePanel={onSetSlidePanel}
        onHandleKeywordsAdd={onHandleKeywordsAdd}
        onSetSelectedText={onSetSelectedText}
        selectedText={selectedText}
      />

      <div className="view-modal-close-btn">
        <IoMdClose
          className="modal__close"
          title="Close"
          onClick={handleCloseModal}
        />
      </div>
      {isSmartReaderOpen && (
        <div className="footer d-flex justify-content-between">
          <div className="prev d-flex justify-content-between">
            {prevId && (
              <button onClick={handlePrevious}>
                <MdArrowBackIosNew className="icon" />{" "}
                <span className="pub-no">{prevId}</span>
              </button>
            )}
          </div>
          <div className="next d-flex justify-content-between">
            {nextId && (
              <button onClick={handleNext} className="next">
                <span className="pub-no">{nextId}</span>{" "}
                <MdArrowForwardIos className="icon" />
              </button>
            )}
          </div>
        </div>
      )}
      <Modal.Header className="header">
        <RwireViewModalHeader
          {...props}
          detailsData={detailsData}
          onSetFilter={onSetFilter}
          totalHighlightedWords={totalHighlightedWords}
          isExact={isExact}
          queryKeywordsHighlightColor={queryKeywordsHighlightColor}
          languageSelected={languageSelected}
          userAnnotations={userAnnotations}
          onClaimMapping={onClaimMapping}
          onSetSlidePanel={onSetSlidePanel}
        />
      </Modal.Header>
      <Modal.Body>
        <RwireModalBody {...props} languageSelected={languageSelected} />
      </Modal.Body>
    </Modal>
  );
}

export default RwireViewModal;
