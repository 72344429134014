import { BiInfoCircle } from "react-icons/bi";
import { useStore } from "react-redux";

const TooltipContent = () => (
  <div className="tooltip-text">
    Incremental results are the new results, which were not available in the
    previously selected query
    <div className="arrow-down" />
  </div>
);

const ResultBase = () => {
  const store = useStore();

  const {
    app: { isSearchFromIncremental },
  } = store.getState();

  if (!isSearchFromIncremental) {
    return "";
  }

  return (
    <div className="result-base-info">
      Incremental Results.
      <div className="info-circle-container">
        <BiInfoCircle
          alt="edit"
          className="img-fluid info-circle"
          width="20"
          height="20"
          style={{
            fontSize: "23px",
            cursor: "pointer",
          }}
        />
        <TooltipContent />
      </div>
    </div>
  );
};

export default ResultBase;
