export const removeMismatchedTags = (inputString) => {
  try {
    const regex =
      /<a\s+class="user-mention-span mention-span-([-\w]+)"\s+(.*?)\s*href="front\/en\/report\/notes"\s*>(.*?)<\/a>/g;

    let matches;
    while ((matches = regex.exec(inputString)) !== null) {
      const propsString = matches[2];
      const name = matches[3];

      const propsRegex = /\b(\w+)="([^"]*)"/g;
      let propsMatches;
      const props = {};
      while ((propsMatches = propsRegex.exec(propsString)) !== null) {
        const propName = propsMatches[1];
        const propValue = propsMatches[2];
        props[propName] = propValue;
      }

      if (`@${props.title}` !== name) {
        const fullMatch = matches[0];
        inputString = inputString.replace(fullMatch, "");
      }
    }

    return inputString;
  } catch {
    return inputString;
  }
};

export const getCaretPosition = () => {
  var x = 0;
  var y = 0;
  var sel = window.getSelection();

  if (sel.rangeCount > 0) {
    var range = sel.getRangeAt(0);
    var rect;

    var span = document.createElement("span");
    range.insertNode(span);
    rect = span.getBoundingClientRect();
    span.parentNode.removeChild(span);

    if (rect) {
      var modal = document.getElementById("report-collab-modal");
      var header = document.getElementById("rwire-header");
      var mentionList = document.getElementById("mention-list");

      var modalWidth = modal ? modal.offsetWidth : 0;
      var headerHeight = header ? header.offsetHeight : 0;
      var mentionListWidth = mentionList ? mentionList.offsetWidth : 0;

      x =
        rect.left +
        window.pageXOffset -
        (window.innerWidth - modalWidth - mentionListWidth);

      y = rect.top + window.pageYOffset - headerHeight;

      // Check if MentionList exceeds the right edge of the screen
      if (x + mentionListWidth > window.innerWidth) {
        x = window.innerWidth - mentionListWidth;
      }

      // Check if MentionList exceeds the top edge of the screen
      if (y < 0) {
        y = 0;
      }
    }
  }

  return {
    left: x,
    top: y,
  };
};

export const isValidURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
};
