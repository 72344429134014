import { chartList, inventorChartList } from "./chart-config";
import ChartDataBlock from "./chart-data-block";
import { getChartConfig } from "../../../rwire-cognizance/functions/chart-config";
import { Block } from "../../../../ui-components";
import "./styles.scss";
import ClassWordCloudChart from "./class-word-cloud-chart";

const ChartBlock = (props) => {
  const {
    geographicChartData: { aggregations: geoChartdata = {} } = {},
    deadAliveChartData: { aggregations: chartData = {} } = {},
    wordCloudChartData: { aggregations: wordCloudData } = {},
    inventorChartData: { aggregations: invChartdata = {} } = {},
  } = props;

  if (!Object.keys(geoChartdata).length && !Object.keys(chartData).length) {
    return "Loading........";
  }

  return (
    <Block className="chart_wrapper d-flex justify-content-start flex-wrap">
      {chartList.map((item, key) => {
        return (
          <div className="w-50">
            <ChartDataBlock
              key={key}
              id={item.id}
              item={item}
              chartData={
                item.type === "maps/worldwithcountries"
                  ? geoChartdata
                  : chartData
              }
              {...props}
              chartConfig={getChartConfig({
                type: item.type,
                field1: item.field1,
                field2: item.field2,
                width: "100%",
                height: 400,
                chartTitle: item.chartMainTitle,
                ...item.extraChartconfig,
              })}
              className="chart-data"
            />
          </div>
        );
      })}
      {Object.keys(wordCloudData).length ? (
        <ClassWordCloudChart wordCloudData={wordCloudData} />
      ) : (
        ""
      )}
      {inventorChartList.map((item, key) => {
        return (
          <div className="w-50">
            <ChartDataBlock
              key={key}
              id={item.id}
              item={item}
              chartData={invChartdata}
              {...props}
              chartConfig={getChartConfig({
                type: item.type,
                field1: item.field1,
                field2: item.field2,
                width: "100%",
                height: 400,
                chartId: "dashboard-inv",
                chartTitle: item.chartMainTitle,
                ...item.extraChartconfig,
              })}
              className="chart-data"
            />
          </div>
        );
      })}
    </Block>
  );
};

export default ChartBlock;
