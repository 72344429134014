export const keywordsMapping = {
  Keywords: "title",
  Synonyms: "body",
};
export const cpcMapping = {
  CPC: "classes",
  Definition: "body",
};
export const ipcMapping = {
  IPC: "classes",
  Definition: "body",
};
export const usMapping = {
  US: "classes",
  Definition: "body",
};
export const inventorsMapping = {
  Inventors: "body",
};
export const assigneesMapping = {
  Assignees: "body",
};
export const keyFeaturesMapping = {
  "Key Features": "body",
};
export const stringsMapping = {
  Strings: "body",
  Count: "publicationCount/applicationCount",
};

export const keyFeaturePatentsMapping = {
  "Relevant Results": "relevance",
  Definition: "Defination",
  Patents: "patents",
};

export const patentKeyFeatureMapping = {
  "Publication Number": "publicationNumber",
  "Available Key Features No.": "availableKeyFeatures",
  "Not Available Key Features No.": "notAvailableKeyFeatures",
};
