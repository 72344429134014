import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CreateProject } from "../../components/report";
import {
  createReportProject,
  getReportWorkspaceElements,
  resetProjectData,
  setProjectReport,
  getReportProjects,
  getBackwardCitedPatentsList,
} from "../../action/report/report-project";
import { getAllProjectUsers } from "../../action/report/report-users";
import { checkIfPatentAvailable } from "../../action/keyword-suggestor";

const Container = (props) => {
  return <CreateProject {...props} />;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onGetReportProjects: getReportProjects,
      onCreateReportProject: createReportProject,
      onGetReportWorkspaceElements: getReportWorkspaceElements,
      onResetProjectData: resetProjectData,
      OnSetProjectReport: setProjectReport,
      onGetAllProjectUsers: getAllProjectUsers,
      onCheckIfPatentAvailable: checkIfPatentAvailable,
      onGetBackwardCitedPatentsList: getBackwardCitedPatentsList,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
