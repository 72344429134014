import { Modal } from "rsuite";
import "../../keyword-suggestions/keyword-suggestions.scss";
import { Block } from "../../../ui-components";
import RWireButton from "../../rwire-ui/rwire-button/rwire-button";
import { useState } from "react";

const ReleventResultFeaturesEditModal = (props) => {
  const {
    isModalOpen,
    onSetReportRelevantResults,
    selectedRows,
    onCloseModal,
    reportTableData,
    userInput,
    onAddKeyFeaturesRelevantResult,
    onGetReportProjectRelevantResultFromPN,
    projectId,
  } = props;

  let selectedId = [];
  const [initialSelection] = useState(selectedRows);
  const handleCheckbox = (e, id) => {
    const selectedItem = id;
    selectedId = [...selectedRows, selectedItem];
    if (!e.target.checked) {
      selectedId = selectedId.filter((item) => {
        return `${item}` !== `${id}`;
      });
    }
    onSetReportRelevantResults({
      selectedRows: selectedId,
    });
  };

  const getUniqueElements = (array1, array2) => {
    return array1.filter((item) => !array2.includes(item));
  };

  const handleApply = async () => {
    const addedElements = getUniqueElements(selectedRows, initialSelection);
    const removedElements = getUniqueElements(initialSelection, selectedRows);

    await onAddKeyFeaturesRelevantResult({
      relevantResultId: userInput.relevantResultId,
      addKeyFeaturesId: addedElements,
      removeKeyFeaturesId: removedElements,
      projectId,
    });

    await onGetReportProjectRelevantResultFromPN({ projectId, selectedId: [] });

    onCloseModal({ isModalOpen: false });
  };

  const handleClose = () => {
    onSetReportRelevantResults({
      selectedRows: [],
    });
    onCloseModal({ isModalOpen: false });
  };
  const handleClear = () => {
    onSetReportRelevantResults({
      selectedRows: [],
    });
  };

  return (
    <Modal
      className="modalClassFilter relevant-results-section report-integration edit-patent-no-modal"
      static="static"
      keyboard={false}
      open={isModalOpen}
      onClose={handleClose}
    >
      <Block className="modal-title">
        <p className="modal-title-heading">Relevant Results</p>
        <p
          className="modal-title-heading"
          onClick={() => onCloseModal({ isModalOpen: false })}
        >
          X
        </p>
      </Block>
      <Block className="classes-suggestor-modal__wrapper">
        <Block className="relevant-results-filter border-class">
          <table className="responsive-table container w-100 d-block">
            <thead>
              <tr className="key-features-table-header">
                <th style={{ width: "19%" }}>Key Features</th>
                <th style={{ width: "75%" }}>Definitions</th>
                <th className="text-center" style={{ width: "15%" }}>
                  Select
                </th>
              </tr>
            </thead>
            <tbody>
              {reportTableData &&
                reportTableData.length > 0 &&
                reportTableData
                  .filter((i) => i.body)
                  .map((row, index) => (
                    <tr
                      key={index}
                      className={`${index % 2 === 0 ? "even-row" : "odd-row"}`}
                    >
                      <td style={{ width: "15.1%" }} className="fw-bold">
                        Key Feature {index + 1}
                      </td>
                      <td
                        style={{ width: "75.5%" }}
                        dangerouslySetInnerHTML={{ __html: row.body }}
                      ></td>
                      <td
                        style={{ width: "10%" }}
                        className={`text-center select-checkbox ${
                          selectedRows.includes(row.id) ? "checked" : ""
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedRows.includes(row.id)}
                          onChange={(e) => handleCheckbox(e, row.id)}
                          className="form-check-input"
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </Block>
        <Block className="model-footer-buttons">
          <RWireButton
            cNameDiv="add-btn_wrapper ps-2"
            buttonCName={`clear-btn`}
            name="Clear"
            onClick={() => handleClear()}
          />
          <RWireButton
            cNameDiv="add-btn_wrapper ps-2"
            buttonCName={`add-btn`}
            name="Apply"
            onClick={() => handleApply()}
          />
        </Block>
      </Block>
    </Modal>
  );
};

export default ReleventResultFeaturesEditModal;
