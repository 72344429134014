import React from "react";

const Image = ({ className = "", src, alt = "", title, onClick, style }) => {
  return (
    <img
      src={src}
      alt={alt}
      title={title}
      className={className}
      onClick={onClick}
      style={style}
    />
  );
};

export default Image;
