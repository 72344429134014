import React, { useRef, useState } from "react";
import CommentMediaUploader from "./media-uploader";
import ContentEditable from "react-contenteditable";
import ListOfUploadedMedia from "./list-of-upload-media";
import { useStore } from "react-redux";
import { uuidv4 } from "../../action/app";
import useClickOutside from "../custom-hooks/useClickoutside";
import {
  getCaretPosition,
  isValidURL,
  removeMismatchedTags,
} from "../functions/report-collab/mention-helpers";

const CommentInput = (props) => {
  const {
    comment,
    setComment,
    uploadedFiles,
    setUploadedFiles,
    commentingRowID,
    onSetfilesToBeRemovedFromDB,
    filesToBeRemovedFromDB,
    users = [],
  } = props;
  const commentEditorRef = useRef(null);
  const listRef = useRef(null);

  const [mentionListPosition, setMentionListPosition] = useState({
    top: 0,
    left: 0,
  });
  const [mentionList, setMentionList] = useState([]);
  const [isMentionListVisible, setIsMentionListVisible] = useState(false);
  const [caretPosition, setCaretPosition] = useState(0);
  const [tempComment, setTempComment] = useState("");
  const store = useStore();
  const {
    app: { userEmailId },
  } = store.getState();
  const handleDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...files]);
  };

  const handleChange = (e) => {
    setComment(removeMismatchedTags(e.target.value));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      const inputStyle = sessionStorage.getItem("styl");
      if (
        inputStyle === "bold" ||
        inputStyle === "italic" ||
        inputStyle === "underline"
      ) {
        document.execCommand(inputStyle);
      }
      sessionStorage.setItem("styl", "nostyle");
    }

    if (e.key === "Enter" || e.code === "Space") {
      setIsMentionListVisible((prev) => {
        if (prev) return false;
        else return false;
      });
    }
    if (e.key === "@") {
      try {
        const selection = window.getSelection();
        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(commentEditorRef.current);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          const caretContainer = document.createElement("div");
          caretContainer.appendChild(preCaretRange.cloneContents());
          setCaretPosition(
            (caretContainer.innerHTML.match(new RegExp("@", "g")) || [])
              .length + 1
          );
        }

        setTimeout(() => {
          const caretPosition = getCaretPosition();
          setMentionListPosition({
            top: caretPosition.top + 20,
            left: caretPosition.left,
          });
          setIsMentionListVisible(true);
          setTempComment(commentEditorRef.current.innerHTML);
        }, 100);
      } catch {
        setIsMentionListVisible(false);
      }
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handlePaste = (e) => {
    const clipboardData = e.clipboardData;
    const items = clipboardData.items;
    const imageItems = [];

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const file = items[i].getAsFile();
        const uniqueName = `${Date.now()}-${Math.random()
          .toString(36)
          .substring(7)}.png`;
        var blob = file.slice(0, file.size, "image/png");
        var newFile = new File([blob], `${uniqueName}.png`, {
          type: "image/png",
        });
        imageItems.push(newFile);
      }
    }

    if (imageItems.length > 0) {
      e.preventDefault();
      setUploadedFiles((prevUploadedFiles) => [
        ...prevUploadedFiles,
        ...imageItems,
      ]);
    } else {
      const text = clipboardData.getData("text/plain");
      if (isValidURL(text)) {
        e.preventDefault();
        const link = document.createElement("a");
        link.href = text;
        link.target = "_blank";
        link.className = "inside-comment-link";
        link.textContent = text;
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(link);
      }
      // else {
      //   setComment((prevComment) => prevComment + text);
      // }
    }
  };

  function insertStringAtIndex(originalString, stringToInsert) {
    try {
      const stringArray = originalString.split("");
      let atIndex = -1;
      for (let i = 0, count = 0; i < stringArray.length; i++) {
        if (stringArray[i] === "@") {
          count++;
          if (count === caretPosition) {
            atIndex = i;
            break;
          }
        }
      }

      if (atIndex === -1) {
        return originalString;
      }

      stringArray.splice(atIndex, 1, ...stringToInsert.split(""));
      const resultString = stringArray.join("");
      return resultString;
    } catch (err) {
      return originalString;
    }
  }
  const handleListClick = (name) => {
    const id = uuidv4();
    setComment(
      insertStringAtIndex(
        tempComment,
        `<a class="user-mention-span mention-span-${id}" title="${name}" href="front/en/report/notes">@${name}</a> `
      )
    );
    setMentionList([...mentionList, name]);
    setIsMentionListVisible(false);
    commentEditorRef.current.focus();
  };
  useClickOutside(listRef, () => setIsMentionListVisible(false));
  return (
    <div
      className="comment-box"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="comment-input">
        <ContentEditable
          id="contentEditable"
          innerRef={commentEditorRef}
          className="input-textarea w-100"
          contentEditable="true"
          onChange={handleChange}
          onPaste={handlePaste}
          html={comment}
          spellCheck="false"
          onKeyDown={handleKeyDown}
          tagName="div"
          placeholder="Type @ to mention and notify someone"
        ></ContentEditable>
        {users && users.length > 0 && isMentionListVisible && (
          <div
            ref={listRef}
            style={{
              position: "fixed",
              top: mentionListPosition.top,
              left: mentionListPosition.left,
            }}
            className="users-list"
          >
            {users
              .filter((user) => user.email !== userEmailId)
              .map((user) => (
                <div
                  className="unselectable single-user"
                  onClick={() => handleListClick(user.userName)}
                >
                  {user.userName}
                </div>
              ))}
          </div>
        )}
        <ListOfUploadedMedia
          uploadedFiles={uploadedFiles}
          setUploadedFiles={setUploadedFiles}
          onSetfilesToBeRemovedFromDB={onSetfilesToBeRemovedFromDB}
          filesToBeRemovedFromDB={filesToBeRemovedFromDB}
        />
      </div>
      <CommentMediaUploader
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        commentingRowID={commentingRowID}
      />
    </div>
  );
};

export default CommentInput;
