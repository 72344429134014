import React from "react";
import { useNavigate } from "react-router-dom";

function CitedLinks(props) {
  const { onRwireSearchAPI, data, index, length, parameter } = props;

  const navigate = useNavigate();

  const handleClick = (shortCode = "", queryString = "") => {
    const query = `${shortCode}=(${queryString})`;
    navigate(`/en/rwire-patents`);
    onRwireSearchAPI(query, { isResetHighlight: true });
  };

  return (
    <span
    // className="pn-hyperlink"
    // onClick={() => {
    //   handleClick(parameter, data);
    // }}
    >{`${data} ${index === length - 1 ? "" : "| "}`}</span>
  );
}

export default CitedLinks;
