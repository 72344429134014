import HighLighter from "./check-word-for-highlight";

const Highlight = (props) => {
  const {
    children,
    isExact,
    highlightedWords,
    component: Component = "div",
  } = props;
  const value = HighLighter(children, highlightedWords, isExact);

  return (
    <Component
      dangerouslySetInnerHTML={{
        __html: typeof children === "number" ? children : value,
      }}
    ></Component>
  );
};

export default Highlight;
