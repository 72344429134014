import React from "react";
import ProjectSharedWithSection from "./project-shared-with-sections";
// import AddExistingUsers from "./add-existing-users";
// import AddUsersByAdmin from "./add-users-by-admin";

const AdminNewAccessPage = (props) => {
  const { projectUsers, existingOrganizationalUsers } = props;

  return (
    <div className="rwire-report-dashboard d-flex">
      <div className={`report-users-page`}>
        <div className="h-100 d-flex flex-column general-user">
          <div className="bottom-section mt-0 p-3">
            <div className="d-flex justify-content-between gap-2 h-100">
              <>
                <div className="users-table-section w-100 gap-1 px-3">
                  <ProjectSharedWithSection
                    headingText="Add Users- Admin Screen"
                    users={[...projectUsers]}
                    {...props}
                  />
                  {/* <AddExistingUsers
                    headingText="Add Existing Users"
                    users={[...existingOrganizationalUsers]}
                    {...props}
                  /> */}
                </div>
                {/* DO NOT REMOVE THIS . will need this part */}
                {/* <AddUsersByAdmin {...props} /> */}
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNewAccessPage;
