import React, { useRef, useState } from "react";
import { Image } from "../../../ui-components/ui-component";
import useClickOutside from "../../../ui-components/custom-hooks/useClickoutside";
import { DownArrow } from "../../../assets/images/report";

const Dropdown = ({
  onChange,
  defaultValue = "",
  placeholder = "",
  disabled = false,
  data,
  menuClassName = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (!disabled && data && data.length > 0) {
      setIsOpen((prevState) => !prevState);
    }
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value.label);
    toggleDropdown();
    onChange(value);
  };

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });
  return (
    <div className={`dropdown internal-dropdown`}>
      <div ref={dropdownRef}>
        <div
          className={`${
            disabled || (data && data.length === 0) ? "disable-dropdown" : ""
          } dropdown-box`}
          onClick={toggleDropdown}
        >
          <button
            className="btn bg-white ellipsis"
            type="button"
            id="multiSelectDropdown"
            disabled={disabled || (data && data.length === 0)}
          >
            {selectedOption
              ? selectedOption
              : defaultValue
              ? defaultValue
              : placeholder}
          </button>
          <Image src={DownArrow} alt="" />
        </div>
        {isOpen && (
          <div
            className={`dropdown-menu end-0 ${menuClassName} ${
              isOpen ? "show" : ""
            }`}
            ariaLabelledby="multiSelectDropdown"
          >
            {data &&
              data.length > 0 &&
              data.map((option) => (
                <div
                  key={option.code}
                  className="form-check form-check-without-icon"
                  onClick={() =>
                    handleOptionChange({
                      label: option.title,
                      code: option.code,
                    })
                  }
                >
                  <label className="form-check-label">{option.title}</label>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
