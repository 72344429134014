export const tableDropdownFieldsMapping = {
  publication_no: ["PN_B", "ALD"],
  patent_information: [
    "PD",
    "AD",
    "EPRD",
    "CA_EN",
    "CA_FR",
    "CA_JP",
    "CA_KR",
    "CA_DE",
    "CA_ES",
    "CA_CN",
    "IN_EN",
    "IN_FR",
    "IN_JP",
    "IN_KR",
    "IN_DE",
    "IN_ES",
    "IN_CN",
    "PNC",
    "PRC",
    "AAPN",
    "AAPS",
    "AAPO",
    "AN_B",
    "AN_O",
    "AN_D",
    "AN_DA",
    "AN_EPO",
    "AN_T",
    "AN_L",
    "PRN_B",
    "PRN_O",
    "PRN_D",
    "PRN_DA",
    "PRN_EPO",
    "PRN_T",
    "PRN_L",
    "PKC",
    "ED",
    "PTS",
    "AO_EN",
    "AO_FR",
    "AO_JP",
    "AO_KR",
    "AO_DE",
    "AO_ES",
    "AO_CN",
    "AS_EN",
    "AS_FR",
    "AS_JP",
    "AS_KR",
    "AS_DE",
    "AS_ES",
    "AS_CN",
    "ANZ_EN",
    "ANZ_FR",
    "ANZ_JP",
    "ANZ_KR",
    "ANZ_DE",
    "ANZ_ES",
    "ANZ_CN",
  ],
  title: ["TI_EN"],
  abstract: ["AB_EN"],
  Claims: ["CL_EN"],
  ipc: ["IPCRD", "IPC"],
  cpc: ["CPC", "CPCD"],
  "US Class": ["USC", "USM"],
  ECL: ["ECL"],
  "JP-FI/F-Terms": ["JCL"],
  "Legal Status": ["LST"],
  "Main Family": ["MF"],
  "Simple Family": ["SF"],
  "Domestic Family": ["DF"],
  "Complete Family": ["CF"],
  "Extended Family": ["EF"],
};

// publication_date: ["PD"],
//   application_date: ["AD"],
//   Earliest_Priority_Date: ["EPRD"],
//   "Current Assignee": [
//     "CA_EN",
//     "CA_FR",
//     "CA_JP",
//     "CA_KR",
//     "CA_DE",
//     "CA_ES",
//     "CA_CN",
//   ],
//   Inventor: ["IN_EN", "IN_FR", "IN_JP", "IN_KR", "IN_DE", "IN_ES", "IN_CN"],
//   // "Jurisdiction": [ "DSEP_CST","DSEP_EST","DSEP_VST","DSEP_PST","DSPCT_RGCN","DSPCT_AOST","DSPCT_RGNCN","DSPCT_NT","DSPCT_NDSCN",],
//   Country: ["PNC"],
//   "Priority Country": ["PRC"],
//   normalized_assignee: ["AAPN"],
//   standardized_assignee: ["AAPS"],
//   assignee_original: ["AAPO"],
//   application_number: [
//     "AN_B",
//     "AN_O",
//     "AN_D",
//     "AN_DA",
//     "AN_EPO",
//     "AN_T",
//     "AN_L",
//   ],
//   priority_number: [
//     "PRN_B",
//     "PRN_O",
//     "PRN_D",
//     "PRN_DA",
//     "PRN_EPO",
//     "PRN_T",
//     "PRN_L",
//   ],
//   "Kind Code": ["PKC"],
//   "Expiry Date": ["ED"],
//   patent_type: ["PTS"],
//   "Assignee/Applicant_Original": [
//     "AO_EN",
//     "AO_FR",
//     "AO_JP",
//     "AO_KR",
//     "AO_DE",
//     "AO_ES",
//     "AO_CN",
//   ],
//   "Assignee/Applicant_Standardized": [
//     "AS_EN",
//     "AS_FR",
//     "AS_JP",
//     "AS_KR",
//     "AS_DE",
//     "AS_ES",
//     "AS_CN",
//   ],
//   "Assignee/Applicant_Normalized": [
//     "ANZ_EN",
//     "ANZ_FR",
//     "ANZ_JP",
//     "ANZ_KR",
//     "ANZ_DE",
//     "ANZ_ES",
//     "ANZ_CN",
//   ],
