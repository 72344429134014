import { Modal } from "rsuite";
import KeywordTable from "../../keyword-suggestions/keyword-table";
import KeywordForm from "../../keyword-suggestions/keyword-form";
import KeywordAction from "../../keyword-suggestions/action-button";
import "../../keyword-suggestions/keyword-suggestions.scss";
import { uuidv4 } from "../../../action/app";
import { useEffect, useState } from "react";
import GenerateKeywords from "../../keyword-suggestions/keywords-generator/generate-keywords";

const ReportKeywordModal = (props) => {
  const {
    isKeywordModalOpen,
    onSetKeywordModal,
    onSearch,
    tableData = [],
    onSetSelectedRows,
    onCloseModal,
    reportTableData,
    selectedKeywordId,
    onAddRowToreportProject,
    onUpdateReportProjectKeywordsRow,
    onGetReportProjectKeywords,
    isSynonymsLoading,
    onSetKeyword,
  } = props;
  const [queryField, setQueryField] = useState({});
  const [count, setCount] = useState({
    title: "5",
    code: 5,
  });
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedKeywordsBox, setSelectedKeywordsBox] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isGenerateKeywordsSection, setIsGenerateKeywordsSection] =
    useState(false);

  useEffect(() => {
    setSelectedKeywords([]);
  }, [isGenerateKeywordsSection]);

  const handleApply = async () => {
    let extraKeywords = [];
    let newReportTableData = [...reportTableData];
    if (selectedKeywordId) {
      const index = newReportTableData.findIndex(
        (item) => item.id === selectedKeywordId
      );
      if (index !== -1) {
        newReportTableData[index].body = `${newReportTableData[index].body} ${
          newReportTableData[index].body ? " | " : ""
        } ${selectedKeywordsBox.join(" | ")}`;
        await onUpdateReportProjectKeywordsRow({
          id: selectedKeywordId,
          body: {
            body: newReportTableData[index].body,
          },
        });
        onSetKeywordModal({
          tableData: [],
        });
        onGetReportProjectKeywords();
      }
    } else {
      const emptyTitleIndices = reportTableData.reduce(
        (indices, item, index) => {
          if (item.title === "") {
            indices.push(index);
          }
          return indices;
        },
        []
      );

      if (emptyTitleIndices.length > 0) {
        selectedKeywordsBox.forEach((title, rowIndex) => {
          const emptyIndex =
            emptyTitleIndices[rowIndex % emptyTitleIndices.length];
          if (emptyIndex !== undefined) {
            newReportTableData[emptyIndex].title = title;
            extraKeywords.push({
              id: newReportTableData[emptyIndex].id,
              title: title,
            });
          }
        });

        await Promise.all(
          extraKeywords.map(async (keyword) => {
            await onUpdateReportProjectKeywordsRow({
              id: keyword.id,
              body: {
                title: keyword.title,
              },
            });
          })
        );
      } else {
        selectedKeywordsBox.forEach((title, index) => {
          extraKeywords.push({
            title: title,
            body: "",
            order: index + 1,
          });
        });
        const _id = sessionStorage.getItem("projectId");
        await onAddRowToreportProject({
          data: extraKeywords,
          projectId: parseInt(_id),
        });
      }
      onSetKeywordModal({
        selectedKeywords: [],
      });

      onGetReportProjectKeywords();
    }

    onSetKeywordModal({
      selectedKeyword: "",
      selectedKeywordId: "",
    });
    onSetKeyword({
      tableData: [],
    });
    setSelectedKeywordsBox([]);
    setIsGenerateKeywordsSection(false);
    setIsSelectAllChecked(false);
    onCloseModal();
  };

  const handleClear = () => {
    setSelectedKeywordsBox([]);
    setSelectedKeywords([]);
    setIsSelectAllChecked(false);
  };

  const handleSelect = ({ name, id }) => {
    onSetKeywordModal({
      selectedKeyword: name,
      selectedKeywordId: id,
    });
  };
  const handleSelectKeywords = (val = []) => {
    setSelectedKeywordsBox(val || []);
  };
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setIsSelectAllChecked(isChecked);

    if (isChecked) {
      const allKeywords = tableData.flatMap((item) =>
        Object.values(item).flat()
      );
      setSelectedKeywordsBox(allKeywords);
    } else {
      setSelectedKeywordsBox([]);
    }
  };
  return (
    <Modal
      className="modalClassFilter keyword-suggestor-modal"
      static="static"
      keyboard={false}
      open={isKeywordModalOpen}
    >
      <div className="modal-title">
        <p className="modal-title-heading">
          {isGenerateKeywordsSection
            ? "Keywords Generator"
            : "Keyword Suggestions"}
        </p>
        <button
          className="blue-primary-button-color ms-auto me-4 mt-auto px-3 py-1"
          onClick={() => setIsGenerateKeywordsSection((prev) => !prev)}
        >
          {isGenerateKeywordsSection
            ? "Keywords Suggestions"
            : "Generate Keywords"}
        </button>
        <p
          className="modal-title-heading"
          onClick={() => {
            handleClear();
            onCloseModal();
            onSetKeyword({
              tableData: [],
            });
            setSelectedKeywordsBox([]);
            setIsSelectAllChecked(false);
            setTimeout(() => {
              setIsGenerateKeywordsSection(false);
            }, 500);
          }}
        >
          X
        </p>
      </div>
      {isGenerateKeywordsSection ? (
        <GenerateKeywords
          {...props}
          setIsGenerateKeywordsSection={setIsGenerateKeywordsSection}
          existingKeyword={reportTableData
            .filter((i) => i.title)
            .map((i) => {
              return {
                title: i.title
                  .replace(/<[^>]*>/g, "")
                  .replaceAll("&nbsp;", " "),
                code: i.id,
              };
            })}
          isReportModal={true}
          onSetKeywordSelector={handleSelectKeywords}
          selectedKeywordsBox={selectedKeywordsBox}
          onSelect={handleSelect}
          onApply={handleApply}
        />
      ) : (
        <div className="classes-suggestor-modal__wrapper">
          <KeywordForm
            onSearch={onSearch}
            onSetQueryField={setQueryField}
            queryField={queryField}
            isSynonymsLoading={isSynonymsLoading}
            count={count}
            onSetCount={setCount}
            existingKeyword={reportTableData
              .filter((i) => i.title)
              .map((i) => {
                return {
                  title: i.title
                    .replace(/<[^>]*>/g, "")
                    .replaceAll("&nbsp;", " "),
                  code: i.id,
                };
              })}
            isReportModal={true}
            onSelect={handleSelect}
            onSetSelectedRows={onSetSelectedRows}
          />
          {isSynonymsLoading ? (
            <>
              <div className="search_reault_wrapper d-flex justify-content-center align-items-center">
                <p>Loading...</p>
              </div>
              <KeywordAction
                isDisable={selectedKeywords.length ? false : true}
                onClear={handleClear}
                onApply={handleApply}
              />
            </>
          ) : (
            <>
              <div className="keyword-suggestion-table">
                <div className="search_reault_wrapper search-text">
                  {!tableData.length ? (
                    <div className="no-records-found">
                      <h3 className="main">
                        Please type in keywords for search
                      </h3>
                    </div>
                  ) : (
                    tableData.map((item) => (
                      <KeywordTable
                        key={uuidv4()}
                        tableData={item}
                        selectedKeywords={selectedKeywordsBox}
                        onSetKeywordSelector={setSelectedKeywordsBox}
                      />
                    ))
                  )}
                </div>
              </div>

              <div className="d-flex mx-2">
                <input
                  className={` cursor-pointer mr-2 mt-2`}
                  type="checkbox"
                  checked={isSelectAllChecked}
                  onChange={handleSelectAll}
                  disabled={tableData.length === 0}
                />
                <span
                  className={`mt-2 select-all-text keyword-footer ${
                    tableData.length > 0 ? "" : "opacity-75"
                  }`}
                >
                  Select All
                </span>
                <KeywordAction
                  isDisable={selectedKeywordsBox.length ? false : true}
                  onClear={handleClear}
                  onApply={handleApply}
                />
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ReportKeywordModal;
