import { fetch } from "../service/fetch";

export const addNewUserToRwire = (body) => async (dispatch) => {
  try {
    let response = "";
    response = await fetch(
      {
        url: `/api/user/create`,
        body,
      },
      "POST"
    );
    return response.id;
  } catch (error) {
    throw error;
  }
};
