import React from "react";
import { useState } from "react";
import RwireJurisdiction from "./rwire-jurisdiction";

function RwirePatentInfoJurisdiction(props) {
  const { row } = props;

  const [jurisdictionToggle, setJurisdictionToggle] = useState(false);

  const handleJurisdictionToggle = (e) => {
    setJurisdictionToggle(!jurisdictionToggle);
  };
  const jurisdiction1 = row._source["DSEP_CST"];
  const jurisdiction2 = row._source["DSEP_EST"];
  const jurisdiction3 = row._source["DSEP_VST"];
  const jurisdiction4 = row._source["DSEP_PST"];
  const jurisdiction5 = row._source["DSPCT_RGCN"];
  const jurisdiction6 = row._source["DSPCT_AOST"];
  const jurisdiction7 = row._source["DSPCT_RGNCN"];
  const jurisdiction8 = row._source["DSPCT_NT"];
  const jurisdiction9 = row._source["DSPCT_NDSCN"];

  return (
    <div
      className="result-page-details d-flex"
      onClick={handleJurisdictionToggle}
    >
      <div className="details-header-result font-weight-bold">
        Jurisdiction:
      </div>
      <div className="patent-info menu">
        <RwireJurisdiction
          jurisdiction={jurisdiction1.concat(
            jurisdiction2,
            jurisdiction3,
            jurisdiction4,
            jurisdiction5,
            jurisdiction6,
            jurisdiction7,
            jurisdiction8,
            jurisdiction9
          )}
          jurisdictionToggle={jurisdictionToggle}
          setJurisdictionToggle={setJurisdictionToggle}
        />
      </div>
    </div>
  );
}

export default RwirePatentInfoJurisdiction;
