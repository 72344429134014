import React, { useEffect, useState } from "react";
import KeyFeatureInputSection from "./keyfeatureinput-section";
import KeyfeaturesSection from "./keyfeatures-section";

const GenerateKeyfeatures = (props) => {
  const [resultKeywords, setResultKeywords] = useState([]);
  return (
    <div className="classes-suggestor-modal__wrapper">
      <div className="keywords-generator-wrapper">
        {/* input section */}
        <KeyFeatureInputSection
          {...props}
          resultKeywords={resultKeywords}
          onSetResultKeywords={setResultKeywords}
        />

        {/* keywords section */}
        <KeyfeaturesSection {...props} tableElements={resultKeywords} />
      </div>
    </div>
  );
};

export default GenerateKeyfeatures;
