import React from "react";

const SingleSummarySection = ({ heading, content }) => {
  return (
    <div className="d-flex flex-column">
      <div className="heading">{heading}</div>
      <div className="summary-content">{content}</div>
    </div>
  );
};

export default SingleSummarySection;
