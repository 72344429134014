import React, { useRef, useState, useEffect } from "react";
import RwireViewPatentInfo from "./rwire-view-patent-info";
import RwireViewImageSection from "./rwire-view-image-section";
import { applyMultipleHighlights, removeTagNames } from "../../action/patent-view";
import { HighlightFromQueryOrManually } from "../../container/highlight";
import RwirePhrasesTable from "./rwire-image-annotations";

function Thirdblock(props) {
  const {
    abstractText = "",
    languageSelected = "EN",
    userAnnotations = [],
  } = props;
  const containerRef = useRef(null);
  const newAbstractText = abstractText
    ? removeTagNames(abstractText).replace(/(<([^>]+)>)/gi, "")
    : "";
  const finalText = applyMultipleHighlights(
    newAbstractText,
    userAnnotations && userAnnotations[`AB_${languageSelected}`]
  );

  const [showAnnotations, setShowAnnotations] = useState(false);
  const [meaningfulPhrases, setMeaningfulPhrases] = useState({});
  const [extractedNumbers, setExtractedNumbers] = useState([]);

  const toggleAnnotations = () => {
    setShowAnnotations((prev) => !prev);
  };

  useEffect(() => {
    setShowAnnotations(false);
  }, [abstractText]);

  return (
    <div className="view-third-block d-flex mt-4 position-relative">
      <div className="abstract-block">
        <h5 className="mb-2">Abstract</h5>
        {newAbstractText ? (
          <p
            ref={containerRef}
            className={`AB_${languageSelected} text-justify highlighted-component-abs`}
          >
            <HighlightFromQueryOrManually>
              {finalText}
            </HighlightFromQueryOrManually>
          </p>
        ) : (
          <p className="pt-2 no_data">Data not available</p>
        )}
      </div>
      <div
        className="annotations-table"
        style={{ display: showAnnotations ? 'block' : 'none' }}
      >
        <span className="float-left fw-bold mb-2 mr-2">Labels List</span>
        <div
          className="close-icon cursor-pointer float-right mb-2"
          onClick={toggleAnnotations}
        >
          X
        </div>
        <RwirePhrasesTable
          meaningfulPhrases={meaningfulPhrases}
          extractedNumbers={extractedNumbers}
        />
      </div>
      <div className="patent-details-block d-flex flex-column">
        <h5 className="mb-2">Patent Information </h5>
        <RwireViewPatentInfo {...props} />
      </div>

      <RwireViewImageSection
        {...props}
        setShowAnnotations={setShowAnnotations}
        showAnnotations={showAnnotations}
        setMeaningfulPhrases={setMeaningfulPhrases}
        setExtractedNumbers={setExtractedNumbers}
      />
    </div>
  );
}

export default Thirdblock;
