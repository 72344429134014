import { patent_detials } from "../../components/constant";
import { search } from "../../service/elasticSearch";
import { fetch } from "../../service/fetch";
import { convertToObjectArray } from "../../ui-components/functions/notes/utils";

export const setReportNotes = (payload) => ({
  type: "SET_NOTES",
  payload: {
    ...payload,
  },
});

export const undoReportNotes = () => ({
  type: "UNDO_NOTES",
});

export const redoReportNotes = () => ({
  type: "REDO_NOTES",
});

export const setPatentClaims = (id) => async (dispatch) => {
  setReportNotes({ isLoadingClaims: true });
  const queryObj = {
    queryToSearch: id,
  };
  const body = JSON.stringify(queryObj);
  const dataResponse = await search(body, patent_detials);
  if (dataResponse.data.hits.hits[0]) {
    const data = {
      ...dataResponse.data.hits.hits[0]._source.CLN,
    };
    return dispatch(
      setReportNotes({
        claimsData: convertToObjectArray(data),
        searchedPatentNumber: dataResponse.data.hits.hits[0]._source.PN_B,
        claimsDataFound: true,
        isLoadingClaims: false,
      })
    );
  } else {
    return dispatch(
      setReportNotes({
        claimsData: [],
        searchedPatentNumber: "",
        claimsDataFound: false,
        isLoadingClaims: false,
      })
    );
  }
};

export const getReportProjectNotes = () => async (dispatch) => {
  try {
    var response = {};
    const id = sessionStorage.getItem("projectId");
    response = await fetch(
      {
        url: `/api/project-key-feature/project/${id}`,
      },
      "GET"
    );
    dispatch(
      setReportNotes({
        reportTableData: response.result.sort((a, b) =>
          parseInt(a.sortOrder) > parseInt(b.sortOrder) ? 1 : -1
        ),
        undoStack: [],
        redoStack: [],
      })
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const updateReportProjectNotesRow =
  ({ id, body }) =>
  async (dispatch) => {
    try {
      await fetch(
        {
          url: `/api/project-key-feature/${id}`,
          body,
        },
        "PUT"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  };

export const updateReportProjectNotesSortOrder = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-key-feature/sort-order`,
        body,
      },
      "POST"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const addRowToReportProject = (body) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-key-feature`,
        body,
      },
      "POST"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const deleteRowFromReportProject = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-key-feature/${id}`,
      },
      "DELETE"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const removeKeyFetureDetail = (id) => async (dispatch) => {
  try {
    await fetch(
      {
        url: `/api/project-key-feature-details/${id}`,
      },
      "DELETE"
    );
  } catch (error) {
    console.log("error>>>", error);
  }
};

export const addKeyFeatureDetailsHighlight = (body) => async (dispatch) => {
  body?.activeKeyFeatureIds?.forEach(async (element) => {
    try {
      await fetch(
        {
          url: `/api/project-key-feature-details`,
          body: {
            data: [
              {
                body: body.text,
                highlight: body.highlight,
              },
            ],
            keyFeatureId: element,
            patentNumber: body.publication_number,
          },
        },
        "POST"
      );
    } catch (error) {
      console.log("error>>>", error);
    }
  });
  const id = sessionStorage.getItem("projectId");
  await fetch(
    {
      url: `/api/project-relavent-key-feature`,
      body: {
        patentNumber: body.publication_number,
        addKeyFeaturesId: body?.activeKeyFeatureIds,
        projectId: id,
      },
    },
    "POST"
  );
};
