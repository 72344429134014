import React, { useRef } from "react";
import {
  applyMultipleHighlights,
  transformedString,
} from "../../action/patent-view";
import { HighlightFromQueryOrManually } from "../../container/highlight";
import { convertSubscriptTags } from "./desc-block";

function SingleClaim({ claim, userAnnotations, claimNum, claimNo = "" }) {
  const containerRef = useRef(null);
  const claimClass = `highlighted-component-cl${claimNum}`;

  // to process subscript tags
  const processedClaim = convertSubscriptTags(claim);

  const finalText = applyMultipleHighlights(
    transformedString(processedClaim),
    userAnnotations && userAnnotations[`${claimNo}`]
  );

  return (
    <li
      className="d-flex mb-2 pe-2 single-claim-li"
      key={claimNum}
      style={{ display: "flex", alignItems: "baseline" }}
    >
      <div style={{ fontWeight: 700, paddingRight: "6px" }}>
        <span>{claimNum}.</span>
      </div>
      {claim && (
        <div
          ref={containerRef}
          className={`${claimNo} ${claimClass}`}
          style={{ flex: 1, marginLeft: "6px" }}
        >
          <HighlightFromQueryOrManually>
            {finalText}
          </HighlightFromQueryOrManually>
        </div>
      )}
    </li>
  );
}

export default SingleClaim;
