import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ACCESS_COMMENTOR,
  ACCESS_EDITOR,
  ACCESS_VIEWER,
  ROLE_OWNER,
} from "../../../common/user-roles";
import {
  DownArrowSolid,
  addWithWhiteBgIcon,
} from "../../../assets/images/report";

const SharedWithCell = (props) => {
  const { project, userProjectAccess } = props;
  const [showAllUsers, setShowAllUsers] = useState(false);
  const navigate = useNavigate();
  const handleNavigateToUsersPage = (id) => {
    sessionStorage.setItem("projectIdForUsers", id);
    navigate("/en/report/users");
  };
  const handleClick = () => {
    setShowAllUsers(!showAllUsers);
  };
  const getAccessCard = (access) => {
    switch (access) {
      case ACCESS_EDITOR:
        return (
          <span className="access-tab" style={{ background: "#59E6F64D" }}>
            Editor
          </span>
        );
      case ACCESS_COMMENTOR:
        return (
          <span className="access-tab" style={{ background: "#638DFF4D" }}>
            Commentor
          </span>
        );
      case ACCESS_VIEWER:
        return (
          <span className="access-tab" style={{ background: "#FFB3424D" }}>
            Viewer
          </span>
        );

      default:
        return null;
    }
  };
  return (
    <div className="d-flex">
      <div>
        {project &&
          project.users &&
          project.users.length > 0 &&
          // eslint-disable-next-line array-callback-return
          project.users.map((user, index) => {
            if (!showAllUsers && index === 0) {
              return project?.users?.[0].email;
            } else if (showAllUsers) {
              return (
                <div className="d-flex justify-content-between align-items-center gap-2">
                  <div>{user.email}</div>
                  <div>{getAccessCard(user.access)}</div>
                </div>
              );
            }
          })}
      </div>
      <span className="more-users-btn">
        <div className="d-flex">
          {project?.users && project.users.length > 0 && (
            <div className="d-flex" onClick={handleClick}>
              {!showAllUsers && project.users.length > 1 && (
                <>
                  <span>+</span>
                  <span>{(project.users.length - 1).toString()}</span>
                </>
              )}
              <img
                src={DownArrowSolid}
                alt=""
                title={`${
                  !showAllUsers ? "Click to view all users" : "Click to close"
                }`}
                className={`${showAllUsers ? "rotate-180" : "rotate-0"} arrow`}
              />
            </div>
          )}
          {project.role === ROLE_OWNER && (
            <img
              onClick={() => handleNavigateToUsersPage(project?.id)}
              src={addWithWhiteBgIcon}
              alt=""
              title="Click to view more users"
            />
          )}
        </div>
      </span>
    </div>
  );
};

export default SharedWithCell;
