import React from "react";
import "./styles.scss";
import { Toggle } from "rsuite";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../../ui-components";

const ReportModeToggle = (props) => {
  const { onSetApp, isProjectMode } = props;
  const navigate = useNavigate();

  const handleChange = (value) => {
    const _id = sessionStorage.getItem("projectId");
    if (_id) {
      sessionStorage.setItem("projectMode", value);
      onSetApp({ isProjectMode: value });
      if (!value) {
        sessionStorage.removeItem("projectId");
        navigate("/en");
      }
    }
  };

  return (
    <div className="d-flex">
      <div
        className="report-mode-toggle"
        title={`Project Mode: ${isProjectMode ? "ON" : "OFF"}`}>
        <Toggle
          size="lg"
          checkedChildren="Project Mode"
          unCheckedChildren="Normal Mode"
          checked={isProjectMode}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default ReportModeToggle;
