import React, { useRef } from "react";
import { applyMultipleHighlights } from "../../action/patent-view";
import { HighlightFromQueryOrManually } from "../../container/highlight";

function RwireViewModalHeader(props) {
  const {
    detailsData,
    onSetFilter,
    languageSelected,
    userAnnotations,
    onClaimMapping,
    onSetSlidePanel,
  } = props;
  const containerRef = useRef(null);
  const titleText = Array.isArray(detailsData[`TI_${languageSelected}`])
    ? detailsData[`TI_${languageSelected}`][0]
    : detailsData[`TI_${languageSelected}`];
  const finalText = applyMultipleHighlights(
    titleText,
    userAnnotations && userAnnotations[`TI_${languageSelected}`]
  );

  const projectId = sessionStorage.getItem("projectId");

  const handleClose = () => {
    onSetFilter(
      {
        isViewPageModalOpen: false,
        isSmartReaderOpen: false,
      },
      onSetSlidePanel(false)
    );
  };
  return (
    <div className="view-modal-header d-flex flex-column">
      <div className="d-flex justify-content-between">
        <div className="pub-no d-flex">
          <div>
            {detailsData[`PN_B`] ? (
              detailsData[`PN_B`]
            ) : (
              <p>Data not available</p>
            )}
          </div>
          {detailsData.ALD && (
            <>
              <span className="pub-no-dot"></span>
              <div
                className={`${
                  detailsData.ALD.toLowerCase() === "dead"
                    ? "pub-status-red "
                    : "pub-status-green"
                }`}
              >
                {detailsData.ALD.toUpperCase()}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {detailsData[`TI_${languageSelected}`] ? (
          <div
            ref={containerRef}
            className={`TI_${languageSelected} patent-title highlighted-component--modal-title`}
          >
            <HighlightFromQueryOrManually>
              {finalText}
            </HighlightFromQueryOrManually>
          </div>
        ) : (
          <p>Data not available</p>
        )}
        <button className="view-page-btn px-3 py-1" onClick={handleClose}>
          Default view
        </button>
        {projectId && (
          <button
            className="smart-reader-claim-mapping"
            onClick={onClaimMapping}
          >
            Claim Mapping
          </button>
        )}
      </div>
    </div>
  );
}

export default RwireViewModalHeader;
