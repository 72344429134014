import { fetch } from "../../service/fetch";
import { search } from "../../service/elasticSearch";
import { chart_data } from "../../components/constant";

export const setReportDashboard = (payload) => ({
  type: "SET_REPORT_DASHBOARD",
  payload: {
    ...payload,
  },
});

export const getProjectDashboard = (projectId) => async (dispatch) => {
  try {
    const data = await fetch(
      {
        url: `/api/project-dashboard/${projectId}`,
      },
      "GET",
    );

    dispatch(setReportDashboard({ ...data }));
  } catch (e) { }
};

export const getReportProjectString = (projectId) => async (dispatch) => {
  try {
    const { result: data } = await fetch(
      {
        url: `/api/project-strings/project/${projectId}`,
      },
      "GET",
    );
    const queryString = data.filter((i) => i.body).map((i) => i.body);
    const queryObj = {
      queryToSearch: queryString.join(" OR "),
      isNumberWithIncludeSearch: false,
      selectedIncludes: [],
      filters: [],
      field1: "PNC",
      field2: "PN_B",
      isMultiSeries: false,
      orderBy: "desc",
      sortBy: "_key",
    };

    const deadAliveParams = {
      queryToSearch: queryString.join(" OR "),
      isNumberWithIncludeSearch: false,
      selectedIncludes: [],
      filters: [],
      field1: "ALD",
      field2: "PN_B",
      isMultiSeries: false,
      orderBy: "desc",
      sortBy: "_key",
    };

    const wordCloudChartParams = {
      queryToSearch: queryString.join(" OR "),
      isNumberWithIncludeSearch: false,
      selectedIncludes: [],
      filters: [],
      field1: "PCL",
      field2: "PN_B",
      isMultiSeries: false,
      orderBy: "desc",
      sortBy: "_key",
      topNumber: "50"
    };

    const inventorParams = {
      queryToSearch: queryString.join(" OR "),
      isNumberWithIncludeSearch: false,
      selectedIncludes: [],
      filters: [],
      field1: "IN_EN",
      field2: "PN_B",
      orderBy: "desc",
      sortBy: "_key"
    };

    const body = JSON.stringify(queryObj);
    try {
      const { data: dataResponse } = await search(body, chart_data);
      const { data: response } = await search(deadAliveParams, chart_data);
      const { data: wordCloudChartResponse } = await search(
        wordCloudChartParams,
        chart_data,
      );
      const { data: inventorChartResponse } = await search(
        inventorParams,
        chart_data,
      );
      dispatch(
        setReportDashboard({
          geographicChartData: dataResponse,
          deadAliveChartData: response,
          wordCloudChartData: wordCloudChartResponse,
          inventorChartData: inventorChartResponse
        }),
      );
    } catch (e) {
      dispatch(
        setReportDashboard({
          graphError: e.message,
        }),
      );
    }
  } catch (e) { }
};
