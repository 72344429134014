const initialState = {
  itemsPerPage: 10,
  totalPage: 1,
  page: 1,
  limit: 10,
  totalRecordsCount: 0,
  data: [],
  selectedItemList: [],
  filterFileType: "",
  filterDate: "",
  filterSearch: ""
};

const downloadCenter = (state = initialState, { type, payload }) => {
  switch (type) {
    case "DOWNLOAD_CENTER_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default downloadCenter;
