import { Modal } from "rsuite";
import ClassesTable from "../../../classes-suggestions/clsses-table";
import ClassesForm from "../../../classes-suggestions/classes-form";
import ClassesActions from "../../../classes-suggestions/classes-action";
import "../../../classes-suggestions/classes-suggestions.scss";
import React, { useState } from "react";

const ReportClassesModal = (props) => {
  const {
    isClassModalOpen,
    onSetClassesModal,
    onClassesSearch,
    clasessData = [],
    selectedItemList,
    selectedRows,
    onSetItemPerPage,
    onSetSelectedRows,
    onSetClassesSelector,
    classesType,
    formData,
    onCloseModal,
    onGetPagination,
    totalRecordsCount,
    itemsPerPage,
    page,
    onSetPage,
    totalPage,
    selectedRowsWithCodeAndDefination,
    onAddRowToreportProject,
    onGetReportProjectClasses,
    isNoResultFound,
  } = props;

  const [isSearching, setIsSearching] = useState(false);

  const handleApply = async () => {
    // const lastId = findGreatestIdFromClasses({ ...reportTableData });
    // let updatedTableData = selectedRowsWithCodeAndDefination.map((i, index) => {
    //   return {
    //     title: i.code,
    //     body: i.definition,
    //     id: lastId + index + 1,
    //     order: index + 1,
    //   };
    // });

    // onSetClassesSelector({
    //   reportTableData: {
    //     ...reportTableData,
    //     [classesType]: [...updatedTableData, ...reportTableData[classesType]],
    //     selectedRowsWithCodeAndDefination: [],
    //   },
    // });
    // updatedTableData = updatedTableData.map((data) => {
    //   return {
    //     classes: data.title,
    //     body: data.body,
    //     order: data.order,
    //   };
    // });
    const _id = sessionStorage.getItem("projectId");
    let updatedTableData = selectedRowsWithCodeAndDefination.map(
      (data, index) => {
        return {
          classes: data.code,
          body: data.definition,
          order: index + 1,
        };
      }
    );
    await onAddRowToreportProject({
      type: classesType.toUpperCase(),
      data: updatedTableData,
      projectId: parseInt(_id),
    });
    await onGetReportProjectClasses();
    onCloseModal();
  };

  return (
    <Modal
      className="modalClassFilter classes-suggestor-modal"
      static="static"
      keyboard={false}
      open={isClassModalOpen}
      onClose={onCloseModal}
    >
      <div className="modal-title">
        <p className="modal-title-heading">Classes Suggestions</p>
        <p className="modal-title-heading" onClick={onCloseModal}>
          X
        </p>
      </div>
      <div className="classes-suggestor-modal__wrapper">
        <ClassesForm
          onClassesSearch={onClassesSearch}
          classesType={classesType}
          onSetClassesSelector={onSetClassesSelector}
          onSetFormData={onSetClassesModal}
          formData={formData}
          setIsSearching={setIsSearching}
          isSearching={isSearching}
        />
        <ClassesTable
          tableData={clasessData}
          selectedRows={selectedRows}
          onSetSelectedRows={onSetSelectedRows}
          selectedItemList={selectedItemList}
          onSetItemPerPage={onSetItemPerPage}
          onSetClassesSelector={onSetClassesSelector}
          selectedRowsWithCodeAndDefination={selectedRowsWithCodeAndDefination}
          isSearching={isSearching}
          isNoResultFound={isNoResultFound}
        />
        <ClassesActions
          tableData={clasessData}
          onSetItemPerPage={onSetItemPerPage}
          onGetPagination={onGetPagination}
          totalRecordsCount={totalRecordsCount}
          itemsPerPage={itemsPerPage}
          page={page}
          onSetClassesModal={onSetClassesModal}
          selectedRows={selectedRows}
          onSetPage={onSetPage}
          onClassesSearch={onClassesSearch}
          onSetClassesSelector={onSetClassesSelector}
          onApply={handleApply}
          totalPage={totalPage}
        />
      </div>
    </Modal>
  );
};

export default ReportClassesModal;
