const CharacterStyleMenu = ({ handleAction }) => {
  return (
    <div className="capitalize-dropdown">
      <div
        className="border-bottom capitalize-btns unselectable"
        onClick={(e) => {
          const selection = window.getSelection();
          handleAction(e, "insertText", selection.toString().toUpperCase());
        }}
      >
        UPPERCASE
      </div>
      <div
        className="capitalize-btns unselectable"
        onClick={(e) => {
          const selection = window.getSelection();
          handleAction(e, "insertText", selection.toString().toLowerCase());
        }}
      >
        lowercase
      </div>
    </div>
  );
};

export default CharacterStyleMenu;
