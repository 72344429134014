import React, { useEffect, useState } from "react";
import KeyfeaturesTable from "./keyfeatures-table";
import FieldSelectDropdown from "./field-select-dropdown";
import { keywordFieldsOptions } from "../../../resources/data/options";
import { uuidv4 } from "../../../action/app";
import { Message, toaster } from "rsuite";
const KeyfeaturesSection = (props) => {
  const {
    tableElements,
    onSetApp,
    onSetAllField,
    onSetClassesModal,
    onSetKeywordModal,
    setIsGenerateKeywordsSection,
    existingKeyword,
    isReportModal,
    onSelect,
    onApply,
    selectedKeywordsBox,
    onSetKeywordSelector,
    currentFieldedCursorLocation,
    queryFields,
    isEditModalOpen,
    localQueryFields,
    isKeywordsGeneratorLoading,
    onAddKeyFeatureToReportProject,
    onGetReportProjectNotes,
  } = props;
  const [queryField, setQueryField] = useState({
    title: "Title",
    code: "TI",
  });
  const isProjectMode = sessionStorage.getItem("projectMode");
  const stringToCopy = selectedKeywordsBox.join("\n");
  const handleApply = async () => {
    if (!sessionStorage.getItem("projectId")) return;
    try {
      const dataArray = selectedKeywordsBox;
      const apiBody = {
        data: [],
        projectId: parseInt(sessionStorage.getItem("projectId")),
      };

      // Iterate over the array elements and construct the object
      for (let i = 0; i < dataArray.length; i++) {
        apiBody.data.push({
          body: dataArray[i],
          order: i + 1,
        });
      }
      await onAddKeyFeatureToReportProject(apiBody);
      onGetReportProjectNotes();
      toaster.push(
        <Message type="success">
          Key features added in project successfully.
        </Message>
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }

    onSetKeywordModal({
      isOpenKeywordSuggestorModal: false,
      tableData: [],
    });
    onSetKeywordSelector([]);
    setIsGenerateKeywordsSection(false);
  };

  const handleClose = () => {
    onSetKeywordModal({
      isOpenKeywordSuggestorModal: false,
      tableData: [],
    });
    onSetKeywordSelector([]);
    setIsGenerateKeywordsSection(false);
  };

  const copyToClipboard = async (code) => {
    if (navigator.clipboard?.writeText) {
      try {
        await navigator.clipboard.writeText(code);
      } catch (e) {
        console.error("Error while copying code", e);
      }
    }
  };

  return (
    <div className="keywords-result-section mt-2 d-flex flex-column flex-grow-1">
      <div className="table-heading">
        <div className="fw-bold">Key Features</div>
      </div>
      <KeyfeaturesTable
        options={tableElements}
        selectedRows={selectedKeywordsBox}
        onSetSelectedRows={onSetKeywordSelector}
        isKeywordsGeneratorLoading={isKeywordsGeneratorLoading}
      />
      <div className="d-flex justify-content-end mt-auto action-buttons">
        {isProjectMode === "true" ? (
          <button
            className={`yellow-primary-button-color ${
              selectedKeywordsBox.length > 0 ? "" : "opacity-75"
            }`}
            onClick={() => onSetKeywordSelector([])}
            disabled={selectedKeywordsBox.length < 1}
          >
            Clear
          </button>
        ) : (
          <button
            className={`yellow-primary-button-color `}
            onClick={handleClose}
          >
            Close
          </button>
        )}
        {isProjectMode === "true" ? (
          <button
            className={`blue-primary-button-color  ${
              selectedKeywordsBox.length > 0 ? "" : "opacity-75"
            }`}
            onClick={handleApply}
            disabled={selectedKeywordsBox.length < 1}
          >
            Apply
          </button>
        ) : (
          <button
            className={`blue-primary-button-color`}
            onClick={() => copyToClipboard(stringToCopy)}
          >
            Copy
          </button>
        )}
      </div>
    </div>
  );
};

export default KeyfeaturesSection;
