import React, { useEffect, useState } from "react";
import "./styles.scss";
import FeaturesFiltersTable from "./features-filters-table";
import RelevantResultsTable from "./relevant-results-table";
import ReleventResultFeaturesEditModal from "./relevent-result-features-edit-modal";

const RwireReportRelevantResults = (props) => {
  const {
    onGetReportProjectRelevantResultFromPN,
    onGetReportProjectNotes,
    onSetReportRelevantResults,
    reportTableData,
  } = props;
  const projectId = sessionStorage.getItem("projectId");
  const [userInput, setUserInput] = useState({
    selectedFeatures: [],
    relevantResultId: 0,
    isModalOpen: false,
    isShowFilter: false,
  });
  const [isGenerateListCalled, setIsGenerateListCalled] = useState(false);
  
  useEffect(() => {
    onGetReportProjectNotes({ projectId });
    onGetReportProjectRelevantResultFromPN({ projectId, selectedId: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleShowAll = () => {
    onGetReportProjectRelevantResultFromPN({ projectId, selectedId: [] });
    setUserInput({
      ...userInput,
      selectedFeatures: [],
    });
  };

  const handleModalDetails = (row) => {
    const selectedId = row.projectRelevantResultKeyFeatureData.map(
      (i) => i.keyFeature
    );
    onSetReportRelevantResults({
      selectedRows: selectedId,
    });
    setUserInput({
      ...userInput,
      isModalOpen: true,
      relevantResultId: row.id,
    });
  };

  const handleCloseModal = () => {
    setUserInput({ ...userInput, isModalOpen: false, relevantResultId: 0 });
  };

  let selectedId = [];
  const handleCheckbox = (e, id) => {
    const selectedItem = id;
    selectedId = [...userInput.selectedFeatures, selectedItem];
    if (!e.target.checked) {
      selectedId = selectedId.filter((item) => {
        return `${item}` !== `${id}`;
      });
    }
    setUserInput({
      ...userInput,
      selectedFeatures: selectedId,
    });
  };

  const handleGenerateList = () => {
    onGetReportProjectRelevantResultFromPN({
      projectId,
      selectedId: userInput.selectedFeatures,
    });
    setIsGenerateListCalled(true);
  };

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      const allIds = reportTableData.map((row) => row.id);
      setUserInput({
        ...userInput,
        selectedFeatures: allIds,
      });
    } else {
      setUserInput({
        ...userInput,
        selectedFeatures: [],
      });
    }
  };
  
  const handleClearFilters = () => {
    setUserInput({
      ...userInput,
      selectedFeatures: [],
    });
    onGetReportProjectRelevantResultFromPN({
      projectId,
      selectedId: [],
    });
  };
  return (
    <div className="relevant-results-section" style={{ padding: "10px" }}>
      <div>
        <FeaturesFiltersTable
          {...props}
          selectedRows={userInput.selectedFeatures}
          onChange={handleCheckbox}
          onGenerateList={handleGenerateList}
          onShowAll={handleShowAll}
          userInput={userInput}
          onCheckAll={handleCheckAll}
          setUserInput={setUserInput}
          onClearFilters={handleClearFilters}
        />
      </div>
      <RelevantResultsTable
        selectedFeatures={userInput.selectedFeatures}
        {...props}
        onHandleModal={handleModalDetails}
        userInput={userInput}
        setUserInput={setUserInput}
        isGenerateListCalled={isGenerateListCalled}
      />
      {userInput.isModalOpen && (
        <ReleventResultFeaturesEditModal
          isModalOpen={userInput.isModalOpen}
          onCloseModal={handleCloseModal}
          userInput={userInput}
          projectId={projectId}
          setUserInput={setUserInput}
          {...props}
        />
      )}
    </div>
  );
};

export default RwireReportRelevantResults;
