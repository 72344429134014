import React from "react";
import "../../../assets/css/columns.scss";
import { HighlightFromQueryOrManually } from "../../../container/highlight";

const RwireFamiliesTdComponent = (props) => {
  const { tdClassName = "", families = "" } = props;
  const handleClick = () => {};
  return (
    <td className={tdClassName}>
      <div>
        {families &&
          families.length > 0 &&
          families.map((family) => (
            <div className="fw-bold result-table-family" onClick={handleClick}>
              <HighlightFromQueryOrManually>
                {family}
              </HighlightFromQueryOrManually>
            </div>
          ))}
      </div>
    </td>
  );
};

export default RwireFamiliesTdComponent;
