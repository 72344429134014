import React from 'react';

function RWirefooter() {

  return (
    <div className='footer'>
      {/* <p>Select language</p>
    <button onClick={changeLanguage} value='en'>English</button>
    <button onClick={changeLanguage} value='chi'>Chinese</button>
    <button onClick={changeLanguage} value='ko'>Korean</button> */}
  </div>
  );
}
export default RWirefooter;
