import React, { useState } from "react";
import { Input, InputGroup } from "rsuite";
import CustomizeListItem from "./customize-list";
import { Trans, useTranslation } from "react-i18next";
import CloseIcon from "@rsuite/icons/Close";

const RwireCustomize = (props) => {
  const { highlightedWords = [], onSetHighlight } = props;

  const [scrolled, setScrolled] = useState(true);
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const { t } = useTranslation();
  const handleKeywordChange = (e) => {
    setSearchedKeyword(e.target.value);
  };
  return (
    <>
      <div className="d-flex justify-content-between">
        <Trans>
          <div className="customize-heading">{t("highlight_keywords")}</div>
        </Trans>
        <div
          className="cursor-pointer"
          title="Close"
          onClick={() =>
            onSetHighlight({ isVisibleCustomiseHighlightModal: false })
          }>
          <CloseIcon />
        </div>
      </div>
      <div className="d-flex customize-card-spacing">
        <div className="customize mr-3">
          <div className="pl-2 pr-4 py-1 d-flex justify-content-between list-upper-section">
            <Trans>
              <div className="keywords-list-heading">{t("query_keyword")}</div>
            </Trans>
            <Trans>
              <div className="keywords-list-heading"> {t("color")}</div>
            </Trans>
          </div>
          <div>
            <ul
              className="list-unstyled w-100 keywords-list"
              onScroll={() => setScrolled(!scrolled)}>
              {highlightedWords
                .filter(
                  (i) =>
                    i.isQueryWord &&
                    i.word
                      .toLowerCase()
                      .includes(searchedKeyword.toLowerCase()),
                )
                .map((item, key) => (
                  <CustomizeListItem
                    key={key}
                    word={item.word}
                    wordColor={item.color}
                    onSetHighlight={onSetHighlight}
                    scrolled={scrolled}
                    highlightedWords={highlightedWords}
                  />
                ))}
            </ul>
          </div>
        </div>
        <div className="customize ml-3">
          <div className="pl-2 pr-4 py-1 d-flex justify-content-between border-bottom border-secondary">
            <Trans>
              <div className="keywords-list-heading">{t("other_keyword")} </div>
            </Trans>
            <Trans>
              <div className="keywords-list-heading"> {t("color")}</div>
            </Trans>
          </div>
          <ul
            className="list-unstyled keywords-list"
            onScroll={() => setScrolled(!scrolled)}>
            {highlightedWords
              .filter(
                (i) =>
                  !i.isQueryWord &&
                  i.word.toLowerCase().includes(searchedKeyword.toLowerCase()),
              )
              .map((item, key) => (
                <CustomizeListItem
                  key={key}
                  word={item.word}
                  wordColor={item.color}
                  onSetHighlight={onSetHighlight}
                  scrolled={scrolled}
                  highlightedWords={highlightedWords}
                />
              ))}
          </ul>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <InputGroup value={searchedKeyword} onChange={handleKeywordChange}>
            <Input placeholder="Search a keyword" />
          </InputGroup>
        </div>
      </div>
    </>
  );
};

export default RwireCustomize;
