import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Message, toaster } from "rsuite";
import {
  addHistory,
  constructQueries,
  getHistoryDetailsById,
  getQueryCount,
  updateHistoryRecord,
} from "../../action/history";
import RWIRE_IMAGES from "../common/common-functions/rwire-images";
import RWireButton from "../rwire-ui/rwire-button/rwire-button";

export const RwireCombineQuery = (props) => {
  const {
    selectedRowsForCombine,
    selectedItemList,
    onSetApp,
    onPerformSearch,
    onFetchHistory,
    onSetExportSelected,
    onSetSelectedRows,
    onSetItemPerPage,
    selectedCombineQuery,
    selectedCombineQueryId,
  } = props;
  const [fullQuery, setFullQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };
  useEffect(() => {
    if (selectedCombineQuery) {
      setFullQuery(selectedCombineQuery);
    } else if (selectedRowsForCombine.length === 1) {
      setFullQuery(selectedRowsForCombine[0]);
    } else if (selectedRowsForCombine.length > 1) {
      setErrorText("");
      setFullQuery(
        fullQuery +
        " OR " +
        selectedRowsForCombine[selectedRowsForCombine.length - 1]
      );
      setFullQuery(selectedRowsForCombine.join(" OR "));
    } else {
      setErrorText("");
      setFullQuery("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowsForCombine, selectedCombineQuery]);
  const handleChange = (str) => {
    setFullQuery(str);
  };

  const handleSearch = () => {
    if (selectedRowsForCombine.length === 1) {
      setErrorText(" Please select minimum 2 queries for combine");
    } else if (fullQuery) {
      let numbers = fullQuery.match(/\d+/g);
      let operators = fullQuery.match(/(OR|AND|NOT)/gim);
      let searchQuery = [];
      let queryLists = {};
      selectedItemList.forEach((query) => {
        queryLists = { ...queryLists, [query.userSearchNumber]: query.query };
      });
      let invalidFlag = false;
      // eslint-disable-next-line array-callback-return
      numbers.map((id, index) => {
        if (selectedRowsForCombine.includes(id)) {
          // eslint-disable-next-line no-useless-concat
          let realQuery =
            "(" +
            queryLists[id] +
            ")" +
            " " +
            (operators ? (operators[index] ? operators[index] : "") : "");
          searchQuery.push(
            realQuery.replaceAll("( ", "(").replaceAll(" )", ")")
          );
        } else {
          invalidFlag = true;
          // eslint-disable-next-line no-console
          toaster.push(
            <Message type="error">
              Please enter search query number from selected list only !
            </Message>
          );
        }
      });
      searchQuery = searchQuery.join(" ");
      if (!invalidFlag) {
        onSetApp({ searchQuery: searchQuery });
        onPerformSearch(
          searchQuery,
          { isResetHighlight: true },
          navigate,
          "/en/rwire-patents"
        ).then((data) => {
          if (data) {
            onSetItemPerPage({ selectedRowsForCombine: [] });
            onSetSelectedRows({ selectedRows: [] });
            onSetExportSelected({ selectedItemList: [] });
          } else {
            // eslint-disable-next-line no-console
            console.log(props.error);
          }
        });
      }
    }
  };
  const handleSave = async () => {
    if (selectedCombineQuery) {
      const { query, numberOfHits, operators, ids } =
        await getUpdatedCombinedQuery();
      await updateHistoryRecord(selectedCombineQueryId, {
        query: query,
        numberOfHits: numberOfHits,
        operators: operators,
        combineId: ids,
      });
      await onSetApp({
        queryEvaluatorText: query,
        queryEvaluatorExpertSearchError: "",
      });
      setTimeout(async () => {
        await onFetchHistory();
      }, 1000);
    } else if (selectedRowsForCombine.length === 1) {
      setErrorText(" Please select minimum 2 queries for combine");
    } else if (fullQuery) {
      let numbers = fullQuery.match(/\d+/g);
      let operators = fullQuery.match(/(OR|AND|NOT)/gim);
      let searchQuery = [];
      let queryLists = {};
      selectedItemList.forEach((query) => {
        queryLists = { ...queryLists, [query.userSearchNumber]: query.query };
      });
      let invalidFlag = false;
      let finalNumbers = [];
      // eslint-disable-next-line array-callback-return
      numbers.map((id, index) => {
        if (selectedRowsForCombine.includes(id)) {
          finalNumbers.push(id);
          // eslint-disable-next-line no-useless-concat
          let realQuery =
            "(" +
            queryLists[id] +
            ")" +
            " " +
            (operators ? (operators[index] ? operators[index] : "") : "");
          searchQuery.push(
            realQuery.replaceAll("( ", "(").replaceAll(" )", ")")
          );
        } else {
          invalidFlag = true;
        }
      });
      searchQuery = searchQuery.join(" ");
      if (!invalidFlag) {
        const resultTotal = await getQueryCount(searchQuery);
        const ids = [];
        selectedItemList.forEach((obj) => {
          if (finalNumbers.includes(`${obj.userSearchNumber}`)) {
            ids.push(obj.id);
          }
        });
        await addHistory({
          query: searchQuery,
          numberOfHits: resultTotal.count,
          operators: operators,
          combineId: ids,
        });

        await onSetApp({
          queryEvaluatorText: searchQuery,
          queryEvaluatorExpertSearchError: "",
        });

        setTimeout(async () => {
          await onFetchHistory();
        }, 1000);
      } else {
        toaster.push(
          <Message type="error">
            Please enter search query number from selected list only !
          </Message>
        );
      }
    }
  };

  const handleSaveAsNew = async () => {
    try {
      const { query, numberOfHits, operators, ids } =
        await getUpdatedCombinedQuery();

      if (!query) {
        // Handle the case where getUpdatedCombinedQuery returns null (indicating an error)
        throw new Error("Failed to get updated combined query.");
      }

      await addHistory({
        query: query,
        numberOfHits: numberOfHits,
        operators: operators,
        combineId: ids,
      });

      await onSetApp({
        queryEvaluatorText: query,
        queryEvaluatorExpertSearchError: "",
      });

      setTimeout(async () => {
        await onFetchHistory();
      }, 1000);
    } catch (error) {
      // Handle errors
      console.error("Error occurred while saving as new:", error.message);
    }
  };

  const getUpdatedCombinedQuery = async () => {
    try {
      let numbers = fullQuery.match(/\d+/g);
      let operators = fullQuery.match(/(OR|AND|NOT)/gim);

      if (!numbers || !operators) {
        throw new Error(
          "Invalid query format. Unable to extract numbers or operators."
        );
      }

      // Fetch the history details for the given numbers
      let detailsArray = await getHistoryDetailsById({ ids: numbers });

      const query = constructQueries(numbers, operators, detailsArray);

      if (query[2].includes(selectedCombineQueryId)) {
        alert("Can't add current query in combine query.");
        throw new Error("Can't add current query in combine query.");
      }

      const resultTotal = await getQueryCount(query[1]);

      return {
        query: query[1],
        numberOfHits: resultTotal.count,
        operators: operators,
        ids: query[2],
      };
    } catch (error) {
      // Handle errors
      console.error(
        "Error occurred while getting updated combined query:",
        error.message
      );
      return null;
    }
  };

  return (
    <>
      <div className="combine_section">
        <div
          className={`combine_tooltip ${isFocused ? "focused_tooltip" : "blurred_tooltip"
            } `}
        >
          Available Operators: AND | OR | NOT
        </div>
        <Input
          as="textarea"
          rows={7}
          className={`combine_textarea ${isFocused ? "focused_combine" : "blurred_combine"
            } `}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={fullQuery}
          onChange={handleChange}
        />
        <div className="d-flex">
          <div className="combine-error">{errorText}</div>
        </div>
        <div className="d-flex justify-content-end">
          <div className="combine-buttons">
            <div className="clearall search-query">
              {selectedCombineQuery && (
                <button
                  className={`clear-all input-button-text-form save-as-new-btn  ${fullQuery ? "" : "text-syntax-opcaity"
                    }`}
                  onClick={handleSaveAsNew}
                >
                  Save as new
                </button>
              )}
              <button
                className={`clear-all input-button-text-form ${fullQuery ? "" : "text-syntax-opcaity"
                  }`}
                onClick={handleSave}
              >
                Save
              </button>
            </div>
            {fullQuery ? (
              <RWireButton
                cNameDiv="search-query"
                buttonCName="input-button-text-form"
                name="Search"
                buttonImg={RWIRE_IMAGES.RwireSearchBlackIcon}
                onClick={handleSearch}
              />
            ) : (
              <RWireButton
                cNameDiv="search-query text-syntax-opcaity"
                buttonCName="input-button-text-form"
                name="Search"
                buttonImg={RWIRE_IMAGES.RwireSearchBlackIcon}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
