const getRegEx = (prePostRegx, newHighlightRegex) =>
  new RegExp(
    `${prePostRegx}((?:${newHighlightRegex
      .join("|")
      .replaceAll("?", "[a-zA-Z0-9]")
      .replaceAll("*", "\\w*")
      .replaceAll("+", "")}))${prePostRegx}`,
    "gi"
  );

const HighLighter = (text, highlight = [], isExact = true) => {
  const highlightedWords = JSON.parse(localStorage.getItem("highlightedWords")) || [];
  const storedQueryWords = JSON.parse(localStorage.getItem('queryWords')) || [];

  if (!highlightedWords.length && storedQueryWords.length === 0) return text;

  const wordsToHighlight = highlightedWords.length > 0 ? highlightedWords : storedQueryWords.map(word => ({ word, color: '#fffb00' }));

  const newHighlight = wordsToHighlight.map((v) => `${v.word.toLowerCase()}`);
  const prePostRegx = isExact ? "\\b" : "";

  const highlightRegex = getRegEx(prePostRegx, newHighlight);

  const parser = new DOMParser();
  const doc = parser.parseFromString(text, "text/html");

  const traverse = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const textContent = node.textContent;
      const matches = textContent.match(highlightRegex);
      if (matches && matches.length > 0) {
        const highlightedText = textContent.replace(
          highlightRegex,
          (matchedWord) => {
            const matchedWordLower = matchedWord.toLowerCase();
            const highlightObj = wordsToHighlight.find((i) => i.word === matchedWordLower);
            return `<span class="highlight-word" style="background-color: ${
              highlightObj?.color
            }">${matchedWord}</span>`;
          }
        );
        const wrapper = document.createElement("div");
        wrapper.innerHTML = highlightedText;
        const highlightedNodes = Array.from(wrapper.childNodes);
        highlightedNodes.forEach((n) => node.parentNode.insertBefore(n, node));
        node.remove();
      }
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      node.nodeName.toLowerCase() === "span" &&
      node.classList.contains("font-weight-bold") &&
      node.classList.contains("cursor-pointer")
    ) {
      return;
    } else {
      for (const childNode of node.childNodes) {
        traverse(childNode);
      }
    }
  };

  traverse(doc.body);

  const serializedHighlighted = new XMLSerializer().serializeToString(doc.body);
  return serializedHighlighted;
};

export default HighLighter;
