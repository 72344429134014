import { useState } from "react";
import TableHeader from "./table-header";
import TableRow from "./table-row";

const ClassesTable = (props) => {
  const {
    tableData = [],
    selectedItemList,
    selectedRows,
    onSetItemPerPage,
    onSetSelectedRows,
    onSetClassesSelector,
    selectedRowsWithCodeAndDefination = [],
    isSearching,
    isNoResultFound,
  } = props;

  const [fullDefinationText, setFullDefinationText] = useState(false);
  const [loading, setLoading] = useState(false);
  return (
    <div className={`table-wrapper normal-scroll-bar`}>
      <div className="table-inner">
        <TableHeader
          onSetFullDefinationText={setFullDefinationText}
          fullDefinationText={fullDefinationText}
        />
        <div className="body_wrapper">
          {isSearching ? (
            <div className="search-body-wrapper-loading d-flex justify-content-center align-items-center">
              <p>Loading...</p>
            </div>
          ) : isNoResultFound ? (
            <div className="no-records-found">
              <h3 className="nrf_classes">No Result Found</h3>
            </div>
          ) : !tableData.length ? (
            <div className="no-records-found ">
              <h3 className="main">
                Please type in classes or keywords for search
              </h3>
            </div>
          ) : (
            tableData.map((item) => (
              <TableRow
                key={item._id}
                code={item._source.code}
                type={item._source.type}
                definition={item._source.definition}
                selectedRows={selectedRows}
                selectedRowsWithCodeAndDefination={
                  selectedRowsWithCodeAndDefination
                }
                onSetSelectedRows={onSetSelectedRows}
                data={tableData}
                selectedItemList={selectedItemList}
                onSetItemPerPage={onSetItemPerPage}
                onSetClassesSelector={onSetClassesSelector}
                fullDefinationText={fullDefinationText}
                onSetFullDefinationText={setFullDefinationText}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassesTable;
