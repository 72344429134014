import { Dropdown } from "rsuite";
import { fieldOptionsCountry } from "../../../resources/data/options";
import { useRef, useState } from "react";
import { handleListScroll } from "../../../common/dropdown-position";
const MAX_LENGTH = 14;

const DropDown = ({
  item,
  fieldData,
  index,
  setAllField,
  onSetApp,
  isEditModalOpen,
}) => {
  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  const handleSelect = (eventKey) => {
    const dtOp = fieldData.map((item, key) => {
      if (index === key) {
        return {
          ...item,
          query: eventKey[0],
          type: eventKey[1],
          placeholder: eventKey[2],
        };
      }
      return { ...item };
    });
    if (isEditModalOpen) {
      setAllField({ localQueryFields: dtOp });
    } else {
      setAllField({ queryFields: dtOp });
    }
    onSetApp({ smartSearchWord: "", isUpdateQuery: false });

    const allNode = Array.from(
      document.getElementsByClassName("rs-dropdown-menu")
    );

    allNode.forEach((element) => {
      element.setAttribute("hidden", true);
    });
  };

  const handleOpen = () => {
    setSearchText("");
    const allNode = Array.from(
      document.getElementsByClassName("rs-dropdown-inner-menu")
    );

    allNode.forEach((element) => {
      element.classList.add("d-none");
    });

    handleListScroll();
    setTimeout(() => {
      inputRef.current.focus();
    }, 50);
  };

  const handleListEnter = (e) => {
    if (!Array.from(e.target.classList).includes("inner")) {
      const allNode = Array.from(
        document.getElementsByClassName("rs-dropdown-inner-menu")
      );

      document
        .getElementsByClassName("fields-form")[0]
        .addEventListener("scroll", () => {
          const allNode = Array.from(
            document.getElementsByClassName("rs-dropdown-menu")
          );

          allNode.forEach((element) => {
            element.setAttribute("hidden", true);
          });
        });

      let modalRect = { left: 0, top: 0 };
      let isModel = false;

      if (Array.from(document.getElementsByClassName("rs-modal-dialog"))[0]) {
        modalRect = Array.from(
          document.getElementsByClassName("rs-modal-dialog")
        )[0].getBoundingClientRect();
        isModel = true;
      }

      allNode.forEach((element) => {
        element.classList.add("d-none");
      });
      if (e.target.getElementsByTagName("ul")[0]) {
        e.target.getElementsByTagName("ul")[0].classList.remove("d-none");
        let rect = e.target.getBoundingClientRect();
        const bottom =
          rect.top + 250 > window.innerHeight
            ? isModel
              ? "-100px"
              : "0"
            : "auto";
        e.target.getElementsByTagName("ul")[0].setAttribute(
          "style",
          `left: ${rect.left - modalRect.left + (rect.width - 5)}px;
        top: ${rect.top - modalRect.top}px !important;
        bottom: ${bottom};
        overflow: auto;`
        );
      }
    }
  };

  return (
    <Dropdown
      title={
        item.type.length < MAX_LENGTH
          ? item.type
          : `${item.type.substring(0, MAX_LENGTH)}...`
      }
      onSelect={(e) => handleSelect(e)}
      onOpen={handleOpen}
      id={item.id}
    >
      <li className="outer search-input">
        <input
          key={index}
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearch}
          ref={inputRef}
          className=" w-100"
        />
      </li>
      {searchText
        ? fieldOptionsCountry.map((option, index) => {
            if (option.valuecode) {
              if (
                option.valuecode
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              ) {
                return (
                  <Dropdown.Item
                    key={index}
                    title={option.valuecode}
                    onMouseEnter={handleListEnter}
                    eventKey={[option.sortcode, option.valuecode]}
                  >
                    {option.valuecode
                      .split(new RegExp(`(${searchText})`, "gi"))
                      .map((part, i) =>
                        part.toLowerCase() === searchText.toLowerCase() ? (
                          <strong
                            className="matched-string-highlight-bg"
                            key={i}
                          >
                            {part}
                          </strong>
                        ) : (
                          part
                        )
                      )}
                  </Dropdown.Item>
                );
              }
            } else {
              const categoryKey = Object.keys(option)[0];
              const nestedOptions = option[categoryKey].filter(
                (nestedOption) =>
                  nestedOption.valuecode
                    .toLowerCase()
                    .includes(searchText.toLowerCase()) ||
                  Object.keys(option)[0]
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
              );

              if (nestedOptions.length > 0) {
                return nestedOptions.map((nestedOption, nestedIndex) => (
                  <Dropdown.Item
                    key={nestedIndex}
                    title={nestedOption.valuecode}
                    eventKey={[
                      nestedOption.sortcode,
                      nestedOption.valuecode,
                      nestedOption.placeholder,
                    ]}
                  >
                    <span>
                      {"..."}{" "}
                      {categoryKey
                        .split(new RegExp(`(${searchText})`, "gi"))
                        .map((part, i) =>
                          part.toLowerCase() === searchText.toLowerCase() ? (
                            <strong
                              className="matched-string-highlight-bg"
                              key={i}
                            >
                              {part}
                            </strong>
                          ) : (
                            part
                          )
                        )}
                      {"// "}
                      {nestedOption.valuecode
                        .split(new RegExp(`(${searchText})`, "gi"))
                        .map((part, i) =>
                          part.toLowerCase() === searchText.toLowerCase() ? (
                            <strong
                              className="matched-string-highlight-bg"
                              key={i}
                            >
                              {part}
                            </strong>
                          ) : (
                            part
                          )
                        )}
                    </span>
                  </Dropdown.Item>
                ));
              }
              return null;
            }
            return null;
          })
        : fieldOptionsCountry.map((item, index) => {
            return item.valuecode ? (
              <Dropdown.Item
                key={index}
                title={item.valuecode}
                onMouseEnter={handleListEnter}
                eventKey={[item.sortcode, item.valuecode]}
              >
                {item.valuecode}
              </Dropdown.Item>
            ) : (
              <li
                key={index}
                className="outer"
                onMouseEnter={handleListEnter}
                title={Object.keys(item)[0]}
              >
                <span>{Object.keys(item)[0]}</span>
                <ul className=" rs-dropdown-inner-menu d-none">
                  {Object.values(item)[0].map((item, index) => {
                    return (
                      <li
                        className="inner"
                        key={index}
                        onClick={() =>
                          handleSelect([
                            item.sortcode,
                            item.valuecode,
                            item.placeholder,
                          ])
                        }
                        title={item.valuecode}
                      >
                        <span>{item.valuecode}</span>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
    </Dropdown>
  );
};

export default DropDown;
