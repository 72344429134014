import React, { useState } from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";
import { options } from "../../resources/data/about-us-option-data";
import { features } from "../../resources/data/about-us-option-data";
import FirstPart from "./first-part";
import SecondPart from "./second-part";
import Commitment from "./commitment-part";
import WhyChooseUs from "./whychooseus-part";
import ImageSlider from "./imagesilder-part";
import ContactUs from "./contactus-part";

const RwireAboutUs = () => {
  const images = [
    RWIRE_IMAGES.RWireImagesSectionFirst,
    RWIRE_IMAGES.RWireImagesSectionSecond,
    RWIRE_IMAGES.RWireImagesSectionThird,
    RWIRE_IMAGES.RWireImagesSectionFourth,
    RWIRE_IMAGES.RWireImagesSectionFifth,
  ];

  const [selectedOptions, setSelectedOptions] = useState(options[0]);

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleClick = (option) => {
    setSelectedOptions(option);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <>
      <FirstPart />
      <SecondPart
        features={features}
        activeIndex={activeIndex}
        handleToggle={handleToggle}
      />
      <Commitment />
      <WhyChooseUs
        options={options}
        selectedOptions={selectedOptions}
        handleClick={handleClick}
      />
      <ImageSlider images={images} settings={settings} />
      <ContactUs />
    </>
  );
};

export default RwireAboutUs;
