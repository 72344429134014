import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

const SliderWrapper = (props) => {
  const { children, slidesToShow = 4, slidesToScroll = 1, nextIcon, prevIcon } = props;
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    nextArrow: nextIcon,
    prevArrow: prevIcon,
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default SliderWrapper;
