import { useState } from "react";
import { Block } from "../../../ui-components";
import RwireReportDashboardContent from "./main";
import ReportSidebar from "./report-sidebar";
import "./styles.scss";
import { Route, Routes } from "react-router-dom";
import Settings from "./settings";
import Support from "./support";

const RwireReportDashboard = (props) => {
  const { onGetReportProjectDataById, onSetProjectReport, workspaceElements } =
    props;

  const [isOpenSidebar, setSidebar] = useState(false);

  return (
    <Block className="rwire-report-dashboard d-flex">
      <>
        <ReportSidebar
          onGetReportProjectDataById={onGetReportProjectDataById}
          onSetProjectReport={onSetProjectReport}
          isOpenSidebar={isOpenSidebar}
          onSetSidebar={setSidebar}
          workspaceElements={workspaceElements}
        />
        <Block
          className={`rwire-report-dashboard-content ${
            isOpenSidebar ? "expanded" : "colapse"
          }`}
        >
          <Routes>
            <Route
              path=""
              element={
                <RwireReportDashboardContent
                  isOpenSidebar={isOpenSidebar}
                  {...props}
                />
              }
            ></Route>
            {/* User story is yet to be developed, so commenting it for now */}
            {/* <Route
              path="/report/support"
              element={<Support {...props} />}
            ></Route>
            <Route
              path="/report/settings"
              element={<Settings {...props} />}
            ></Route> */}
          </Routes>
        </Block>
      </>
    </Block>
  );
};

export default RwireReportDashboard;
