import React from "react";
import { Block, Modal } from "../ui-component";
import "./styles.scss";
import { Button } from "react-bootstrap";
const AlertModal = (props) => {
  const { isOpen, onClose, title, description, confirmBtnLabel, onConfirm } =
    props;
  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="confirm-alert-modal"
      className="confirm-alert-modal-body"
    >
      <Block className="title">
        <div>{title}</div>
        <div className="ms-auto cursor-pointer" title="Close" onClick={onClose}>
          x
        </div>
      </Block>
      <Block className="description">{description}</Block>
      <Block className="actions w-100">
        <Button className="cancel-btn" onClick={onClose}>
          Cancel
        </Button>
        <Button className="confirm-btn" onClick={onConfirm}>
          {confirmBtnLabel}
        </Button>
      </Block>
    </Modal>
  );
};

export default AlertModal;
