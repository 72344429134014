import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import EditableTable from "./editable-table";
import NoProjectPage from "./no_project_page";
const ExistingProject = ({
  onGetReportProjects,
  onUpdateReportProject,
  onResetProjectData,
  onSetApp,
  onSetProjectReport,
  onGetReportProjectDataById,
  onGetAllProjectUsers,
  onGetBackwardCitationsData,
}) => {
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await onGetReportProjects();
        if (response && response.result && response.result.length > 0) {
          setData([response.result][0]);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      } finally {
        setDataFetched(true);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-100 existing-project">
      <div className="heading-strip">Existing Projects</div>
      {dataFetched && data.length === 0 ? (
        <NoProjectPage />
      ) : (
        <>
          <div className="list-section">
            <EditableTable
              data={data}
              onUpdateReportProject={onUpdateReportProject}
              onResetTablesData={() => onResetProjectData()}
              onSetApp={onSetApp}
              onSetProjectReport={onSetProjectReport}
              onGetReportProjectDataById={onGetReportProjectDataById}
              onGetAllProjectUsers={onGetAllProjectUsers}
              onGetBackwardCitationsData={onGetBackwardCitationsData}
            />
          </div>
          <div className="create-new-section">
            <div className="cancel">
              <button onClick={() => navigate("/")}>Cancel</button>
            </div>
            <div className="inner">
              <button onClick={() => navigate("/en/create-project")}>
                Create New
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ExistingProject;
