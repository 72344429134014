import React, { useRef } from "react";
import { applyMultipleHighlights } from "../../action/patent-view";
import { applyMappings } from "./desc-block";
import { HighlightFromQueryOrManually } from "../../container/highlight";
import { removeMultipleWhiteSpaces } from "./tooltip";

function DescBlockOnly({
  description = "",
  userAnnotations = {},
  languageSelected,
}) {
  const containerRef = useRef(null);

  const finalText = applyMultipleHighlights(
    applyMappings(description),
    userAnnotations && userAnnotations[`DESC_${languageSelected}`]
  );
  return (
    <>
      <h5 className="me-4 mt-4">Description</h5>

      {description ? (
        <div className="mt-2 text-justify desc-only-block">
          <div
            ref={containerRef}
            className={`DESC_${languageSelected} highlighted-component-desc`}
          >
            <HighlightFromQueryOrManually>
              {finalText}
            </HighlightFromQueryOrManually>
          </div>
        </div>
      ) : (
        <p className="pt-2">Data not available</p>
      )}
    </>
  );
}

export default DescBlockOnly;
