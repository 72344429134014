import React, { useEffect, useState } from "react";
import { Block, Image, Span } from "../../../ui-components";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStore } from "react-redux";
import {
  ACCESS_EDITOR,
  ACCESS_VIEWER,
  ROLE_OWNER,
} from "./../../../common/user-roles";
import {
  dashboardIcon,
  generateReportIcon,
  projectProgressIcon,
  usersIcon,
  colapseIcon,
  supportIcon,
  settingIcon,
} from "../../../assets/images/report";
import dashboardLogoIcon from "../../../assets/images/logo.png";
import { Button } from "../../../ui-components";
import { ReportsRoutes } from "../rwire-dashboard-wrapper/rwire-dashboard-wrapper";
import ShareProjectModal from "./share-project-modal";

const ReportSidebar = ({
  onGetReportProjectDataById,
  onSetProjectReport,
  isOpenSidebar,
  onSetSidebar,
  workspaceElements,
}) => {
  const [showShareReportModal, setShowShareReportModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  useEffect(() => {
    document.body.classList.add("report-dashboard");
  }, []);

  const handleNavigateToProjectPage = () => {
    const firstElement = workspaceElements[0];
    navigate(ReportsRoutes[parseInt(firstElement) - 1].to);
  };

  return (
    <Block
      className={`report-sidebar-wrapper ${
        isOpenSidebar ? "expanded" : "colapse"
      }`}
    >
      {isOpenSidebar ? (
        <Block>
          <Block className="sidebar-sections-list flex-column d-flex">
            <Link
              to="/en/report"
              className={`sidebar-links d-flex ${
                location.pathname === "/en/report" ? "active" : ""
              }`}
            >
              <Image src={dashboardIcon} alt="" title="Dashboard" />
              <Span>Dashboard</Span>
              <Image
                src={colapseIcon}
                alt=""
                onClick={() => onSetSidebar(!isOpenSidebar)}
                className="dashboard-collapse"
              />
            </Link>
            <Block
              onClick={handleNavigateToProjectPage}
              className="sidebar-links"
            >
              <Image
                src={projectProgressIcon}
                alt=""
                title="Project Progress Report"
              />
              <Span>Project Progress Report</Span>
            </Block>
            <Link
              to="/en/report/final-view"
              className={`sidebar-links d-flex ${
                location.pathname === "/en/report/final-view" ? "active" : ""
              }`}
            >
              <Image src={generateReportIcon} alt="" title="Generate Report" />
              <Span>Generate Report</Span>
            </Link>
          </Block>
          {/* User story is yet to be developed, so commenting it for now */}
          {/* <Block className="sidebar-support flex-column d-flex">
            {userProjectAccess !== ACCESS_VIEWER && (
              <Link
                to="/en/report/support"
                className={`sidebar-links d-flex ${
                  location.pathname === "/en/report/support" ? "active" : ""
                }`}
              >
                <Image src={supportIcon} alt="" title="Support" />
                <Span>Support</Span>
              </Link>
            )}
            {userProjectAccess !== ACCESS_VIEWER && (
              <Link to="/en/report/settings" className="sidebar-links">
                <Image src={settingIcon} alt="" title="Settings" />
                <Span>Settings</Span>
              </Link>
            )}
          </Block> */}
          {sessionStorage.getItem("userRole") === ROLE_OWNER && (
            <Block
              className="share-report"
              onClick={() => {
                setShowShareReportModal(true);
              }}
            >
              <Link to="/en/report/users">
                <Button
                  className={"share-project-button"}
                  onClick={() => {
                    sessionStorage.setItem(
                      "projectIdForUsers",
                      sessionStorage.getItem("projectId")
                    );
                  }}
                >
                  Share your project
                </Button>
              </Link>
            </Block>
          )}
        </Block>
      ) : (
        <Block>
          <Block className="d-flex sidebar-logo">
            <Image
              src={colapseIcon}
              alt=""
              title="Open"
              onClick={() => onSetSidebar(!isOpenSidebar)}
            />
          </Block>
          <Block className="sidebar-sections-list flex-column d-flex">
            <Link to="/en/report" className="sidebar-links d-flex">
              <Image src={dashboardIcon} alt="" title="Dashboard" />
            </Link>
            <Block
              onClick={handleNavigateToProjectPage}
              className="sidebar-links"
            >
              <Image
                src={projectProgressIcon}
                alt=""
                title="Project Progress Report"
              />
            </Block>
            <Link to="/en/report/final-view" className="sidebar-links">
              <Image src={generateReportIcon} alt="" title="Generate Report" />
            </Link>
          </Block>

          {/* User story is yet to be developed, so commenting it for now */}
          {/* <Block className="sidebar-support flex-column d-flex">
            {userProjectAccess !== ACCESS_VIEWER && (
              <Link to="/en/report/support" className="sidebar-links d-flex">
                <Image src={supportIcon} alt="" title="Support" />
              </Link>
            )}
            {userProjectAccess !== ACCESS_VIEWER && (
              <Link to="/en/report/settings" className="sidebar-links">
                <Image src={settingIcon} alt="" title="Settings" />
              </Link>
            )}
          </Block> */}
        </Block>
      )}

      {showShareReportModal && (
        <ShareProjectModal
          isOpen={showShareReportModal}
          onClose={() => {
            setShowShareReportModal(false);
          }}
        />
      )}
    </Block>
  );
};

export default ReportSidebar;
