import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RwireHighlightIcon from "../pages/rwire-patent-view/rwire-highlight-icon";
import { setHighlight } from "../action/highlight";

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      onSetHighlight: setHighlight,
    },
    dispatch
  ),
});

export default connect(null, mapDispatchToProps)(RwireHighlightIcon);
