import { chartList } from "../resources/data/cognizance-chart-data";

const initialState = {
  listFilters: [],
  listMerge: [],
  listTopNumbers: {
    "innovation-trend": "20",
    "filing-trend": "20",
    "ipc-ay": "20",
  },
  chartList: chartList,
};

const chart = (state = initialState, { type, payload }) => {
  switch (type) {
    case "CHART_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default chart;
