import React, { useState } from "react";
import { Modal } from "../../../ui-components";
import FileFormatSelector from "./file-format-selector";
import { getExcelDownloadableData } from "../../../ui-components/functions/report-view/utils";
import { ExcelExportHelper } from "./excel-export-helper";
import { PPTExportHelper } from "./ppt-export-handler";
import { PDFExportHelper } from "./pdf-export-handler";

const SendMailModal = (props) => {
  const { isOpen, onClose, projectData, onSentReportOnMail } = props;
  const [selectedFormat, setSelectedFormat] = useState("PDF");
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleSentOnMail = async () => {
    switch (selectedFormat) {
      case "PDF":
        onClose();
        setTimeout(() => {
          const fileData = PDFExportHelper(
            getExcelDownloadableData(projectData || {}),
            false
          );
          onSentReportOnMail({
            fileData,
            selectedFormat,
            email: inputValue,
            fileName: projectData.name,
          });
        }, 100);
        break;
      case "XLSX":
        onClose();

        setTimeout(() => {
          ExcelExportHelper(
            getExcelDownloadableData(projectData || {}),
            false
          ).then((fileData) => {
            setTimeout(() => {
              onSentReportOnMail({
                fileData,
                selectedFormat,
                email: inputValue,
                fileName: projectData.name,
              });
            }, 1000);
          });
        }, 100);
        break;
      case "PPT":
        onClose();
        PPTExportHelper(
          getExcelDownloadableData(projectData || {}),
          false
        ).then((fileData) => {
          setTimeout(() => {
            onSentReportOnMail({
              fileData,
              selectedFormat,
              email: inputValue,
              fileName: projectData.name,
            });
          }, 1000);
        });
        break;
      default:
        break;
    }
  };

  const handleRadioChange = (event) => {
    setSelectedFormat(event.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="project-report-download-modalbg"
      className="project-report-download-modal-body project-report-mail-modal">
      <div className="report-download-modal">
        <div className="header-section">
          <div className="py-2">Send on mail</div>
          <div className="close-btn" onClick={onClose} title="Close">
            X
          </div>
        </div>
        <div className="input-section">
          <div style={{ padding: "20px" }}>
            <div className="text-left fw-bold mb-1">Email-id</div>
            <div className=" mb-3">
              <input
                type="text"
                className="form-control download-email"
                placeholder="Type an email id"
                value={inputValue}
                onChange={handleInputChange}
              />
            </div>
            <div className="text-left fw-bold mb-1">Choose File Format</div>
            <div className=" w-75 mx-auto">
              <div className="d-flex justify-content-between mt-2">
                <div className="border mx-2 p-2 px-3 file-type-selector">
                  <input
                    type="radio"
                    name="filetype"
                    className="file-type-radio"
                    value="PDF"
                    onChange={handleRadioChange}
                    checked={selectedFormat === "PDF"}
                  />{" "}
                  <span>PDF</span>
                </div>
                <div
                  className="border mx-2 p-2 px-3 file-type-selector"
                  for="filetype">
                  <input
                    type="radio"
                    name="filetype"
                    className="file-type-radio"
                    value="XLSX"
                    onChange={handleRadioChange}
                    checked={selectedFormat === "XLSX"}
                  />{" "}
                  <span>XLSX</span>
                </div>
                <div className="border mx-2 p-2 px-3 file-type-selector">
                  <input
                    type="radio"
                    name="filetype"
                    className="file-type-radio"
                    value="PPT"
                    onChange={handleRadioChange}
                    checked={selectedFormat === "PPT"}
                  />{" "}
                  <span>PPT</span>
                </div>
              </div>
            </div>
          </div>
          <div className="download-btn" onClick={handleSentOnMail}>
            <button>Send</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SendMailModal;
