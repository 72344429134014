import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ReportAddComment from "../../ui-components/add-comment-section";
import {
  addCommentByRowId,
  deleteCollaborationFile,
  deleteCommentByCommentId,
  getAllCommentsByRowId,
  setReportCollab,
  updateCommentByRowId,
} from "../../action/report/report-collaboration";

const Container = (props) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("projectId");
  useEffect(() => {
    const getAllComments = async () => {
      try {
        setLoading(true);
        await props.onGetAllCommentsByRowId({
          elementId: props.workspaceId, //string
          contentId: props.commentingRowID.toString(), // string
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    if (_id) {
      getAllComments();
    } else {
      navigate("/en/existing-project");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.commentingRowID]);

  return <ReportAddComment {...props} isLoading={loading} />;
};

const mapStateToProps = ({
  collab,
  app: { userEmailId },
  project: { projectUsers },
}) => ({
  ...collab,
  userEmailId,
  projectUsers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportCollab: setReportCollab,
      onAddCommentByRowId: addCommentByRowId,
      onGetAllCommentsByRowId: getAllCommentsByRowId,
      onDeleteCommentByCommentId: deleteCommentByCommentId,
      onUpdateCommentById: updateCommentByRowId,
      onDeleteCollaborationFile: deleteCollaborationFile,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
