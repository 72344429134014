import React, { useState } from "react";
import "./style.scss";
import { Heading, Image } from "../ui-component";
import {
  CollabIcon,
  DownArrow,
  RightLongArrow,
  UpArrow,
  UserAvatar,
} from "../../assets/images/report";
import SingleComment from "./single-comment";
import StackedAvatars from "./stacked-avatars";
import CommentInput from "./comment-input";

const AddCommentSection = (props) => {
  const {
    commentingRowID,
    rowComments,
    onUpdateCommentById,
    onClose,
    workspaceId,
    onAddCommentByRowId,
    onGetAllCommentsByRowId,
    onDeleteCommentByCommentId,
    onSetReportCollab,
    onDeleteCollaborationFile,
    userEmailId,
    projectUsers = [],
  } = props;
  const [isEditing, setIsEditing] = useState(null);
  const [comment, setComment] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showInput, setShowInput] = useState(true);
  return (
    <div className="reports-collab-mode d-flex flex-column">
      <div className="action-section d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <div>
            <Image src={CollabIcon} />
          </div>
          <div className="collab-heading">Collaboration</div>
        </div>
        <div className="d-flex align-items-end gap-2">
          <div className="comment-text">Comment</div>
          <div
            className="close-panel cursor-pointer"
            title="Close"
            onClick={onClose}
          >
            <Image src={RightLongArrow} />
          </div>
        </div>
      </div>
      <div className="below-section flex-grow-1">
        <div className="comments-section">
          <div className="comments-list-section">
            {rowComments && rowComments.length > 0 ? (
              rowComments.map((comment, index) => (
                <SingleComment
                  isEditing={isEditing?.id === index + 1}
                  setIsEditing={setIsEditing}
                  idx={index + 1}
                  key={comment.id}
                  comment={comment}
                  commentingRowID={commentingRowID}
                  onUpdateCommentById={onUpdateCommentById}
                  rowComments={rowComments}
                  onSetReportCollab={onSetReportCollab}
                  onDeleteCommentByCommentId={onDeleteCommentByCommentId}
                  onGetAllCommentsByRowId={onGetAllCommentsByRowId}
                  workspaceId={workspaceId}
                  onDeleteCollaborationFile={onDeleteCollaborationFile}
                  userEmailId={userEmailId}
                />
              ))
            ) : (
              <div className="d-flex w-100 justify-content-center">
                <p className="p-3">No Comments Added</p>
              </div>
            )}
          </div>

          <div className="comment-input-section">
            <div className="d-flex justify-content-between px-2">
              <div className="author-avatar my-1 d-flex align-items-center">
                <Image src={UserAvatar} alt="" />
                <Heading as="h6" className="px-2">
                  You
                </Heading>
              </div>
              <div
                className="hide-comment-section cursor-pointer"
                onClick={() => setShowInput(!showInput)}
                title={showInput ? "Hide" : "Show"}
              >
                <Image src={showInput ? DownArrow : UpArrow} />
              </div>
            </div>
            {showInput && (
              <>
                <div style={{ pointerEvents: isEditing ? "none" : "auto" }}>
                  <CommentInput
                    isEditing={isEditing ? true : false}
                    comment={comment}
                    setComment={setComment}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    commentingRowID={commentingRowID}
                    users={projectUsers}
                  />
                </div>
                <div className="px-2 d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <StackedAvatars users={projectUsers} maxCount={3} />
                    <Heading
                      as="h6"
                      className={`collaborators ${
                        projectUsers && projectUsers.length > 3
                          ? "px-2"
                          : "px-4"
                      }`}
                    >
                      Collaborators
                    </Heading>
                  </div>

                  <button
                    className="add-comment-button"
                    disabled={!(comment !== "" || uploadedFiles.length > 0)}
                    onClick={async (e) => {
                      e.preventDefault();
                      setComment("");
                      setUploadedFiles([]);
                      await onAddCommentByRowId({
                        comment: comment,
                        workspaceElements: workspaceId,
                        projectId: sessionStorage.getItem("projectId"),
                        contentId: commentingRowID.toString(),
                        collaborationFile: uploadedFiles,
                      });
                      await onGetAllCommentsByRowId({
                        elementId: workspaceId,
                        contentId: commentingRowID.toString(),
                      });
                    }}
                  >
                    Comment
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCommentSection;
