import { useState } from "react";
import closeIcon from "../../images/close-icon-white.svg";
import KeywordModalRow from "./keyword-modal-row";

const KeywordModal = (props) => {
  const {
    reportTableData,
    onAddProjectKeyword,
    onSetKeywordsModal,
    isKeywordsModal,
    selectedText,
    onUpdateProjectKeyword,
    onUpdateReportProjectKeywordsRow,
    onGetReportProjectKeywords,
  } = props;

  const [selectedTextModal, setSelectedTextModal] = useState(selectedText);
  console.log("selectedTextModal>>>>>", selectedTextModal);
  const handleClick = async () => {
    if (selectedTextModal) {
      const projectId = sessionStorage.getItem("projectId");
      // Check if any existing row has an empty title
      const emptyTitleRow = reportTableData.find((item) => item.title === "");
      if (emptyTitleRow) {
        // Update the existing row
        await onUpdateReportProjectKeywordsRow({
          id: emptyTitleRow.id,
          body: {
            title: selectedTextModal,
          },
        });
      } else {
        // Add a new row
        await onAddProjectKeyword({
          data: [
            {
              title: selectedTextModal,
              body: "",
              order: 1,
            },
          ],
          projectId,
        });
      }
      onGetReportProjectKeywords();
      handleClose();
    }
  };

  const handleClose = () => {
    onSetKeywordsModal(false);
  };

  return (
    <>
      <div className="d-flex keyword-modal-header">
        <div>Add Keyword</div>
        <div className="close-icon" onClick={handleClose}>
          <img src={closeIcon} className="close-icon" alt="" />
        </div>
      </div>
      <div className="keywords-row-wrapper">
        <div onClick={handleClick} className="keywords-row">
          Add New Row
        </div>
        {reportTableData.map((item) => {
          if (item.title === "") {
            return null;
          }

          return (
            <KeywordModalRow
              id={item.id}
              key={item.id}
              title={item.title}
              onHandleClose={handleClose}
              selectedTextModal={selectedTextModal}
              onUpdateProjectKeyword={onUpdateProjectKeyword}
              reportTableData={reportTableData}
            />
          );
        })}
      </div>
    </>
  );
};

export default KeywordModal;
