import React from "react";
import { Block } from "../../../ui-components";
import Sidebar from "./sidebar";
import "./styles.scss";
import ProjectNameSection from "./project-name-section";
import {
  NavIconKeyFeatures,
  NavIconKeywords,
  NavIconClasses,
  NavIconAssignee,
  NavIconInventor,
  NavIconStrings,
  NavIconChecklist,
} from "../../../assets/images/report";
export const ReportsRoutes = [
  {
    to: "/en/report/notes",
    name: "Key Features",
    navImgIcon: NavIconKeyFeatures,
  },
  { to: "/en/report/keyword", name: "Keyword", navImgIcon: NavIconKeywords },
  { to: "/en/report/classes", name: "Classes", navImgIcon: NavIconClasses },
  {
    to: "/en/report/assignee",
    name: "Assignee",
    navImgIcon: NavIconAssignee,
  },
  {
    to: "/en/report/inventor",
    name: "Inventor",
    navImgIcon: NavIconInventor,
  },
  { to: "/en/report/string", name: "Strings", navImgIcon: NavIconStrings },
  {
    to: "/en/report/relevant-results",
    name: "Relevant Results",
    navImgIcon: NavIconChecklist,
  },
];
const DashboardWrapper = (props) => {
  const { workspaceElements, projectPatentNumber } = props;
  return (
    <Block className="d-flex flex-column dashboard-wrapper">
      <ProjectNameSection {...props} />
      <Block className="d-flex wrapper flex-grow-1">
        <Sidebar
          routes={ReportsRoutes}
          workspaceElements={workspaceElements}
          projectPatentNumber={projectPatentNumber}
        />

        <Block className="page-content-wrapper">{props.children}</Block>
      </Block>
    </Block>
  );
};

export default DashboardWrapper;
