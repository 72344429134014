import { priorityArray } from "../common/quick-view-heading-map";

export const filterHighlightData = (highlightData) => {
  const result = {};

  for (const field of priorityArray) {
    if (Array.isArray(field)) {
      for (const innerField of field) {
        if (highlightData.hasOwnProperty(innerField)) {
          result[innerField] = highlightData[innerField];
          break;
        }
      }
    } else {
      if (highlightData.hasOwnProperty(field)) {
        result[field] = highlightData[field];
      }
    }
  }

  return result;
};
