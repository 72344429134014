import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ReportSortableTable } from "../../ui-components";
import { performSearch } from "../../action/rwire-search-api";
import { setApp } from "../../action/app";
import { setAllField } from "../../action/search-query";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../common/user-roles";

const Container = (props) => {
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  return (
    <ReportSortableTable
      lockAxis="y"
      lockToContainerEdges={true}
      {...props}
      disabled={userProjectAccess === ACCESS_VIEWER}
    />
  );
};

const mapStateToProps = ({
  app: { activeTab },
  searchQuery: { tabWiseSearchQuery },
}) => ({
  activeTab,
  tabWiseSearchQuery,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onPerformSearch: performSearch,
      onSetApp: setApp,
      onSetAllField: setAllField,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
