import React from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const FirstSection = () => (
  <div className="first_section">
    <img
      className="service-image"
      alt=""
      src={RWIRE_IMAGES.RwireServiceFirstpart}
    />
    <div className="first_section_text">
      <div className="first_heading mb-3">Our Services</div>
      <div className="first_para">
        At Researchwire, we offer a suite of services designed to provide
        comprehensive support for all your patent research and analysis needs.
        Our advanced tools and expert assistance ensure you have the information
        and insights required to drive innovation, protect intellectual
        property, and make informed business decisions.
      </div>
    </div>
  </div>
);

export default FirstSection;
