import React from 'react';
import { useNavigate } from "react-router-dom";

const NoProjectPage = ()  =>{
    const navigate = useNavigate();
  return (
    <div className="no-project-page-container">
    <div className="no-prj"> No Project Created Yet</div>
    <div className="no-prj-msg ml-3">Please Create a New Project</div>
    <div className="d-flex np-button pt-2 ml-4">
      <div className="cancel_np">
        <button onClick={() => navigate("/")}>Cancel</button>
      </div>
      <div className="create_new">
        <button onClick={() => navigate("/en/create-project")}>
          Create New
        </button>
      </div>
    </div>
  </div>
  )
}

export default NoProjectPage