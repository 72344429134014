import React, { useState } from "react";
import { UserAvatar } from "../../assets/images/report";

const Avatar = ({ src, alt }) => (
  <img title={alt} src={src} alt={alt} className="avatar" />
);

const StackedAvatars = ({ users, maxCount = 5 }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const avatarsToDisplay = users.slice(0, maxCount);
  const remainingAvatars = users.slice(maxCount);

  return (
    <div className="report-avatar-group">
      {avatarsToDisplay.map((avatar, index) => (
        <Avatar key={index} src={UserAvatar} alt={avatar.userName} />
      ))}
      {users.length > maxCount && (
        <>
          <span className="overflow-count" onClick={togglePopup}>
            +{users.length - maxCount}
          </span>
          {isPopupOpen && (
            <div className="remaining-avatars-popup">
              {remainingAvatars.map((avatar, index) => (
                <div className="d-flex gap-3 py-1">
                  <div>
                    <img
                      src={UserAvatar}
                      alt={avatar.userName}
                      className="remaining-avatar"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    {avatar.userName}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StackedAvatars;
