import React from "react";

const KeyFeatureDataRow = ({
  options = [],
  selectedRows,
  onSetSelectedRows,
}) => {
  const isProjectMode = sessionStorage.getItem("projectMode");
  const handleCheckboxChange = (event, keyword) => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      // If the user unchecks a row, we need to remove it from the selection list and re-render with updated state
      const updatedSelectedRows = selectedRows.filter((row) => row !== keyword);
      onSetSelectedRows(updatedSelectedRows);
    } else {
      onSetSelectedRows([...selectedRows, keyword]);
    }
  };
  console.log(options);
  return (
    <div
      className={`d-flex w-100 border border-top-0`}
      style={{ height: "240px" }}
    >
      <table class="table" style={{ height: "240px" }}>
        <tbody>
          {options &&
            options.length > 0 &&
            options
              .filter((option) => option.trim())
              .map((option, index) => {
                return (
                  <tr className={`${(index + 1) % 2 !== 0 ? "oddRowBg" : ""}`}>
                    <td className="index-col" style={{ width: "20%" }}>
                      Key Feature {index + 1}
                    </td>
                    <td className="feature-col" style={{ width: "75%" }}>
                      {option}
                    </td>
                    {
                      <td className="input-col">
                        <div className="input-group">
                          <label>
                            <input
                              className="cursor-pointer"
                              type="checkbox"
                              checked={selectedRows.includes(option)}
                              onChange={(event) =>
                                handleCheckboxChange(event, option)
                              }
                            />
                          </label>
                        </div>
                      </td>
                    }
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
};

export default KeyFeatureDataRow;
