import React from "react";
import { Block, Button } from "../../../ui-components";

const ActionButtons = (props) => {
  const {
    buttonOneText = "",
    buttonTwoText = "",
    buttonOneAction,
    buttonTwoAction,
  } = props;
  return (
    <Block className="action-buttons-container d-flex gap-2 mt-3">
      <Button className="submit-button" onClick={buttonOneAction}>
        {buttonOneText}
      </Button>
      <Button className="other-button" onClick={buttonTwoAction}>
        {buttonTwoText}
      </Button>
    </Block>
  );
};

export default ActionButtons;
