import { saveAs } from "file-saver";
import {
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  AlignmentType,
  WidthType,
  TextRun,
} from "docx";

export const generate = (data = []) => {
  const tableRows = data.map((item) => {
    return new TableRow({
      children: [
        new TableCell({
          children: [new Paragraph({ text: item.keyFeature })],
          verticalAlign: VerticalAlign.CENTER,
        }),
        new TableCell({
          children: [new Paragraph({ text: item.mapping })],
          verticalAlign: VerticalAlign.CENTER,
        }),
      ],
    });
  });

  const doc = new Document({
    sections: [
      {
        children: [
          new Paragraph({
            children: [new TextRun("Claim Chart", { bold: true, size: 44 })],
            alignment: AlignmentType.CENTER,
          }),
          new Table({
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: "Key Feature",
                        heading: HeadingLevel.HEADING_2,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    children: [
                      new Paragraph({
                        text: "Mapping",
                        heading: HeadingLevel.HEADING_2,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
                tableHeader: true,
              }),
              ...tableRows,
            ],
          }),
        ],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, "claim-chart.docx");
  });
};
