import React, { useState } from "react";

function PriorityNumberToggle(props) {
  const { priorityNumber } = props;

  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div
      className={`mb-2 ms-3 ps-1 details d-flex ${
        priorityNumber.length > 1 ? "cursor-pointer" : ""
      }`}
      onClick={handleToggle}
    >
      <div className="details-header font-weight-bold ml-2">Priority Number:</div>
      <div className="ms-5 font-weight-bold">
        {toggle ? (
          <div className="d-flex justify-content-between">
            <div>{priorityNumber.join(", ")}</div>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <div>{priorityNumber[0]}</div>
            {priorityNumber.length > 1 ? (
              <div className="ms-1 inventor"> +{priorityNumber.length - 1}</div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default PriorityNumberToggle;
