import React, { useEffect, useState } from "react";
import collapse from "../../assets/images/collapse.png";
import { HighlightFromQueryOrManually } from "../../container/highlight";

function IPCTable(props) {
  const {
    ipcd = [],
    onSearchForClassAndKeyword,
    extraClass,
    classesTableData,
  } = props;

  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    onSearchForClassAndKeyword(ipcd, "ipc");
  }, [ipcd, onSearchForClassAndKeyword]);

  return (
    <>
      {classesTableData.ipc && classesTableData.ipc.length > 0 && (
        <div className={`view-tables ${extraClass ? extraClass : ""}`}>
          <table className="p-0">
            <thead className="table border table-borderless">
              <tr>
                <th className="classification-first-col">IPC Classes</th>
                <th style={{ borderRight: "none" }}>Definition</th>
                <th
                  className="close-classification text-end"
                  scope="col"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  <img
                    alt=""
                    src={collapse}
                    className="close-classification-btn"
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              {toggle &&
                classesTableData.ipc.map((ipc) => {
                  let text = ipc._source.definition;
                  let code = ipc._source.code;
                  return (
                    <tr>
                      <td className="classification-first-col">
                        <HighlightFromQueryOrManually>
                          {code[0]}
                        </HighlightFromQueryOrManually>
                      </td>
                      <td style={{ borderRight: "none" }}>
                        <HighlightFromQueryOrManually>
                          {text}
                        </HighlightFromQueryOrManually>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default IPCTable;
