import { Accordion } from "react-bootstrap";
import { Checkbox } from "rsuite";
import KeyFeatureNumberList from "./key-fature-list-number-list";
import { mergeObjects } from "../../action/patent-view";

const KeyFeatureListRow = (props) => {
  const {
    item,
    indexKey,
    activeKeyFeature,
    userAnnotations,
    onSetFullView,
    keyFeatureSelectList,
    onSetKeyFeatureSelectList,
    isAnyTextSelected,
    removeKeyFeatureDetails,
    onGetUserAnnotations
  } = props;

  const handleChange = () => {
    if (!keyFeatureSelectList.includes(item.id)) {
      keyFeatureSelectList.push(item.id);
    } else {
      const index = keyFeatureSelectList.indexOf(parseInt(item.id));
      if (index > -1) {
        keyFeatureSelectList.splice(index, 1);
      }
    }
    onSetKeyFeatureSelectList([...keyFeatureSelectList]);
  };

  const patentId = sessionStorage.getItem("patentId");

  const handleAccordionToggle = (e) => {
    if (e.target.className.includes("collapsed")) {
      item.keyFeatures.forEach((i) => {
        if (patentId === i.patentNumber) {
          onSetFullView({
            userAnnotations: mergeObjects(
              userAnnotations,
              JSON.parse(i.highlight)
            ),
          });
        }
      });
    } else {
      let userAnnotationsNew = userAnnotations;
      let uidsToRemove = item.keyFeatures
        .map((obj) => {
          let highlightObj = JSON.parse(obj.highlight);
          return Object.values(highlightObj)[0].map((item) => item.Uid);
        })
        .flat();

      Object.keys(userAnnotationsNew).forEach((key) => {
        userAnnotationsNew[key] = userAnnotationsNew[key].filter(
          (item) => !uidsToRemove.includes(item.Uid)
        );
      });

      onSetFullView({
        userAnnotations: userAnnotationsNew,
      });
    }
  };

  return (
    <Accordion.Item
      eventKey={indexKey}
      className={`${
        parseInt(item.id) === parseInt(activeKeyFeature) && "active"
      } ${item.keyFeatures.length > 0 && "hasKeyFeatures active"}`}
    >
      <Accordion.Header onClick={handleAccordionToggle}>
        {isAnyTextSelected && (
          <Checkbox
            onChange={handleChange}
            checked={keyFeatureSelectList.includes(parseInt(item.id))}
          ></Checkbox>
        )}
        Key Features {indexKey + 1}
      </Accordion.Header>
      <Accordion.Body>
        <div dangerouslySetInnerHTML={{ __html: item.body }} />
        <div className="d-flex number-list">
          {item.keyFeatures.map((feature, k) => {
            if (patentId === feature.patentNumber) {
              return (
                <KeyFeatureNumberList
                  item={feature}
                  keyFeatures={item.keyFeatures}
                  key={`${k}-${feature.patentNumber}`}
                  index={k}
                  userAnnotations={userAnnotations}
                  onSetFullView={onSetFullView}
                  patentId={patentId}
                  removeKeyFeatureDetails={removeKeyFeatureDetails}
                  onGetUserAnnotations={onGetUserAnnotations}
                />
              );
            }
            return null;
          })}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default KeyFeatureListRow;
