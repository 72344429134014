import React, { useEffect } from "react";
import { useState } from "react";
import VisibleIcon from "@rsuite/icons/Visible";
import UnvisibleIcon from "@rsuite/icons/Unvisible";
import {
  exportEmailRequired,
  email,
  passwordRequired,
  required,
} from "../../common/validation";
import { Link } from "react-router-dom";
import "./login.scss";
import { Input, InputGroup } from "rsuite";
import Youtubevideo from "./video-component";

const renderField = ({
  input,
  label,
  type,
  className,
  placeholder,
  meta: { touched, error, warning },
}) => (
  <div>
    {label && <label>{label}</label>}
    <div>
      <div className="input-box">
        <input
          {...input}
          className={className}
          placeholder={placeholder}
          type={type}
        />
      </div>
      {touched &&
        ((error && (
          <div className="text-danger position-absolute">{error}</div>
        )) ||
          (warning && (
            <div className="text-danger position-absolute">{warning}</div>
          )))}
    </div>
  </div>
);

const RwireLogin = (props) => {
  const { onSubmit, loginError, onSetLogin } = props;

  // eslint-disable-next-line no-unused-vars
  const [isExpired, setExpired] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    setExpired(sessionStorage.getItem("expired"));

    sessionStorage.removeItem("expired");
  }, []);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    return onSubmit({
      email: e.currentTarget.email.value,
      password: e.currentTarget.password.value,
    });
  };

  const emailOnChangeHandler = () => {
    onSetLogin({ loginError: " " });
  };

  const passwordOnChangeHandler = () => {
    onSetLogin({ loginError: " " });
  };

  return (
    <div className="login-page-design">
      <div className="login-font row g-0 bg-image">
        <div className="d-none d-md-flex col-md-6 col-lg-6 login-inner-left align-items-center">
          <div className="d-flex flex-column left-side-alignment">
            <p className="left-side-value font-weight-bold">
              Innovate with Insight: Your Gateway to
            </p>

            <p className="prop-header font-weight-bold mb-2">
              Global Patents and Technological Brilliance
            </p>

            <div>
              <p className="para">
                Embark on an odyssey of intellectual ingenuity through our
                patent database —a global patent gateway igniting innovation.
                Explore a realm where ideas converge, exploring a vast patent
                library's canvas of creation. Illuminate your journey with
                intellectual discovery, powered by our comprehensive repository,
                bridging past, future, and curious minds accessing tomorrow's
                ideas today.
              </p>
            </div>

            <div className="original-img mt-4">
              <Youtubevideo
                src="https://www.youtube.com/embed/gt57IcOl7qY"
                title="YouTube video player"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 login-main">
          <div className="d-flex align-items-center py-4 login-page-inner">
            <div className="container container-bg">
              <div className="row">
                <div className="col-md-12 col-lg-11 mx-auto ">
                  <div>
                    <div className="mb-4">
                      <h1 className="login-heading">Welcome to RW</h1>
                      {Boolean(isExpired) ? (
                        <div className="text-danger login-session-error font-weight-bold">
                          Your session has expired, Please login again.
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <form
                      onSubmit={handleFormSubmit}
                      autoComplete="off"
                      method="post"
                      validate={required}
                      className="label-size pt-2"
                    >
                      <label>Email *</label>
                      <div className="form-group mb-3">
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          id="floatingInput"
                          placeholder="Email id"
                          component={renderField}
                          onChange={emailOnChangeHandler}
                          autoComplete="off"
                          validate={[exportEmailRequired, email]}
                        />
                      </div>
                      <label className="pt-3">Password *</label>
                      <div className="form-group mb-3 password-input-section">
                        <InputGroup inside>
                          <Input
                            name="password"
                            type={`${passwordVisible ? "text" : "password"}`}
                            className="form-control password-input"
                            id="floatingPassword"
                            placeholder="Password"
                            component={renderField}
                            onChange={passwordOnChangeHandler}
                            autoComplete="off"
                            validate={passwordRequired}
                          />
                          <InputGroup.Addon
                            className="cursor-pointer"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? (
                              <VisibleIcon color="black" />
                            ) : (
                              <UnvisibleIcon color="#000" />
                            )}
                          </InputGroup.Addon>
                        </InputGroup>
                      </div>

                      <div className="login-remember d-flex flex-column col-md-12 p-0">
                        <div className="d-flex justify-content-end">
                          <Link
                            className="label-size frgt-pass"
                            to="/en/forgot-password"
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="login-text-danger">
                          {loginError ? loginError : ""}
                        </div>
                      </div>
                      <div className="d-grid mt-3">
                        <button className="button p-2 text-black" type="submit">
                          <b>Sign In</b>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RwireLogin;
