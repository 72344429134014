import React, { useState } from "react";
import Image from "../../ui-components/ui-component/image";
import { SummaryVector } from "../../assets/images/report";
import KeyFeatureSuggestions from "../../container/keyfeature-suggestions";
import "./styles.scss";

const PatentKeyFeatures = (props) => {
  const { onSetKeywordModal } = props;
  return (
    <>
      <button
        className="patent-summary-button"
        onClick={() => {
          onSetKeywordModal({ isOpenKeywordSuggestorModal: true });
        }}
      >
        <Image src={SummaryVector} /> Key Features
      </button>
      <KeyFeatureSuggestions />
    </>
  );
};

export default PatentKeyFeatures;
