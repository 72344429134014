import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import RwireCitedReferences from "../../components/report/rwire-cited-references";
import { rwireSearchAPI } from "../../action/rwire-search-api";
import { setFilterFields } from "../../action/result-table"
const Container = (props) => {
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("projectId");
  if (!_id) {
    navigate("/en/existing-project");
  }

  return <RwireCitedReferences {...props} />;
};

const mapStateToProps = ({ project: { citedPatents, citedReferences } }) => ({
  citedPatents,
  citedReferences,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  onRwireSearchAPI: rwireSearchAPI,
  onSetFilter: setFilterFields
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Container);
