import React from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const ThirdSection = () => (
  <div className="third_section pb-5">
    <div className="third_section_heading py-4">Why Choose Researchwire?</div>

    <div className="box-section d-flex px-5">
      <div className="image_section">
        <img
          className="third_section_img_size"
          alt=""
          src={RWIRE_IMAGES.RwireFirstIcon}
        />
      </div>
      <div className="box card_1 mr-2 px-4 py-4">
        <div className="box_heading mb-2">Extensive Coverage</div>
        <div className="box_para">
          Access a global database of patents, ensuring comprehensive coverage
          for your research needs.
        </div>
      </div>

      <div>
        <img
          className="third_section_img_size"
          alt=""
          src={RWIRE_IMAGES.RwireSecondIcon}
        />
      </div>
      <div className="box  card_2 mr-2 px-4 py-4">
        <div className="box_heading  mb-2">Cutting-Edge Tools</div>
        <div className="box_para">
          Utilize advanced search and analytics tools that simplify and enhance
          your patent research process.
        </div>
      </div>

      <div className="">
        <img
          className="third_section_img_size"
          alt=""
          src={RWIRE_IMAGES.RwireThirdIcon}
        />
      </div>
      <div className="box card_3 mr-2 px-4 py-4">
        <div className="box_heading  mb-2">Expert Assistance</div>
        <div className="box_para">
          Gain insights with our analytical tools that help you understand
          patent trends, technological advancements, and market opportunities.
        </div>
      </div>

      <div>
        <img
          className="third_section_img_size"
          alt=""
          src={RWIRE_IMAGES.RwireFourthIcon}
        />
      </div>
      <div className="box card_4 mr-2 px-4 py-4">
        <div className="box_heading  mb-2">Reliable Data</div>
        <div className="box_para">
          Trust in the accuracy and reliability of our up-to-date patent
          information.
        </div>
      </div>

      <div>
        <img
          className="third_section_img_size"
          alt=""
          src={RWIRE_IMAGES.RwireFifthIcon}
        />
      </div>
      <div className="box  card_5  px-4 py-4">
        <div className="box_heading  mb-2">Customer Centric</div>
        <div className="box_para">
          Experience exceptional customer service and tailored support designed
          to meet your specific needs.
        </div>
      </div>
    </div>
  </div>
);

export default ThirdSection;
