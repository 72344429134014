import RWireButton from "../rwire-ui/rwire-button/rwire-button";
import Pagination from "../rwire-ui/rwire-pagination";

const ActionButton = ({ onApply, onClear, isDisable, isClearDisable }) => {
  return (
    <div className="model-footer-buttons">
      <RWireButton
        cNameDiv="search-query ps-3"
        buttonCName={`input-button-text-form ${
          isClearDisable ? "disabled" : ""
        }`}
        name="Clear"
        onClick={() => onClear()}
      />
      <RWireButton
        cNameDiv="search-query ps-3"
        buttonCName={`input-button-text-form ${isDisable ? "disabled" : ""}`}
        name="Apply"
        onClick={onApply}
      />
    </div>
  );
};

const Actions = (props) => {
  const {
    onSetItemPerPage,
    onGetPagination,
    totalRecordsCount,
    itemsPerPage,
    page,
    onSetClassesModal,
    selectedRows,
    onSetPage,
    onClassesSearch,
    onSetClassesSelector,
    onApply,
    tableData = [],
  } = props;

  const handleClear = () => {
    onSetClassesSelector({
      selectedRows: [],
    });
  };

  const handleChange = (p) => {
    onSetClassesSelector({
      dataFrom: (parseInt(p) - 1) * 10,
    });
    onSetPage(p);
    onClassesSearch();
  };

  return (
    <div>
      <div className="pagination-action-buttons">
        <div className="pagination-block mt-2">
          <div className="container d-flex align-items-center justify-content-end text-nowrap">
            {tableData && tableData.length > 0 && (
              <Pagination
                {...props}
                onSetItemPerPage={onSetItemPerPage}
                onGetPagination={onGetPagination}
                total={totalRecordsCount ? totalRecordsCount : 0}
                itemsPerPage={itemsPerPage}
                page={page}
                onSetPage={handleChange}
                isResultFetch={false}
              />
            )}
            <ActionButton
              onSetClassesModal={onSetClassesModal}
              isDisable={selectedRows && selectedRows.length ? false : true}
              isClearDisable={totalRecordsCount <= 1}
              onApply={onApply}
              onClear={handleClear}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
