/* eslint-disable no-useless-escape */
import { useNavigate } from "react-router-dom";
import moment from "moment";
import refreshIcon from "../../images/refresh.svg";
import bookmarkIcon from "../../images/bookmark.svg";
import removeIcon from "../../images/remove.svg";
import { BiEdit } from "react-icons/bi";
import { clearFielded } from "../../common/clear-filded";
import { getChunksData } from "../../common/getChunksData";
import { constructQueries, getHistoryDetailsById } from "../../action/history";

const HistoryRow = (props) => {
  let navigate = useNavigate();

  const {
    isBookmark,
    id,
    numberOfHits,
    query,
    createdAt,
    updatedAt,
    onDeleteHistory,
    onFetchHistory,
    onBookmarkHistory,
    selectedRows,
    onSetSelectedRows,
    onSetExportSelected,
    selectedItemList,
    onSetApp,
    setEditQuery,
    userSearchNumber,
    onPerformSearch,
    clickOnEditQuery,
    selectedRowsForCombine,
    onSetItemPerPage,
    historyTableRow = {},
    setSelectedField,
    onSetSelectedCombineQuery,
    onSetSelectedCombineQueryId,
  } = props;

  const deleteRecord = async () => {
    await onDeleteHistory(id);
    await onFetchHistory();
    const updatedSelectedRowsForCombine = selectedRowsForCombine.filter((item) => {
      return `${item}` !== `${userSearchNumber}`;
    });
    onSetItemPerPage({ selectedRowsForCombine: updatedSelectedRowsForCombine });
  };

  const addBookMarkRecord = () => {
    onBookmarkHistory(id, !Boolean(isBookmark));
  };
  const getUpdatedCombinedQuery = async () => {
    let detailsArray = await getHistoryDetailsById({
      ids: historyTableRow.combine_queries,
    });

    const query = constructQueries(
      historyTableRow.combine_queries,
      historyTableRow.operators,
      detailsArray
    );
    return query[1];
  };
  const handleRunQuery = async () => {
    const finalQuery = historyTableRow.is_combined
      ? await getUpdatedCombinedQuery()
      : query;
    onSetApp({ searchQuery: finalQuery });
    onPerformSearch(
      finalQuery,
      {
        isReRunId: id,
        isResetHighlight: true,
      },
      navigate,
      "/en/rwire-patents"
    );
  };

  let selectedId = [];
  let selectedRowNumber = [];

  const handleCheckbox = (e) => {
    selectedId = [...selectedRows, `${id}`];
    selectedRowNumber = [...selectedRowsForCombine, `${userSearchNumber}`];
    if (!e.target.checked) {
      selectedId = selectedId.filter((item) => {
        return `${item}` !== `${id}`;
      });
    }
    if (!e.target.checked) {
      selectedRowNumber = selectedRowNumber.filter((item) => {
        return `${item}` !== `${userSearchNumber}`;
      });
    }
    onSetItemPerPage({ selectedRowsForCombine: selectedRowNumber });

    const selectedList = historyTableRow;
    if (e.target.checked) {
      selectedItemList.push(selectedList);
      onSetExportSelected({ selectedItemList: selectedItemList });
    } else if (!e.target.checked) {
      const filteredNumbers = selectedItemList.filter((element) => {
        return element.id !== selectedList.id;
      });
      onSetExportSelected({ selectedItemList: filteredNumbers });
    }

    onSetSelectedRows({ selectedRows: selectedId });
  };
  const isNumberSearchHistory = (string) => {
    const allowedKeywords = [
      "PN",
      "AN",
      "PRN",
      "PN_B",
      "AN_B",
      "PAPR",
      "PA",
      "PPR",
      "APR",
    ];
    string = string.match(/([^=]+)(?=\>\=|\<\=|\=)/)
      ? string.match(/([^=]+)(?=\>\=|\<\=|\=)/)[1]
      : string;
    const words = string.split(" OR ");

    // Check if any of the words are not in the list of allowed keywords
    for (const word of words) {
      if (!allowedKeywords.includes(word)) {
        return false;
      }
    }
    return true;
  };
  const handleEditQuery = async () => {
    if (historyTableRow.is_combined) {
      setSelectedField("Combine");
      onSetSelectedCombineQuery(
        combineQueries(
          historyTableRow.combine_queries,
          historyTableRow.operators
        )
      );
      onSetSelectedCombineQueryId(id);
    } else {
      onSetSelectedCombineQuery("");
      onSetSelectedCombineQueryId(null);
      const clearData = clearFielded(props.queryFields);
      props.onSetAllField({
        queryFields: clearData,
        tabWiseSearchQuery: { fielded: query },
      });

      setEditQuery(query);
      onSetApp({
        editQuery: query,
        clickOnEditQuery: !clickOnEditQuery,
        editQueryId: id,
        isNumberSearchHistory: isNumberSearchHistory(query),
        editEnableOnSave: true,
      });

      let { equations, operators, input } = getChunksData(query);
      await onSetApp({
        chunksArray: equations,
        operatorsArray: operators,
        inputsValue: input,
      });
    }
  };
  const combineQueries = (combine_queries, operators) => {
    let query = "";

    // Use a loop to construct the query string with the desired operators
    for (let i = 0; i < combine_queries.length; i++) {
      query += combine_queries[i];
      if (i < operators.length) {
        query += ` ${operators[i]} `;
      }
    }
    return query;
  };

  return (
    <div className={`table-row ${isBookmark ? "active" : ""} edit-row`}>
      <div className="table-column regular-checkbox form-check regular-checkbox">
        <input
          className={`form-check-input cursor-pointer ${
            selectedRows.includes(`${id}`) ? "checked" : ""
          }`}
          key={id}
          type="checkbox"
          defaultChecked={selectedRows.includes(`${id}`)}
          value={id}
          onChange={handleCheckbox}
        />
      </div>
      <div className="table-column  numbers">{userSearchNumber}</div>
      <div className="table-column  records">{numberOfHits}</div>
      <div
        className="table-column  search-query query-column d-flex justify-content-between gap-2"
        title={
          historyTableRow?.is_combined
            ? combineQueries(
                historyTableRow.combine_queries,
                historyTableRow.operators
              )
            : query
        }
      >
        <div className="edit-query-text">
          {historyTableRow?.is_combined
            ? combineQueries(
                historyTableRow.combine_queries,
                historyTableRow.operators
              )
            : query}
        </div>

        <div className="edit-button">
          <BiEdit
            alt="edit"
            onClick={handleEditQuery}
            className="img-fluid edit-button-icon"
            width="30"
            height="30"
          />
        </div>
      </div>
      <div className="table-column  date-time">
        {moment.unix(updatedAt).format("DD-MM-YYYY HH:mm:ss")}
      </div>
      <div className="table-column  run">
        <img
          alt=""
          onClick={handleRunQuery}
          className="img-fluid search_history_icon cursor-pointer"
          src={refreshIcon}
        />
      </div>
      <div className="table-column  bookmark">
        <img
          alt=""
          onClick={addBookMarkRecord}
          className="img-fluid rwire-img cursor-pointer"
          src={bookmarkIcon}
        />
      </div>
      <div className="table-column delete ">
        <img
          alt=""
          onClick={deleteRecord}
          className="img-fluid rwire-imgs cursor-pointer"
          src={removeIcon}
        />
      </div>
    </div>
  );
};

export default HistoryRow;
