export const isImageFile = (str) => {
  const imageTypes = ["jpg", "jpeg", "png", "image/jpeg", "image/png"];
  return imageTypes.includes(str.toLowerCase());
};

export const isPDFFile = (str) => {
  const pdfTypes = ["pdf", "application/pdf"];
  return pdfTypes.includes(str.toLowerCase());
};

export const isVideoFile = (str) => {
  const videoTypes = [
    "mp4",
    "avi",
    "mkv",
    "video/mp4",
    "video/avi",
    "video/x-matroska",
  ];
  return videoTypes.includes(str.toLowerCase());
};

export const isAudioFile = (str) => {
  const audioTypes = [
    "mp3",
    "wav",
    "ogg",
    "audio/mp3",
    "audio/wav",
    "audio/ogg",
  ];
  return audioTypes.includes(str.toLowerCase());
};

export const isTXTFile = (str) => {
  const txtTypes = ["txt", "plain", "text/plain"];
  return txtTypes.includes(str.toLowerCase());
};

export const isCSVFile = (str) => {
  const csvTypes = ["csv", "text/csv"];
  return csvTypes.includes(str.toLowerCase());
};

export const isJSONFile = (str) => {
  const jsonTypes = ["json", "application/json"];
  return jsonTypes.includes(str.toLowerCase());
};

export const isXMLFile = (str) => {
  const xmlTypes = ["xml", "application/xml"];
  return xmlTypes.includes(str.toLowerCase());
};

export const isZIPFile = (str) => {
  const zipTypes = ["zip", "application/zip"];
  return zipTypes.includes(str.toLowerCase());
};
export const isPPTXFile = (str) => {
  const pptxTypes = [
    "pptx",
    "ppt",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.ms-powerpoint",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  ];
  return pptxTypes.includes(str.toLowerCase());
};

export const isDOCFile = (str) => {
  const docTypes = [
    "doc",
    "docx",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/wps-office.docx",
  ];
  return docTypes.includes(str.toLowerCase());
};

export const isXLSFile = (str) => {
  const xlsTypes = [
    "xls",
    "xlsx",
    "application/wps-office.xlsx",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  ];
  return xlsTypes.includes(str.toLowerCase());
};
