import { isObjectBlank } from "../../../action/result-table";
export const removeTags = (inputString) => {
  var pattern = /<.*?>/g;
  var resultString = inputString.replaceAll(pattern, "");
  return resultString;
};
export const filterByType = (data, targetType) => {
  if (data && data.length > 0) {
    return data.filter((item) => item.type === targetType);
  } else return [];
};

export const filterNonBlank = (data = []) => {
  return data.filter((item) => {
    if ("title" in item) {
      // Case 1: 'title' field exists
      return item.title.trim() !== "" || item.body.trim() !== "";
    } else if ("classes" in item) {
      // Case 2: 'classes' field exists
      return item.classes.trim() !== "" || item.body.trim() !== "";
    } else {
      // Case 3: 'title' field doesn't exist
      return item.body.trim() !== "";
    }
  });
};
export const getTodaysDate = () => {
  let today = new Date();
  return `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
};
const transformKeyFeaturePatents = (keyFeaturePatents) => {
  return keyFeaturePatents.map((item, index) => ({
    "Key Feature": `Key Feature-${index + 1}`,
    Definition: `${removeTags(item.Defination)}`,
    Patents: item.patents,
  }));
};
export const getExcelDownloadableData = (data = {}) => {
  if (isObjectBlank(data)) return [];
  // console.log(data);
  const multiSheetData = [];
  multiSheetData.push({
    projectName: data.name,
  });
  // Transform 'keyFeatures' data
  const keyFeaturesSheet = {
    sheetName: "Key Features",
    details: filterNonBlank(data.keyFeatures).map((feature, index) => ({
      "Sr. No": `Key Feature-${index + 1}`, // Assuming you want to include 'id' as 'title'
      Features: removeTags(feature.body),
    })),
    tableDescription:
      "The following important key features are identified based on the Prosecution history (if available) and claims of the subject patent:",
  };
  multiSheetData.push(keyFeaturesSheet);

  // Transform 'keywords' data
  const keywordsSheet = {
    sheetName: "Keywords",
    details: filterNonBlank(data.keywords).map((keyword) => ({
      Keywords: removeTags(keyword.title),
      Synonyms: removeTags(keyword.body),
    })),
    tableDescription:
      "The following key words are identified for the keyword based searching. Keywords are selected from the subject patent. Synonyms are included to keep the search broad and exhaustive.",
  };
  multiSheetData.push(keywordsSheet);

  // Transform 'cpcClasses' data
  const cpcClassesSheet = {
    sheetName: "Relevant CPC Classes",
    details: filterNonBlank(data.cpcClasses).map((cpcClass) => ({
      "CPC Classes": removeTags(cpcClass.classes),
      Definition: removeTags(cpcClass.body),
    })),
    tableDescription:
      "Following is the list of relevant CPC classes identified during the search:",
  };
  multiSheetData.push(cpcClassesSheet);

  // Transform 'ipcClasses' data
  const ipcClassesSheet = {
    sheetName: "Relevant IPC Classes",
    details: filterNonBlank(data.ipcClasses).map((ipcClass) => ({
      "IPC Classes": removeTags(ipcClass.classes),
      Definition: removeTags(ipcClass.body),
    })),
    tableDescription:
      "Following is the list of relevant IPC classes identified during the search:",
  };
  multiSheetData.push(ipcClassesSheet);

  // Transform 'usClasses' data
  const usClassesSheet = {
    sheetName: "Relevant US Classes",
    details: filterNonBlank(data.usClasses).map((usClass) => ({
      "US Classes": removeTags(usClass.classes),
      Definition: removeTags(usClass.body),
    })),
    tableDescription:
      "Following is the list of relevant US classes identified during the search:",
  };
  multiSheetData.push(usClassesSheet);

  // Transform 'assignees' data
  const assigneesSheet = {
    sheetName: "Key Assignees",
    details: filterNonBlank(data.assignees).map((assignee, index) => ({
      "Sr. No": index + 1,
      "Key Assignees": removeTags(assignee.body),
    })),
    tableDescription:
      "Following is the list of dominant assignees identified during the search:",
  };
  multiSheetData.push(assigneesSheet);

  // Transform 'inventors' data
  const inventorsSheet = {
    sheetName: "Key Inventors",
    details: filterNonBlank(data.inventors).map((inventor, index) => ({
      "Sr. No": index + 1,
      Inventors: removeTags(inventor.body),
    })),
    tableDescription:
      "Following is the list of dominant inventors identified during the search:					",
  };
  multiSheetData.push(inventorsSheet);

  // Transform 'strings' data
  const stringsSheet = {
    sheetName: "Relevant Search Strings",
    details: filterNonBlank(data.strings).map((string) => ({
      Strings: removeTags(string.body),
      Count: `${string.applicationCount}/${string.publicationCount}`,
    })),
    tableDescription:
      "Following is the list of relevant strings identified during the search:",
  };
  multiSheetData.push(stringsSheet);
  const keyFeaturePatentsSheet = {
    sheetName: "Relevant Results",
    details: transformKeyFeaturePatents(data.keyFeaturePatents), // Transform keyFeaturePatents data
    tableDescription: "Description of the key feature patents...",
  };
  multiSheetData.push(keyFeaturePatentsSheet);

  const patentKeyFeaturesSheet = {
    sheetName: "Patent Key Features",
    details: data.patentKeyFeatures.map((patent) => ({
      "Publication Number": patent.publicationNumber,
      "Available Key Features No.": patent.availableKeyFeatures.join(", "),
      "Not Available Key Features No.": patent.notAvailableKeyFeatures.join(", "),
    })),
    tableDescription: "Description of patent key features...",
  };
  multiSheetData.push(patentKeyFeaturesSheet);

  return multiSheetData;
};
