import React, { useEffect, useState } from "react";
import { Button, InternalInput, Modal } from "../../../ui-components";

import ClaimsResultsTable from "./claims-results-table";
import ManualClaimsSection from "./manual-claims-section";
import RwireLoader from "../../../container/rwire-loader";

const ImportClaimsModal = (props) => {
  const {
    isOpen,
    onClose,
    onSetPatentClaims,
    onSetData,
    isLoadingClaims,
    tableData,
    projectPatentNumber = "",
  } = props;
  const [patentNumber, setPatentNumber] = useState("");
  const [isClaimsFetched, setIsClaimsFetched] = useState(false);
  const [isManuallyEntering, setIsManuallyEntering] = useState(false);
  const closeModalHandle = () => {
    onClose();
    onSetData({ claimsData: [], searchedPatentNumber: "" });
  };
  useEffect(() => {
    setPatentNumber(projectPatentNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchHandle = (e) => {
    // e.preventDefault();
    const trimmedPatentNumber = patentNumber.trim();
    onSetPatentClaims(trimmedPatentNumber);
    if (trimmedPatentNumber) {
      setIsClaimsFetched(true);
      setIsManuallyEntering(false);
    }
  };
  return (
    <Modal
      isOpen={isOpen}
      isStatic={false}
      overlayClass="import-claims-modal"
      className="import-claims-modal-body"
    >
      <div className="header-section">
        <div>Patent Number</div>
        <div onClick={closeModalHandle} className="cursor-pointer">
          X
        </div>
      </div>
      <div
        className={`input-section ${
          isClaimsFetched || isManuallyEntering
            ? "justify-content-end"
            : "flex-column align-items-left"
        } `}
      >
        <InternalInput
          type="text"
          inputName="publicationNumber"
          placeholder="Enter Patent Number"
          InputValue={patentNumber}
          onSetValue={(_, value) => setPatentNumber(value)}
          InputClassName="patent-number-input"
          containerClass={`input-container ${
            isManuallyEntering || isClaimsFetched ? "" : "w-100"
          }`}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              fetchHandle();
            }
          }}
        />
        <div className="d-flex gap-2 import-buttons">
          {!isClaimsFetched && !isManuallyEntering && (
            <Button
              className="manual-data-button"
              onClick={() => {
                setIsManuallyEntering(true);
              }}
            >
              Enter Claim Manually
            </Button>
          )}
          <Button className="fetch-data-button" onClick={fetchHandle}>
            Fetch Data
          </Button>
        </div>
      </div>
      {isManuallyEntering && (
        <ManualClaimsSection
          {...props}
          onCloseModal={closeModalHandle}
          tableData={tableData}
        />
      )}

      {isClaimsFetched &&
        !isManuallyEntering &&
        (isLoadingClaims ? (
         <RwireLoader/>
        ) : (
          <ClaimsResultsTable
            {...props}
            setPatentNumber={setPatentNumber}
            setIsClaimsFetched={setIsClaimsFetched}
            setIsManuallyEntering={setIsManuallyEntering}
            tableData={tableData}
          />
        ))}
    </Modal>
  );
};

export default ImportClaimsModal;
