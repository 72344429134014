import { MAX_TABS, PATENT_VIEW_PATH_TEMPLATE } from "../components/constant";

export const openPatentInNewTab = (
  id,
  locale,
  openTabs,
  setOpenTabs,
  setMessageModalContent,
  setVisibilityMessageModal
) => {
  const frontUrl = window.location.href.includes("/front") ? "/front" : "";

  const path = PATENT_VIEW_PATH_TEMPLATE.replace("{frontUrl}", frontUrl)
    .replace("{locale}", locale)
    .replace("{id}", id);

  if (openTabs.length < MAX_TABS) {
    const newTab = window.open(path, "_blank");
    setOpenTabs([...openTabs, { name: id, tab: newTab }]);
    if (newTab) {
      newTab.focus();
    }
  } else {
    setMessageModalContent("Maximum Tab Limit Reached");
    setVisibilityMessageModal(true);
  }
};
