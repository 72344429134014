import { useState, useEffect } from "react";
import "../rwire-report-clasess/style.scss";
import { Block } from "../../../ui-components";
import {
  ReportSortableTable,
  ReportKeywordModal,
} from "../../../container/report";
import { arrayMove } from "react-sortable-hoc";
import { updateSortOrder } from "../../../ui-components/functions/notes/utils";

const RwireReportKeyword = (props) => {
  const {
    onSetReportKeyword,
    reportTableData,
    column,
    onUpdateKeywordsSortOrder,
    onUpdateReportProjectKeywordsRow,
    onAddRowToreportProject,
    onDeleteRowFromReportProject,
    onGetReportProjectKeywords,
  } = props;
  const [isKeywordModalOpen, setKeywordModalOpen] = useState(false);

  // As component renders immediate fetch keywords data
  useEffect(() => {
    const fetchKeywordData = async () => {
      try {
        await onGetReportProjectKeywords();
      } catch (error) {
        console.error(error);
      }
    };
    fetchKeywordData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    onSetReportKeyword({
      reportTableData: arrayMove(reportTableData, oldIndex, newIndex),
    });
    const bodyData = updateSortOrder(reportTableData, oldIndex, newIndex);
    await onUpdateKeywordsSortOrder(bodyData);
  };

  const handleSetKeywordModal = () => {
    setKeywordModalOpen(true);
  };

  const handleChange = (event, rowIndex, options) => {
    const value = event.target.value;
    const updatedTableData = reportTableData.map((i) => {
      if (i.id === rowIndex && options.isChangeText) {
        return {
          ...i,
          title: value,
        };
      } else if (i.id === rowIndex && options.isChangeDesc) {
        return {
          ...i,
          body: value,
        };
      }
      return {
        ...i,
      };
    });

    onSetReportKeyword({
      reportTableData: [...updatedTableData],
    });
  };
  const handleSaveInDb = async (value, rowIndex, idx, options) => {
    if (options.isChangeText && reportTableData[idx].title !== value) {
      await onUpdateReportProjectKeywordsRow({
        id: rowIndex,
        body: {
          title: value,
        },
      });
    } else if (options.isChangeDesc && reportTableData[idx].body !== value) {
      await onUpdateReportProjectKeywordsRow({
        id: rowIndex,
        body: {
          body: value,
        },
      });
    }
  };

  const handleAddRow = async (rowIndex) => {
    // let lastId = findGreatestId([...reportTableData]);
    // const updatedTableData = [...reportTableData];
    // const newRow = { title: "", id: lastId + 1, body: "" };

    // updatedTableData.splice(rowIndex + 1, 0, newRow);

    // onSetReportKeyword({
    //   reportTableData: updatedTableData,
    // });
    const _id = sessionStorage.getItem("projectId");
    await onAddRowToreportProject({
      data: [
        {
          title: "",
          body: "",
          order: parseInt(`${rowIndex + 1}`),
        },
      ],
      projectId: parseInt(_id),
    });

    await onGetReportProjectKeywords();
  };

  const handleDeleteRow = async (rowIndex, id) => {
    if (reportTableData.length === 1) {
      return;
    }
    // const updatedTableData = [...reportTableData];
    // updatedTableData.splice(rowIndex, 1);
    // onSetReportKeyword({
    //   reportTableData: updatedTableData,
    // });

    await onDeleteRowFromReportProject(id);
    await onGetReportProjectKeywords();
  };

  return (
    <Block className="rwire-report-keyword">
      <Block>
        <ReportSortableTable
          columns={column}
          data={reportTableData}
          onSetData={onSetReportKeyword}
          isAddExtraClass={true}
          pressDelay={100}
          useDragHandle
          className="w-100"
          onSortEnd={onSortEnd}
          onOpenModal={handleSetKeywordModal}
          onCellChange={(value, rowIndex, options) =>
            handleChange(value, rowIndex, options)
          }
          onSaveInDb={(value, rowIndex, id, options) =>
            handleSaveInDb(value, rowIndex, id, options)
          }
          onAddRowBelow={(rowIndex) => handleAddRow(rowIndex)}
          onDeleteRow={(rowIndex, id) => handleDeleteRow(rowIndex, id)}
          workspaceId="2"
        />
      </Block>
      {isKeywordModalOpen && (
        <ReportKeywordModal
          isKeywordModalOpen={isKeywordModalOpen}
          onCloseModal={() => setKeywordModalOpen(false)}
          {...props}
        />
      )}
    </Block>
  );
};

export default RwireReportKeyword;
