import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ImArrowRight2, ImArrowLeft2 } from "react-icons/im";
import loading from "../../assets/images/loader.gif";

const PatentsActionButtons = (props) => {
  const { onSetFilter, onSetImages } = props;
  const [currentId, setCurrentId] = useState(null);
  const [prevId, setPrevId] = useState(null);
  const [nextId, setNextId] = useState(null);
  const [displayDataIds, setDisplayDataIds] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const storedIds = JSON.parse(localStorage.getItem("storedPatentIds"));
    if (storedIds) {
      setDisplayDataIds(storedIds);
    }
  }, []);

  useEffect(() => {
    const storedIds = JSON.parse(localStorage.getItem("storedPatentIds"));
    const patentIdFromStorage = sessionStorage.getItem("patentId");
    if (storedIds && patentIdFromStorage) {
      setCurrentId(patentIdFromStorage);
      const currentIndex = storedIds.indexOf(patentIdFromStorage);
      setPrevId(currentIndex > 0 ? storedIds[currentIndex - 1] : null);
      setNextId(currentIndex < storedIds.length - 1 ? storedIds[currentIndex + 1] : null);
    }
  }, [displayDataIds]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "ArrowLeft" && prevId) {
        handlePrevious();
      } else if (event.key === "ArrowRight" && nextId) {
        handleNext();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [prevId, nextId]);

  const handlePrevious = () => {
    onSetImages([loading]);
    const currentIndex = displayDataIds.indexOf(currentId);
    if (currentIndex > 0) {
      const newPrevId = displayDataIds[currentIndex - 1];
      setPrevId(newPrevId);
      setNextId(currentId);
      setCurrentId(newPrevId);
      sessionStorage.setItem("patentId", newPrevId);
      onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
      navigate(`/en/patent-view/${newPrevId}`);
    }
    setPrevId(displayDataIds[currentIndex - 2] || null);
  };

  const handleNext = () => {
    onSetImages([loading]);
    const currentIndex = displayDataIds.indexOf(currentId);
    if (currentIndex < displayDataIds.length - 1) {
      const newNextId = displayDataIds[currentIndex + 1];
      setPrevId(currentId);
      setNextId(newNextId);
      setCurrentId(newNextId);
      sessionStorage.setItem("patentId", newNextId);
      onSetFilter({ isViewPageModalOpen: false, isSmartReaderOpen: false });
      navigate(`/en/patent-view/${newNextId}`);
    }
    setNextId(displayDataIds[currentIndex + 2] || null);
  };

  return (
    <div className="d-flex gap-3">
      {prevId && (
        <div
          className="d-flex gap-1 align-items-center cursor-pointer"
          title={prevId}
          onClick={handlePrevious}
        >
          <div className="action-arrow">
            <ImArrowLeft2 />
          </div>
          <div className="action-text">Prev</div>
        </div>
      )}
      {nextId && (
        <div
          className="d-flex gap-1 align-items-center cursor-pointer"
          title={nextId}
          onClick={handleNext}
        >
          <div className="action-arrow">
            <ImArrowRight2 />
          </div>
          <div className="action-text">Next</div>
        </div>
      )}
    </div>
  );
};

export default PatentsActionButtons;
