import React from 'react';
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const WhyChooseUs = ({ options, selectedOptions, handleClick }) => (
    <div className="fourth_part d-flex py-4 px-5">
      <div className="fourth_right_side mr-3">
        <div className="choose_text ml-2">Why Choose Us</div>
        <div className="choose_us_buttons cursor-pointer">
          {options.map((option, index) => (
            <div
              key={index}
              className={`d-flex justify-content-between px-3 option_button ${selectedOptions.title === option.title ? 'selected' : ''}`}
              onClick={() => handleClick(option)}
            >
              <div>{option.title}</div>
              <div className="">
                <img src={RWIRE_IMAGES.RwireServicesArrow} alt="" className="arrow_image" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {selectedOptions && (
        <div className="fourth_left_side">
          <p className="option_heading mt-2">{selectedOptions.title}</p>
          <p className="option_para">{selectedOptions.description}</p>
        </div>
      )}
    </div>
  );

export default WhyChooseUs