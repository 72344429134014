import React, { useEffect, useRef, useState } from "react";
import { Block, Button, Image, InternalInput, Span } from "../index";
import useClickOutside from "../custom-hooks/useClickoutside";
import { DownArrow } from "../../assets/images/report";

const Dropdown = ({
  options = [],
  inputLabel = "",
  isRequired,
  className = "",
  isCustomInput = true,
  onChange,
  placeholder = "Select Options",
}) => {
  const [dropdownOptions, setDropdownOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [customInput, setCustomInput] = useState("");
  const [isAddingCustom, setIsAddingCustom] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    setDropdownOptions(options);
  }, [options]);
  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
    setIsAddingCustom(false);
    setCustomInput("");
  };

  const handleOptionChange = (value) => {
    setSelectedOption(value.label);
    toggleDropdown();
    onChange(value);
  };

  const handleCustomInputChange = (value) => {
    setCustomInput(value);
  };

  const handleAddCustomOption = () => {
    if (customInput.trim() !== "") {
      const newOption = {
        id: dropdownOptions.length + 1,
        label: customInput.trim(),
      };
      const updatedOptions = [...dropdownOptions, newOption];
      setSelectedOption(newOption.label);
      setIsAddingCustom(false);
      setDropdownOptions(updatedOptions);
      setCustomInput("");
    }
  };
  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
  });
  return (
    <Block className={`dropdown internal-dropdown ${className}`}>
      <Block className="input-label-text">
        {inputLabel}
        {isRequired && <Span className="required-star"> *</Span>}
      </Block>
      <div ref={dropdownRef}>
        <div className="dropdown-box" onClick={toggleDropdown}>
          <button
            className="btn  ellipsis"
            type="button"
            id="multiSelectDropdown"
          >
            {selectedOption !== "" ? selectedOption : placeholder}
          </button>
          <Image src={DownArrow} alt="" />
        </div>
        {isOpen && (
          <div
            className={`dropdown-menu ${isOpen ? "show" : ""}`}
            ariaLabelledby="multiSelectDropdown"
          >
            {dropdownOptions.map((option) => (
              <Block
                key={option.id}
                className="form-check form-check-without-icon"
                onClick={() =>
                  handleOptionChange({ label: option.label, id: option.id })
                }
              >
                <label className="form-check-label">{option.label}</label>
              </Block>
            ))}
            {isCustomInput && (
              <>
                {isAddingCustom ? (
                  <Block className="form-check form-check-without-icon d-flex align-items-center">
                    <InternalInput
                      type="text"
                      inputName="addCustom"
                      placeholder="Enter custom option"
                      InputValue={customInput}
                      onSetValue={(name, value) =>
                        handleCustomInputChange(value)
                      }
                      InputClassName="custom-input-field"
                    />
                    <Button
                      onClick={handleAddCustomOption}
                      className="custom-input-button"
                    >
                      Add
                    </Button>
                  </Block>
                ) : (
                  <Block
                    className="form-check form-check-with-icon"
                    onClick={() => setIsAddingCustom(true)}
                  >
                    <Image className="form-check-input" src={""}></Image>
                    <label className="form-check-label">
                      Add Custom Workspace
                    </label>
                  </Block>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </Block>
  );
};

export default Dropdown;
