import React from "react";
import { Block, Image, Span } from "../index";

const InternalInput = (props) => {
  const {
    isReveal = true,
    isInputWithButton = false,
    srcImg,
    ImgClassName = "",
    InputClassName = "",
    inputLabel = "",
    isRequired = false,
    placeholder = "",
    InputValue = "",
    onSetValue,
    OnSetIsReveal,
    inputName = "",
    imgTitle = "",
    onFocus,
    onBlur,
    containerClass = "",
    onKeyDown,
    onClickIcon,
  } = props;
  return (
    <Block className={`internal-input-container ${containerClass}`}>
      {inputLabel && (
        <Block className="input-label-text">
          {inputLabel}
          {isRequired && <Span className="required-star"> *</Span>}
        </Block>
      )}
      <Block className="input-box">
        <input
          name={inputName}
          placeholder={placeholder}
          className={InputClassName}
          type={isReveal ? "text" : "password"}
          value={InputValue}
          onChange={(e) =>
            onSetValue && onSetValue(e.target.name, e.target.value)
          }
          required={isRequired}
          onFocus={() => {
            onFocus && onFocus();
          }}
          onKeyDown={onKeyDown}
          onBlur={() => {
            onBlur && onBlur();
          }}
        />
        {isInputWithButton && (
          <Image
            title={imgTitle}
            src={srcImg}
            onClick={() => {
              OnSetIsReveal &&
                OnSetIsReveal({ [`${inputName}_reveal`]: !isReveal });

              onClickIcon && onClickIcon();
            }}
            alt=""
            className={ImgClassName}
          />
        )}
      </Block>
    </Block>
  );
};

export default InternalInput;
