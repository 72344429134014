export const chartList = [
  {
    id: "innovation-trend",
    type: "line",
    field1: "PRY",
    field2: "PN_B",
    chartMainTitle: "Innovation Trend",
    isDelete: false,
    availableChartList: [
      "line",
      "column2D",
      "bar2D",
      "pie2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "pie3d",
      "doughnut3d",
    ],
  },
  {
    id: "research-center",
    type: "maps/worldwithcountries",
    field1: "PRC",
    field2: "PN_B",
    extraChartconfig: {
      showLabels: "0",
      LegendPosition: "bottom",
    },
    chartMainTitle: "Research Centers",
    isDelete: false,
    isCountryMap: true,
    availableChartList: [
      "column2D",
      "bar2D",
      "pie2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "doughnut3d",
      "maps/worldwithcountries",
    ],
  },
  {
    id: "countrywise-patent-records",
    type: "maps/worldwithcountries",
    field1: "PNC",
    field2: "PN_B",
    extraChartconfig: {
      showLabels: "0",
      LegendPosition: "bottom",
    },
    chartMainTitle: "Geographical Reach",
    isDelete: false,
    isCountryMap: true,
    availableChartList: [
      "column2D",
      "bar2D",
      "pie2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "doughnut3d",
      "maps/worldwithcountries",
    ],
  },
  {
    id: "filing-trend",
    type: "line",
    field1: "AY",
    field2: "PN_B",
    chartMainTitle: "Filing Trend",
    isDelete: false,
    availableChartList: [
      "line",
      "column2D",
      "bar2D",
      "pie2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "pie3d",
      "doughnut3d",
    ],
  },
  {
    id: "top-standardized-assignee",
    type: "bar2d",
    field1: "AAPS",
    field2: "PN_B",
    chartMainTitle: "Top Standardized Assignee",
    isDelete: false,
    availableChartList: [
      "column2D",
      "bar2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "treemap",
    ],
  },
  {
    id: "top-inventor-name",
    type: "bar2d",
    field1: "IN_EN",
    field2: "PN_B",
    chartMainTitle: "Top Inventor Name",
    isDelete: false,
    availableChartList: [
      "column2D",
      "bar2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "treemap",
    ],
  },
  {
    id: "assignee-citations",
    type: "splinearea",
    field1: "AAPS",
    field2: "BCP",
    chartMainTitle: "Top Standardized Assignee - Backward Citation",
    isDelete: false,
    availableChartList: [
      "column2D",
      "bar2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "treemap",
    ],
  },
  {
    id: "inventor-citations",
    type: "bar2D",
    field1: "IN_EN",
    field2: "BCP",
    chartMainTitle: "Top Inventor - Backward Citation",
    isDelete: false,
    availableChartList: [
      "column2D",
      "bar2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "treemap",
    ],
  },
  {
    id: "ipc-ay",
    type: "msline",
    field1: "IPC",
    field2: "AY",
    extraChartconfig: {
      xAxisName: "Application year",
      yAxisName: "IPC count",
    },
    isMultiSeries: true,
    chartMainTitle: "IPC Filing Trend",
    isDelete: false,
    availableChartList: [
      "msline",
      "mscolumn2D",
      "msbar2D",
      "mssplinearea",
      "mscolumn3d",
      "msbar3d",
    ],
  },
  {
    id: "ipc-pn",
    type: "bar2d",
    field1: "IPC",
    field2: "PN_B",
    chartMainTitle: "Top IPC Classes",
    isDelete: false,
    availableChartList: [
      "column2D",
      "bar2D",
      "splinearea",
      "doughnut2D",
      "column3d",
      "bar3d",
      "pie3d",
      "treemap",
    ],
  },
  {
    id: "asd-ipc",
    type: "stackedbar2D",
    field1: "AAPS",
    field2: "IPC",
    isMultiSeries: true,
    orderBy: "desc",
    sortBy: "_count",
    extraChartconfig: {
      showLabels: "0",
      LegendPosition: "bottom",
    },
    chartMainTitle: "Standardized Assignee Vs IPC Class (Technology)",
    isDelete: false,
    availableChartList: [
      "stackedbar2D",
      "stackedcolumn2D",
      "stackedbar3D",
      "stackedcolumn3D",
    ],
  },
];

export const availableChartList = [
  "line",
  "column2D",
  "bar2D",
  "pie2D",
  "splinearea",
  "doughnut2D",
  "column3d",
  "pie3d",
  "doughnut3d",
];

export const availableMultiSeriesChartList = [
  "msline",
  "mscolumn2D",
  "msbar2D",
  "mssplinearea",
  "mscolumn3d",
  "msbar3d",
];
