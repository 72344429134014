import React, { useState } from "react";
import moment from "moment";
import { Block, Image, Span, SlidingSidePanel } from "../../../ui-components";
import {
  RightBlackIcon,
  deleteIcon,
  EditBlackIcon,
} from "../../../assets/images/report";
import ReportCollaboration from "../../../container/report/report-collaboration";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../../common/user-roles";
const RelevantResultsTableRow = (props) => {
  const {
    onHandleModal,
    reportTableData,
    index,
    row,
    onHandleRemove,
    onRedirect,
  } = props;

  const [commentingRowID, setCommentingRowID] = useState(null);
  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();
  const getData = (featuresData = []) => {
    let greenElement = [];
    let redElement = [];
    const featuresId = featuresData.map((i) => i.keyFeature);
    reportTableData
      .filter((f) => f.body)
      .forEach((i, key) => {
        if (featuresId.includes(i.id)) {
          greenElement.push(key + 1);
        } else {
          redElement.push(key + 1);
        }
      });
    return (
      <>
        {greenElement &&
          greenElement.length > 0 &&
          greenElement.map((ele, index) => (
            <span className={`success`}>
              {ele} {index + 1 === greenElement.length ? "" : ", "}
            </span>
          ))}
        <span
          className={`${
            greenElement.length && redElement.length ? "px-2" : ""
          }`}
        >
          {greenElement.length && redElement.length ? "|" : ""}
        </span>
        {redElement &&
          redElement.length > 0 &&
          redElement.map((ele, index) => (
            <span className={`danger text-break`}>
              {ele} {index + 1 === redElement.length ? "" : ", "}
            </span>
          ))}
      </>
    );
  };

  return (
    <>
      <tr className={`${index % 2 === 0 ? "odd-row" : "even-row"}`}>
        <td
          className="fw-bold publication-no"
          onClick={() => onRedirect(row.patentNumber)}
        >
          {row.patentNumber}
        </td>
        <td className="title">{row.title ? row.title : "N/A"}</td>
        <td className="publication-date">
          {row.publicationDate
            ? moment(row.publicationDate).format("DD/MM/YYYY")
            : "N/A"}
        </td>
        <td className="priority-date">
          {row.priorityDate
            ? moment(row.priorityDate).format("DD/MM/YYYY")
            : "N/A"}
        </td>
        <td className="comments"></td>
        <td>
          <Block className="table_actions">
            <Block className="actions_wrapper w-100">
              <Block className="d-flex flex-wrap">
                {getData(row.projectRelevantResultKeyFeatureData)}
              </Block>{" "}
            </Block>
            <Block className="actions_wrapper">
              {userProjectAccess !== ACCESS_VIEWER && (
                <Block
                  className="action-button-bg h-100 d-flex align-items-center"
                  style={{
                    height: "fit-content",
                  }}
                  onClick={() => onHandleModal(row)}
                >
                  <Span className="action-btn cursor-pointer">
                    <Image
                      src={EditBlackIcon}
                      title="Edit"
                      className="icon-size"
                    />
                  </Span>
                </Block>
              )}
              <Block
                className="action-button-bg h-100 d-flex align-items-center"
                style={{
                  height: "fit-content",
                }}
                onClick={() => setCommentingRowID(row.id)}
              >
                <Span className="action-btn cursor-pointer">
                  <Image src={RightBlackIcon} title="Collaboration" />
                </Span>
              </Block>
              {userProjectAccess !== ACCESS_VIEWER && (
                <Block
                  className="action-button-bg h-100 d-flex align-items-center"
                  onClick={() => onHandleRemove(row)}
                >
                  <Span className="action-btn cursor-pointer">
                    <Image
                      src={deleteIcon}
                      title="Delete"
                      className="icon-size"
                    />
                  </Span>
                </Block>
              )}
            </Block>
          </Block>
        </td>
      </tr>
      <SlidingSidePanel
        isOpen={row.id === commentingRowID ? true : false}
        onClose={() => setCommentingRowID(null)}
      >
        <ReportCollaboration
          commentingRowID={commentingRowID}
          onClose={() => setCommentingRowID(null)}
          workspaceId="8"
        />
      </SlidingSidePanel>
    </>
  );
};

export default RelevantResultsTableRow;
