import React from "react";
import { useState } from "react";

import { exportEmailRequired, email, required } from "../../common/validation";
import RWireButton from "../../components/rwire-ui/rwire-button/rwire-button";
import Youtubevideo from "../rwire-login/video-component";

const renderField = ({
  input,
  label,
  type,
  className,
  placeholder,
  meta: { touched, error, warning },
}) => (
  <div>
    {label && <label>{label}</label>}
    <div>
      <div className="input-box">
        <input
          {...input}
          className={className}
          placeholder={placeholder}
          type={type}
        />
      </div>
      {touched &&
        ((error && (
          <div className="text-danger position-absolute">{error}</div>
        )) ||
          (warning && (
            <div className="text-danger position-absolute">{warning}</div>
          )))}
    </div>
  </div>
);

const RwireForgot = (props) => {
  const { onSubmitForgot, onSetForgot, forgotSuccess } = props;
  const [emailId, setEmailID] = useState();
  const handleFormSubmit = (e) => {
    e.preventDefault();
    return onSubmitForgot({ email: e.currentTarget.email.value });
  };

  const emailOnChangeHandler = (e) => {
    setEmailID(e.target.value);
    onSetForgot({ forgotError: "" });
  };

  return (
    <div className="login-page-design forgot-password-page">
      <div className="login-font row g-0 bg-image">
        <div className="d-none d-md-flex col-md-4 col-lg-6">
          <div className="d-flex flex-column justify-content-center left-side-alignment">
            <p className="left-side-value font-weight-bold">
              Innovate with Insight: Your Gateway to
            </p>

            <p className="prop-header font-weight-bold mb-2 ">
              Global Patents and Technological Brilliance
            </p>

            <div>
              <p className="para">
                Embark on an odyssey of intellectual ingenuity through our
                patent database —a global patent gateway igniting innovation.
                Explore a realm where ideas converge, exploring a vast patent
                library's canvas of creation. Illuminate your journey with
                intellectual discovery, powered by our comprehensive repository,
                bridging past, future, and curious minds accessing tomorrow's
                ideas today.
              </p>
            </div>
            <div className="original-img mt-4">
              <Youtubevideo
                src="https://www.youtube.com/embed/gt57IcOl7qY"
                title="YouTube video player"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6 login-main">
          <div class="col-md-12">
            <div className="d-flex align-items-center pb-1_5 login-page-inner">
              <div className="container container-bg">
                <div className="row">
                  <div className="col-md-12 col-lg-11 mx-auto ">
                    <div className="forgot-main">
                      <h1 className="login-heading mb-4 pb-2">
                        Forgot Your Password
                      </h1>

                      <p className="pb-2 link-info">
                        Enter Your Address And We'll Send Link To Reset Your
                        Password
                      </p>
                      <form
                        onSubmit={handleFormSubmit}
                        autoComplete="off"
                        method="post"
                        validate={required}
                        className="label-size pt-2 forgot-password"
                      >
                        <label>Enter Your Email</label>
                        <div className="form-group mb-3">
                          <input
                            name="email"
                            type="email"
                            className="form-control enter-email-forgot"
                            id="floatingInput"
                            placeholder="Enter Your Email"
                            component={renderField}
                            onChange={emailOnChangeHandler}
                            autoComplete="off"
                            validate={[exportEmailRequired, email]}
                          />
                        </div>
                        <div className="d-grid mt-3 reset-group">
                          <RWireButton
                            buttonCName={`button text-black reset-button screen-button ${
                              emailId ? "" : "reset-hide"
                            }`}
                            type="submit"
                            disabled={!emailId}
                          >
                            Reset Password
                          </RWireButton>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
              {forgotSuccess ? (
                <div className="email-link-info">
                  <p>{forgotSuccess}</p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RwireForgot;
