import React from "react";
import Modal from "react-bootstrap/Modal";
import RWireButton from "../../components/rwire-ui/rwire-button/rwire-button";
import { Trans } from "react-i18next";

const CancelAlertModal = (props) => {
  const {
    isOpen,
    id,
    onSetIsOpen,
    onCancelDownloadCenter,
    onFetchDownloadCenterData,
  } = props;

  const handleCancelItem = async () => {
    await onCancelDownloadCenter(id);
    await onFetchDownloadCenterData();
    onSetIsOpen(false);
  };

  const handleClose = () => {
    onSetIsOpen(false);
  };

  return (
    <Modal
      show={isOpen}
      size="md"
      className="timeout-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>
        <div className="modal-content medium-modal space-around-modal-large border-0">
          <h5>
            <Trans>If you cancel generated file will lost.</Trans>
          </h5>
          <div className="d-flex justify-content-end mt-3">
            <RWireButton
              buttonCName="input-button-text-form me-2"
              name="Ok"
              onClick={handleCancelItem}
            />
            <RWireButton
              buttonCName="input-button-text-form"
              name="Cancel"
              onClick={handleClose}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CancelAlertModal;
