import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { RwireReportKeyword } from "../../components/report";
import {
  addRowToKeywordsReportProject,
  deleteRowKeywordsFromReportProject,
  setReportKeyword,
  getReportProjectKeywords,
  updateReportProjectKeywordsRow,
  updateReportProjectKeywordsSortOrder,
} from "../../action/report/report-keyword";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Container = (props) => {
  const navigate = useNavigate();
  const _id = sessionStorage.getItem("projectId");
  useEffect(() => {
    if (_id) {
      if (props.reportTableData.length === 0) {
        props.onGetReportProjectKeywords();
      }
    } else {
      navigate("/en/existing-project");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_id]);
  return <RwireReportKeyword {...props} />;
};

const mapStateToProps = ({ reportKeyword }) => ({
  ...reportKeyword,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onSetReportKeyword: setReportKeyword,
      onUpdateKeywordsSortOrder: updateReportProjectKeywordsSortOrder,
      onGetReportProjectKeywords: getReportProjectKeywords,
      onUpdateReportProjectKeywordsRow: updateReportProjectKeywordsRow,
      onAddRowToreportProject: addRowToKeywordsReportProject,
      onDeleteRowFromReportProject: deleteRowKeywordsFromReportProject,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Container);
