import React, { useState, useEffect } from "react";
import { OpenTable, CloseTable } from "../../../assets/images/report";
import { useStore } from "react-redux";
import { ACCESS_VIEWER } from "./../../../common/user-roles";

const FeaturesFiltersTable = (props) => {
  const {
    reportTableData,
    selectedRows = [],
    onChange,
    onGenerateList,
    setUserInput,
    userInput,
    onCheckAll,
    onClearFilters,
    relevantResultReportTableData,
  } = props;

  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  useEffect(() => {
    setIsSelectAllChecked(
      reportTableData.length > 0 &&
        selectedRows.length === reportTableData.length
    );
  }, [selectedRows, reportTableData]);

  const handleGenerateList = () => {
    if (onGenerateList) {
      onGenerateList();
      setIsFilterApplied(true);
    }
  };
  const nonEmptyRowBodiesCount = reportTableData.filter(
    (row) => row.body && row.body.trim() !== ""
  ).length;

  const isApplyFilterDisabled =
    relevantResultReportTableData.length === 0 &&
    nonEmptyRowBodiesCount > 1 &&
    !isFilterApplied;

  const store = useStore();
  const {
    app: { userProjectAccess },
  } = store.getState();

  return (
    <>
      <div className="relevant-results-filter">
        <div className="table-wrapper">
          <table
            className="responsive-table w-100"
            style={{ position: "sticky", top: "-2px" }}
          >
            <thead className="table-header">
              <tr className="key-features-table-header">
                <th style={{ width: "15%" }} className="key_feature_column ">
                  Key Features
                </th>
                <th style={{ width: "65%" }} className="border-right-0">
                  Definitions
                </th>
                {reportTableData &&
                  reportTableData.length > 0 &&
                  reportTableData.every((row) => row.body.length === 0) ===
                    false && (
                    <th
                      className={`text-right select-checkbox border-right-0 pl-0${
                        selectedRows.length > 0 ? "checked pl-0" : ""
                      }`}
                      style={{ width: "10%" }}
                    >
                      <span className="mr-1 m-left"> Select All </span>
                      <input
                        type="checkbox"
                        className="form-check-input ml-1"
                        onChange={onCheckAll}
                        checked={isSelectAllChecked}
                      />
                    </th>
                  )}
                <th
                  className=""
                  style={{ width: "10%" }}
                  onClick={() =>
                    setUserInput({
                      ...userInput,
                      isShowFilter: !userInput.isShowFilter,
                    })
                  }
                >
                  {userInput.isShowFilter ? (
                    <img
                      src={OpenTable}
                      className="mr-2 float-end"
                      style={{ height: "22.5px" }}
                      alt=""
                    />
                  ) : (
                    <img
                      src={CloseTable}
                      className="mr-2 float-end"
                      style={{ height: "22.5px" }}
                      alt=""
                    />
                  )}
                </th>
              </tr>
            </thead>
          </table>
          <div
            className={`filter-table-wrapper ${
              userInput.isShowFilter ? "open" : ""
            }`}
          >
            <table className="responsive-table w-100">
              <tbody>
                {reportTableData &&
                reportTableData.length > 0 &&
                reportTableData.every((row) => row.body.length === 0) ? (
                  <tr>
                    <td className="text-center">
                      {" "}
                      Please add the key features
                    </td>
                  </tr>
                ) : (
                  reportTableData
                    .filter((i) => i.body)
                    .map((row, index) => (
                      <tr
                        key={index}
                        className={`${
                          index % 2 === 0 ? "even-row" : "odd-row"
                        }`}
                      >
                        <td
                          style={{ width: "15%" }}
                          className="key_feature_column"
                        >
                          Key Feature {index + 1}
                        </td>
                        <td
                          style={{ width: "65%" }}
                          className="border-right-0"
                          dangerouslySetInnerHTML={{ __html: row.body }}
                        ></td>
                        <td
                          style={{ width: "10%" }}
                          className={`text-end select-checkbox border-right-0 ${
                            selectedRows.includes(row.id) ? "checked" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            checked={selectedRows.includes(row.id)}
                            onChange={(e) => onChange(e, row.id)}
                            className="form-check-input key_feature_ckbox"
                          />
                        </td>
                        <td style={{ width: "10%" }}></td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {nonEmptyRowBodiesCount > 0 ||
      relevantResultReportTableData.length > 0 ? (
        <div className="action-button-section mt-3 border-bottom">
          <div
            className="generate-list-btn ms-auto float-right"
            onClick={onClearFilters}
          >
            Clear Filters
          </div>
          <div
            className={`generate-list-btn float-right ${
              userProjectAccess == ACCESS_VIEWER || isApplyFilterDisabled
                ? "disabled"
                : ""
            }`}
            onClick={
              reportTableData.every((row) => !row.body)
                ? null
                : handleGenerateList
            }
          >
            Apply Filters
          </div>
        </div>
      ) : null}
    </>
  );
};

export default FeaturesFiltersTable;
