import React from "react";
import "./style.scss";
import RWIRE_IMAGES from "../../components/common/common-functions/rwire-images";

const ContactUs = () => (
  <div className="last_section d-flex">
    <div className="last_left_section py-4 px-4">
      <p className="left_section_heading">Contact Us</p>
      <p className="left_section_para">
        Ready to explore the full potential of Researchwire’s services? Contact
        us today at <b>[mailto:query@researchwire.in]</b>
      </p>
      <p className="left_section_second_para">
        <b>(mailto: query@researchwire.in)</b> or call us at (+91)
        22 – 4122 5210 to learn more about how we can support your patent research and
        innovation efforts. Connect with us on social media for the latest
        updates and insights
      </p>
    </div>

    <div className="last_right_section px-5 py-5">
      <p className="right_section_heading">Do You Have Any Questions?</p>
      <p className="right_section_heading mb-3">Get Help From Us</p>
      <div className="d-flex">
        <div className="d-flex">
          <img
            className="support_img mr-1 chat_live_text"
            alt=""
            src={RWIRE_IMAGES.RwireLastpartIcon}
          />
          <p className="mr-1 support_text">Chat live with our support team</p>
        </div>
        <div className="d-flex">
          <img
            className="support_img mr-1 browse_text"
            alt=""
            src={RWIRE_IMAGES.RwireLastpartIcon}
          />
          <p className="support_text">Browse our FAQ</p>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-2">
        <input
          className="last_section_input px-2 py-2 mr-3"
          placeholder="Enter Your Email Address"
        />
        <div className="last_section_submit">
          <p className="last_btn_text">Submit</p>
        </div>
      </div>
    </div>
  </div>
);

export default ContactUs;
