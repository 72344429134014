const initialState = {
  rowComments: [],
};

const collab = (state = initialState, { type, payload }) => {
  switch (type) {
    case "RESET_COLLAB":
      return { ...initialState };

    case "COLLAB_SET":
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export default collab;
