const StatusLabel = (props) => {
  const { status } = props;
  let finalStatus = "In Queue";

  if (status === "Cancelled") {
    finalStatus = "Cancelled";
  } else if (status === "Done") {
    finalStatus = "Available";
  } else if (status === "Failed") {
    finalStatus = "Failed";
  }

  return <div className="mr-3">{finalStatus}</div>;
};

export default StatusLabel;
